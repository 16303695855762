var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-ir-agm-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-ir-agm-page__top" },
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 10 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { level: 4, noMarginTop: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 10, offsetMd: 4 } },
                [
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Intro",
                        expression: "'Intro'",
                      },
                    ],
                    attrs: { items: _vm.model.intro },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FileListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "FileListBlock",
                    expression: "'FileListBlock'",
                  },
                ],
                staticClass: "o-bg o-bg--beige-light c-ir-agm-page__files",
              },
              "FileListBlock",
              _vm.model.fileListBlock.content,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }