<!-- TODO: Fix form -->
<template>
<main class="c-software-partners-overview-page">
    <Container>
        <Row class="c-software-partners-overview-page__top">
            <Column :md="14" :offsetMd="4">
                <Heading noMarginTop :level="1" v-epi-edit="'Title'">{{model.title}}</Heading>
                <Heading :level="4" v-epi-edit="'IntroText'"><XhtmlField :items="model.introText"/></Heading>
            </Column>
        </Row>
        <Row class="c-software-partners-overview-page__partners">
            <Column :md="20" :offsetMd="2">
                <SoftwarePartnerList :partners="model.partners"/>
            </Column>
        </Row>
    </Container>
    <Container isFluid background="beige-light" marginHack>
        <Container>
            <FormBlock v-epi-edit="'Form'" id="form" class="c-service-page__form" v-if="notEmptyObject(model.form && model.form.content) || isEditable" v-bind="model.form.content"/>
        </Container>
    </Container>
</main>
</template>

<script>
import SoftwarePartnerList from '@/Scripts/components/organisms/SoftwarePartnerList.vue';

export default {
    props: {
        model: Object
    },
    components: {
        SoftwarePartnerList
    }
};
</script>

<style lang="scss">
.c-software-partners-overview-page {
    &__top {
        text-align: center;
        margin-bottom: 4rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
    &__partners {
         margin-bottom: 4rem;

        @include media-md {
            margin-bottom: 7rem;
        }
    }
}
</style>
