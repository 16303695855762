<template>
<Row class="c-person-list">
    <Column v-for="(person, index) in people" :key="`person_${_uid}_${index}`" :md="10" :offsetMd="index % 2 === 0 ? 0 : 4">
        <Person v-bind="person" class="c-person-list__item"/>
    </Column>
</Row>
</template>

<script>
import Person from '@/Scripts/components/molecules/Person.vue';

export default {
    props: {
        people: Array
    },
    components: {
        Person
    }
};
</script>

<style lang="scss">
.c-person-list {
    margin-bottom: -3rem;

    @include media-md {
        margin-bottom: -5rem;
    }

    &__item {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
