var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-employees-block" },
    [
      _c(
        "Heading",
        { staticClass: "u-add-colored-period", attrs: { noMarginTop: "" } },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c("HorizontalSlider", {
        staticClass: "u-display-md-up",
        attrs: {
          items: _vm.employees,
          scroll: 1,
          noOfItemsToShow: 2,
          padding: 100,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c(
                  "Row",
                  { staticClass: "c-employees-block__employee" },
                  [
                    _c(
                      "Column",
                      { attrs: { md: 12 } },
                      [
                        _c("ResponsiveImage", {
                          attrs: {
                            src: item.image,
                            xs: { h: 150, w: 150 },
                            sm: { h: 150, w: 150 },
                            md: { h: 256, w: 256 },
                            lg: { h: 304, w: 304 },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "u-block c-employees-block__employee__name",
                          },
                          [_vm._v(_vm._s(item.employeeName))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "u-block c-employees-block__employee__jobtitle",
                          },
                          [_vm._v(_vm._s(item.jobTitle))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("Column", { attrs: { md: 12 } }, [
                      _c(
                        "p",
                        {
                          staticClass: "c-employees-block__employee__question",
                        },
                        [_vm._v(_vm._s(item.question))]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "c-employees-block__employee__answer" },
                        [_vm._v(_vm._s(item.answer))]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "c-employees-block__dot" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "c-employees-block__dot" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "u-block c-employees-block__employee__quote",
                        },
                        [_vm._v(_vm._s(item.quote))]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("HorizontalSlider", {
        staticClass: "u-display-sm u-display-xs",
        attrs: { items: _vm.employees, scroll: 1, noOfItemsToShow: 1 },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c(
                  "Row",
                  { staticClass: "c-employees-block__employee" },
                  [
                    _c(
                      "Column",
                      { attrs: { sm: 6 } },
                      [
                        _c("ResponsiveImage", {
                          attrs: {
                            src: item.image,
                            xs: { h: 150, w: 150 },
                            sm: { h: 150, w: 150 },
                            md: { h: 210, w: 210 },
                            lg: { h: 210, w: 210 },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "u-block c-employees-block__employee__name",
                          },
                          [_vm._v(_vm._s(item.employeeName))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "u-block c-employees-block__employee__jobtitle",
                          },
                          [_vm._v(_vm._s(item.jobTitle))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("Column", { attrs: { sm: 15, offsetSm: 3 } }, [
                      _c(
                        "p",
                        {
                          staticClass: "c-employees-block__employee__question",
                        },
                        [_vm._v(_vm._s(item.question))]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "c-employees-block__employee__answer" },
                        [_vm._v(_vm._s(item.answer))]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "c-employees-block__dot" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "c-employees-block__dot" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "u-block c-employees-block__employee__quote",
                        },
                        [_vm._v(_vm._s(item.quote))]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }