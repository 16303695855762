var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-single-teaser-block" },
    [
      _c(
        "Column",
        {
          staticClass: "c-single-teaser-block__left",
          class: { "o-bg": _vm.notEmptyObject(_vm.extraTeaser) },
          attrs: { md: 6, sm: 8 },
        },
        [
          _vm.notEmptyObject(_vm.extraTeaser)
            ? _c(
                "Row",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: _vm.leftEdit,
                      expression: "leftEdit",
                    },
                  ],
                },
                [
                  _c(
                    "Column",
                    {
                      staticClass: "c-single-teaser-block__text-wrapper",
                      attrs: { md: 24 },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("Heading", { attrs: { isCategory: "" } }, [
                            _vm._v(_vm._s(_vm.extraTeaser.label)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "Heading",
                            {
                              staticClass: "u-no-margin-top",
                              attrs: { level: 4 },
                            },
                            [_vm._v(_vm._s(_vm.extraTeaser.title))]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "o-large-body" }, [
                            _vm._v(_vm._s(_vm.extraTeaser.intro)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "Anchor",
                            {
                              attrs: {
                                isFancy: "",
                                href: _vm.extraTeaser.link,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.extraTeaser.linkText
                                    ? _vm.extraTeaser.linkText
                                    : _vm.getLabel("global", "readMore")
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "BreakoutColumn",
        {
          staticClass:
            "o-bg o-bg--right o-bg--beige-light c-single-teaser-block__right",
          attrs: {
            md: 18,
            sm: _vm.notEmptyObject(_vm.extraTeaser) ? 16 : 24,
            breakoutSide: "right",
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: _vm.rightEdit,
                      expression: "rightEdit",
                    },
                  ],
                  staticClass: "c-single-teaser-block__text-wrapper",
                  attrs: {
                    md: 12,
                    offsetSm: _vm.notEmptyObject(_vm.extraTeaser) ? 3 : 2,
                    offsetMd: 0,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "Heading",
                        {
                          staticClass: "c-single-teaser-block__right__label",
                          attrs: { isCategory: "" },
                        },
                        [_vm._v(_vm._s(_vm.label))]
                      ),
                      _vm._v(" "),
                      _c(
                        "Heading",
                        { staticClass: "u-no-margin-top", attrs: { level: 3 } },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "o-ingress" }, [
                        _vm._v(_vm._s(_vm.intro)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("Anchor", { attrs: { isFancy: "", href: _vm.link } }, [
                        _vm._v(
                          _vm._s(
                            _vm.linkText
                              ? _vm.linkText
                              : _vm.getLabel("global", "readMore")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "Column",
                {
                  staticClass: "c-single-teaser-block__image-wrapper",
                  attrs: {
                    md: 12,
                    offsetSm: _vm.notEmptyObject(_vm.extraTeaser) ? 3 : 2,
                    offsetMd: 0,
                  },
                },
                [
                  _c(
                    "ResponsiveImage",
                    _vm._b(
                      { attrs: { src: _vm.image } },
                      "ResponsiveImage",
                      _vm.imageConfig,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }