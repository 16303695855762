var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-shareholders" },
    [
      _c(
        "div",
        { staticClass: "c-shareholders-heading" },
        [
          _c("Heading", { attrs: { level: 5 } }, [
            _vm._v(_vm._s("Top Shareholders")),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.shareholders.entries, function (shareholder, index) {
        return _c(
          "div",
          { key: index },
          [
            shareholder.topPositionShares !== 0
              ? _c("ShareholderItem", {
                  attrs: {
                    shareholder: shareholder,
                    totalShares: _vm.shareholders.totalShares,
                    totalSharesTopTwenty: _vm.getTotalSharesOfTopTwenty,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("ShareholderItem", {
            staticClass: "c-shareholders-sum",
            attrs: {
              shareholder: _vm.totalTopTwenty,
              totalShares: _vm.shareholders.totalShares,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("ShareholderItem", {
            attrs: {
              shareholder: _vm.other,
              totalShares: _vm.shareholders.totalShares,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("ShareholderItem", {
            staticClass: "c-shareholders-sum",
            attrs: {
              shareholder: _vm.total,
              totalShares: _vm.shareholders.totalShares,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-shareholders-update-label" }, [
        _vm._v(
          "\r\n        Updated " +
            _vm._s(_vm.shareholders.updateTime) +
            "\r\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-shareholders-header" }, [
      _c("div", [_vm._v("Investor")]),
      _vm._v(" "),
      _c("div", { staticClass: "c-shareholders-header-label" }, [
        _vm._v("Number of shares"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "u-hidden-xs c-shareholders-header-label" }, [
        _vm._v("% of top 20"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "u-hidden-xs c-shareholders-header-label" }, [
        _vm._v("% of total"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "u-hidden-xs c-shareholders-header-label" }, [
        _vm._v("Type"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "u-hidden-xs c-shareholders-header-label" }, [
        _vm._v("Country"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }