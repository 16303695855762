var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalShowing
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container" }, [
              _c(
                "div",
                { staticClass: "modal-content" },
                [
                  _vm._t("content", function () {
                    return [
                      _vm._v(
                        "\n                        default content\n                    "
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer", function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "Button modal-default-button",
                          on: {
                            click: function ($event) {
                              return _vm.closeModal()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            OK\n                        "
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }