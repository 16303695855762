<!--
To use the dropdown send in the options and bind a variable with v-model like this:

<Dropdown v-model="selected" :options="[{label: 'Canada', code: 'ca'}, {label: 'Norway', code: 'no'}]"/>

The variable 'selected' will update automatically. This variable needs to be a local variable, not a prop.


The component is a wrapper around vue-select (https://vue-select.org/). Vue Select supports a lot of features.
This Dropdown component can be extended to support more functionality.
-->

<template>
<div :class="{'vs--multiple' : multiple}">
    <v-select v-bind="$props" @input="updateSelected" v-model="localValue">
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                <!-- TODO: Translate-->
                Ingen resultater <em>{{ search }}</em>.
            </template>
        </template>
    </v-select>
</div>
</template>

<script>
import vSelect from 'vue-select';

export default {
    props: {
        value: {},
        options: {
            type: Array,
            default: [] // eslint-disable-line
        },
        disabled: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        /**
         * Enables multiple selections.
         */
        multiple: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        /**
         * Placeholder in the inputfield.
         */
        placeholder: {
            type: String,
            default: '' // eslint-disable-line
        },
        /**
         * Enables the user to search in the input field.
         */
        searchable: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        /**
         * Hides/shows 'X' to clear input-field
         */
        clearable: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        /**
         * Tells vue-select what key to use when generating option labels when each option is an object.
         */
        label: {
            type: String,
            default: "label" // eslint-disable-line
        },
    },
    /* data: function () {
        return {
            localValue: this.value
        };
    }, */
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(newName) {
                return newName;
            }
        }
    },
    methods: {
        updateSelected: function (value) {
            this.localValue = value;
            this.$emit('input', value);
        }
    },
    components: {
        vSelect
    }
};
</script>

<!-- Styling inside styles folder. Must be there because we're overwriting default values in sass. -->
