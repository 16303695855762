<template>
<div class="c-shareholder-item">
    <div>{{shareholder.topInvestor}}</div>
    <div>{{numberWithCommas(shareholder.topPositionShares)}}</div>
    <div class="u-hidden-xs">{{getPercentageTopTwenty()}}</div>
    <div class="u-hidden-xs">{{getPercentage()}}</div>
    <div class="u-hidden-xs">{{shareholder.topInvestorAccountType}}</div>
    <div class="u-hidden-xs">{{shareholder.topInvestorCountry}}</div>
</div>
</template>

<script>

export default {
    props: {
        shareholder: {
            type: Object,
        },
        totalShares: {
            type: Number,
        },
        totalSharesTopTwenty: {

        }
    },
    methods: {
        getPercentage() {
            const percentagenr = ((this.shareholder.topPositionShares / this.totalShares) * 100).toFixed(2);
            const percentagestring = percentagenr + '%';
            return percentagestring;
        },
        getPercentageTopTwenty() {
            const percentagenr = ((this.shareholder.topPositionShares / this.totalSharesTopTwenty) * 100).toFixed(2);
            if (isNaN(percentagenr)) {
                return;
            }
            const percentagestring = percentagenr + '%';
            return percentagestring;
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
};
</script>

<style lang="scss">
.c-shareholder-item{
 display: flex;
 flex-direction: row;
 align-items: center;
 padding: 12px;
 border-bottom: 1px solid black;
}
.c-shareholder-item > div{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    &-number{
        width: 100%;
        display: flex;
        align-items: center;
        text-align: left;
        font-size: 1.2em;
    }
}
  .c-shareholder-item > div:first-child{
        width: 250%;
    }
</style>
