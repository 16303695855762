var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-two-link-block" },
    [
      _c(
        "Column",
        {
          staticClass: "c-two-link-block__item",
          attrs: { sm: 12, offsetMd: 3, md: 8 },
        },
        [
          _c(
            "Anchor",
            { attrs: { href: _vm.url1.uri, target: "_blank" } },
            [
              _c("ResponsiveImage", {
                attrs: {
                  src: _vm.image1,
                  xs: { w: 345, h: 139 },
                  sm: { w: 345, h: 139 },
                  md: { w: 440, h: 190 },
                  lg: { w: 440, h: 190 },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        {
          staticClass: "c-two-link-block__item",
          attrs: { sm: 12, offsetMd: 2, md: 8 },
        },
        [
          _c(
            "Anchor",
            { attrs: { href: _vm.url2.uri, target: "_blank" } },
            [
              _c("ResponsiveImage", {
                attrs: {
                  src: _vm.image2,
                  xs: { w: 345, h: 139 },
                  sm: { w: 345, h: 139 },
                  md: { w: 440, h: 190 },
                  lg: { w: 440, h: 190 },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }