var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notEmptyObject(_vm.siblings)
    ? _c(
        "Container",
        { staticClass: "c-sibling-navigation", attrs: { isFluid: "" } },
        [
          _c(
            "Container",
            {
              staticClass:
                "u-hidden-xs u-hidden-sm c-sibling-navigation__wrapper",
            },
            _vm._l(_vm.siblings, function (item, index) {
              return _c(
                "span",
                {
                  key: `${_vm._uid}${index}`,
                  staticClass: "c-sibling-navigation__service",
                },
                [
                  _c("Anchor", { attrs: { href: item.url } }, [
                    _vm._v(_vm._s(_vm.getName(item))),
                  ]),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("Dropdown", {
            staticClass: "u-display-xs u-display-sm",
            attrs: { options: _vm.dropdownValues, clearable: false },
            on: { input: _vm.changeRoute },
            model: {
              value: _vm.dropdownSelection,
              callback: function ($$v) {
                _vm.dropdownSelection = $$v
              },
              expression: "dropdownSelection",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }