var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-some-share-block",
      class: { "c-some-share-block--no-border": _vm.noBorder },
    },
    [
      _c(
        "Heading",
        { staticClass: "c-some-share-block__title", attrs: { isCategory: "" } },
        [_vm._v(_vm._s(_vm.getLabel("soMeBlock", "share")))]
      ),
      _vm._v(" "),
      _c("Icon", {
        staticClass: "c-some-share-block__icon",
        attrs: { icon: "twitter", url: _vm.twitterLink, target: "_blank" },
      }),
      _vm._v(" "),
      _c("Icon", {
        staticClass: "c-some-share-block__icon",
        attrs: { icon: "facebook", url: _vm.facebookLink, target: "_blank" },
      }),
      _vm._v(" "),
      _c("Icon", {
        staticClass: "c-some-share-block__icon",
        attrs: { icon: "linkedin", url: _vm.linkedinLink, target: "_blank" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }