var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-form-block" },
    [
      _c(
        "Column",
        { attrs: { md: 10 } },
        [
          _c("Heading", { attrs: { noMarginTop: "" } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        { attrs: { md: 8, offsetMd: 4 } },
        [_c(_vm.dynamicComponent, { tag: "component" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }