<template>
<div class="c-share-details">
    <table class="c-share-details-table">
        <tr class="c-share-details-table-row">
            <th>Ticker</th>
            <th>Change (NOK)</th>
            <th>Change (%)</th>
            <th>Bid</th>
            <th>Ask</th>
            <th>High</th>
            <th>Low</th>
            <th>Market Cap</th>
            <th>Currency</th>
        </tr>
        <tr class="c-share-details-table-row">
            <td class="c-share-details-table-data">{{snapShot.item}}</td>
            <td class="c-share-details-table-data">{{decimalFormatter(snapShot.change, 2)}}</td>
            <td class="c-share-details-table-data">{{decimalFormatter(snapShot.changePct, 2)}}</td>
            <td class="c-share-details-table-data">{{snapShot.bid}}</td>
            <td class="c-share-details-table-data">{{snapShot.ask}}</td>
            <td class="c-share-details-table-data">{{snapShot.high}}</td>
            <td class="c-share-details-table-data">{{snapShot.low}}</td>
            <td class="c-share-details-table-data">{{nFormatter(snapShot.marketCap, 2)}}</td>
            <td class="c-share-details-table-data">{{snapShot.currency}}</td>
        </tr>
    </table>
    <div class="c-share-details-table-mobile u-display-xs">
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Ticker</div>
            <div>{{snapShot.item}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Change (NOK)</div>
            <div>{{decimalFormatter(snapShot.change, 2)}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Change (%)</div>
            <div>{{decimalFormatter(snapShot.changePct, 2)}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Bid</div>
            <div>{{snapShot.bid}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Ask</div>
            <div>{{snapShot.ask}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">High</div>
            <div>{{snapShot.high}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Low</div>
            <div>{{snapShot.low}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Market Cap</div>
            <div>{{nFormatter(snapShot.marketCap, 2)}}</div>
        </div>
        <div class="c-share-details-table-mobile-item">
            <div class="c-share-details-table-mobile-item-label">Currency</div>
            <div>{{snapShot.currency}}</div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    props: {
        snapShot: {
            type: Object
        }
    },
    methods: {
        nFormatter(num, digits) {
            const lookup = [
                { value: 1, symbol: '' },
                { value: 1e6, symbol: ' M' },
                { value: 1e9, symbol: ' B' },
            ];
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            var item = lookup.slice().reverse().find((item) => {
                return num >= item.value;
            });
            return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
        }
    }
};
</script>

<style lang="scss">


.c-share-details-table {
    text-align: left;
    width:100%;
    display: none;

    @media (min-width: $screen-sm-min) {
        display: table;
    }
    }
.c-share-details-table-row {
   border-bottom: 1px solid black;
   height: 3em;
    }
.c-share-details-table-data {
    padding:0;
    width: 10%;
}
.c-share-details-table-mobile-item{
    display:flex;
    justify-content: space-between;
    padding: 1em 0 1em 0;
    border-bottom: 1px solid black;
    &-label{
        font-weight: 600;
    }
}
</style>
