<template>
<div class="c-infoWithHighlights-block" :style="style" >
    <div class="c-infoWithHighlights-block__top" >
        <Heading  class="c-infoWithHighlights-block__title u-block" noMarginBottom noMarginTop :level="2">{{heading}}</Heading>
        <span class="c-infoWithHighlights-block__title u-block o-ingress" noMarginTop :level="4" >{{subHeading}}</span>
    </div>

    <Row class="c-infoWithHighlights-block__bottom" >
        <Column :sm="12" :md="6" class="c-infoWithHighlights-block__item" v-for="(column, index) in columnData" :key="index">
            <div v-if="index > 0" class="c-infoWithHighlights-block__borderLeft"></div>
            <div v-if="column.image" class="c-infoWithHighlights-block__imageWrapper">
                <div class="c-infoWithHighlights-block__imageBackground">
                    <ResponsiveImage v-bind="imageConfig" :src="column.image && column.image" class="c-infoWithHighlights-block__image"/>
                </div>
            </div>
            <div class="c-infoWithHighlights-block__subHeader" align="center">
                {{column.title}}
            </div>
            <XhtmlField class="c-infoWithHighlights-block__textField" :items="column.text"/>
            <div class="c-infoWithHighlights-block__smallButtonContainer">
            <Button class ="c-infoWithHighlights-block__smallbutton" v-if="column.buttonUrl" :href="column.buttonUrl"> {{column.buttonText}}</Button>
            </div>
        </Column>
        <Column align="center" class="c-infoWithHighlights-block__buttoncontainer">
            <Button v-if="bottomButtonUrl && bottomButtonText" class="c-infoWithHighlights-block__button" :href="bottomButtonUrl"> {{bottomButtonText}} </Button>
        </Column>
    </Row>
</div>
</template>

<script>
import Heading from '@/Scripts/components/atoms/Heading.vue';
import Row from '@/Scripts/components/grid/Row.vue';
import Column from '@/Scripts/components/grid/Column.vue';
import XhtmlField from '../atoms/XhtmlField.vue';
import Button from '@/Scripts/components/atoms/Button.vue';
export default {
    props: {
        columnData: Array,
        bottomButtonUrl: String,
        bottomButtonText: String,
        heading: String,
        subHeading: String,
        backgroundColor: String,
        textColor: String,
        color: Object,
        color2: Object
    },

    components: {
        Heading,
        Row,
        Column,
        Button,
        XhtmlField
    },
    computed: {
        style() {
            return {
                backgroundColor: this.color2 ? this.color2.value : this.backgroundColor,
                color: this.color ? this.color.value : this.textColor
            };
        }
    },
    data() {
        return {
            imageConfig: {
                xs: {w: 217, h: 217},
                sm: {w: 185, h: 185},
                md: {w: 148, h: 148},
                lg: {w: 194, h: 194}
            }
        };
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_breakpoints.scss';

.c-infoWithHighlights-block {
        padding-left: 1rem;
        padding-right: 1rem;
    &__title {
        text-align: center;
    }
    &__subHeader{
        font-size: 30px;
        font-style: bold;
    }
    &__imageWrapper {
            padding: 4rem 5rem  3rem 5rem;
        }
    &__imageBackground {
        background-color: $beige-light;
        padding:1rem 1rem 0.7rem 1rem;
        border-radius: 50%;
    }
    &__item {
        position: relative;
        display:flex;
        flex-direction: column;
        margin-bottom: 3rem;
        text-align: center;
    }
    &__textField {
        padding: 0 0.5rem 0 0.5rem;
    }
    &__borderLeft {
        @include media-md {
            border-left: 1px solid $gray;
            position: absolute;
            top:10%;
            bottom:0;
            }

    }
    &__buttoncontainer {
        padding: 0 0 2rem 0;

    }
    &__button {
        text-align: center;
        display: inline-block;
    }
    &__smallButtonContainer {
        margin-top:auto;
    }
}
</style>
