<template>
  <div class="c-horizontal-slider" ref="container">
    <div class="c-horizontal-slider__nav" v-if="!autoScroll">
      <div @click="prev" v-if="_hasPrev"
           class="c-horizontal-slider__nav__left">
        <svg width="32px" height="32px" viewBox="0 0 24 24">
          <path d="M10.757 12l4.95 4.95a1 1 0 1 1-1.414 1.414l-5.657-5.657a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 0 1 1.414 1.414L10.757 12z"/>
        </svg>
      </div>
      <div @click="next" v-if="_hasNext"
           class="c-horizontal-slider__nav__right">
        <svg width="32px" height="32px" viewBox="0 0 24 24">
          <path d="M13.314 12.071l-4.95-4.95a1 1 0 0 1 1.414-1.414l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l4.95-4.95z"/>
        </svg>
      </div>
    </div>

    <div class="vhl-container__container" >
      <div class="c-horizontal-slider__list" ref="list">
        <div class="c-horizontal-slider__track" :style="_trackStyle">
            <div v-for="(item, index) in allItems" ref="item" class="c-horizontal-slider__item" :style="[_itemStyle, _itemStyleOpacity(index)]" :key="`${_uid}_${index}`">
              <slot v-bind:item="item">{{item}}</slot>
            </div>
        </div>

        <div>
        </div>
      </div>
    </div>

     <div v-if="autoScroll && items.length > 1" class="c-horizontal-slider__dot-wrapper">
          <div v-for="(i, index) in items.length" :key="`slider_dot_${i}`">
              <span class="c-horizontal-slider__dot" :class="{'c-horizontal-slider__dot--active' : isActive(index)}"></span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        options: {
            type: Object,
            required: false
        },
        // Number of items to scroll through on click.
        scroll: {
            default: 1, // eslint-disable-line
            type: Number
        },
        noOfItemsToShow: {
            type: Number,
            default: 2.5 // eslint-disable-line
        },
        autoScroll: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        autoScrollInterval: {
            type: Number,
            default: 5000 // eslint-disable-line
        },
        transitionSpeed: {
            type: Number,
            default: 500 // eslint-disable-line
        },
        padding: {
            type: Number,
            default: 24 // eslint-disable-line
        }
    },
    data() {
        return {
            /**
             * See update hook for why this exists. Do not touch.
             */
            resizeHack: false,
            /**
             * Current item position of list
             */
            position: 0,
            /**
             * Width of ist and window
             */
            width: {
                container: 0,
                window: 576
            },
            scrollInterval: null,
            allItems: []
        };
    },
    mounted() {
        this.$resize = () => {
            this.width.window = window.innerWidth;
            this.width.container = this.$refs.container.clientWidth;
        };
        // Added a simple SSR fix, need to look into it for optimization in the future
        //require('smoothscroll-polyfill').polyfill();
        this.$resize();

        window.addEventListener('resize', this.$resize);

        if (this.autoScroll && this.items.length > 1) {
            // Get all items and duplicate needed for autoscroll
            this.allItems = [...this.items, ...this.items.slice(0, this.noOfItemsToShow)];
            this.scrollInterval = setInterval(() => {
                if (this.position === 0) {
                    setTimeout(() => {
                        // console.log('timeout');
                        this.prev();
                    }, this.autoScrollInterval * this.items.length - this.autoScrollInterval + this.transitionSpeed);
                }
                if (this._hasNext) {
                    this.next();
                }
                /* else {
                    this.prev();
                } */
            }, this.autoScrollInterval);
        } else {
            // Dont duplicate when no autoscroll
            this.allItems = [...this.items];
        }
    },
    updated() {
        // If used inside BreakoutColumn size will be wrong. This is a hack to fix it.
        if (!this.resizeHack) {
            this.$resize();
            this.resizeHack = true;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.$resize);
        if (this.autoScroll && this.items.length > 1) {
            clearInterval(this.scrollInterval);
        }
    },
    computed: {
        _trackStyle() {
            return {
                transform: `translate3d(-${this._itemWidthWithPadding * this.position}px,0,0)`,
                transition: this.position === 0 ? null : `transform ${this.transitionSpeed}ms ease`
            };
        },
        _itemStyle() {
            return {
                width: this._itemWidthWithPadding + 'px',
                paddingRight: this.padding + 'px'
            };
        },
        _workingWidth() {

            return this.width.container;

        },
        _itemWidth() {
            return (this.width.container / this.noOfItemsToShow);
        },
        _itemWidthWithPadding() {
            return ((this.width.container - this.padding * (Math.ceil(this.noOfItemsToShow) - 1)) / this.noOfItemsToShow) + this.padding;
        },
        _hasNext() {
            return this.allItems.length - this.noOfItemsToShow > this.position;

            //return this.position < this._workingWidth;
        },
        _hasPrev() {
            return this.position > 0;
        },
    },
    methods: {
        isActive(index) {
            if (index === this.position) {
                return true;
            } else if (this.position > this.items.length - 1  && index === 0) {
                return true;
            } else {
                return false;
            }
        },
        /**
       * @param position of item to scroll to
       */
        go(position) {
            this.position = position;
            /*this.$refs.list.scrollTo({top: 0, left: this._itemWidthWithPadding * position, behavior: 'smooth'}); */
        },
        /**
       * Go to a set of previous items
       */
        prev() {
            this.go(0);
        },
        /**
       * Go to a set of next items
       */
        next() {
            this.go(this.position + this.scroll);

        },
        _itemStyleOpacity(index) {
            // If number contains decimal add opacity class
            if (this.noOfItemsToShow % 1 !== 0) {
                if (index === this.position + Math.ceil(this.noOfItemsToShow) - 1) {
                    return {
                        opacity: 0.3
                    };
                }
            }
        },
    }
};
</script>
<style lang="scss" scoped>
    svg {
        fill: white;
    }
    .c-horizontal-slider {
        position: relative;

        &__dot-wrapper {
            display: flex;
            justify-content: center;
        }
        &__dot {
            height: 15px;
            width: 15px;
            background-color: lighten($gray, 20);
            border-radius: 50%;
            display: inline-block;
            margin-right: .5rem;

            &--active {
                background-color: $gray;
            }
        }
        &__item {
            flex: 1 0 auto;
        }
        &__list {
            position: relative;
            display: block;
            overflow: hidden;
            margin: 0;
            padding: 0;
        }
        &__track {
            display: flex;
        }
        &__track, &__list {
            transform: translate3d(0, 0, 0);
        }
        .c-horizontal-slider__nav {
            display: flex;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .c-horizontal-slider__nav__left, .c-horizontal-slider__nav__right {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            background: $orange;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            z-index: 2;
        }
        .c-horizontal-slider__nav__left:hover, .c-horizontal-slider__nav__right:hover {
            cursor: pointer;
        }
        .c-horizontal-slider__nav__left {
            margin-left: 3rem;
            margin-right: auto;
        }
        .c-horizontal-slider__nav__right {
            margin-left: auto;
            margin-right: 3rem;
        }
    }
</style>
