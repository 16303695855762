<template>
<div class="c-trade-item">
    <div class="c-trade-item-left">{{formatTime(new Date(trade.time))}}</div>
    <div class="c-trade-item-right">{{trade.price.toFixed(2)}}</div>
    <div class="c-trade-item-right">{{trade.volume}}</div>
</div>
</template>

<script>

export default {
    props: {
        trade: {
            type: Object,
        },
    },
    methods: {
        formatTime(date) {
            var timeString = date.getHours() + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);
            return timeString;
        }
    }
};
</script>

<style lang="scss">
.c-trade-item{
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 padding: 1em;
 border-bottom: 1px solid black;
 &-right{
     width: 100%;
     text-align: right;
 }
  &-left{
    width: 100%;
    text-align: left;
 }
}
</style>
