var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.src
    ? _c("div", [
        _c("iframe", {
          attrs: {
            name: _vm.name,
            id: _vm.name,
            src: _vm.src,
            height: `${_vm._height}px`,
            frameborder: "0",
            scrolling: "no",
          },
          on: { load: _vm.postMessage },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }