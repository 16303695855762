<template>
<div class="c-analyst-item">
    <div class="u-hidden-sm u-hidden-xs">{{analyst.company}}</div>
    <div class="u-hidden-sm u-hidden-xs">{{analyst.name}}</div>
    <div class="u-hidden-sm u-hidden-xs"><a :href="'tel:'+analyst.phone">{{analyst.phone}}</a></div>
    <div class="u-hidden-sm u-hidden-xs"><a :href="'mailto:'+analyst.email">{{analyst.email}}</a></div>
    <div class="u-display-sm-down c-analyst-item-mobile">
        <p class="">{{analyst.company}}</p>
        <p>{{analyst.name}}</p>
        <p><a :href="'tel:'+analyst.phone">{{analyst.phone}}</a></p>
        <p><a :href="'mailto:'+analyst.email">{{analyst.email}}</a></p>
    </div>
</div>
</template>

<script>

export default {
    props: {
        analyst: {
            type: Object,
        }
    }
};
</script>

<style lang="scss">
.c-analyst-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid black;
    > div{
        display: flex;
        align-items: center;
        width: 100%;
        text-align: left;

        &.c-analyst-item-mobile {
            display: none;
            > p {
                margin: 0;
            }
            .c-analyst-item-mobile-company {
                font-weight: 900;
            }
        }
    }
}
</style>
