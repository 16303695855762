<template>
<main class="c-ir-contact-us-page">
    <Container>
        <Row class="c-ir-contact-us-page__top">
            <Column>
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
            <Column :md="10">
                <Heading :level="3" noMarginTop v-epi-edit="'Title'">{{model.title}}</Heading>
            </Column>
        </Row>
        <Row class="c-ir-contact-us-page__info">
            <Column :md="10">
                <XhtmlField :items="model.contactInfo" v-epi-edit="'ContactInfo'"/>
            </Column>
        </Row>
        <FormBlock v-epi-edit="'Form'" id="form" class="c-ir-contact-us-page__form o-bg o-bg--beige-light" v-if="notEmptyObject(model.formBlock && model.formBlock.content) || isEditable" v-bind="model.formBlock.content"/>
    </Container>
</main>
</template>

<script>
export default {
    props: {
        model: Object
    }
};
</script>

<style lang="scss">
.c-ir-contact-us-page {
    &__info {
        margin-bottom: 3rem;
    }
    &__form {
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
</style>
