var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-ir-corporate-governance-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-ir-corporate-governance-page__top" },
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 10 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { noMarginTop: "", level: 4 },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                  _vm._v(" "),
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "IntroLeft",
                        expression: "'IntroLeft'",
                      },
                    ],
                    attrs: { items: _vm.model.introLeft },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 10, offsetMd: 4 } },
                [
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "IntroRight",
                        expression: "'IntroRight'",
                      },
                    ],
                    attrs: { items: _vm.model.introRight },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "AdvancedFileListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "AdvancedFileListBlock",
                    expression: "'AdvancedFileListBlock'",
                  },
                ],
                staticClass: "c-ir-corporate-governance-page__files",
              },
              "AdvancedFileListBlock",
              _vm.model.advancedFileListBlock.content,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "ExpandableListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "ExpandableListBlock",
                    expression: "'ExpandableListBlock'",
                  },
                ],
                staticClass: "c-ir-corporate-governance-page__expand",
              },
              "ExpandableListBlock",
              _vm.model.expandableListBlock.content,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }