<template>
<div class="c-notification-item">
    <div>{{notification.time}}</div>
    <div>
        <a :href="notification.url">{{notification.headline}}</a>
    </div>
</div>
</template>

<script>

export default {
    props: {
        notification: {
            type: Object,
        },
    },
};
</script>

<style lang="scss" scoped>
.c-notification-item{
 display: flex;
 flex-direction: row;
 border-bottom: 1px solid black;
 align-items: center;
 padding: 12px;
 min-height: 48px;
}
.c-notification-item > div{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
}
.c-notification-item > div:first-child{
    width:30%;
    padding-right:1em;
    @include media-md{
    padding: 0;
    }
}
.c-notification-item > a{
    width: 100%;
    text-align: left;
}
</style>
