<template>
<Row class="c-advanced-file-list-block">
    <Column :md="8">
        <Heading :level="3" noMarginTop>{{title}}</Heading>
    </Column>
    <Column :md="16"/>
    <Column :md="6" :sm="12" v-for="(file, index) in files" :key="`${_uid}_${index}`" class="c-advanced-file-list-block__item-wrapper">
        <div class="c-advanced-file-list-block__item">
            <Row>
                <Column :md="24" :xs="11">
                    <ResponsiveImage :src="file.image" :xs="{h: 127, w: 138}" :sm="{h: 127, w: 138}" :md="{h: 285, w: 295}" :lg="{h: 285, w: 295}"/>
                </Column>
                <Column :md="24" :xs="13" class="c-advanced-file-list-block__item__text">
                    <Heading :level="5" noMarginTop class="c-advanced-file-list-block__item__title">{{file.title}}</Heading>
                    <Button class="c-advanced-file-list-block__item__button" :href="file.file" download>
                        {{file.buttonLabel}}
                    </Button>
                </Column>
            </Row>
        </div>
    </Column>
</Row>
</template>

<script>
export default {
    props: {
        files: Array,
        title: String
    }
};
</script>

<style lang="scss">
.c-advanced-file-list-block {
    $padding-small: 1rem;
    $padding-large: 2rem;

    &__item {
        background-color: $beige-light;
        margin-bottom: 2rem;
        padding-bottom: $padding-small;
        position: relative;

        @include media-md {
            padding-left: $padding-large;
            padding-bottom: $padding-large;
        }

        &__text {
            padding: $padding-small $padding-small 0 $padding-small;
        }
        &__title {
            margin-bottom: 1rem;
        }
        &__button {
            padding: .2em 1em;
            position: absolute;
            bottom: 1.5rem;

            @include media-md {
                position: initial;
            }
        }
    }
}
</style>
