<template>
    <div class="event-main-speaker-block">
        <div class="event-main-speaker-block__content container">
            <div class="event-main-speaker-block__content__left">
                <div class="event-main-speaker-block__content__left__title">
                    <p>{{model.title}}</p>
                </div>
                <div class="event-main-speaker-block__content__left__text">
                    <h2>{{model.header}}</h2>
                    <p>{{model.position}}</p>
                </div>
                <div>
                    <XhtmlField :items="model.description" />
                </div>
            </div>
            <div class="event-main-speaker-block__content__right">
                <div>
                    <ResponsiveImage :alt="name" :xs="{w: 250, h: 250}" :sm="{w: 300, h: 300}" :md="{w: 300, h: 300}" :lg="{w: 400, h: 400}" :src="getUrlForImage" class="circular-image"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['model'],
    computed: {
        getUrlForImage() {
            if (this.model.image) {
                return this.model.image.url;
            }
            return '';
        }
    }
};

</script>

<style lang="scss">
.circular-image {
    border-radius: 50%;
}
.event-main-speaker-block {
    background-color: #E6E4D9;
    &__content {
        display: flex;
        flex-direction: column-reverse;
        gap:5rem;
        align-items: center;
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;
        @include media-md{
            flex-direction: row;
            gap:10rem;
        }
        &__left {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            @include media-md{
                width:50%
            }
            &__text {
                h2 {
                    font-size: 80px;
                    font-weight: 400;
                    color: #FF6A4C;
                    margin:0;
                    white-space: wrap;
                    @include media-md{
                        white-space: nowrap;
                    }
                }
                p {
                    font-size: 16px;
                    color: #04242D;
                    margin:0;
                }
            }
            &__title {
                p {
                    margin:0;
                    font-size: 22px;
                    font-weight: 500;
                    color: #094E5D;
                }
            }
        }
    }
}
</style>
