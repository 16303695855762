<template>
<div v-if="content.analysts && content.analysts.length > 0" class="c-analysts">
    <div class="c-analysts-heading">
            <Heading :level="5">{{content.title}}</Heading>
    </div>
    <div class="c-analysts-header">
        <div class="u-hidden-sm u-hidden-xs">Company</div>
        <div class="u-hidden-sm u-hidden-xs c-analysts-header-label">Analyst</div>
        <div class="u-hidden-sm u-hidden-xs c-analysts-header-label">Phone</div>
        <div class="u-hidden-sm u-hidden-xs c-analysts-header-label">Email</div>
   </div>
   <div class="" v-for="(analyst, index) in content.analysts" :key="index">
        <AnalystItem :analyst="analyst" />
    </div>
</div>
</template>

<script>

import AnalystItem from '@/Scripts/components/atoms/AnalystItem.vue';

export default {
    props: {
        content: {
            type: Object,
        }
    },
    components: {
        AnalystItem
    }
};
</script>

<style lang="scss">
.c-analysts{
    padding-bottom: 5em;
}
.c-analysts-header{
    display: flex;
    flex-direction: row;
    @media (min-width: #{$screen-md-min - 1}) {
        padding: 12px;
    }
    border-bottom: 2px solid black;
}
.c-analysts-header {
    > div{
        width: 100%;
        text-align: left;
    }
    &-label {
        font-size: 0.9em;
        @include media-md {
            font-size:1em;
        }
    }
}

</style>
