<template>
<div class="c-software-partner-list">
    <SoftwarePartnerRow :key="`${_uid}${index}@md`" class="c-software-partner-list@md-up" :partners="row" v-for="(row, index) in chunkedPartners(3)" :selected="selectedPartner" @select="updateSelectedPartner" :currentRow="index"/>
    <SoftwarePartnerRow :key="`${_uid}${index}@sm`" class="c-software-partner-list@sm" :partners="row" v-for="(row, index) in chunkedPartners(2)" :selected="selectedPartner" @select="updateSelectedPartner" :currentRow="index"/>
    <SoftwarePartnerRow :key="`${_uid}${index}@xs`" class="c-software-partner-list@xs" :partners="row" v-for="(row, index) in chunkedPartners(1)" :selected="selectedPartner" @select="updateSelectedPartner" :currentRow="index"/>
</div>
</template>

<script>
import SoftwarePartnerRow from '@/Scripts/components/organisms/SoftwarePartnerRow.vue';

export default {
    data: () => ({
        selectedPartner: null
    }),
    props: {
        partners: Array
    },
    components: {
        SoftwarePartnerRow
    },
    methods: {
        updateSelectedPartner(selected) {
            if (this.selectedPartner && selected) {
                if (selected.row === this.selectedPartner.row && selected.index === this.selectedPartner.index) {
                    this.selectedPartner = null;
                } else {
                    this.selectedPartner = selected;
                }
            } else {
                this.selectedPartner = selected;
            }
        },
        chunkedPartners(chunkSize) {
            var partners = [...this.partners];
            var results = [];

            while (partners.length) {
                results.push(partners.splice(0, chunkSize));
            }

            return results;
        }
    }
};
</script>

<style lang="scss">
.c-software-partner-list {
    &\@xs {
        @include media-sm {
            display: none;
        }
    }
    &\@sm {
        display: none;
        @include media-sm {
            display: flex;
        }
        @include media-md {
            display: none;
        }
    }
    &\@md-up {
        display: none;
        @include media-md {
            display: flex;
        }
    }
}
</style>
