<!--
xs, md, lg: Decides width for screensizes.
Offset: Used for offset(spacing) between columns.
Auto: Column width will match content.
Equal: Columns will become same width.
-->
<template>
<div :is="wrapper" :class="['o-col', className, computedClass, computedOffset, auto, equal, alignment]">
    <slot></slot>
</div>
</template>

<script>
import columnMixin from '@/Scripts/components/grid/columnMixin.js';

export default {
    mixins: [columnMixin]
};
</script>
