var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-list o-flat-list", attrs: { wrapper: "ul" } },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "Column",
        {
          key: `listitem_${_vm._uid}_${index}`,
          staticClass: "c-list__item",
          attrs: { wrapper: "li" },
        },
        [
          _c(
            item.url && !_vm.disableUrl ? "Anchor" : "div",
            {
              tag: "component",
              staticClass: "c-list__item-wrapper",
              class: _vm.itemClass,
              attrs: { target: _vm.target, href: item.url },
            },
            [
              _c(
                "Row",
                [
                  _c(
                    "Column",
                    { attrs: { xs: 20 } },
                    [
                      _vm._t(
                        "default",
                        function () {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "u-no-margins",
                                attrs: { level: 6 },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ]
                        },
                        { item: item }
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "Column",
                    { staticClass: "c-list__icon-wrapper", attrs: { xs: 4 } },
                    [
                      _vm._t(
                        "icon",
                        function () {
                          return [
                            item.url
                              ? _c("Icon", {
                                  staticClass: "c-list__icon",
                                  attrs: { icon: "chevron_right" },
                                })
                              : _vm._e(),
                          ]
                        },
                        { item: item }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }