<template>
<div class="c-some-share-block" :class="{'c-some-share-block--no-border' : noBorder}">
    <Heading isCategory class="c-some-share-block__title">{{getLabel('soMeBlock', 'share')}}</Heading>
    <Icon class="c-some-share-block__icon" icon="twitter" :url="twitterLink" target="_blank"/>
    <Icon class="c-some-share-block__icon" icon="facebook" :url="facebookLink" target="_blank"/>
    <Icon class="c-some-share-block__icon" icon="linkedin" :url="linkedinLink" target="_blank"/>
</div>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';

export default {
    props: {
        model: {
            type: Object
        },
        noBorder: {
            type: Boolean
        }
    },
    components: {
        Icon,
        Heading
    },
    computed: {
        facebookLink() {
            return 'https://www.facebook.com/sharer/sharer.php?u=' + location.href;
        },
        twitterLink() {
            return 'http://www.twitter.com/share?url=' + location.href;
        },
        linkedinLink() {
            return 'https://www.linkedin.com/sharing/share-offsite/?url=' + location.href;
        }
    }
};
</script>

<style lang="scss">
.c-some-share-block {
    $self: &;

    a[target=_blank]:after, .o-link--external:after {
        content: none;
    }

    &__title {
        border-bottom: 1px solid $orange;

        #{$self}--no-border & {
            border: none;
        }
    }
    &__icon {
        margin-right: 1em;
    }
}
</style>
