<template>
<div :class="[isFluid ? 'container-fluid' : 'container', background && 'container--' + background, {'container--margin-hack' : marginHack}]">
    <slot/>
</div>
</template>

<script>
export default {
    props: {
        isFluid: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        background: {
            type: String
        },
        className: {
            type: String
        },
        marginHack: Boolean
    }
};
</script>

<style lang="scss">
@import '@/Styles/Tools/_breakpoints.scss';

.container--beige {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $beige;

    @include media-md {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}
.container--beige-light {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $beige-light;

    @include media-md {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

// Used for containers with background.
// If the block has hardcoded margins it will get container padding + block margin. This creates a lot of space.
// The hack tricks the background to use the blocks margin as padding instead.
.container--margin-hack {
    padding: 0;
    &:before, &:after {
        content: '';
        height: 1px;
        width: 1px;
        display: block;
    }
}
</style>
