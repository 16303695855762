<template>
 <Row class="c-bubbles-block">
    <Column><Heading isCategory>{{label}}</Heading></Column>
    <Column :md="10">
        <Heading :level="3" class="u-no-margin-top">{{title}}</Heading>
    </Column>
    <Column :md="12" :offsetMd="2">
        <Row>
            <Column v-for="(item, index) in bubbles" :key="`${_uid}${index}`" :sm="8" class="c-bubbles-block__bubble">
                <div class="c-bubbles-block__bubble__image-wrapper">
                    <AspectRatioBox>
                        <ResponsiveImage :src="item.image" :alt="item.text" class="c-bubbles-block__bubble__image"/>
                    </AspectRatioBox>
                </div>
                <p class="c-bubbles-block__bubble__text">{{item.text}}</p>
            </Column>
        </Row>
    </Column>
</Row>
</template>

<script>
import AspectRatioBox from '@/Scripts/components/atoms/AspectRatioBox.vue';

export default {
    props: {
        label: String,
        title: String,
        bubbles: Array
    },
    components: {
        AspectRatioBox
    }
};
</script>

<style lang="scss">
.c-bubbles-block {
    .c-aspect-box {
        border-radius: 50%;
    }
    &__bubble {
        &__text {
            text-align: center;
            margin-left: 7%;
            max-width: 86%;
        }
        &__image-wrapper {
            margin-left: 32%;
            max-width: 38%;

            @include media-sm {
                margin-left: 14%;
                max-width: 72%;
            }
        }
        &__image {
            border-radius: 50%;
            background-color: $beige-light;
        }
    }
}
</style>
