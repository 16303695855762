<template>
  <div class="c-pagination">
    <Button :disabled="currentPage <= 1" buttonStyle="no-style" @click.native="$emit('change', currentPage - 1)" class="c-pagination__button">{{getLabel('pagination', 'previousButton')}}</Button>
    <ul class="o-flat-list">
       <li>
        <a href='#'
           :class="{'active': currentPage === 1}"
           @click="$emit('change', 1)">
          {{1}}
        </a>
      </li>
      <li v-if="currentPage > 3 && maxPages > 3">
        ...
      </li>

      <!-- eslint-disable-next-line no-irregular-whitespace -->
      <li v-for="i in listItems" :key="i" :class="{'u-hidden' : i <= 1 || i >= maxPages}" >
        <a href='#'
           :class="{'active': currentPage === i}"
           @click="$emit('change', i)">
          {{ i}}
        </a>
      </li>

      <li v-if="currentPage < maxPages - 2">
        ...
      </li>
      <li v-if="maxPages > 1">
        <a href='#'
           :class="{'active': currentPage === maxPages}"
           @click="$emit('change', maxPages)">
          {{maxPages}}
        </a>
      </li>
    </ul>
    <Button :disabled="currentPage >= maxPages" buttonStyle="no-style" @click.native="$emit('change', currentPage + 1)" class="c-pagination__button">{{getLabel('pagination', 'nextButton')}}</Button>
  </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        currentPage: {
            'default': 1,
            required: true,
        },
        maxPages: {
            type: Number,
            'default': 1,
            required: true,
        }
    },
    computed: {
        listItems() {
            var listItems = [];
            listItems.push(this.currentPage - 1);
            listItems.push(this.currentPage);
            listItems.push(this.currentPage + 1);
            return listItems;
        }
    }
};
</script>

<style lang="scss">
.c-pagination {
  display: flex;

  &__button {
    border-bottom: 1px solid $orange;

    &:hover {
      border-bottom: 1px solid $orange;
    }

    &[disabled], &:disabled {
      color: $gray;
      border-bottom: 1px solid $gray;
      cursor: default;
    }
  }
  ul {
    display: inline-block;
  }
  li {
    float: left;
    padding-left: 16px;
    padding-right: 16px;
  }
  .active {
    color: $black;
  }
}
</style>
