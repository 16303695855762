var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    {
      staticClass: "o-block o-custom-block o-bg",
      class: { "o-bg": _vm.backgroundColor },
      style: _vm.style,
    },
    [
      _c(
        "Column",
        { staticClass: "c-text-block", attrs: { md: 12 } },
        [
          _vm.title
            ? _c("Heading", { attrs: { level: 3 } }, [
                _vm._v(_vm._s(_vm.title)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.text
            ? _c("XhtmlField", { attrs: { items: _vm.text } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }