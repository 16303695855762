<!-- TODO: Set size for images -->
<!--
    Used to be one component. Genrated too much logic, so seperated all the different views.
    Results in almost duplicated code..
-->
<template>
<div class="c-news-component">
    <template v-if="viewMode === 'Vertical'">
        <VerticalItem v-bind="model" :flip="flip" :published="model.published" :hideDate="model.hideDate" :useUsDateFormat="useUsDateFormat"/>
    </template>
    <template v-else-if="viewMode === 'FullSize'">
        <FullSizeItem v-bind="model" :published="model.published" :hideDate="model.hideDate" :useUsDateFormat="useUsDateFormat"/>
    </template>
    <template v-else-if="viewMode === 'Compact'">
        <NewsItem v-bind="model" :showImage="false" :published="model.published" :hideDate="model.hideDate" :useUsDateFormat="useUsDateFormat"/>
    </template>
    <template v-else>
        <NewsItem v-bind="model" :imageConfig="imageConfig" :published="model.published" :hideDate="model.hideDate" :useUsDateFormat="useUsDateFormat"/>
    </template>
</div>
</template>

<script>
import FullSizeItem from '@/Scripts/components/molecules/NewsComponents/FullSizeItem.vue';
import VerticalItem from '@/Scripts/components/molecules/NewsComponents/VerticalItem.vue';
import NewsItem from '@/Scripts/components/molecules/NewsComponents/NewsItem.vue';

export default {
    props: {
        // Only flips vertical item
        flip: Boolean,
        useUsDateFormat: Boolean,
        viewMode: {
            type: String
        },
        model: Object,
        showArrow: {
            type: Boolean,
            default: true // eslint-disable-line
        },
        imageConfig: Object
    },
    components: {
        FullSizeItem,
        VerticalItem,
        NewsItem
    }
};
</script>
