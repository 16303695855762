var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "o-xhtml-field" },
    [
      _vm._l(_vm.items, function (item, index) {
        return [
          item.isBlock
            ? _c("BlockComponentSelector", {
                key: index,
                attrs: { model: item.blockModel },
              })
            : _c("XhtmlString", {
                key: index,
                attrs: { string: item.xhtmlString },
              }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }