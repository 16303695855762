<template>
<main class="c-page-list-page">
    <Container>
        <div v-if="model.showGartnerWidget" class="c-page-list-page__widget-container">
            <GartnerWidgetLineComponent />
        </div>
        <Row>
            <Column v-epi-edit="'Title'">
                <Heading noMarginTop>{{model.title}}</Heading>
            </Column>
        </Row>
        <Row class="c-page-list-page__categories u-display-md-up">
            <Column>
                <Heading isCategory class="c-page-list-page__categories__heading">{{getLabel('pageListPage', 'categories')}}</Heading>
            </Column>
            <Column>
                <Button buttonStyle="no-style" :class="{'active' : filter.id === null}" @click.native="changeCategory(CATEGORY_START_ITEM)">{{CATEGORY_START_ITEM.name}}</Button>
                <Button buttonStyle="no-style" :class="{'active' : filter.id === category.id}" v-for="category in model.selectableCategories" :key="category.id" @click.native="changeCategory(category)">
                    {{category.name}}
                </Button>
            </Column>
        </Row>
        <Row class="c-page-list-page__categories u-display-xs u-display-sm">
            <Dropdown class="c-page-list-page__categories__dropdown" label="name" v-model="filter" @input="changeCategory" :options="[CATEGORY_START_ITEM,...model.selectableCategories]" :clearable="false"/>
        </Row>
        <!-- Blocks for desktop. Cant use responsive css because of different number of items on screen sizes. -->
        <div class="u-display-md-up">
            <Row>
                <Column v-for="(item, index) in getTeasers(0, 1)" :key="index">
                    <NewsComponent :model="item" v-epi-edit="'Teasers'" viewMode="FullSize" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(1, 5)" :key="index" :md="6" :sm="12">
                    <NewsComponent v-epi-edit="'Teasers'" viewMode="Compact" :model="item" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(5, 7)" :key="index" :md="12">
                    <NewsComponent v-epi-edit="'Teasers'" :model="item" :imageConfig="{lg: {h: 334, w: 668}, md: {h: 334, w: 569}}" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(7, 10)" :key="index" :md="8">
                    <NewsComponent v-epi-edit="'Teasers'" :model="item" :imageConfig="{lg: {h: 193, w: 438}, md: {h: 193, w: 374}}" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
        </div>
        <!-- Blocks for tablet. Cant use responsive css because of different number of items on screen sizes.  -->
        <div class="u-display-sm">
            <Row>
                <Column v-for="(item, index) in getTeasers(0, 1)" :key="index">
                    <NewsComponent :model="item" v-epi-edit="'Teasers'" viewMode="FullSize" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(1, 5)" :key="index" :sm="12">
                    <NewsComponent v-epi-edit="'Teasers'" :model="item" :imageConfig="{sm: {h: 176, w: 313}}" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(5, 6)" :key="index">
                    <NewsComponent :model="item" v-epi-edit="'Teasers'" viewMode="FullSize" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(6,10)" :key="index" :sm="12">
                    <NewsComponent v-epi-edit="'Teasers'" :model="item" :imageConfig="{sm: {h: 176, w: 313}}" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
        </div>
        <!-- Blocks for phone. Cant use responsive css because of different number of items on screen sizes. -->
        <div class="u-display-xs">
            <Row>
                <Column v-for="(item, index) in getTeasers(0, 1)" :key="index">
                    <NewsComponent :model="item" v-epi-edit="'Teasers'" :imageConfig="{xs: {h: 184, w: 345}} " :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(1, 5)" :key="index">
                    <NewsComponent :model="item" v-epi-edit="'Teasers'" viewMode="Vertical" flip :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(5, 6)" :key="index">
                    <NewsComponent :model="item" v-epi-edit="'Teasers'" :imageConfig="{sm: {h: 184, w: 345}}" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
            <Row>
                <Column v-for="(item, index) in getTeasers(6, 10)" :key="index">
                    <NewsComponent v-epi-edit="'Teasers'" :model="item" viewMode="Vertical" flip :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
                </Column>
            </Row>
        </div>
        <Row v-if="totalPages > 0" class="c-page-list-page__pagination-wrapper">
            <Column align="right" class="u-flex">
                <Pagination
                    @change="changePage"
                    :current-page="page"
                    :max-pages="totalPages"
                />
            </Column>
        </Row>
    </Container>
</main>
</template>

<script>
import Pagination from '@/Scripts/components/molecules/Pagination.vue';
import Dropdown from '@/Scripts/components/atoms/Dropdown.vue';
import NewsComponent from '@/Scripts/components/molecules/NewsComponents/NewsComponent.vue';
import GartnerWidgetLineComponent from '@/Scripts/components/atoms/GartnerWidgetLineComponent.vue';

export default {
    props: ['model'],
    components: {
        GartnerWidgetLineComponent,
        NewsComponent,
        Pagination,
        Dropdown
    },
    data: function () {
        return {
            page: 1,        // Current page
            itemsPerPage: 10,
            filter: {
                name: 'Categories',
                id: null
            },
            // Const. Do not edit contents
            CATEGORY_START_ITEM: {
                name: this.getLabel('pageListPage', 'selectAllCategoriesText'),
                id: null
            }
        };
    },
    mounted() {
        var parameters = this.$route.query;
        //console.log(parameters);
        if (parameters && parameters.offset) {
            this.page = parameters.offset / this.itemsPerPage + 1;
        }
        this.getCategoryFromQuery(parameters);
    },
    watch: {
        '$route.query': function (parameters) {
            this.getCategoryFromQuery(parameters);
        }
    },
    computed: {
        // Calculates number of pages.
        totalPages() {
            return Math.ceil(this.model.total / this.itemsPerPage);
        }
    },
    methods: {
        getCategoryFromQuery(parameters) {
            if (parameters && parameters.category) {
                var temp = parseInt(parameters.category);
                this.filter = this.model.selectableCategories.find(x => x.id === temp);
            }
        },
        // Updates current page.
        changePage(value) {
            this.page = value;
            this.$router.push({ query: { offset: (this.page - 1) * this.itemsPerPage, count: this.itemsPerPage, category: this.filter.id } });
        },
        changeCategory(value) {
            this.filter = value;
            this.$router.push({ query: {category: this.filter.id }});
        },
        /**
         * value1: starting point in array
         * value2: ending point in array
         * itemsPerPage: Items per page. Use variables defined in data.
         */
        getTeasers(value1, value2, test) {
            return this.model && this.model.teasers ? this.model.teasers.slice(value1 , value2) : [];
        }
    }
};
</script>

<style lang="scss">
.c-page-list-page {
     &__widget-container{
            min-height: 100px;
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #04242D;
        }
    &__categories {
        margin-bottom: 3rem;
        &__heading {
            border-bottom: 1px solid black;
        }
        button {
            color: $gray;
            margin-right: 5em;
            margin-top: .5em;

            &.active {
                color: $black;
            }
        }
        &__dropdown {
            width: 100%;
        }
    }
    .c-news-component {
        margin-bottom: 2rem;
        height: calc(100% - 2rem);
    }
    &__pagination-wrapper {
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
}
</style>
