var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-page-list--large" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "Column",
        {
          key: index,
          staticClass: "c-page-list--large__item",
          attrs: { md: 6 },
        },
        [
          _c(
            "div",
            { staticClass: "c-page-list--large__content" },
            [
              _c(
                "AspectRatioBox",
                { attrs: { width: 323, height: 193 } },
                [
                  _c(
                    "ResponsiveImage",
                    _vm._b(
                      { attrs: { src: item.imageUrl } },
                      "ResponsiveImage",
                      _vm.imageConfig,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-page-list--large__content__text" },
                [
                  _c("Heading", { attrs: { isCategory: "" } }, [
                    _vm._v(_vm._s(item.label)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "Heading",
                    {
                      staticClass:
                        "c-page-list--large__heading u-no-margin-top",
                      attrs: { level: 5 },
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-clamp",
                    {
                      staticClass: "o-large-body",
                      attrs: { autoresize: "", "max-lines": 3, tag: "p" },
                    },
                    [_vm._v(_vm._s(item.intro))]
                  ),
                  _vm._v(" "),
                  _c(
                    "Anchor",
                    {
                      staticClass: "c-page-list--large__link",
                      attrs: { isFancy: "", href: item.url },
                    },
                    [_vm._v(_vm._s(_vm.getLabel("global", "readMore")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }