<template>
  <div class="c-gartner-widget-block o-bg">
    <div v-once id="largeWidget" class="c-gartner-widget-block__widget-container">
      <!-- The widget will be injected here -->
      <div>
        <div style=" display: flex; align-items: center; padding: 10px; margin-top: 10 px; margin-bottom:10px; text-align: center; height: 100%; width: 100%; background-color: #ffffff; border-radius:0px 10px 10px 0px;">
        <a href="https://www.gartner.com/reviews/market/software-asset-management-managed-service/vendor/crayon/product/crayon-software-asset-management-managed-services">
          <img src='/Assets/Images/GartnerPeerInsightsLogo_onlight.svg' title="Read reviews on Gartner peer insights" width="250"/>
        </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'GartnerWidgetBlock',

    mounted() {
        this.initializeGartnerWidget();
    },

    methods: {
        initializeGartnerWidget() {
            // Initialize the widget here
            this.GartnerPI_Widget({
                size: 'large',
                theme: 'light',
                sourcingLink: '',
                widget_id: 'ZWQ1ZTY2ZjEtYmY1MC00M2EyLTk4NTUtODBmMDRjZDExMTgz',
                version: '2',
                container: document.querySelector('#largeWidget')
            });
        }
    }
};
</script>

<style lang="scss">
.c-gartner-widget-block {
  background-color: #04242D;
  &__widget-container{
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-height: 100px;
    width:100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .gartner-pi-card{
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .gartner-pi-stats{
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 0.5rem;
  }

  .gartner-pi-as-of-date{
    transform: (rotate(0deg) translate(0px, 0px) scale(1, 1) skew(0deg, 0deg) ) !important;
    -webkit-transform: rotate(0deg) translate(0px, 0px) scale(1, 1) skew(0deg, 0deg) !important;
    font-size: 10px !important;
  }
  .gartner-pi-logo {
    display: none;
  }
  .gartner-pi-alignRight{
    display:none !important;
  }
  .gartner-pi-link{
    padding: 10px 10px;
  }
  .gartner-pi-overall-rating{
    display: flex;
    flex-direction: column;
    gap:10px;
  }
  #gartner-pi-datacontainer-ZWQ1ZTY2ZjEtYmY1MC00M2EyLTk4NTUtODBmMDRjZDExMTgz{
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: start;
    gap:3rem;
  }
  #gartner-pi-widget-ZWQ1ZTY2ZjEtYmY1MC00M2EyLTk4NTUtODBmMDRjZDExMTgz{
    width: 100%;
    border-radius: 10px 0px 0px 10px;
    border:none;
  }
  .gartner-pi-reviews{
    ul{
      display: flex;
      flex-direction:row;
    }
    li {
      display: flex;
      flex-direction: column;
      min-width: 450px;

    }
    border-top: none !important;
    display: flex;
    flex-direction: row;
    justify-content: start;
    max-width: 800px;
    overflow: hidden;
    gap: 3rem;
  }
}

</style>
