<template>
<div class="c-open-positions-block">
    <Row>
        <Column :md="12">
            <Heading noMarginTop class="u-add-colored-period" v-if="title">{{title}}</Heading>
            <p class="c-open-positions-block__intro" v-if="intro">{{intro}}</p>
        </Column>
    </Row>
    <Row class="c-open-positions-block__categories">
        <Column :xs="11" :sm="5" :md="5">
            <Dropdown v-model="category" :options="categoriesDropdown" placeholder="Categories" @input="updateData" />
        </Column>
        <Column :xs="1" class="col-no-gutter">
            <Button buttonStyle="no-style" icon='cross' class="c-clear-button" v-if="category" @click.native="reset('category')"></Button>
        </Column>

        <Column :xs="12" :sm="6" :md="5">
            <Dropdown v-model="country" :options="countriesDropdown" placeholder="Countries" @input="updateData" />
        </Column>
        <Column :xs="1" class="col-no-gutter">
            <Button buttonStyle="no-style" icon='cross' class="c-clear-button" v-if="country" @click.native="reset('country')"></Button>
        </Column>
    </Row>
    <Row class="c-vacancy-table-header">
        <Column :xs="12" :md="15">
            <Button buttonStyle="no-style" icon="toggle_sort" @click.native="sortyByTitle()">
                {{getLabel('vacancyPage', 'jobTitleText')}}
            </Button>
        </Column>
        <Column :xs="12" :md="9">
            <Button buttonStyle="no-style" icon="toggle_sort" @click.native="sortByCountry()">
                {{getLabel('vacancyPage', 'locationHeadingText')}}
            </Button>
        </Column>
    </Row>
    <List :items="vacancyList.vacancies" disableUrl>
        <template v-slot:default="{item}" :url="'/'">
            <Row class="c-open-positions-block__vacancy">
                <Column :md="10" :sm="14" class="c-open-positions-block__vacancy__col1">
                    <span class="u-block u-display-sm-down c-open-positions-block__vacancy__country@sm">{{item.country}}</span>
                    <span class="u-block c-open-positions-block__vacancy__title">{{item.title}}</span>
                </Column>
                <Column :md="8" :sm="10" class="u-hidden-xs">
                    <span>{{item.category.label}}</span>
                </Column>
                <Column :md="6" class="u-display-md-up">
                    <span>{{item.country}}</span>
                </Column>
            </Row>
            </template>
            <template v-slot:icon={item}>
                <Anchor class="c-open-positions-block__vacancy__icon@md" isFancy :href="detailedBaseUrl + '?id=' + item.id">{{getLabel('global', 'readMore')}}</Anchor>
                <Icon class="c-open-positions-block__vacancy__icon@sm" icon="arrow_right" :url="detailedBaseUrl + '?id=' + item.id"/>
            </template>
    </List>
</div>
</template>

<script>
import List from '@/Scripts/components/molecules/List.vue';
import Dropdown from '@/Scripts/components/atoms/Dropdown.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';
import api from '@/Scripts/api/api';

export default {
    props: ['intro', 'title', 'vacancyData', 'detailedBaseUrl'],
    components: {
        List,
        Dropdown,
        Icon
    },
    computed: {},
    methods: {
        updateData: function () {
            // console.log(this.sortOrder.code, this.category.code, this.country.code);
            let params = {
                ...(this.category.code && {
                    category: this.category.code
                }),
                ...(this.country.code && {
                    country: this.country.code
                })
            };

            api.getTalentSoftListData(params).then(response => {
                let newList = JSON.parse(response.data);
                // console.log('vacancies: ', newList);
                this.vacancyList.vacancies = newList.vacancies;
            });
        },
        reset: function (type) {
            if (type === 'category') {
                this.category = '';
            }

            if (type === 'country') {
                this.country = '';
            }

            this.updateData();
        },


        sortTitle: function (x,y) {
            const xTitle = x.title.toLowerCase();
            const yTitle = y.title.toLowerCase();
            if (xTitle < yTitle) {
                return -1;
            }
            if (xTitle > yTitle) {
                return 1;
            }
            return 0;
        },

        sortyByTitle: function () {
            const vacancies = this.vacancyList.vacancies;
            if (this.vacancyListSort.title === 'DESC') {
                vacancies.sort(this.sortTitle);
                this.vacancyListSort.title = 'ASC';
                return;
            }

            if (this.vacancyListSort.title === 'ASC') {
                vacancies.sort(this.sortTitle); // Make sure it's sorted by alphabet before reverse the array
                this.vacancyList.vacancies = vacancies.reverse();
                this.vacancyListSort.title = 'DESC';
                return;
            }
        },

        sortCountry: function (x,y) {
            const xCountry = x.country.toLowerCase();
            const yCountry = y.country.toLowerCase();
            if (xCountry < yCountry) {
                return -1;
            }
            if (xCountry > yCountry) {
                return 1;
            }
            return 0;
        },

        sortByCountry: function () {
            const vacancies = this.vacancyList.vacancies;

            if (this.vacancyListSort.country === 'DESC') {
                vacancies.sort(this.sortCountry);
                this.vacancyListSort.country = 'ASC';
                return;
            }

            if (this.vacancyListSort.country === 'ASC') {
                vacancies.sort(this.sortCountry); // Make sure it's sorted by alphabet before reverse the array
                this.vacancyList.vacancies = vacancies.reverse();
                this.vacancyListSort.country = 'DESC';
                return;
            }

        }
    },
    data: function () {
        return {
            vacancyList: this.vacancyData,
            vacancyListSort: {
                title: 'ASC',
                country: 'ASC'
            },
            category: '',
            country: '',
            categoriesDropdown: this.vacancyData.categories.map(c => {
                return {
                    label: c.label,
                    code: c.code
                };
            }),
            countriesDropdown: this.vacancyData.countries.map(c => {
                return {
                    label: c,
                    code: c
                };
            })
        };
    }
};
</script>

<style lang="scss">
.c-open-positions-block {
    a:hover {
        text-decoration: none;
    }
    &__intro {
        margin-bottom: 4rem;
    }
    &__categories {
        margin-bottom: 5rem;

        .o-col {
            margin-bottom: 1rem;
        }
    }
    &__vacancy {
        align-items: center;

        &__col1 {
            position: relative;
        }
        &__title {
            font-size: 24px;

            @include media-md {
                font-size: 16px;
            }
        }
        &__country\@sm {
            font-size: 14px;
            color: $gray;
            position: absolute;
            top: -20px;
        }
        &__icon\@sm {
            height: 1.2em;
            width: 1.2em;

            @include media-md {
                display: none;
            }
        }
        &__icon\@md {
            display: none;
            @include media-md {
                display: block;
            }
        }
    }
    .c-list__item {
        &:first-of-type {
            .c-list__item-wrapper {
                @include media-md {
                    border-top: 1px solid $gray !important;
                }
            }
        }
        &-wrapper {
            border: none !important;
            padding-top: 2rem;
            padding-bottom: 2rem;
            @include media-md {
                border-bottom: 1px solid $gray !important;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
        &:nth-child(odd) {
            z-index: 1;
            position: relative;
            &:before {
                content: "";
                background: white;
                position: absolute;
                width: 200vw;
                top: -1px;
                height: 100%;
                left: -100vw;
                right: 0;
                z-index: -1;
            }
            @include media-md {
                &:before {
                    content: none;
                }
            }
        }
    }
}

.c-clear-button {
    margin-top: 0.3rem;
}

.c-vacancy-table-header {
    padding: 1rem 0;
}
</style>
