var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-campaign-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-campaign-page__top" },
            [
              _vm.model.label || _vm.isEditable
                ? _c(
                    "Column",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                    },
                    [
                      _c("Heading", { attrs: { isCategory: "" } }, [
                        _vm._v(_vm._s(_vm.model.label)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.title || _vm.isEditable
                ? _c(
                    "Column",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { md: 12 },
                    },
                    [
                      _c(
                        "Heading",
                        {
                          attrs: {
                            level: 1,
                            noMarginTop: "",
                            noMarginBottom: "",
                          },
                        },
                        [_vm._v(_vm._s(_vm.model.title))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 10, offsetMd: 2 } },
                [
                  _vm.model.introText || _vm.isEditable
                    ? _c("XhtmlField", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "IntroText",
                            expression: "'IntroText'",
                          },
                        ],
                        staticClass: "o-ingress",
                        attrs: { items: _vm.model.introText },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.notEmptyObject(_vm.model.form && _vm.model.form.content)
                    ? _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#form",
                              expression: "'#form'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.model.buttonText
                                ? _vm.model.buttonText
                                : _vm.getLabel("campaignPage", "topButtonText")
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.media && _vm.model.media.content) ||
          _vm.isEditable
            ? _c(
                "MediaBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Media",
                        expression: "'Media'",
                      },
                    ],
                    staticClass: "c-campaign-page__media",
                  },
                  "MediaBlock",
                  _vm.model.media.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(
            _vm.model.twoColumnBlock && _vm.model.twoColumnBlock.content
          ) || _vm.isEditable
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "TwoColumnBlock",
                        expression: "'TwoColumnBlock'",
                      },
                    ],
                    staticClass: "c-campaign-page__two-column",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoColumnBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.file && _vm.model.file.content) ||
          _vm.isEditable
            ? _c(
                "FileDownloadBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "File",
                        expression: "'File'",
                      },
                    ],
                    staticClass: "c-campaign-page__file-download",
                  },
                  "FileDownloadBlock",
                  _vm.model.file.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.form && _vm.model.form.content) ||
          _vm.isEditable
            ? _c(
                "FormBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Form",
                        expression: "'Form'",
                      },
                    ],
                    staticClass: "c-campaign-page__form",
                    attrs: { id: "form" },
                  },
                  "FormBlock",
                  _vm.model.form.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.blocks || _vm.isEditable
            ? _c("ContentArea", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Blocks",
                    expression: "'Blocks'",
                  },
                ],
                staticClass: "c-campaign-page__content-area",
                attrs: { model: _vm.model.blocks || _vm.isEditable },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }