var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-subscription" }, [
    _c("h4", [_vm._v(_vm._s(_vm.heading))]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "form",
        attrs: {
          method: "post",
          name: "PageForm",
          action:
            "https://publish.ne.cision.com/Subscription/SubscribeWithCaptcha",
        },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _c("input", {
          attrs: {
            type: "hidden",
            name: "subscriptionUniqueIdentifier",
            value: "53ebfd8bb5",
          },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "Replylanguage", value: "en" },
        }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "regulatoryOption", value: "all" },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "input textarea c-subscription-item",
          attrs: { type: "text", name: "Name", placeholder: "Your name" },
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("input", {
          staticClass: "input textarea c-subscription-item",
          attrs: { type: "text", name: "Email", placeholder: "Your e-mail" },
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", {
          staticClass: "g-recaptcha",
          attrs: {
            "data-sitekey": "6LcqjxwiAAAAAPIRQJ3pZIBq5dzADjVbD3juhRls",
            "data-size": "normal",
            "data-theme": "light",
          },
        }),
        _vm._v(" "),
        _c(
          "Button",
          {
            attrs: {
              disabled: _vm.submitButtonDisabled,
              type: "submit",
              value: "Subscribe",
            },
          },
          [_vm._v("Subscribe")]
        ),
        _vm._v(" "),
        _vm.statusSubscribedMessage
          ? _c("div", [
              _vm.subscribeIsSuccess
                ? _c("div", [
                    _c(
                      "p",
                      { staticClass: "c-subscription__statusTextSuccess" },
                      [_vm._v(_vm._s(_vm.statusSubscribedMessage))]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "p",
                      { staticClass: "c-subscription__statusTextError" },
                      [_vm._v(_vm._s(_vm.statusSubscribedMessage))]
                    ),
                  ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }