<!--
    Renders the Vue router view. The router.js is configured to always load
    `PageComponentSelector`.

    * Views/DefaultPage/Index.cshtml outputs this component.
    Compared to Preview.vue, this does not use the content link, but uses the
    page's friendly URL instead.
-->
<template>
    <div>
        <loader-component :isLoading="!modelLoaded"/>
        <router-view>
        </router-view>
    </div>
</template>
<script>
import {mapState} from 'vuex';
import Loader from '@/Scripts/components/atoms/Loader.vue';
export default {
    components: {
        Loader
    },
    computed: mapState({
        modelLoaded: state => state.epiDataModel.modelLoaded
    }),
};
</script>
