var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-page-list-slider__item" },
    [
      _c(
        "AspectRatioBox",
        { attrs: { width: 503, height: 338 } },
        [
          _c(
            "ResponsiveImage",
            _vm._b(
              {
                staticClass: "c-page-list-slider__item__image",
                attrs: { src: _vm.imageUrl },
              },
              "ResponsiveImage",
              {
                lg: { h: 338, w: 503 },
                md: { h: 338, w: 503 },
                sm: { h: 176, w: 235 },
                xs: { h: 176, w: 235 },
              },
              false
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Heading",
        {
          staticClass: "c-page-list-slider__item__label",
          attrs: { isCategory: "" },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _c(
        "Heading",
        { staticClass: "c-page-list-slider__item__title", attrs: { level: 5 } },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c(
        "v-clamp",
        {
          staticClass: "c-page-list-slider__item__intro",
          attrs: { autoresize: "", "max-lines": 3 },
        },
        [_vm._v(_vm._s(_vm.intro))]
      ),
      _vm._v(" "),
      _c("Anchor", { attrs: { isFancy: "", href: _vm.url } }, [
        _vm._v(_vm._s(_vm.getLabel("global", "readMore"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }