var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-person" },
    [
      _c(
        "Row",
        { staticClass: "c-person__top" },
        [
          _vm.image
            ? _c(
                "Column",
                { attrs: { xs: 10, sm: 6, md: 7 } },
                [
                  _c("ResponsiveImage", {
                    attrs: {
                      alt: _vm.name,
                      xs: { w: 150, h: 155 },
                      sm: { w: 150, h: 155 },
                      md: { w: 150, h: 155 },
                      lg: { w: 150, h: 155 },
                      src: _vm.image,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Column",
            {
              staticClass: "c-person__text-wrapper",
              attrs: { sm: _vm.image ? 18 : 24, md: _vm.image ? 17 : 24 },
            },
            [
              _c(
                "Heading",
                { attrs: { level: 3, noMarginBottom: "", noMarginTop: "" } },
                [_vm._v(_vm._s(_vm.name))]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "u-block o-small-body c-person__title" },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "u-block o-small-body" }, [
                _vm._v(_vm._s(_vm.email)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("XhtmlField", { attrs: { items: _vm.description } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }