var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEditable
    ? _c(
        "button",
        {
          directives: [
            {
              name: "epi-edit",
              rawName: "v-epi-edit",
              value: _vm.propertyName,
              expression: "propertyName",
            },
          ],
          attrs: { type: "button" },
        },
        [_vm._v("Edit property: " + _vm._s(_vm.propertyName))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }