<template>
<main class="c-external-content-page">
    <Container>
        <iframe :src="model.externalContent"></iframe>
    </Container>
</main>
</template>

<script>
export default {
    props: ['model'],
    components: {}
};
</script>
