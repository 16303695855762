var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapper,
    {
      ref: "column",
      tag: "div",
      staticClass: "o-col--breakout__wrapper",
      class: [
        "o-col o-col--breakout",
        _vm.computedClass,
        _vm.computedOffset,
        _vm.auto,
        _vm.equal,
        _vm.alignment,
        { "o-col--breakout__left": _vm.breakoutSide === "left" },
      ],
    },
    [
      _vm.$screen.breakpoint === "xs"
        ? _c(
            "div",
            { staticClass: "o-col--breakout__content", style: _vm.styleXs },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$screen.breakpoint === "sm"
        ? _c(
            "div",
            { staticClass: "o-col--breakout__content", style: _vm.styleSm },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$screen.breakpoint === "md"
        ? _c(
            "div",
            { staticClass: "o-col--breakout__content", style: _vm.styleMd },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$screen.breakpoint === "lg"
        ? _c(
            "div",
            { staticClass: "o-col--breakout__content", style: _vm.styleLg },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }