<template>
<main class="c-vacancy-page">
    <Container>
        <div class="u-spinner" v-if="loading">Loading...</div>
        <Row v-else>
            <Column :offsetMd="8">
                <Heading :level="2" noMarginTop>{{model.Title}}</Heading>
            </Column>
            <Column :md="4">
                <div class="c-vacancy-page__left">
                    <Heading isCategory class="c-vacancy-page__left__title">{{getLabel('vacancyPage', 'positionDetailsHeadingText')}}</Heading>
                    <div class="c-vacancy-page__left__text-wrapper" v-if="model.Role">
                        <span class="u-block">{{getLabel('vacancyPage', 'positionRoleText')}}</span>
                        <span class="u-block">{{model.Role.Label}}</span>
                    </div>
                    <div class="c-vacancy-page__left__text-wrapper" v-if="model.Title">
                        <span class="u-block">{{getLabel('vacancyPage', 'jobTitleText')}}</span>
                        <span class="u-block">{{model.Title}}</span>
                    </div>
                    <div class="c-vacancy-page__left__text-wrapper" v-if="model.ExperienceLevel.Label">
                        <span class="u-block">{{getLabel('vacancyPage', 'experienceLevelText')}}</span>
                        <span class="u-block">{{model.ExperienceLevel.Label}}</span>
                    </div>
                </div>
                <div v-if="model.Location">
                    <Heading isCategory class="c-vacancy-page__left__title">{{getLabel('vacancyPage', 'locationHeadingText')}}</Heading>
                    <div class="c-vacancy-page__left__text-wrapper">
                        <span class="u-block">{{getLabel('vacancyPage', 'locationText')}}</span>
                        <span class="u-block">{{model.Location}}</span>
                    </div>
                </div>
            </Column>
            <Column :offsetMd="4" :md="12">
                <div class="c-vacancy-page__right">
                    <div class="c-vacancy-page__right__text-wrapper">
                        <p class="o-ingress" v-if="model.Intro">
                            {{model.Intro}}
                        </p>
                        <Button :href="model.Url" v-if="model.Url" target="_blank">{{getLabel('vacancyPage', 'applyButtonText')}}</Button>
                    </div>
                    <div class="c-vacancy-page__right__text-wrapper" v-if="model.AboutRole">
                        <p class="c-vacancy-page__right__small-title o-large-body">{{getLabel('vacancyPage', 'aboutRoleHeadingText')}}</p>
                        <pre>{{model.AboutRole}}</pre>
                    </div>
                    <div class="c-vacancy-page__right__text-wrapper" v-if="model.Competancy">
                        <p class="c-vacancy-page__right__small-title o-large-body">{{getLabel('vacancyPage', 'competancyHeadingText')}}</p>
                        <pre>{{model.Competancy}}</pre>
                    </div>
                    <div class="c-vacancy-page__right__text-wrapper" v-if="model.AboutCompany">
                        <p class="c-vacancy-page__right__small-title o-large-body">{{getLabel('vacancyPage', 'aboutCompanyHeadingText')}}</p>
                        <pre>{{model.AboutCompany}}</pre>
                    </div>
                    <Button :href="model.Url" v-if="model.Url" target="_blank" class="c-vacancy-page__right__button">{{getLabel('vacancyPage', 'applyButtonText')}}</Button>
                </div>
            </Column>
        </Row>
    </Container>
</main>
</template>

<script>
import api from '@/Scripts/api/api';

export default {
    data() {
        return {
            loading: true,
            model: null
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            var params = {reference: this.$route.query.id};
            api.getTalentSoftDetailedData(params).then(response => {
                this.model = JSON.parse(response.data);
                this.loading = false;
            });
        }
    }
};
</script>

<style lang="scss">
.c-vacancy-page {
    .u-spinner {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }
    &__left {
        &__title {
            padding-bottom: .2em;
            border-bottom: 1px solid $gray;
        }
        &__text-wrapper {
            white-space: pre-line;
            margin-top: 1.5em;

            &:last-of-type {
                margin-bottom: 3rem;
            }
        }
    }
    &__right {
        &__small-title {
            margin-bottom: 1em;
        }
        &__text-wrapper {
            margin-bottom: 5rem;

            pre {
                white-space: pre-wrap;
                word-wrap: break-word;
                font-family: inherit;
            }
        }
        &__button {
            margin-bottom: 5rem;
        }
    }
}
</style>
