<template>
<Row class="c-news-component-list">
    <Column v-for="(item,index) in items" :md="md" :sm="sm" :xs="xs" :key="`ncl_${_uid}${index}`">
        <NewsComponent :model="item" :viewMode="isVertical ? 'Vertical' : ''" :flip="flip" :imageConfig="imageConfig"/>
    </Column>
</Row>
</template>

<script>
import NewsComponent from '@/Scripts/components/molecules/NewsComponents/NewsComponent.vue';

export default {
    props: {
        md: Number,
        sm: Number,
        xs: Number,
        isVertical: Boolean,
        flip: Boolean,
        items: Array,
    },
    components: {
        NewsComponent
    },
    computed: {
        imageConfig() {
            var config = {};
            if (this.sm === 12) {
                config.sm = {
                    h: 176,
                    w: 310
                };
                if (!this.xs) {
                    config.xs = config.sm;
                }
            }
            if (this.md === 6) {
                config.md = {
                    h: 193,
                    w: 323
                };
                config.lg = config.md;
            }
            return config;
        }
    }
};
</script>

<style lang="scss">
.c-news-component-list {
    .c-news-component {
        margin-bottom: 2rem;
        height: calc(100% - 2rem);
    }
}
</style>
