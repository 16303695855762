<template>
<main class="c-search-page">
    <Container>
        <Row>
            <Column>
                <Heading v-epi-edit="'Title'" v-if="model.title || isEditable" class="u-add-colored-period">{{model.title}}</Heading>
            </Column>
            <Column :md="8" :sm="15">
                <div class="c-search-page__input-wrapper">
                    <input class="c-search-page__input" v-model="query" v-on:keyup.enter="getSearchResults(query)"/>
                    <Button buttonStyle="no-style" class="c-search-page__input-icon" icon="search" @click.native="getSearchResults(query)"/>
                </div>
            </Column>
        </Row>
        <div v-if="results === null" class ="c-search-page__no__results">No results for "{{queryAfterSearch}}"</div>
        <div class="u-spinner" v-if="isLoading">Loading...</div>
        <List v-if="!isLoading && dataLoaded" :items="results" class="c-search-page__results" itemClass="c-search-page__item">
            <template v-slot:default="{item}">
                <Anchor class="c-search-page__item__url" :href="item.url">{{item.displayUrl}}</Anchor>
                <Heading :level="5" class="u-no-margins">{{item.name}}</Heading>
                <p>{{item.snippet}}</p>
            </template>
        </List>
    </Container>
</main>
</template>

<script>
import api from '@/Scripts/api/api';
import List from '@/Scripts/components/molecules/List.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';

export default {
    props: {
        model: Object,
    },
    components: {
        List,
        Icon
    },
    data() {
        return {
            isLoading: false,
            dataLoaded: false,
            query: this.getQueryFromUrl() ? this.getQueryFromUrl() : '',
            queryAfterSearch: '',
            results: []
        };
    },
    mounted: function () {
        if (this.query && this.checkIfQueryIsValid(this.query)) {
            this.getSearchResults(this.query);
        }
    },
    methods: {
        getSearchResults(query) {
            this.isLoading = true;
            var parameters = {
                query: query,
                currentUrl: window.location.href
            };
            api.getSearchResults(parameters).then(response => {
                this.isLoading = false;
                this.dataLoaded = true;
                var data = response.data;
                this.queryAfterSearch = query;
                if (!data.error) {
                    this.results = data.webPages;
                }
                if (this.checkIfQueryIsValid(query)) {
                    this.pushQueryToUrl(query);
                }
            });
        },
        checkIfQueryIsValid(query) {
            if (query === null || query === undefined) {
                return false;
            }
            const trimmedquery = query.trim();
            return (trimmedquery !== '' || trimmedquery !== 'null');
        },
        pushQueryToUrl(query) {
            var url = new URL(window.location.href);
            url.searchParams.set('q', query);
            if (this.checkIfQueryIsValid(query)) {
                history.pushState({}, '', url);
            }
            return null;
        },
        getQueryFromUrl() {
            var url = new URL(window.location.href);
            var query = url.searchParams.get('q');
            this.getSearchResults(query);
            if (this.checkIfQueryIsValid(query)) {
                return query;
            }
            return null;
        }
    }
};
</script>

<style lang="scss">
.c-search-page {
    &__no__results {
        top: 0;
        padding: 0 5em 5em 0.5em;
        font-size: x-large;
    }
    &__input-wrapper {
        position: relative;
    }
    &__input-icon {
        position: absolute;
        fill: $orange;
        top: 2px;
        right: 1em;
    }
    &__input {
        width: 100%;
        margin-bottom: 5rem;
    }
    &__results {
        margin-bottom: 5rem;
    }
    &__item {
        transition: background-color 0.5s ease;
        &__url {
            color: $gray !important;
        }
        &:hover {
            text-decoration: none;
            background-color: $beige-light;
        }
    }
}
</style>
