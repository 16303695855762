var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-news-block" },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            { staticClass: "c-news-block__col1", attrs: { md: 12 } },
            [
              _c("Heading", { attrs: { isCategory: "" } }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
              _vm._v(" "),
              _c(
                "Heading",
                {
                  staticClass: "u-no-margin-top u-add-colored-period",
                  attrs: { level: 1 },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticClass: "c-news-block__view-all-button u-hidden-xs",
                  attrs: { href: _vm.url },
                },
                [_vm._v(_vm._s(_vm.getLabel("global", "viewAllArticles")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("Column", { attrs: { md: 10, offsetMd: 2 } }, [
            _c(
              "ul",
              { staticClass: "o-flat-list" },
              _vm._l(_vm.teasers, function (item, index) {
                return _c(
                  "li",
                  {
                    key: `newsblock_item_${index}`,
                    staticClass: "c-news-block__item",
                  },
                  [
                    !item.hideDate
                      ? _c("Datetime", {
                          staticClass: "c-news-block__item__date",
                          attrs: {
                            value: item.published,
                            useUsDateFormat: _vm.useUsDateFormat,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "Heading",
                      {
                        staticClass:
                          "c-news-block__item__title u-no-margin-top",
                        attrs: { level: 4 },
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-clamp",
                      { attrs: { autoresize: "", "max-lines": 3, tag: "p" } },
                      [_vm._v(_vm._s(item.intro))]
                    ),
                    _vm._v(" "),
                    _c(
                      "Anchor",
                      {
                        staticClass: "c-news-block__item__readmore",
                        attrs: { isFancy: "", href: item.url },
                      },
                      [_vm._v(_vm._s(_vm.getLabel("global", "readMore")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "Anchor",
                      {
                        staticClass: "c-news-block__item__chevron",
                        attrs: { href: item.url },
                      },
                      [
                        _c("Icon", {
                          staticClass: "c-news-block__item__chevron2",
                          attrs: { icon: "chevron_right" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "Column",
            { staticClass: "u-display-xs" },
            [
              _c("Anchor", { attrs: { isFancy: "", href: _vm.url } }, [
                _vm._v(_vm._s(_vm.getLabel("global", "viewAllArticles"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }