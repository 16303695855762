<template>
<Row class="c-single-teaser-block">
    <Column :md="6" :sm="8" class="c-single-teaser-block__left" :class="{'o-bg' : notEmptyObject(extraTeaser)}">
        <Row v-if="notEmptyObject(extraTeaser)" v-epi-edit="leftEdit">
            <Column :md="24" class="c-single-teaser-block__text-wrapper">
                <div>
                    <Heading isCategory>{{extraTeaser.label}}</Heading>
                    <Heading :level="4" class="u-no-margin-top">{{extraTeaser.title}}</Heading>
                    <p class="o-large-body">{{extraTeaser.intro}}</p>
                </div>
                <div>
                    <Anchor isFancy :href="extraTeaser.link">{{extraTeaser.linkText ? extraTeaser.linkText : getLabel('global', 'readMore')}}</Anchor>
                </div>
            </Column>
        </Row>
    </Column>
    <BreakoutColumn :md="18" :sm="notEmptyObject(extraTeaser) ? 16 : 24" breakoutSide="right" class="o-bg o-bg--right o-bg--beige-light c-single-teaser-block__right">
        <Row>
            <Column :md="12" class="c-single-teaser-block__text-wrapper" :offsetSm="notEmptyObject(extraTeaser) ? 3 : 2" :offsetMd="0" v-epi-edit="rightEdit">
                <div>
                    <Heading class="c-single-teaser-block__right__label" isCategory>{{label}}</Heading>
                    <Heading :level="3" class="u-no-margin-top">{{title}}</Heading>
                    <p class="o-ingress">{{intro}}</p>
                </div>
                <div>
                    <Anchor isFancy :href="link">{{linkText ? linkText : getLabel('global', 'readMore')}}</Anchor>
                </div>
            </Column>
            <Column :md="12" class="c-single-teaser-block__image-wrapper" :offsetSm="notEmptyObject(extraTeaser) ? 3 : 2" :offsetMd="0">
                <ResponsiveImage :src="image" v-bind="imageConfig"/>
            </Column>
        </Row>
    </BreakoutColumn>
</Row>
</template>

<script>
export default {
    props: {
        label: String,
        title: String,
        intro: String,
        link: String,
        linkText: String,
        image: String,
        extraTeaser: Object,
        leftEdit: String,
        rightEdit: String
    },
    data() {
        return {
            imageConfig: {
                xs: {w: 355, h: 321},
                sm: {w: 376, h: 242},
                md: {w: 715, h: 684},
                lg: {w: 715, h: 684}
            }
        };
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_breakpoints.scss';

.c-single-teaser-block {
    $content-padding: 3rem;

    &__left,
    &__right {
        padding-top: 3rem;

        @include media-md {
            padding-top: 5em;
        }
    }

    &__left {
        .c-single-teaser-block__text-wrapper {
            padding-bottom: $content-padding;

            @include media-sm {
                padding-right: $content-padding;
            }
        }
    }

    &__right {
        &__label {
            margin-top: 3em;

            @include media-md {
                margin-top: 0;
            }
        }

        .o-col--breakout__content {
            max-width: 1177px;
        }

        .c-single-teaser-block__text-wrapper {
            padding-bottom: $content-padding;

            @include media-md {
                padding-right: $content-padding;
                padding-left: $content-padding;
            }
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        order: 1;

        @include media-md {
            order: 0;
        }
    }

    &,
    .row {
        height: 100%;
    }
}
</style>
