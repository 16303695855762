<template>
<div class="c-employees-block">
    <Heading noMarginTop class="u-add-colored-period">{{title}}</Heading>
    <HorizontalSlider :items="employees" :scroll="1" :noOfItemsToShow="2" :padding="100" class="u-display-md-up">
            <template  v-slot:default="{item}">
                <Row class="c-employees-block__employee">
                    <Column :md="12">
                        <ResponsiveImage :src="item.image" :xs="{h: 150, w: 150}" :sm="{h: 150, w: 150}" :md="{h: 256, w: 256}" :lg="{h: 304, w: 304}"/>
                        <span class="u-block c-employees-block__employee__name">{{item.employeeName}}</span>
                        <span class="u-block c-employees-block__employee__jobtitle">{{item.jobTitle}}</span>
                    </Column>
                    <Column :md="12">
                        <p class="c-employees-block__employee__question">{{item.question}}</p>
                        <p class="c-employees-block__employee__answer">{{item.answer}}</p>
                        <span class="c-employees-block__dot"></span>
                        <span class="c-employees-block__dot"></span>
                        <span class="u-block c-employees-block__employee__quote">{{item.quote}}</span>
                    </Column>
                </Row>
            </template>
        </HorizontalSlider>
        <HorizontalSlider :items="employees" :scroll="1" :noOfItemsToShow="1" class="u-display-sm u-display-xs">
            <template  v-slot:default="{item}">
                <Row class="c-employees-block__employee">
                    <Column :sm="6">
                        <ResponsiveImage :src="item.image" :xs="{h: 150, w: 150}" :sm="{h: 150, w: 150}" :md="{h: 210, w: 210}" :lg="{h: 210, w: 210}"/>
                        <span class="u-block c-employees-block__employee__name">{{item.employeeName}}</span>
                        <span class="u-block c-employees-block__employee__jobtitle">{{item.jobTitle}}</span>
                    </Column>
                    <Column :sm="15" :offsetSm="3">
                        <p class="c-employees-block__employee__question">{{item.question}}</p>
                        <p class="c-employees-block__employee__answer">{{item.answer}}</p>
                        <span class="c-employees-block__dot"></span>
                        <span class="c-employees-block__dot"></span>
                        <span class="u-block c-employees-block__employee__quote">{{item.quote}}</span>
                    </Column>
                </Row>
            </template>
        </HorizontalSlider>
</div>
</template>

<script>
import HorizontalSlider from '@/Scripts/components/molecules/HorizontalSlider.vue';

export default {
    props: {
        title: String,
        employees: Array
    },
    components: {
        HorizontalSlider
    }
};
</script>

<style lang="scss">
.c-employees-block {
    &__employee {
        @include media-md {
            //padding-right: 100px;
        }
        &__name {
            font-size: 24px;
            border-top: 7px double $orange;
            padding-top: .4em;
        }
        &__jobtitle {
            font-size: 14px;
            color: $gray;
        }
        &__question {
            font-size: 20px;
            margin-bottom: 1em;
        }
        &__quote {
            font-size: 28px;;
        }
    }
    &__dot {
        height: 10px;
        width: 10px;
        background-color: $orange;
        border-radius: 50%;
        margin-top: .5em;
        display: inline-block;
    }
    .c-horizontal-slider {
        padding-bottom: 6rem;
    }
    .c-horizontal-slider__nav {
        align-items: flex-end !important;
        justify-content: flex-end;

        &__right, &__left {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        @include media-md {
            align-items: center !important;
            justify-content: normal;

            &__right {
                margin-left: auto !important;
                margin-right: 3rem !important;
            }
            &__left {
                margin-left: 3rem !important;
                margin-right: auto !important;
            }
        }
    }
}
</style>
