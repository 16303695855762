var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modelLoaded
    ? _c("section", { staticClass: "Grid Preview" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "Grid-cell u-md-sizeFull" },
          [_c("BlockComponentSelector", { attrs: { model: _vm.model } })],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "Grid-cell u-md-size2of3" },
          [_c("BlockComponentSelector", { attrs: { model: _vm.model } })],
          1
        ),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "Grid-cell u-md-size1of2" },
          [_c("BlockComponentSelector", { attrs: { model: _vm.model } })],
          1
        ),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "Grid-cell u-md-size1of3" },
          [_c("BlockComponentSelector", { attrs: { model: _vm.model } })],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Grid-cell u-border" }, [
      _c("h5", [_vm._v("Full")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Grid-cell u-border" }, [
      _c("h5", [_vm._v("Wide")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Grid-cell u-border" }, [
      _c("h5", [_vm._v("Half")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Grid-cell u-border" }, [
      _c("h5", [_vm._v("Narrow")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }