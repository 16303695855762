var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-software-partner-list" },
    [
      _vm._l(_vm.chunkedPartners(3), function (row, index) {
        return _c("SoftwarePartnerRow", {
          key: `${_vm._uid}${index}@md`,
          staticClass: "c-software-partner-list@md-up",
          attrs: {
            partners: row,
            selected: _vm.selectedPartner,
            currentRow: index,
          },
          on: { select: _vm.updateSelectedPartner },
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.chunkedPartners(2), function (row, index) {
        return _c("SoftwarePartnerRow", {
          key: `${_vm._uid}${index}@sm`,
          staticClass: "c-software-partner-list@sm",
          attrs: {
            partners: row,
            selected: _vm.selectedPartner,
            currentRow: index,
          },
          on: { select: _vm.updateSelectedPartner },
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.chunkedPartners(1), function (row, index) {
        return _c("SoftwarePartnerRow", {
          key: `${_vm._uid}${index}@xs`,
          staticClass: "c-software-partner-list@xs",
          attrs: {
            partners: row,
            selected: _vm.selectedPartner,
            currentRow: index,
          },
          on: { select: _vm.updateSelectedPartner },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }