var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-not-found-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Heading",
            {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "Title",
                  expression: "'Title'",
                },
              ],
              staticClass: "c-not-found-page__title u-no-margins",
            },
            [_vm._v(_vm._s(_vm.model.title))]
          ),
          _vm._v(" "),
          _c("XhtmlField", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "mainBody",
                expression: "'mainBody'",
              },
            ],
            staticClass: "c-not-found-page__body",
            attrs: { items: _vm.model.mainBody },
          }),
          _vm._v(" "),
          _c("Button", { attrs: { href: _vm.model.parentLink.url } }, [
            _vm._v(
              _vm._s(
                _vm.getLabel("notFound404Page", "returnToFrontpageButtonText")
              )
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }