var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.isFluid ? "container-fluid" : "container",
        _vm.background && "container--" + _vm.background,
        { "container--margin-hack": _vm.marginHack },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }