<template>
<transition name="fade">
    <div v-if="visible" class="o-redirect--alert">
       <Button :class="'o-redirect--alert__btnClose'" buttonStyle="no-style" iconRight icon="cross" @click.native="close"></Button>
       <p>
           {{ text }}
        </p>

        <div>
            <Button @click.native="_toggleLanguageSelector">
                {{getLabel('global', 'changeCountry')}}
            </Button>
            <Button :class="'u-hidden-xs'" @click.native="close">
                {{getLabel('global', 'close')}}
            </Button>
        </div>
        <p class="o-disclaimer-text">
            This product includes GeoLite2 data created by MaxMind, available from
            <a href="https://www.maxmind.com">MaxMind</a>
        </p>
    </div>
</transition>
</template>

<script>
import { TOGGLE_LANGUAGESELECTOR, DISABLE_SCROLLING, ENABLE_SCROLLING } from '@/Scripts/store/modules/appContext';
import { mapMutations } from 'vuex';

export default {
    data() {
        return {
            visible: true,
            isLanguageSelectorOpen: false
        };
    },
    props: {
        text: String
    },
    methods: {
        ...mapMutations({
            toggleLanguageSelector: TOGGLE_LANGUAGESELECTOR,
            disableScroll: DISABLE_SCROLLING,
            enableScroll: ENABLE_SCROLLING
        }),
        _handleScroll(menuElementOpen) {
            var timeout = this.scrollToTopOnClick ? 700 : 0;
            // If current menu isnt open disable scroll before opening it.
            if (!menuElementOpen) {
                // If used in footer scroll up to menus.
                if (this.scrollToTopOnClick) {
                    this.$scrollTo({x: 0, y: 0});
                }
                // Disable scrolling after set timeout. Is used to handle vue-scrollto.
                setTimeout(() => {
                    this.disableScroll();
                }, timeout);
            } else {
                // Enable scroll if menu is going to close after this method.
                this.enableScroll();
            }
        },
        _toggleMenu() {
            this._handleScroll(this.isMenuOpen);
            this.toggleMenu();
        },
        _toggleLanguageSelector() {
            this._handleScroll(this.isLanguageSelectorOpen);
            this.toggleLanguageSelector();
            this.close();
        },
        close() {
            this.visible = !this.visible;
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/Styles/tools/_font-mixin.scss';
    .fade-enter-active, .fade-leave-active {
        transition: opacity 400ms;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .o-redirect--alert {
        /* Position */
        position: fixed;
        bottom: 0;
        right: 5%;
        z-index: 10;

        /* Size */
        width: 90%;
        padding: 1.4rem 1.4rem 2rem 1.4rem;

        /* Style */
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        @include media-sm {
            width: 100%;
            max-width: 75%;
            padding: 2.8rem 2.8rem 2rem 2.8rem;
            right: 0;

            &__btnClose {
                position: absolute;
                top: 1.2rem;
                left: 1.2rem;
            }
        }

        @include media-md {
            max-width: 50%;
        }

        @include media-lg {
            max-width: 40%;
        }

        > div > button {
            margin-right: 1rem;
        }
    }

    .o-disclaimer-text {
        @include fontSize(12px);
        line-height: em(20, 12);
        color: $gray;
        margin-bottom: 0;
        margin-top: 2em;
    }
</style>
