var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "epi-link",
    {
      staticClass: "c-news-component--fullsize o-link--no-style",
      attrs: { href: _vm.url },
    },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            { staticClass: "c-news-component__image-container" },
            [
              _c("ResponsiveImage", {
                staticClass: "c-news-component__image",
                attrs: {
                  src: _vm.imageUrl,
                  lg: { h: 461, w: 1358 },
                  md: { h: 392, w: 1154 },
                  sm: { h: 206, w: 642 },
                  xs: { h: 184, w: 345 },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Column", [
            _c(
              "div",
              { staticClass: "c-news-component__text-container" },
              [
                _c(
                  "Row",
                  [
                    !_vm.hideDate
                      ? _c(
                          "Column",
                          { attrs: { offsetSm: 2, sm: 22 } },
                          [
                            _c("Datetime", {
                              staticClass:
                                "u-block c-news-component__text-container__date",
                              attrs: {
                                date: _vm.published,
                                value: _vm.published,
                                useUsDateFormat: _vm.useUsDateFormat,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "Column",
                      { attrs: { offsetSm: 2, sm: 8 } },
                      [
                        _c(
                          "Heading",
                          {
                            staticClass:
                              "u-block c-news-component__text-container__title",
                            attrs: {
                              level: 3,
                              noMarginTop: "",
                              noMarginBottom: "",
                            },
                          },
                          [_vm._v(_vm._s(_vm.title))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Column",
                      { attrs: { offsetSm: 1, sm: 11 } },
                      [
                        _c(
                          "v-clamp",
                          {
                            staticClass:
                              "u-block o-ingress c-news-component__text-container__text",
                            attrs: { autoresize: "", "max-lines": 5 },
                          },
                          [_vm._v(_vm._s(_vm.intro))]
                        ),
                        _vm._v(" "),
                        _c(
                          "Anchor",
                          {
                            staticClass: "u-hidden-sm u-hidden-xs",
                            attrs: { isFancy: "", href: _vm.url },
                          },
                          [_vm._v(_vm._s(_vm.getLabel("global", "readMore")))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Column",
                      {
                        staticClass: "c-news-component__arrow",
                        attrs: { xs: 22, align: "right" },
                      },
                      [_c("Icon", { attrs: { icon: "arrow_right" } })],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }