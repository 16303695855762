export default {
    props: {
        wrapper: {
            default: 'div' // eslint-disable-line
        },
        align: {
            type: String
        },
        className: {
            type: String
        },
        xs: {
            type: Number
        },
        sm: {
            type: Number
        },
        md: {
            type: Number
        },
        lg: {
            type: Number
        },
        offsetXs: {
            type: Number
        },
        offsetSm: {
            type: Number
        },
        offsetMd: {
            type: Number
        },
        offsetLg: {
            type: Number
        },
        autoXs: {
            type: Boolean
        },
        autoSm: {
            type: Boolean
        },
        autoMd: {
            type: Boolean
        },
        autoLg: {
            type: Boolean
        },
        equalXs: {
            type: Boolean
        },
        equalSm: {
            type: Boolean
        },
        equalMd: {
            type: Boolean
        },
        equalLg: {
            type: Boolean
        }
    },
    computed: {
        computedClass() {
            var className = '';
            if (!this.xs && !this.autoXs) {
                className = 'col-xs-24';
            }
            if (this.xs && !this.autoXs) {
                className += ' col-xs-' + this.xs;
            }
            if (this.sm && !this.autoSm) {
                className += ' col-sm-' + this.sm;
            }
            if (this.md && !this.autoMd) {
                className += ' col-md-' + this.md;
            }
            if (this.lg && !this.autoLg) {
                className += ' col-lg-' + this.lg;
            }
            return className;
        },
        computedOffset() {
            var className = '';
            if (this.offsetXs !== undefined) {
                className += ' col-xs-offset-' + this.offsetXs;
            }
            if (this.offsetSm !== undefined) {
                className += ' col-sm-offset-' + this.offsetSm;
            }
            if (this.offsetMd !== undefined) {
                className += ' col-md-offset-' + this.offsetMd;
            }
            if (this.offsetLg !== undefined) {
                className += ' col-lg-offset-' + this.offsetLg;
            }
            return className;
        },
        auto() {
            var className = '';
            if (this.autoXs) {
                className += ' col-xs-auto';
            }
            if (this.autoSm) {
                className += ' col-sm-auto';
            }
            if (this.autoMd) {
                className += ' col-md-auto';
            }
            if (this.autoLg) {
                className += ' col-lg-auto';
            }
            return className;
        },
        equal() {
            var className = '';
            if (this.equalXs) {
                className += ' col-xs';
            }
            if (this.equalSm) {
                className += ' col-sm';
            }
            if (this.equalMd) {
                className += ' col-md';
            }
            if (this.equalLg) {
                className += ' col-lg';
            }
            return className;
        },
        alignment() {
            if (this.align) {
                var className = ' u-flex u-justify-content-';
                switch (this.align) {
                    case 'center': className += 'center'; break;
                    case 'left': className += 'flex-start'; break;
                    case 'right': className += 'flex-end'; break;
                    case 'space-between': className += 'between'; break;
                    default: break;
                }
                return className;
            }
        }
    }
};
