<template>
<Row class="c-form-block">
    <Column class="o-col col-xs-24">
        <Heading noMarginTop>{{title}}</Heading>
        <component :is="dynamicComponent"></component>
    </Column>
</Row>
</template>

<script>
import { mapState } from 'vuex';
import { RELOADPAGE } from '@/Scripts/store/modules/appContext';
import { mapMutations } from 'vuex';

export default {
    props: {
        title: String,
        formData: String
    },
    mounted: function () {
        // Even dirtier hack to force loading script multiple times. Do not put this in my CV
        // needs three ticks for location to have updated
        this.$nextTick(function () {
            this.$nextTick(function () {
                this.$nextTick(function () {
                    if (this.reloadPageValue === true) {
                        location.reload();
                    }
                    this.doReloadPage();
                });
            });
        });
    },
    computed: {
        ...mapState({
            epiDisableEditing: state => state.appContext.modalShowing,
            inEditMode: state => state.epiContext.inEditMode,
            reloadPageValue: state => state.appContext.reloadFor !== location.pathname
        }),
        dynamicComponent() {
            let embedHTML = this.formData;
            const parser = new DOMParser();
            const html = parser.parseFromString(this.formData, 'text/html');
            const scripts = html.getElementsByTagName('script');


            // Dirty hack to inject a timestamp to loading external javascript
            if (Object.keys(scripts).length) {
                embedHTML = embedHTML.replace(/\.js\?/, '.js?t=' + Date.now() + '&');
            }

            //check if iframe

            if (embedHTML.indexOf('<iframe') > -1) {
                //get iframe

                const iframe = embedHTML.match(/<iframe[^>]*>(.*?)<\/iframe>/g)[0];

                //set styling on iframe

                const iframeWithStyle = iframe.replace('<iframe', '<iframe style="width:100%;height:100%;"');

                return {
                    template: `<div class="c-iframe-block">${iframeWithStyle}</div>`
                };
            }
            return {
                template: '<div>' + embedHTML + '</div>'
            };
        },
    },
    methods: {
        ...mapMutations({
            doReloadPage: RELOADPAGE
        })
    },
};
</script>

<style lang="scss">
.c-form-block {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media-md {
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
}

.c-iframe-block {
        width: 100%;
        height: 35rem;
}
</style>
