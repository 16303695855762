<template>
<Row class="c-support-centers-block">
    <Column :md="9">
        <Heading :level="3" class="u-no-margin-top">{{title}}</Heading>
        <p class="c-support-centers-block__intro">{{intro}}</p>
    </Column>

    <Column>
        <Row wrapper="ul" class="o-flat-list">
                <!-- <template v-for="(region) in supportCenters"> -->
                    <Column wrapper="li" :md="10" :offsetMd="((index + 1) % 2) === 0 && 4" v-for="(region, index) in supportCenters" :key="region.regionName + index">
                        <Collapse>
                            <template #header>
                                {{region.regionName}}
                            </template>
                            <template #content>
                                <div v-for="(center, index) in region.supportCenters" :key="center.name + index" class="c-support-centers-block__center">
                                    <p class="o-small-body">
                                        <span class="u-block o-large-body">{{center.name}}</span>
                                        <span class="u-block">
                                            {{getLabel('supportCentersBlock', 'supportHours')}}: <span>{{center.supportHours}}</span>
                                        </span>
                                        <span class="u-block" v-if="center.breakFixHours">{{getLabel('supportCentersBlock', 'breakFixHours')}}: {{center.breakFixHours}}</span>
                                        <span class="u-block" v-if="center.email">{{getLabel('supportCentersBlock', 'email')}}: {{center.email}}</span>
                                        <span class="u-block" v-if="center.phoneNumber">{{getLabel('supportCentersBlock', 'telephone')}}: {{center.phoneNumber}}</span>
                                        <span class="u-block" v-if="center.languageName">{{getLabel('supportCentersBlock', 'language')}}: {{center.languageName}}</span>
                                    </p>
                                </div>
                            </template>
                        </Collapse>
                    </Column>
                <!-- </template> -->
            </Row>
    </Column>
</Row>
</template>

<script>
import Collapse from '@/Scripts/components/molecules/Collapse.vue';
import Datetime from '@/Scripts/components/atoms/Datetime.vue';

export default {
    props: {
        title: String,
        intro: String,
        supportCenters: Array
    },
    components: {
        Collapse,
        Datetime
    }
};
</script>

<style lang="scss">
.c-support-centers-block {
    .c-date {
        display: inline-block;
    }
    &__intro {
        margin-bottom: 3rem;
    }
    &__center {
        margin-bottom: 3rem;
    }
}
</style>
