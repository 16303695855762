var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-file-list-block" },
    [
      _c(
        "Column",
        { attrs: { md: 10 } },
        [
          _c("Heading", { attrs: { level: 3, noMarginTop: "" } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("Column", { attrs: { md: 14 } }),
      _vm._v(" "),
      _vm._l(_vm.files, function (item, index) {
        return _c(
          "Column",
          {
            key: index,
            staticClass: "c-file-list-block__item-wrapper",
            attrs: { md: 10, offsetMd: index % 2 === 0 ? 0 : 4 },
          },
          [
            _c(
              "Anchor",
              { attrs: { href: item.url, download: item.name } },
              [
                _c(
                  "Row",
                  [
                    _c("Column", { attrs: { xs: 20 } }, [
                      _c("span", { staticClass: "o-ingress" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "Column",
                      { staticClass: "c-list__icon-wrapper", attrs: { xs: 4 } },
                      [
                        _c("Icon", {
                          staticClass: "c-file-list-block__icon",
                          attrs: { icon: "arrow_down" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }