var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.model.title !== ""
      ? _c("h4", { staticClass: "accordion-title" }, [
          _vm._v("\n        " + _vm._s(_vm.model.title) + "\n        "),
          _c(
            "strong",
            {
              staticStyle: { color: "rgb(255, 106, 76)" },
              attrs: { "aria-hidden": "hidden" },
            },
            [_vm._v(".")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "episerver-accordion",
        class: { "o-bg": _vm.backgroundColor, color: _vm.textColor },
        style: _vm.style,
      },
      _vm._l(_vm.model.content.content, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "accordion-item" },
          [
            _c(
              "div",
              {
                staticClass: "accordion-header",
                on: {
                  click: function ($event) {
                    return _vm.toggleItem(index)
                  },
                },
              },
              [
                _c("span", [
                  _c(
                    "div",
                    { staticClass: "accordion-header" },
                    [_c("XhtmlField", { attrs: { items: item.heading } })],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("svg", { staticClass: "toggle-sign" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        _vm.activeIndex === index ? "#minus" : "#plus",
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("transition", { attrs: { name: "accordion" } }, [
              _vm.activeIndex === index
                ? _c(
                    "div",
                    { staticClass: "accordion-content" },
                    [_c("XhtmlField", { attrs: { items: item.content } })],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }