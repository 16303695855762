var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-expandable-list-block" },
    [
      !_vm.hideIntro
        ? _c(
            "Column",
            { attrs: { md: 10 } },
            [
              _vm.label
                ? _c("Heading", { attrs: { isCategory: "" } }, [
                    _vm._v(_vm._s(_vm.label)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("Heading", { attrs: { level: 3, noMarginTop: "" } }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c("XhtmlField", {
                staticClass: "o-ingress",
                attrs: { items: _vm.intro },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hideIntro
        ? _vm._l(_vm.items, function (item, index) {
            return _c(
              "Column",
              {
                key: `${_vm._uid}_${index}`,
                staticClass:
                  "c-expandable-list-block__item-wrapper--hide-intro",
                attrs: { md: 10, offsetMd: index % 2 === 0 ? 0 : 4 },
              },
              [
                _c(
                  "div",
                  { staticClass: "c-expandable-list-block__item" },
                  [
                    _c("Collapse", {
                      attrs: {
                        border: true,
                        iconOpen: "minus",
                        iconClosed: "plus",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "Heading",
                                  {
                                    attrs: {
                                      noMarginTop: "",
                                      noMarginBottom: "",
                                      level: 4,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(item.title) +
                                        "\r\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("XhtmlField", {
                                  attrs: { items: item.content },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ]
            )
          })
        : [
            _c(
              "Column",
              {
                staticClass: "c-expandable-list-block__item-wrapper",
                attrs: { md: 10, offsetMd: 4 },
              },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `${_vm._uid}_${index}`,
                    staticClass: "c-expandable-list-block__item",
                  },
                  [
                    _c("Collapse", {
                      attrs: {
                        border: true,
                        iconOpen: "minus",
                        iconClosed: "plus",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "Heading",
                                  {
                                    attrs: {
                                      noMarginTop: "",
                                      noMarginBottom: "",
                                      level: 4,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(item.title) +
                                        "\r\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("XhtmlField", {
                                  attrs: { items: item.content },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }