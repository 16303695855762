var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-ir-figures-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-ir-figures-page__top" },
            [
              _vm.model.iframeQuarterlyResults
                ? _c("div", {
                    staticClass: "c-ir-figures-page__top__iframe-wrapper",
                    domProps: {
                      innerHTML: _vm._s(_vm.model.iframeQuarterlyResults),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 10 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { level: 3, noMarginTop: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                  _vm._v(" "),
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "IntroLeft",
                        expression: "'IntroLeft'",
                      },
                    ],
                    attrs: { items: _vm.model.introLeft },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { offsetMd: 4, md: 10 } },
                [
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "IntroRight",
                        expression: "'IntroRight'",
                      },
                    ],
                    attrs: { items: _vm.model.introRight },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.model.twoColumn1.content
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "TwoColumn1",
                        expression: "'TwoColumn1'",
                      },
                    ],
                    staticClass: "c-ir-figures-page__twocol1",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoColumn1.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.twoColumn2.content
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "TwoColumn2",
                        expression: "'TwoColumn2'",
                      },
                    ],
                    staticClass:
                      "c-ir-figures-page__twocol2 o-bg o-bg--beige-light",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoColumn2.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Row",
            {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "FinancialFigures",
                  expression: "'FinancialFigures'",
                },
              ],
              staticClass:
                "c-ir-figures-page__financial-numbers o-bg o-bg--beige-light",
            },
            [
              _c(
                "Column",
                [
                  _c("Heading", { attrs: { isCategory: "" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.getLabel(
                          "irGroupFactsAndFiguresPage",
                          "financialFigures"
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.model.financialFigures, function (item, index) {
                return _c(
                  "Column",
                  { key: "finFig_" + index, attrs: { md: 6 } },
                  [
                    _c(
                      "Heading",
                      { attrs: { noMarginTop: "", noMarginBottom: "" } },
                      [_vm._v(_vm._s(item.figure))]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(item.label))]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "FileListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "FileListBlock",
                    expression: "'FileListBlock'",
                  },
                ],
                staticClass:
                  "c-ir-figures-page__reports o-bg o-bg--beige-light",
              },
              "FileListBlock",
              _vm.model.fileListBlock.content,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "Row",
            {
              staticClass:
                "c-ir-figures-page__accentuated-text o-bg o-bg--beige-light",
            },
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "AccentuatedText1",
                          expression: "'AccentuatedText1'",
                        },
                      ],
                      attrs: { level: 1 },
                    },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(_vm.model.accentuatedText1) +
                          "\r\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BubblesBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "BubblesBlock",
                    expression: "'BubblesBlock'",
                  },
                ],
                staticClass: "c-ir-figures-page__bubbles",
              },
              "BubblesBlock",
              _vm.model.bubblesBlock.content,
              false
            )
          ),
          _vm._v(" "),
          _c(
            "Row",
            {
              staticClass:
                "c-ir-figures-page__accentuated-text o-bg o-bg--beige-light",
            },
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "AccentuatedText2",
                          expression: "'AccentuatedText2'",
                        },
                      ],
                      attrs: { level: 2 },
                    },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(_vm.model.accentuatedText2) +
                          "\r\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "OfficesBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "OfficesBlock",
                    expression: "'OfficesBlock'",
                  },
                ],
                staticClass: "c-ir-figures-page__offices",
              },
              "OfficesBlock",
              _vm.model.officesBlock.content,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }