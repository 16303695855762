var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-service-page" },
    [
      _c("PracticeAreaHero", { attrs: { model: _vm.model } }),
      _vm._v(" "),
      _vm.notEmptyObject(_vm.model.siblings) || _vm.isEditable
        ? _c(
            "Container",
            { staticClass: "practicearea--navigation", attrs: { isFluid: "" } },
            [
              _c("Container", { staticClass: "u-hidden-xs u-hidden-sm" }, [
                _c(
                  "ul",
                  { staticClass: "o-flat-list practicearea--navigation__list" },
                  _vm._l(_vm.model.siblings.siblings, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: `${_vm._uid}${index}`,
                        staticClass: "practicearea--navigation__list--element",
                      },
                      [
                        _c(
                          "Anchor",
                          {
                            class: _vm.compareGuid(item.guid)
                              ? "practicearea--navigation__list--selected"
                              : "",
                            attrs: { href: item.url, ariaLabel: item.title },
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("Dropdown", {
                staticClass: "u-display-xs u-display-sm",
                attrs: { options: _vm.dropdownValues, clearable: false },
                on: { input: _vm.changeRoute },
                model: {
                  value: _vm.dropdownSelection,
                  callback: function ($$v) {
                    _vm.dropdownSelection = $$v
                  },
                  expression: "dropdownSelection",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.notEmptyObject(_vm.model.bubblesBlock.content) || _vm.isEditable
            ? _c(
                "BubblesBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "BubblesBlock",
                        expression: "'BubblesBlock'",
                      },
                    ],
                    staticClass: "c-service-page__bubbles",
                  },
                  "BubblesBlock",
                  _vm.model.bubblesBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.media && _vm.model.media.content) ||
          _vm.isEditable
            ? _c(
                "MediaBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Media",
                        expression: "'Media'",
                      },
                    ],
                    staticClass: "c-service-page__media-block",
                  },
                  "MediaBlock",
                  _vm.model.media.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(
            _vm.model.twoClColumnBlock && _vm.model.twoClColumnBlock.content
          ) || _vm.isEditable
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "twoClColumnBlock",
                        expression: "'twoClColumnBlock'",
                      },
                    ],
                    staticClass: "c-service-page__twocol1",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoClColumnBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.teaserBlock.content) || _vm.isEditable
            ? _c(
                "SingleTeaserBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "teaserBlock",
                        expression: "'teaserBlock'",
                      },
                    ],
                    staticClass: "c-service-page__teaserblock",
                  },
                  "SingleTeaserBlock",
                  _vm.model.teaserBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(
            _vm.model.secondTwoColumnBlock &&
              _vm.model.secondTwoColumnBlock.content
          )
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "secondTwoColumnBlock",
                        expression: "'secondTwoColumnBlock'",
                      },
                    ],
                    staticClass: "c-service-page__twocol2",
                  },
                  "TwoColumnBlock",
                  _vm.model.secondTwoColumnBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.blocks || _vm.isEditable
            ? _c("ContentArea", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Blocks",
                    expression: "'Blocks'",
                  },
                ],
                staticClass: "c-service-page__content-area",
                attrs: { model: _vm.model.blocks || _vm.isEditable },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "PageListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "pageListBottom",
                    expression: "'pageListBottom'",
                  },
                ],
                staticClass: "c-service-page__page-list",
              },
              "PageListBlock",
              _vm.model.pageListBottom && _vm.model.pageListBottom.content,
              false
            )
          ),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.form && _vm.model.form.content) ||
          _vm.isEditable
            ? _c(
                "FormBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Form",
                        expression: "'Form'",
                      },
                    ],
                    ref: "contactform",
                    staticClass: "c-service-page__form",
                    attrs: { id: "form" },
                  },
                  "FormBlock",
                  _vm.model.form.content,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.notEmptyObject(_vm.model.newsBlock) || _vm.isEditable
        ? _c(
            "Container",
            {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "NewsBlock",
                  expression: "'NewsBlock'",
                },
              ],
              attrs: { isFluid: "", background: "beige-light" },
            },
            [
              _c(
                "Container",
                [
                  _c(
                    "NewsBlock",
                    _vm._b(
                      {
                        attrs: {
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      },
                      "NewsBlock",
                      _vm.model.newsBlock.content,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }