var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-ir-people-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 10 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { level: 3, noMarginTop: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("PersonList", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "People",
                expression: "'People'",
              },
            ],
            staticClass: "c-ir-people-page__list",
            attrs: { people: _vm.model.people },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }