var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-speaker" }, [
    _vm.image
      ? _c(
          "div",
          { staticClass: "c-speaker__image" },
          [
            _c("ResponsiveImage", {
              staticClass: "circular-image",
              attrs: {
                alt: _vm.name,
                xs: { w: 200, h: 200 },
                sm: { w: 200, h: 200 },
                md: { w: 200, h: 200 },
                lg: { w: 200, h: 200 },
                src: _vm.image,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "o-ingress" }, [
      _c("p", { staticClass: "c-speaker__name" }, [_vm._v(_vm._s(_vm.name))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-speaker__job-title-and-company" }, [
      _c("div", [
        _vm._v(
          "\r\n            " +
            _vm._s(_vm.jobTitle) +
            " - " +
            _vm._s(_vm.companyName) +
            "\r\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-speaker__biography" }, [
      _c(
        "div",
        { staticClass: "c-speaker__dropdown", on: { click: _vm.toggleBio } },
        [
          _c("Button", {
            class: _vm.isBioVisible ? "rotate-icon" : "",
            attrs: { buttonStyle: "no-style", icon: "chevron_down" },
          }),
          _vm._v(" "),
          _c("div", [
            _vm._v(_vm._s(_vm.isBioVisible ? "Show Less" : "Read Bio")),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isBioVisible
        ? _c("div", [_c("XhtmlField", { attrs: { items: _vm.biography } })], 1)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }