<template>
<div class="o-xhtml-field">
    <template v-for="(item, index) in items" :string="item.xhtmlString" >
        <BlockComponentSelector v-if="item.isBlock" :key="index" :model="item.blockModel"/>
        <XhtmlString v-else :string="item.xhtmlString" :key="index"/>
    </template>
</div>
</template>

<script>
import XhtmlString from '@/Scripts/components/atoms/XhtmlString.vue';
import BlockComponentSelector from '@/Scripts/components/BlockComponentSelector.vue';

export default {
    components: {
        XhtmlString,
        BlockComponentSelector
    },
    props: {
        items: {
            type: Array
        }
    }
};
</script>
