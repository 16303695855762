var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-ebook-page" },
    [
      _vm.model.heroImage && _vm.model.heroImage.url
        ? _c("Hero", {
            attrs: {
              title: _vm.model.title,
              subtitle: _vm.model.label,
              heroImage: _vm.model.heroImage && _vm.model.heroImage.url,
              overlay: _vm.model.heroOverlay,
              changeTitleColor: _vm.model.changeTitleColor,
              changeLinkColor: _vm.model.changeLinkColor,
              introText: _vm.model.introText,
              embed: _vm.model.formData,
              overlayImage: _vm.model.overlayImage,
              formBackground: _vm.model.formBackground,
              formText: _vm.model.formText,
              formLabel: _vm.model.formLabel,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        { staticClass: "c-ebook-page__content" },
        [
          _vm.model.descriptionBlock.content.title
            ? _c(
                "Row",
                [
                  _c(
                    "Column",
                    { staticClass: "c-ebook-page__title" },
                    [
                      _c("Heading", { attrs: { level: 2 } }, [
                        _vm._v(
                          _vm._s(_vm.model.descriptionBlock.content.title)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Row",
            [
              _c(
                "Column",
                {
                  staticClass: "c-ebook-page__content-left",
                  attrs: { md: 11 },
                },
                [
                  _vm.model.descriptionBlock.content.description ||
                  _vm.isEditable
                    ? _c("XhtmlField", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Description",
                            expression: "'Description'",
                          },
                        ],
                        staticClass: "o-ingress",
                        attrs: {
                          items: _vm.model.descriptionBlock.content.description,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.descriptionBlock.content.buttonLink &&
                  _vm.model.descriptionBlock.content.buttonText
                    ? _c(
                        "Button",
                        {
                          staticClass: "o-button--filled",
                          attrs: {
                            isLarge: "",
                            href: _vm.model.descriptionBlock.content.buttonLink
                              .uri,
                          },
                        },
                        [
                          _vm._v(
                            "\r\n                    " +
                              _vm._s(
                                _vm.model.descriptionBlock.content.buttonText
                              ) +
                              "\r\n                "
                          ),
                        ]
                      )
                    : _vm.model.descriptionBlock.content.buttonText
                    ? _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#embed",
                              expression: "'#embed'",
                            },
                          ],
                          staticClass: "o-button--filled",
                          attrs: { isLarge: "" },
                        },
                        [
                          _vm._v(
                            "\r\n                    " +
                              _vm._s(
                                _vm.model.descriptionBlock.content.buttonText
                              ) +
                              "\r\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                {
                  staticClass: "c-ebook-page__content-right",
                  attrs: { md: 12, offsetMd: 1 },
                },
                [
                  _vm.model.descriptionBlock.content.benefits
                    ? _c(
                        "ul",
                        { staticClass: "o-flat-list c-ebook-page__benefits" },
                        _vm._l(
                          _vm.model.descriptionBlock.content.benefits,
                          function (benefit, index) {
                            return _c("li", { key: index }, [
                              _c(
                                "div",
                                { staticClass: "c-ebook-page__benefits--icon" },
                                [
                                  _c("ResponsiveImage", {
                                    attrs: {
                                      alt: benefit.title,
                                      src: benefit.image,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "c-ebook-page__benefits--text" },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(benefit.title) +
                                        "\r\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(benefit.text) +
                                        "\r\n                        "
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }