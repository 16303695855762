var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "o-block c-quote-block",
      class: { "c-quote-block--advanced": !_vm.isSimple },
    },
    [
      _vm.isSimple
        ? _c(
            "Row",
            [
              _c("Column", { attrs: { autoLg: "" } }, [
                _c("span", { staticClass: "c-quote-block__dot" }),
                _vm._v(" "),
                _c("span", { staticClass: "c-quote-block__dot" }),
              ]),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { equalLg: "" } },
                [
                  _c(
                    "Heading",
                    {
                      staticClass: "c-quote-block__quote",
                      attrs: { level: 4 },
                    },
                    [_vm._v(_vm._s(_vm.quote || _vm.model.quote))]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSimple
        ? _c(
            "Row",
            [
              _c("Column", { attrs: { align: "right" } }, [
                _c("span", { staticClass: "c-quote-block__signature" }, [
                  _vm._v(_vm._s(_vm.signature || _vm.model.signature)),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isSimple
        ? _c(
            "Row",
            {
              staticClass: "o-bg o-bg--beige-light c-quote-block__advanced-row",
            },
            [
              _c(
                "BreakoutColumn",
                {
                  staticClass: "c-quote-block__breakout",
                  attrs: {
                    md: 10,
                    offsetMd: 0,
                    sm: 18,
                    offsetSm: 6,
                    breakoutSide: "right",
                  },
                },
                [
                  _c("ResponsiveImage", {
                    attrs: {
                      src: _vm.image,
                      imageSizeLg: 715,
                      imageSizeXs: 515,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                {
                  staticClass:
                    "u-flex u-align-items-center c-quote-block__content",
                  attrs: { md: 14, sm: 22 },
                },
                [
                  _c("QuoteBlock", {
                    attrs: {
                      model: { quote: _vm.quote, signature: _vm.signature },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }