<template>
<div class="c-key-figures-block">
    <Row class="c-key-figures-block__figures">
        <Column :md="8" v-for="(item, index) in model.content" :key="'keyFig_' + index">
            <div class="c-key-figures-block__figure">
                <p>
                    <span class="c-key-figures-block__figurevalue">{{item.figure}}</span>
                    {{item.label}}
                </p>
                <Anchor isClickable isFancy :href="item.url">{{item.linkText}}</Anchor>
            </div>
        </Column>
    </Row>
</div>
</template>

<script>
import Anchor from '@/Scripts/components/atoms/Anchor.vue';

export default {
    props: {
        model: {
            type: Object,
        },
        keyFigures: {
            type: Array,
        }
    },
    components: {
        Anchor
    }
};
</script>

<style lang="scss">
@import '@/Styles/Tools/_breakpoints.scss';
@import '@/Styles/tools/_font-mixin.scss';

.c-key-figures-block {
    $self: &;
    width: 100%;
    margin-bottom: 3.3rem;

    &__figure {
        padding: 0 10px;
        margin-bottom: 1.2rem;
    }
    &__figures {
        @include media-md {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }

    p {
        @include fontSize(26px);
        line-height: 1.6;
        margin-bottom: 1.3rem !important;
    }

    &__figurevalue {
        color: $orange;
        @include fontSize(38px)
    }
}
</style>
