<template>
<div class="c-shareholders">
    <div class="c-shareholders-heading">
            <Heading :level="5">{{'Top Shareholders'}}</Heading>
    </div>
    <div class="c-shareholders-header">
        <div>Investor</div>
        <div class="c-shareholders-header-label">Number of shares</div>
        <div class="u-hidden-xs c-shareholders-header-label">% of top 20</div>
        <div class="u-hidden-xs c-shareholders-header-label">% of total</div>
        <div class="u-hidden-xs c-shareholders-header-label">Type</div>
        <div class="u-hidden-xs c-shareholders-header-label">Country</div>
   </div>
   <div v-for="(shareholder, index) in shareholders.entries" :key="index">
            <ShareholderItem v-if="shareholder.topPositionShares !== 0" :shareholder="shareholder" :totalShares="shareholders.totalShares" :totalSharesTopTwenty="getTotalSharesOfTopTwenty" />
    </div>
    <div>
        <ShareholderItem class="c-shareholders-sum" :shareholder="totalTopTwenty" :totalShares="shareholders.totalShares" />
    </div>
    <div>
        <ShareholderItem :shareholder="other" :totalShares="shareholders.totalShares" />
    </div>
    <div>
        <ShareholderItem class="c-shareholders-sum" :shareholder="total" :totalShares="shareholders.totalShares" />
    </div>
    <div class="c-shareholders-update-label">
        Updated {{shareholders.updateTime}}
    </div>
</div>
</template>

<script>

import ShareholderItem from '@/Scripts/components/atoms/ShareholderItem.vue';

export default {
    data() {
        return {
            totalSharesOfTopTwenty: 0
        };
    },
    props: {
        shareholders: {
            type: Object,
        }
    },
    components: {
        ShareholderItem
    },
    computed: {
        getTotalSharesOfTopTwenty() {
            var totalSharesOfTopTwenty = 0;
            this.shareholders.entries.forEach(shareholder => {
                totalSharesOfTopTwenty += shareholder.topPositionShares;
            });
            return totalSharesOfTopTwenty;
        },
        totalTopTwenty() {
            return {
                topInvestor: 'Sum top 20',
                topPositionShares: this.getTotalSharesOfTopTwenty
            };
        },
        other() {
            return {
                topInvestor: 'Other',
                topPositionShares: this.shareholders.totalShares - this.getTotalSharesOfTopTwenty
            };
        },
        total() {
            return {
                topInvestor: 'Total',
                topPositionShares: this.shareholders.totalShares
            };
        }
    },
};
</script>

<style lang="scss">
.c-shareholders{
    padding-bottom: 5em;
}
.c-shareholders-header{
    display: flex;
    flex-direction: row;
    padding: 12px;
    border-bottom: 2px solid black;
}
.c-shareholders-header {
    > div{
        width: 100%;
        text-align: left;
    }
    &-label {
        font-size: 0.9em;
        @include media-md {
            font-size:1em;
        }
    }
}
.c-shareholders-sum {
    font-weight: 900;
}
.c-shareholders-update-label {
    margin-top: 2em;
}
.c-shareholders-header > div:first-child{
    width:250%;
}

</style>
