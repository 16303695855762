<template>
<Container isFluid class="c-header-top" :class="[{'c-header-top--white' : isWhite && !isMenuOpen && !isLanguageSelectorOpen}]">
    <Row>
        <Column :md="8" :xs="12">
            <Anchor class="c-header-top__logo" :href="currentLanguage.url" icon="crayon_logo"/>
        </Column>
        <Column :md="8" align="center" className="c-header-top__links" class="u-hidden-sm u-hidden-xs" >
            <Button buttonStyle="no-style" iconRight :icon="isLanguageSelectorOpen ? 'cross' : 'chevron_down'" @click.native="_toggleLanguageSelector">{{languageSelectorText}}</Button>
        </Column>
        <Column :md="8" :xs="12" align="right" className="c-header-top__links">
            <Button v-if="loginPage" :href="loginPage" :target="'_blank'" buttonStyle="no-style" icon="social" class="u-hidden-xs">{{getLabel('menu', 'logIn')}}</Button>
            <Button :href="searchPage" buttonStyle="no-style" icon="search" class="u-hidden-xs">{{getLabel('menu', 'searchButton')}}</Button>
            <Button buttonStyle="no-style" :icon="isMenuOpen ? 'cross' : 'menu'" @click.native="_toggleMenu"><span>{{isMenuOpen ? getLabel('global', 'close') : getLabel('menu', 'menu')}}</span></Button>
        </Column>
    </Row>
</Container>
</template>

<script>
import { TOGGLE_LANGUAGESELECTOR, TOGGLE_MENU, DISABLE_SCROLLING, ENABLE_SCROLLING } from '@/Scripts/store/modules/appContext';
import { mapMutations } from 'vuex';

export default {
    props: {
        isWhite: Boolean,
        isMenuOpen: Boolean,
        isLanguageSelectorOpen: Boolean,
        currentLanguage: Object,
        scrollToTopOnClick: Boolean,
        searchPage: String,
        loginPage: String
    },
    methods: {
        ...mapMutations({
            toggleLanguageSelector: TOGGLE_LANGUAGESELECTOR,
            toggleMenu: TOGGLE_MENU,
            disableScroll: DISABLE_SCROLLING,
            enableScroll: ENABLE_SCROLLING
        }),
        _handleScroll(menuElementOpen) {
            var timeout = this.scrollToTopOnClick ? 700 : 0;
            // If current menu isnt open disable scroll before opening it.
            if (!menuElementOpen) {
                // If used in footer scroll up to menus.
                if (this.scrollToTopOnClick) {
                    this.$scrollTo({x: 0, y: 0});
                }
                // Disable scrolling after set timeout. Is used to handle vue-scrollto.
                setTimeout(() => {
                    this.disableScroll();
                }, timeout);
            } else {
                // Enable scroll if menu is going to close after this method.
                this.enableScroll();
            }
        },
        _toggleMenu() {
            this._handleScroll(this.isMenuOpen);
            this.toggleMenu();

        },
        _toggleLanguageSelector() {
            this._handleScroll(this.isLanguageSelectorOpen);
            this.toggleLanguageSelector();
        }
    },
    computed: {
        languageSelectorText() {
            if (this.isLanguageSelectorOpen) {
                return this.getLabel('global', 'close');
            }
            return `${this.getLabel('languageSelector', 'country')}: ${this.currentLanguage.name}`;
        },
    },

};
</script>

<style lang="scss">
.c-header-top {
    $self: &;
    padding: 1rem;
    margin-bottom: 1rem;

    &__logo {
        .c-icon {
            width: 9rem;
            height: 2rem;
            position: absolute;
        }
    }
    &__links {
        a, button {
            color: $black;
            margin-left: 2rem;
            text-decoration: none;
        }
        #{ $self }--white & {
            a, button {
                color: white;
            }
        }
    }
      &--white {
        color: white;

        .c-icon {
            fill: white;
        }
    }
}
</style>
