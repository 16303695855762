var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-custom-campaign-page" },
    [
      _vm.showHeaderFooter
        ? _c(
            "Header",
            _vm._b(
              {
                attrs: {
                  isWhite: _vm.isStartPage,
                  isOverlapping: _vm.isStartPage,
                  showBreadcrumbs: !_vm.isStartPage,
                  isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
                  isMenuOpen: _vm.isMenuOpen,
                  currentLanguage: _vm.currentLanguage,
                },
              },
              "Header",
              _vm.model.header,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.model.heroImage && _vm.model.heroImage.url) ||
      (_vm.model.heroVideo &&
        _vm.model.heroVideo.content &&
        _vm.model.heroVideo.content.videoEmbedCode != null)
        ? _c("Hero", {
            attrs: {
              buttonText: _vm.model.heroLinkText,
              title: _vm.model.title,
              subtitle: _vm.model.heroSubtitle,
              heroImage: _vm.model.heroImage && _vm.model.heroImage.url,
              heroVideo: _vm.model.heroVideo,
              overlay: _vm.model.heroOverlay,
              url: _vm.model.heroLink,
              changeTitleColor: _vm.model.changeTitleColor,
              changeLinkColor: _vm.model.changeLinkColor,
              url2: _vm.model.heroLink2,
              buttonText2: _vm.model.heroLink2Text,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.model.body || _vm.isEditable
            ? _c("XhtmlField", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Body",
                    expression: "'Body'",
                  },
                ],
                attrs: { items: _vm.model.body },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.showBrightTalkForm
        ? _c("div", { staticClass: "iframe-container" }, [
            _c("div", {
              ref: "brighttalkWrapper",
              staticClass: "jsBrightTALKEmbedWrapper",
              staticStyle: {
                width: "100%",
                height: "100%",
                position: "relative",
                background: "#ffffff",
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showHeaderFooter
        ? _c(
            "Footer",
            _vm._b(
              {},
              "Footer",
              {
                ..._vm.model.footer,
                headerTop: {
                  isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
                  isMenuOpen: _vm.isMenuOpen,
                  currentLanguage: _vm.currentLanguage,
                  searchPage: _vm.searchPage,
                  loginPage: _vm.loginPage,
                },
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }