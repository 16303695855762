<template>
<div class="c-order-items">
    <div class="c-order-items-header">
        <div class="c-order-item-number">Volume</div>
        <div class="c-order-item-number">Bid</div>
        <div class="c-order-item-empty">&nbsp;</div>
        <div class="c-order-item-right c-order-item-number">Ask</div>
        <div class="c-order-item-right c-order-item-number">Volume</div>
   </div>
   <div v-for="(order, index) in orders" :key="index">
            <OrderItem :order="order" :maxVolume="biggestVolume" />
    </div>
</div>
</template>

<script>

import OrderItem from '@/Scripts/components/atoms/Orderitem.vue';

export default {
    data() {
        return {
            biggestVolume: 0
        };
    },
    props: {
        orders: {
            type: Array,
            required: true,
        }
    },
    components: {
        OrderItem,
    },
    methods: {
        findMaxVolume() {
            let tempBiggestVolume = 0;
            const tempOrders = this.orders;
            tempOrders.forEach((order) => {
                if (tempBiggestVolume === 0 || tempBiggestVolume < order.askvol || tempBiggestVolume < order.bidvol) {
                    if (order.bidvol < order.askvol) {
                        tempBiggestVolume = order.askvol;
                    } else {
                        tempBiggestVolume = order.bidvol;
                    }
                }
            });
            this.biggestVolume = tempBiggestVolume;
        },
    },
    beforeMount() {
        this.findMaxVolume();
    }
};
</script>

<style lang="scss">
.c-order-items-header{
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    justify-content: space-around;
    border-bottom: 2px solid black;
}
.c-order-item-empty{
    flex-grow: 1;
}
.c-order-item-number{
    width:80px;
}
.c-order-item-right{
    text-align: right !important;
}

</style>
