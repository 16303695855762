var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.src
    ? _c("img", {
        staticClass: "c-responsive-image",
        attrs: {
          alt: _vm.alt,
          src: _vm.src,
          srcset: _vm.computedSrcset,
          sizes: _vm.computedSizes,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }