<!-- TODO: FIX FORM-->
<template>
<main class="c-product-page">
    <Container>
         <Row>
            <Column>
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
        </Row>
        <Row class="c-product-page__intro">
            <Column :md="11">
                <Heading noMarginTop :level="1" v-epi-edit="'title'" class="c-product-page__title">{{model.title}}</Heading>
                <Heading noMarginTop :level="4" v-epi-edit="'LargeIntroText'">
                    <XhtmlField :items="model.largeIntroText"/>
                </Heading>
            </Column>
            <Column :md="11" :offset-md="2" class="c-product-page__left-intro">
                <XhtmlField class="o-ingress" v-epi-edit="'SmallIntroText'" :items="model.smallIntroText"/>
                <Button v-scroll-to="'#form'" v-if="notEmptyObject(model.form && model.form.content)">{{getLabel('productPage','topBannerButtonText')}}</Button>
            </Column>
        </Row>
        <!-- eslint-disable-next-line no-irregular-whitespace -->
        <MediaBlock v-epi-edit="'Media'" v-if="model.media.content ||isEditable" v-bind="model.media.content" class="c-product-page__media"/>
        <TwoColumnBlock v-bind="model.twoColumnBlock.content" v-epi-edit="'TwoColumnBlock'" class="c-product-page__body1"/>
    </Container>
    <Container isFluid background="beige-light" v-if="relatedServices1">
        <Container>
            <Heading :level="2" noMarginTop class="u-add-colored-period">{{getLabel('productPage', 'relatedServicesText')}}</Heading>
            <Row>
                <Column :md="10">
                    <List :items="relatedServices1">
                        <template v-slot:default="{item}">
                            <Heading isCategory>{{item.label}}</Heading>
                            <Heading noMarginTop :level="6">{{item.title}}</Heading>
                            <v-clamp autoresize :max-lines="2">{{item.intro}}</v-clamp>
                        </template>
                    </List>
                </Column>
                <Column :md="10" :offsetMd="4">
                    <List :items="relatedServices2">
                        <template v-slot:default="{item}">
                            <Heading isCategory>{{item.label}}</Heading>
                            <Heading noMarginTop :level="6">{{item.title}}</Heading>
                            <v-clamp autoresize :max-lines="2">{{item.intro}}</v-clamp>
                        </template>
                    </List>
                </Column>
            </Row>
        </Container>
    </Container>
    <Container>
        <TwoColumnBlock v-bind="model.secondTwoColumnBlock.content" v-epi-edit="'SecondTwoColumnBlock'" class="c-product-page__body2"/>
        <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-product-page__content-area" :model="model.blocks  || isEditable"/>
    </Container>
    <Container isFluid background="beige-light" marginHack>
        <Container>
            <FormBlock v-epi-edit="'Form'" id="form" class="c-product-page__form" v-if="notEmptyObject(model.form && model.form.content) || isEditable" v-bind="model.form.content"/>
        </Container>
    </Container>
    <Container v-if="(model.relatedProducts && model.relatedProducts.length > 0)" class="c-product-page__other-products">
       <ProductList :products="model.relatedProducts" :title="`${getLabel('productPage', 'otherProducts1')} ${model.label} ${getLabel('productPage', 'otherProducts2')}`"/>
    </Container>
    <Container isFluid background="beige-light" v-if="model.newsBlock.content || isEditable">
        <Container>
            <NewsBlock v-bind="model.newsBlock.content" :useUsDateFormat="model.siteSettings.useUsDateFormat" v-epi-edit="'NewsBlock'"/>
        </Container>
    </Container>
</main>
</template>

<script>
import List from '@/Scripts/components/molecules/List.vue';
import ProductList from '@/Scripts/components/molecules/ProductList.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import VClamp from 'vue-clamp';

export default {
    props: {
        model: Object
    },
    components: {
        List,
        ProductList,
        VClamp,
        ContentArea
    },
    computed: {
        relatedServices1() {
            if (this.model.relatedServices && this.model.relatedServices.length > 0) {
                let newArr = [...this.model.relatedServices];
                return newArr.splice(0, Math.ceil(newArr.length / 2));
            }
        },
        relatedServices2() {
            if (this.model.relatedServices && this.model.relatedServices.length > 0) {
                let newArr = [...this.model.relatedServices];
                return newArr.splice(Math.ceil(newArr.length / 2), newArr.length);
            }
        }
    }
};
</script>

<style lang="scss">
.c-product-page {
    &__title {
        margin-bottom: 1rem;
        @include media-md {
            margin-bottom: 2rem;
        }
    }
    &__left-intro {
        @include media-md {
            margin-top: 2rem;
        }
    }
    &__intro {
        margin-bottom: 4rem;
    }
    &__media {
        margin-bottom: 5rem;
    }
    &__body1, &__body2 {
        margin-bottom: 5rem;
    }
    &__body2 {
        margin-top: 5rem;
    }
    &__other-products {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    &__content-area {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
