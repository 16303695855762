var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-partners-block" },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            { attrs: { md: 9 } },
            [
              _c("Heading", { attrs: { isCategory: "" } }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
              _vm._v(" "),
              _c(
                "Heading",
                { staticClass: "c-partners-block__text", attrs: { level: 4 } },
                [_vm._v(_vm._s(_vm.intro))]
              ),
              _vm._v(" "),
              _vm.url
                ? _c("Anchor", { attrs: { isFancy: "", href: _vm.url } }, [
                    _vm._v(
                      _vm._s(
                        _vm.linkText
                          ? _vm.linkText
                          : _vm.getLabel("global", "readMore")
                      )
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { attrs: { md: 13, "offset-md": 2 } },
            [
              _c("Heading", { attrs: { isCategory: "" } }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c("HorizontalSlider", {
                attrs: {
                  items: _vm.items,
                  scroll: 1,
                  noOfItemsToShow: 1,
                  autoScroll: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "Row",
                          _vm._l(item, function (partner, index) {
                            return _c(
                              "Column",
                              {
                                key: `partner_${index}`,
                                staticClass: "c-partners-block__partner",
                                attrs: { sm: 8, xs: 12 },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "c-partners-block__image-container",
                                  },
                                  [
                                    _c(
                                      "ResponsiveImage",
                                      _vm._b(
                                        {
                                          attrs: {
                                            src: partner.imageUrl,
                                            alt: partner.imageTitle,
                                          },
                                        },
                                        "ResponsiveImage",
                                        {
                                          xs: { w: 145, h: 125 },
                                          sm: { w: 145, h: 125 },
                                          md: { w: 145, h: 125 },
                                          lg: { w: 145, h: 125 },
                                        },
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }