<template>
<Row wrapper="ul" class="c-list o-flat-list">
    <Column wrapper="li" v-for="(item, index) in items" :key="`listitem_${_uid}_${index}`" class="c-list__item">
        <component :is="item.url && !disableUrl ? 'Anchor' : 'div'" :target="target" :href="item.url" class="c-list__item-wrapper" :class="itemClass">
            <Row>
                <Column :xs="20">
                    <slot v-bind:item="item"><span :level="6" class=" u-no-margins">{{item.title}}</span></slot>
                </Column>
                <Column :xs="4" class="c-list__icon-wrapper">
                    <slot name="icon" v-bind:item="item"><Icon v-if="item.url" :icon="'chevron_right'" class="c-list__icon"/></slot>
                </Column>
            </Row>
        </component>
    </Column>
</Row>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';

export default {
    props: {
        itemClass: String,
        items: Array,
        target: String,
        disableUrl: {
            type: Boolean,
            default: false // eslint-disable-line
        }
    },
    components: {
        Icon,
        Anchor,
        Heading
    }
};
</script>

<style lang="scss">
.c-list {
    $self: &;
    a {
        color: inherit;
        text-decoration: none;
    }
    &__item-wrapper {
        display: block;
        border-bottom: 1px solid $black;
        padding-top: 1em;
        padding-bottom: 1em;
    }
    &__item {
        &:first-of-type {
            #{$self}__item-wrapper {
                border-top: 1px solid $black;
            }
        }
    }
    &__icon {
        fill: $orange;
    }
    &__icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
