<template>
<div>
    <Heading :level="3" v-if="title">{{title}}</Heading>
    <XhtmlField :items="text" v-if="text"/>
</div>
</template>

<script>
export default {
    props: {
        text: String,
        title: String
    }
};
</script>
