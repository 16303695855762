var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "lang" },
    _vm._l(_vm.availableLanguages, function (item) {
      return _c(
        "li",
        { key: item.name, class: { active: _vm.isCurrentLanguage(item) } },
        [
          _c("epi-view-mode-link", { attrs: { url: item.link } }, [
            _vm._v(_vm._s(item.displayName)),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }