<template>
<Row class="c-page-list--small">
    <Column :sm="10" :offsetSm="index % 2 === 0 ? 0 : 3" v-for="(item,index) in items" :key="index" class="c-page-list--small__item">
        <Heading isCategory>{{item.label}}</Heading>
        <Icon v-if="item.icon" :icon="item.icon" class="o-link__icon c-page-list--small__icon"/>
        <Heading :level="5" class="c-page-list--small__heading u-no-margin-top">{{item.title}}</Heading>
        <p class="o-large-body">{{item.intro}}</p>

        <Anchor isFancy :href="item.url" >{{getLabel('global', 'readMore')}}</Anchor>
    </Column>
</Row>
</template>

<script>
import List from '@/Scripts/components/molecules/List.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';

export default {
    props: {
        items: Array
    },
    components: {
        List,
        Anchor,
        Heading,
        Icon
    }
};
</script>

<style lang="scss">
.c-page-list--small {
    &__heading {
        margin-bottom: 1em;
    }
    &__item {
        margin-bottom: 4rem;
    }
    &__icon {
        height: 64px;
        width: 64px;
    }
}
</style>
