var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Page-container GenericBlock" }, [
    _c("div", { staticClass: "Grid Grid--alignMiddle Grid--gutterA" }, [
      _c("div", { staticClass: "Grid-cell" }, [
        _c("p", [
          _vm._v(
            "Could not load " + _vm._s(_vm.model.Name) + " vue component."
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }