var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-key-figures-block" },
    [
      _c(
        "Row",
        { staticClass: "c-key-figures-block__figures" },
        _vm._l(_vm.model.content, function (item, index) {
          return _c("Column", { key: "keyFig_" + index, attrs: { md: 8 } }, [
            _c(
              "div",
              { staticClass: "c-key-figures-block__figure" },
              [
                _c("p", [
                  _c(
                    "span",
                    { staticClass: "c-key-figures-block__figurevalue" },
                    [_vm._v(_vm._s(item.figure))]
                  ),
                  _vm._v(
                    "\r\n                    " +
                      _vm._s(item.label) +
                      "\r\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "Anchor",
                  { attrs: { isClickable: "", isFancy: "", href: item.url } },
                  [_vm._v(_vm._s(item.linkText))]
                ),
              ],
              1
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }