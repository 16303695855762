export default {
    props: {
        pagePropertyName: {
            type: String,
            default: '' // eslint-disable-line
        }
    },
    methods: {
        // Method used in v-epi-edit if inside a block (a block will need prefixing)
        prefixPropertyName(propertyName) {
            if (this.pagePropertyName) {
                return this.pagePropertyName + '.' + propertyName;
            }
            return propertyName;
        },
        // Method used to get translated texts from Vuex
        getLabel(parent, key) {
            var label = this.$store.state.appContext.labels[parent] && this.$store.state.appContext.labels[parent][key];
            if (label) {
                return label;
            }
            return 'MISSING TEXT';
        },
        // TODO: FIX THIS
        notEmptyObject(someObject) {
            if (someObject) {
                return Object.keys(someObject).length !== 0 && !Object.values(someObject).every(o => o === null || o.length === 0);
            }
            return false;
        },
        decimalFormatter(num, digits) {
            const roundingDigit = Math.pow(10, digits);
            return Math.round((num + Number.EPSILON) * roundingDigit) / roundingDigit;
        }
    }
};
