var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-ir-share-information-page" },
    [
      _c(
        "Container",
        [
          _c(
            "div",
            { staticClass: "c-share-information__heading" },
            [
              _c("Heading", { attrs: { level: 3 } }, [
                _vm._v(_vm._s("Share Information")),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("ShareInformation", { attrs: { snapShot: _vm.model.snapShot } }),
          _vm._v(" "),
          _c("ShareDetails", { attrs: { snapShot: _vm.model.snapShot } }),
          _vm._v(" "),
          _vm.loadingGraphData
            ? _c("div", [
                _c("div", { staticClass: "u-spinner" }, [
                  _vm._v("Loading... "),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("SharePriceChart", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loadingGraphData,
                expression: "!loadingGraphData",
              },
            ],
            attrs: { lastPrice: _vm.model.snapShot.last },
            on: { loaded: _vm.showGraph, loading: _vm.hideGraph },
          }),
          _vm._v(" "),
          _c("TradingActivity", {
            attrs: {
              orders: _vm.model.orderDepth,
              latestTrades: _vm.model.trades,
              seeAllLabel: _vm.model.seeAllTradesLabel,
              seeAllUrl: _vm.model.seeAllTrades,
            },
          }),
          _vm._v(" "),
          _c("NewsStream", {
            staticClass: "o-bg o-bg--beige-light",
            attrs: {
              calendar: _vm.model.financialCalendarEntries,
              notifications: _vm.model.companyDisclosures,
              seeAllLabel: _vm.model.seeAllNewsLabel,
              seeAllUrl: _vm.model.seeAllNews,
            },
          }),
          _vm._v(" "),
          _c("TopShareHolders", {
            attrs: { shareholders: _vm.model.shareHolders },
          }),
          _vm._v(" "),
          _c("AnalystCoverage", {
            attrs: { content: _vm.model.analystCoverageList.content },
          }),
          _vm._v(" "),
          _c(
            "FileListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "FileList",
                    expression: "'FileList'",
                  },
                ],
                staticClass: "c-share-information__files",
              },
              "FileListBlock",
              _vm.model.fileList.content,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }