var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-main-speaker-block" }, [
    _c("div", { staticClass: "event-main-speaker-block__content container" }, [
      _c("div", { staticClass: "event-main-speaker-block__content__left" }, [
        _c(
          "div",
          { staticClass: "event-main-speaker-block__content__left__title" },
          [_c("p", [_vm._v(_vm._s(_vm.model.title))])]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-main-speaker-block__content__left__text" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.model.header))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.model.position))]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          [_c("XhtmlField", { attrs: { items: _vm.model.description } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "event-main-speaker-block__content__right" }, [
        _c(
          "div",
          [
            _c("ResponsiveImage", {
              staticClass: "circular-image",
              attrs: {
                alt: _vm.name,
                xs: { w: 250, h: 250 },
                sm: { w: 300, h: 300 },
                md: { w: 300, h: 300 },
                lg: { w: 400, h: 400 },
                src: _vm.getUrlForImage,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }