<template>
    <div class="small">
        <ChartOptions class="c-chart-options" @selected="onSelected" />
        <LineChart
            ref="line"
            :chart-data="datacollection"
            :options="options"
        ></LineChart>
    </div>
</template>

<script>
import LineChart from '@/Scripts/chart/LineChart.js';
import ChartOptions from '@/Scripts/components/molecules/InvestorRelations/ChartOptions.vue';
import api from '@/Scripts/api/api';

export default {
    components: {
        LineChart,
        ChartOptions
    },
    props: {
        lastPrice: {
            type: Number
        }
    },
    data() {
        return {
            sharepriceData: {},
            datacollection: {},
            options: {},
            startDate: new Date(),
            endDate: new Date(),
            chartUnit: 'day',
            unitSteps: 1,
            labelString: 'Date',
        };
    },
    mounted() {
        this.$emit('loading');
        const oneDay = 3600 * 1000 * 24;
        let params = {
            startDate: this.formatDate(new Date(this.startDate - 7 * oneDay)),
            endDate: this.formatDate(this.endDate)
        };
        api.getSharePriceGraphInfo(params)
            .then(response => {
                let result = response.data;
                this.sharepriceData = result;
                if (this.chartUnit === 'minute') {
                    this.fillData(result);
                }
                this.fillData(result);
            });
    },
    methods: {
        fillData(data) {
            var mappedData = [];
            data.map(item => {
                if (this.chartUnit === 'minute') {
                    var time = item.date.split(':');
                    var hour = parseInt(time[0]);
                    var minutes = parseInt(time[1]);
                    mappedData.push({x: new Date().setHours(hour,minutes), y: item.close.toFixed(2)});
                } else {
                    mappedData.unshift({x: new Date(item.date), y: item.close.toFixed(2)});
                }
                this.setOptions(mappedData);
            });
            this.$emit('loaded');
            //append todays price on the end when its before 1600 and after 0900 and the closing price is not yet on the api call.
            // also checks if its weekend and there is no need to append saturday or sunday, since its closed.
            if (this.chartUnit !== 'minute' && this.ObxIsOpen() && !this.isWeekend()) {
                mappedData.push({x: new Date(), y: this.lastPrice});
            }
            this.datacollection = {
                datasets: [
                    {
                        backgroundColor: '#f87979',
                        data: mappedData,
                        fill: false,
                        borderColor: '#FF370F',
                        tension: 0,
                        pointBorderColor: '#000000',
                        pointBackgroundColor: '#000000'
                    }
                ]};
        },

        setOptions() {
            this.options = {
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                labelString: 'Share Price (NOK)',
                                fontStyle: 'bold',
                                fontSize: 15
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                zeroLineColor: 'rgba(0,0,0,0.1)',
                            },
                            type: 'time',
                            time: {
                                displayFormats: {
                                    minute: 'HH:mm',
                                    week: 'DD.MM.YYYY',
                                    day: 'DD.MM.YYYY',
                                    month: 'DD.MM.YYYY',
                                },
                                unit: this.chartUnit,
                                unitStepSize: this.unitSteps,
                                minUnit: 'second'
                            },
                            scaleLabel: {
                                labelString: this.chartUnit === 'minute' ? 'Time (UTC +2 )' : 'Date',
                                display: true,
                                fontStyle: 'bold',
                                fontSize: 15
                            },
                            ticks: {
                                souce: 'data',
                                maxTicksLimit:
                                                window.innerWidth < 1200
                                                    ? window.innerWidth < 700
                                                        ? 3
                                                        : 5
                                                    : 12,
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }
                    ]
                },
                hover: {
                    intersect: false
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 5
                    }
                },
                tooltips: {
                    displayColors: false,
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        title: (tooltipItem) => {
                            if (this.chartUnit !== 'minute') {
                                return tooltipItem[0].label.slice(0,12).replaceAll(',','');
                            }
                            return tooltipItem[0].label;
                        },
                        label: (tooltipItem) => {
                            return tooltipItem.value + ' NOK';
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            };

        },

        titleTooltip(tooltipItems) {
            return 'Test';
        },
        isWeekend() {
            const today = new Date(Date.now());
            return today.getDay() === 6 || today.getDay() === 0;
        },
        ObxIsOpen() {
            const today = new Date(Date.now());
            return today.getHours() <= 16 && today.getHours() >= 9;
        },
        formatDate(date) {
            const dateString =
                ('0' + (date.getMonth() + 1)).slice(-2) +
                '/' +
                ('0' + date.getDate()).slice(-2) +
                '/' +
                date.getFullYear();
            return dateString;
        },
        onSelected(value) {
            this.$emit('loading');
            const oneDay = 1000 * 3600 * 24;
            const today = new Date();
            const endDate = this.formatDate(today);
            let startDate = endDate;
            let onlyMonth = false;
            if (value === 1) {
                this.chartUnit = 'day';
                this.unitSteps = 1;
                startDate = new Date(Date.now() - oneDay * 7);
            }
            if (value === 2) {
                this.chartUnit = 'day';
                this.unitSteps = 2;
                startDate = new Date(Date.now() - oneDay * 30);
            }
            if (value === 3) {
                this.chartUnit = 'week';
                this.unitSteps = 1;
                startDate = new Date(Date.now() - oneDay * 90);
            }
            if (value === 4) {
                this.chartUnit = 'week';
                this.unitSteps = 2;
                startDate = new Date(Date.now() - oneDay * 180);
            }
            if (value === 5) {
                this.chartUnit = 'month';
                this.unitSteps = 1;
                startDate = new Date(Date.now() - oneDay * 365);
            }
            if (value === 6) {
                this.chartUnit = 'month';
                this.unitSteps = 3;
                startDate = new Date(Date.now() - oneDay * 365 * 3);
            }
            if (value === 7) {
                this.unitSteps = 3;
                this.chartUnit = 'month';
                startDate = new Date('11/01/2017');
                onlyMonth = true;
            }
            if (value !== 0) {
                startDate = this.formatDate(startDate);
                let params = {
                    startDate: startDate,
                    endDate: endDate,
                    onlyMonth: onlyMonth
                };
                this.startDate = startDate;
                this.endDate = endDate;
                api.getSharePriceGraphInfo(params).then(response => {
                    let result = response.data;
                    this.loadingGraphData = false;
                    this.sharepriceData = result;
                    this.fillData(result);
                });
            } else {
                this.startDate = startDate;
                this.endDate = endDate;
                this.chartUnit = 'minute';
                this.unitSteps = 5;
                var hours = new Date().getHours();
                var minutes = new Date().getMinutes();
                if (hours < 10) {
                    if (minutes < 30) {
                        this.unitStepSize = 1;
                    }
                    if (minutes > 45) {
                        this.unitStepSize = 5;
                    }
                }
                if (hours > 10) {
                    this.unitSteps = 10;
                }
                if (hours > 11) {
                    this.unitSteps = 15;
                }
                if (hours > 12) {
                    this.unitSteps = 30;
                }
                if (hours > 15) {
                    this.unitSteps = 45;
                }
                this.labelString = 'Time (UTC + 2)';
                if (this.ObxIsOpen() && !this.isWeekend()) {
                    api.getSharePriceGraphInfoDay().then(response => {
                        this.$emit('loading');
                        let result = response.data;
                        this.loadingGraphData = false;
                        this.sharepriceData = result;
                        this.fillData(result);
                    });
                } else {
                    this.$emit('loaded');
                }
            }
        }
    }
};
</script>

<style lang="scss">
.small {
    width: 100%;
    margin: 80px auto;
}
.c-chart-options {
    padding: 1.5em;
}
</style>
