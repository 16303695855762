<template>
    <div :class="['c-icon', className, `c-icon--${icon}`]">
        <epi-link :href="url" v-if="url" :target="target" :title="title" :aria-label="ariaLabel">
            <svg><use :xlink:href="computedIcon"></use></svg>
        </epi-link>
        <svg v-if="!url"><use :xlink:href="computedIcon"></use></svg>
    </div>
</template>

<script>
import EpiLink from '@/Scripts/components/atoms/EpiLink.vue';
/**
 * The Icon component loads SVG-icons from a spritesheet created by
 * svg-sprite-loader https://github.com/JetBrains/svg-sprite-loader.
 * To add new icons simply copy the svg-file to /src/Assets/Images/svg-sprites.
 * They will load automatically.
 *
 * To use your icon set the icon-prop the same as the name of your svg-file.
 */
export default {
    name: 'Icon',
    components: {
        EpiLink
    },
    props: {
        title: {
            type: String
        },
        ariaLabel: {
            type: String
        },
        /**
         * When set component will get wrapped in anchor-tag.
         */
        url: {
            type: String
        },
        /**
         * Target parameter for url. Only used if url is set.
         */
        target: {
            type: String
        },
        /**
         * Icon to display. Use same name as the filename.
         */
        icon: {
            type: String,
            default: 'icon' // eslint-disable-line
        },
        fill: {
            type: String,
            default: 'black' // eslint-disable-line
        },
        className: {
            type: String
        }
    },
    computed: {
        computedIcon: function () {
            return '#' + this.icon;
        }
    }
};
</script>

<style lang="scss">
.c-icon {
    vertical-align: middle;
    display: inline-block;
    height: 1em;
    width: 1em;

    &, svg {
        max-width: 100%;
        max-height: 100%;
    }
    svg {
        display: block;
    }
    &--chevron_right, &--chevron_down {
        height: .8em;
        width: .8em;
    }
}
</style>
