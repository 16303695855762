<template>
    <Row class="practicearea--expertise__single">
        <Column v-if="illustration > 0" :md="8" class="practicearea--expertise__single--illustration__wrapper">
            <img :src="'/Assets/Images/practiceareas/expertise/' + illustration + '.png'" alt=""/>
        </Column>
        <Column :md="illustration > 0 ? '16' : '24'">
            <h3 class="practicearea--expertise__single--header">{{ heading }}</h3>
            <p class="practicearea--expertise__single--preamble">{{ preamble }}</p>
            <div v-if="readMore.length > 0" class="practicearea--expertise__single--wrapper" :class="more ? 'is-open' : ''">
                <div class="practicearea--expertise__single--wrapper__inner">
                    <XhtmlField :items="readMore" />
                </div>
            </div>
            <button v-if="readMore.length > 0" v-on:click="toggle" type="button" class="practicearea--expertise__single--more">
                {{getLabel('global', more ? 'readLess' : 'readMore')}}
                <Icon class="c-news-block__item__chevron2" icon="chevron_down" v-bind:class="[more ? 'practicearea--expertise__single--more__rotate-180' : '']"/>
            </button>
        </Column>
    </Row>
</template>

<script>
import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';

export default {
    props: {
        readMore: Array,
        heading: '',
        preamble: '',
        illustration: 0,
    },
    components: {
        XhtmlField,
        Heading,
        Icon,
    },
    data() {
        return {
            more: false
        };
    },
    methods: {
        toggle: function () {
            this.more = !this.more;
        }
    },
};
</script>

<style lang="scss">
    .practicearea--expertise__single--wrapper {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.5s ease-out;
    }

    .practicearea--expertise__single--wrapper.is-open {
        grid-template-rows: 1fr;
        margin-bottom: 24px;
    }

    .practicearea--expertise__single--wrapper__inner {
        overflow: hidden;
    }
</style>
