var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.isCategory ? "div" : `h${_vm.level}`,
    {
      tag: "div",
      staticClass: "o-heading",
      class: {
        "o-heading--category": _vm.isCategory,
        "u-no-margin-bottom": _vm.noMarginBottom,
        "u-no-margin-top": _vm.noMarginTop,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }