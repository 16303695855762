<template>
<main class="c-ebook-page">
    <Hero
        v-if="model.heroImage && model.heroImage.url"
        :title="model.title"
        :subtitle="model.label"
        :heroImage="model.heroImage && model.heroImage.url"
        :overlay="model.heroOverlay"
        :changeTitleColor="model.changeTitleColor"
        :changeLinkColor="model.changeLinkColor"
        :introText="model.introText"
        :embed="model.formData"
        :overlayImage="model.overlayImage"
        :formBackground="model.formBackground"
        :formText="model.formText"
        :formLabel="model.formLabel"
    />

    <Container class="c-ebook-page__content">
        <Row v-if="model.descriptionBlock.content.title">
            <Column class="c-ebook-page__title">
                <Heading :level="2">{{model.descriptionBlock.content.title}}</Heading>
            </Column>
        </Row>

        <Row>
            <Column :md="11" class="c-ebook-page__content-left">
                <XhtmlField v-epi-edit="'Description'" class="o-ingress" v-if="model.descriptionBlock.content.description || isEditable" :items="model.descriptionBlock.content.description"/>
                <Button v-if="model.descriptionBlock.content.buttonLink && model.descriptionBlock.content.buttonText" isLarge :href="model.descriptionBlock.content.buttonLink.uri" class="o-button--filled">
                    {{ model.descriptionBlock.content.buttonText }}
                </Button>
                <Button v-else-if="model.descriptionBlock.content.buttonText" isLarge v-scroll-to="'#embed'" class="o-button--filled">
                    {{ model.descriptionBlock.content.buttonText }}
                </Button>
            </Column>
            <Column :md="12" :offsetMd="1" class="c-ebook-page__content-right">
               <ul v-if="model.descriptionBlock.content.benefits" class="o-flat-list c-ebook-page__benefits">
                   <li
                    v-for="(benefit, index) in model.descriptionBlock.content.benefits"
                    :key="index"
                   >
                    <div class="c-ebook-page__benefits--icon">
                        <ResponsiveImage :alt="benefit.title" :src="benefit.image" />
                    </div>
                    <div class="c-ebook-page__benefits--text">
                        <h3>
                            {{ benefit.title }}
                        </h3>
                        <p>
                            {{ benefit.text }}
                        </p>
                    </div>
                   </li>
               </ul>
            </Column>
        </Row>
    </Container>
</main>
</template>

<script>
import Hero from '@/Scripts/components/molecules/Ebook/HeroEbook.vue';
import ResponsiveImage from '../atoms/ResponsiveImage.vue';

export default {
    props: {
        model: Object
    },
    components: {
        Hero,
        ResponsiveImage
    }
};
</script>

<style lang="scss">
.c-ebook-page {
    &__top, &__media, &__two-column, &__file-download, &__content-area {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }

    &__content-left {
        margin-bottom: 3rem;
    }

    &__benefits {
        margin-bottom: 3rem;
        li {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            margin-bottom: 40px;

            div {
                align-self: flex-start;
            }
        }

        &--icon {
            width: 50px;
            flex-shrink: 0;
        }

        &--text {
            padding-left: 30px;
            h3 {
                margin: 0 0 16px 0;
                font-size: 28px;
                font-weight: 400;
                line-height: 1;
            }
        }
    }
}
</style>
