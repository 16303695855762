var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "container", staticClass: "c-horizontal-slider" }, [
    !_vm.autoScroll
      ? _c("div", { staticClass: "c-horizontal-slider__nav" }, [
          _vm._hasPrev
            ? _c(
                "div",
                {
                  staticClass: "c-horizontal-slider__nav__left",
                  on: { click: _vm.prev },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "32px",
                        height: "32px",
                        viewBox: "0 0 24 24",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M10.757 12l4.95 4.95a1 1 0 1 1-1.414 1.414l-5.657-5.657a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 0 1 1.414 1.414L10.757 12z",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._hasNext
            ? _c(
                "div",
                {
                  staticClass: "c-horizontal-slider__nav__right",
                  on: { click: _vm.next },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "32px",
                        height: "32px",
                        viewBox: "0 0 24 24",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M13.314 12.071l-4.95-4.95a1 1 0 0 1 1.414-1.414l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l4.95-4.95z",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "vhl-container__container" }, [
      _c("div", { ref: "list", staticClass: "c-horizontal-slider__list" }, [
        _c(
          "div",
          { staticClass: "c-horizontal-slider__track", style: _vm._trackStyle },
          _vm._l(_vm.allItems, function (item, index) {
            return _c(
              "div",
              {
                key: `${_vm._uid}_${index}`,
                ref: "item",
                refInFor: true,
                staticClass: "c-horizontal-slider__item",
                style: [_vm._itemStyle, _vm._itemStyleOpacity(index)],
              },
              [
                _vm._t(
                  "default",
                  function () {
                    return [_vm._v(_vm._s(item))]
                  },
                  { item: item }
                ),
              ],
              2
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div"),
      ]),
    ]),
    _vm._v(" "),
    _vm.autoScroll && _vm.items.length > 1
      ? _c(
          "div",
          { staticClass: "c-horizontal-slider__dot-wrapper" },
          _vm._l(_vm.items.length, function (i, index) {
            return _c("div", { key: `slider_dot_${i}` }, [
              _c("span", {
                staticClass: "c-horizontal-slider__dot",
                class: {
                  "c-horizontal-slider__dot--active": _vm.isActive(index),
                },
              }),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }