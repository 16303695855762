<template>
<div class="c-notification-list">
    <div class="c-notification-list-header">
        <div>Date</div>
        <div>Headline</div>
   </div>
   <div v-for="(notification, index) in notifications" :key="index">
            <NotificationItem :notification="notification" />
    </div>
    <div v-if="seeAllUrl" class="c-notification-list-link">
        <a :href="seeAllUrl">{{seeAllLabel}}</a>
    </div>
</div>
</template>

<script>

import NotificationItem from '@/Scripts/components/atoms/NotificationItem.vue';

export default {
    props: {
        notifications: {
            type: Array,
            required: true,
        },
        seeAllLabel: {
            type: String,
            required: false
        },
        seeAllUrl: {
            type: String,
            required: false
        }
    },
    components: {
        NotificationItem,
    },
};
</script>

<style lang="scss">
.c-notification-list-header{
    display: flex;
    flex-direction: row;
    padding: 12px;
    border-bottom: 2px solid black;
}
.c-notification-list-header > div{
    width: 100%;
    text-align: left;
}

.c-notification-list-header > div:first-child{
    width:30%;
}
.c-notification-list-link{
    margin-top: 2em;
}

</style>
