<template>
<div class="c-lang-selector" :class="{'c-lang-selector--open' : isOpen}">
    <Container class="c-lang-selector__container">
        <Row >
            <Column align="center">
                <Heading isCategory class="c-lang-selector__title">{{getLabel('languageSelector', 'chooseLanguage')}}</Heading> <!-- TODO: Translate -->
            </Column>
        </Row>
        <Row wrapper="ul" class="c-lang-selector__list o-flat-list">
            <Column
                align="center"
                :md="8"
                :sm="12"
                wrapper="li"
                class="c-lang-selector__item"
                :class="{'c-lang-selector__item--selected' : item.isCurrentLanguage}"
                v-for="(item, index) in availableLanguages" :key="`language__${index}`">
                <a :href="item.url" v-on:click="languageChanged">
                    {{item.name}}
                </a>
            </Column>
        </Row>
    </Container>
</div>
</template>

<script>
import Heading from '@/Scripts/components/atoms/Heading.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Container from '@/Scripts/components/grid/Container.vue';
import Row from '@/Scripts/components/grid/Row.vue';
import Column from '@/Scripts/components/grid/Column.vue';

export default {
    props: {
        items: {
            type: Array
        },
        isOpen: {
            type: Boolean,
            default: false // eslint-disable-line
        }
    },
    computed: {
        availableLanguages: function () {
            return this.items.filter(i => i.visibleInMenu);
        }
    },
    components: {
        Anchor,
        Column,
        Row,
        Container,
        Heading
    },
    methods: {
        languageChanged() {
            this.$emit('languageChanged');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/Styles/tools/_breakpoints.scss';
@import '@/Styles/tools/_font-mixin.scss';

.c-lang-selector {
    position: absolute;
    top: 0;
    color: black;
    height:100vh;
    max-height: 0px;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    width: 100%;
    background-color: $white;
    z-index: -1;

    &__container {
        overflow: scroll;
        height: calc(100% - 150px);
        margin-top: 150px; // TODO: Fix size
        padding-bottom: 30px; // TODO: Fix size
    }
    &__title {
        margin-bottom: 2rem;
    }
    a, a:hover {
        color: $black;
        text-decoration: none;
    }
    &--open {
        max-height: 100vh;
    }
    &__item {
        @include fontSize(16px);

        padding-top: em(17, 22);
        padding-bottom: em(17, 22);

        @include media-sm {
            @include fontSize(18px);
            border-left: 1px solid $gray;

            &:nth-child(2n+2) {
                border-right: 1px solid $gray;
            }
        }
        @include media-md {
            @include fontSize(22px);
            &:nth-child(2n+2) {
                border-right: none;
            }
            &:nth-child(3n+3) {
                border-right: 1px solid $gray;
            }
        }

        &--selected, &:hover {
            &:before {
                content: "\2022";
                color: $orange;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
    }
}
</style>
