<template>
<div class="o-block c-quote-block" :class="{'c-quote-block--advanced' : !isSimple}">
   <Row v-if="isSimple">
        <Column autoLg>
            <span class="c-quote-block__dot"></span>
            <span class="c-quote-block__dot"></span>
        </Column>
        <Column equalLg>
            <Heading :level="4" class="c-quote-block__quote">{{quote || model.quote}}</Heading>
        </Column>
    </Row>
    <Row v-if="isSimple">
        <Column align="right"><span class="c-quote-block__signature">{{signature || model.signature}}</span></Column>
    </Row>
    <Row v-if="!isSimple" class="o-bg o-bg--beige-light c-quote-block__advanced-row">
        <BreakoutColumn :md="10" :offsetMd="0" :sm="18" :offsetSm="6" breakoutSide="right" class="c-quote-block__breakout">
            <ResponsiveImage :src="image" :imageSizeLg="715" :imageSizeXs="515"/>
        </BreakoutColumn>
        <Column :md="14" :sm="22" class="u-flex u-align-items-center c-quote-block__content">
            <QuoteBlock :model="{ quote: quote, signature: signature }" />
        </Column>
    </Row>
</div>
</template>

<script>

export default {
    props: {
        model: Object,
        quote: String,
        signature: String,
        image: String,
        isSimple: {
            type: Boolean,
            default: true // eslint-disable-line
        }
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_font-mixin.scss';

.c-quote-block {
    $self: &;
    &__dot {
        height: 15px;
        width: 15px;
        background-color: $orange;
        border-radius: 50%;
        margin-top: .5em;
        display: inline-block;

        @include media-md {
            height: 18px;
            width: 18px;
        }

        &:not(:last-child) {
            margin-right: .5em;
        }
    }
    &__quote {
        margin: 0;
    }
    &__signature {
        @include fontSize(14px);
        color: $gray;
        margin-top: 1em;

        &:before {
            content: '//';
            color: $orange;
            margin-right: 1em;
        }
    }

    &--advanced {
        .o-col--breakout__content {
            max-width: 715px;
        }
        #{$self}__breakout {
            margin-bottom: 2rem;

            @include media-sm {
                margin-bottom: 4rem;
            }

            @include media-md {
                margin-bottom: 0;
                order: 1;
            }
        }
        #{$self}__content {
            @include media-md {
                padding: 80px;
            }
        }
        #{$self}__advanced-row {
            padding-bottom: 4rem;

            @include media-md {
                &:before {
                    right: -200vw;
                    top: 0px;
                    left: 0;
                }
            }
        }
    }
}
</style>
