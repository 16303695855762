var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-gartner-widget-block o-bg" }, [_vm._m(1)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            padding: "10px",
            "margin-top": "10 px",
            "margin-bottom": "10px",
            "text-align": "center",
            height: "100%",
            width: "100%",
            "background-color": "#ffffff",
            "border-radius": "0px 10px 10px 0px",
          },
        },
        [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.gartner.com/reviews/market/software-asset-management-managed-service/vendor/crayon/product/crayon-software-asset-management-managed-services",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: "/Assets/Images/GartnerPeerInsightsLogo_onlight.svg",
                  title: "Read reviews on Gartner peer insights",
                  width: "250",
                },
              }),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "c-gartner-widget-block__widget-container",
        attrs: { id: "largeWidget" },
      },
      [_vm._m(0)]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }