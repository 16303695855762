<template>
<div>
    <template v-if="viewMode === 'Slider'">
        <PageListSlider :items="teasers" v-bind="$props"/>
    </template>
    <template v-else-if="viewMode === 'Large'">
        <PageListLarge :items="teasers" v-bind="$props"/>
    </template>
    <template v-else-if="viewMode === 'Small'">
        <PageListSmall :items="teasers" v-bind="$props"/>
    </template>
    <!-- LIST -->
    <template v-else>
        <!-- TODO: Add url -->
        <PageListList :items="teasers" v-bind="$props"/>
    </template>
</div>
</template>

<script>
import PageListList from '@/Scripts/components/blocks/PageListBlock/PageListList.vue';
import PageListSlider from '@/Scripts/components/blocks/PageListBlock/PageListSlider.vue';
import PageListLarge from '@/Scripts/components/blocks/PageListBlock/PageListLarge.vue';
import PageListSmall from '@/Scripts/components/blocks/PageListBlock/PageListSmall.vue';

export default {
    props: {
        intro: String,
        introHtml: Array,
        label: String,
        teasers: Array,
        title: String,
        viewMode: String
    },
    components: {
        PageListList,
        PageListSlider,
        PageListLarge,
        PageListSmall
    }
};
</script>

