var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-news-component-list" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "Column",
        {
          key: `ncl_${_vm._uid}${index}`,
          attrs: { md: _vm.md, sm: _vm.sm, xs: _vm.xs },
        },
        [
          _c("NewsComponent", {
            attrs: {
              model: item,
              viewMode: _vm.isVertical ? "Vertical" : "",
              flip: _vm.flip,
              imageConfig: _vm.imageConfig,
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }