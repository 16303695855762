var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                { attrs: { md: 10 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                  _vm._v(" "),
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Intro",
                        expression: "'Intro'",
                      },
                    ],
                    staticClass: "o-ingress",
                    attrs: { items: _vm.model.intro },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                [
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Body",
                        expression: "'Body'",
                      },
                    ],
                    attrs: { items: _vm.model.body },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }