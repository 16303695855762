<template>
<main class="c-article-page">
    <Container>
        <Row>
            <Column :md="12" :offsetMd="4" :sm="14" :offsetSm="2">
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
                <Heading class="c-article-page__title" noMarginBottom noMarginTop :level="2" v-epi-edit="'Title'">{{model.title}}</Heading>
                <div v-if="model.siteSettings != null" v-epi-edit="'HideDate'"><Datetime v-if="!model.hideDate" class="c-article-page__date" :value="model.startPublish" :useUsDateFormat="model.siteSettings.useUsDateFormat"/></div>
            </Column>
        </Row>
        <Row>
            <Column :md="4" :sm="20" :offsetSm="2" :offsetMd="0">
                <Row v-epi-edit="'Categories'" class="c-article-page__categories" v-if="model.categories || isEditable">
                    <Column><Heading isCategory v-epi-edit="'Label'">{{getLabel('articlePage', 'category')}}</Heading></Column>
                    <Column :xs="12" :md="24" v-for="item in model.categories" :key="item.id">{{item.name}}</Column>
                </Row>
                <Author class="c-article-page__author@md u-hidden-sm u-hidden-xs"
                    v-epi-edit="'Author'"
                    v-bind="model.author"
                    v-if="notEmptyObject(model.author) || isEditable"/>
                <ContentArea v-epi-edit="'Blocks'" v-if="model.additionalAuthors || isEditable" class="c-article-page__content-area c-article-page__author@md u-hidden-sm u-hidden-xs" :model="model.additionalAuthors || isEditable"/>
                <SocialMediaShareBlock class="u-hidden-sm u-hidden-xs" noBorder/>
            </Column>
            <Column :md="12" :sm="20" :offsetSm="2" :offsetMd="0">
                <main>
                    <Row>
                        <Column>
                            <XhtmlField class="o-ingress" v-epi-edit="'IntroText'" :items="model.introText"/>
                            <MediaBlock v-if="notEmptyObject(model.media.content) || isEditable" v-bind="model.media.content" v-epi-edit="'Media'"/>
                            <XhtmlField class="c-article-page__main-body" :items="model.mainBody" v-epi-edit="'MainBody'"/>
                            <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-article-page__content-area" :model="model.blocks  || isEditable"/>
                            <Author class="c-article-page__author u-hidden-md u-hidden-lg"
                                v-epi-edit="'Author'"
                                v-bind="model.author"/>
                            <ContentArea v-epi-edit="'Blocks'" v-if="model.additionalAuthors || isEditable" class="c-article-page__content-area c-article-page__author u-hidden-md u-hidden-lg" :model="model.additionalAuthors || isEditable"/>
                            <SocialMediaShareBlock class="c-article-page__share-block"/>
                        </Column>
                    </Row>
                </main>
            </Column>
            <Column :md="6" :offsetMd="2" class="u-hidden-xs u-hidden-sm" v-if="notEmptyObject(model.newsBlockRight && model.newsBlockRight.content && model.newsBlockRight.content.teasers) || isEditable">
                <div class="c-article-page__right-content">
                    <Heading isCategory>{{model.newsBlockRight.content.label ? model.newsBlockRight.content.label : getLabel('articlePage', 'otherNews')}}</Heading>
                    <NewsComponentList class="c-article-page__news-block" isVertical flip :items="model.newsBlockRight && model.newsBlockRight.content && model.newsBlockRight.content.teasers" v-epi-edit="'newsBlockRight'" :showArrow="false"/>
                </div>
            </Column>
        </Row>
        <Row>
            <Column class="u-display-xs">
                <NewsComponentList class="c-article-page__news-block" flip isVertical v-if="notEmptyObject(model.pageListBottom && model.pageListBottom.content && model.pageListBottom.content.teasers) || isEditable" :items="model.pageListBottom.content && model.pageListBottom.content.teasers" v-epi-edit="'pageListBottom'" :showArrow="false"/>
            </Column>
            <Column class="u-hidden-xs">
                <NewsComponentList class="c-article-page__news-block" :sm="12" :md="6" v-if="notEmptyObject(model.pageListBottom && model.pageListBottom.content && model.pageListBottom.content.teasers) || isEditable" :items="model.pageListBottom.content && model.pageListBottom.content.teasers" v-epi-edit="'pageListBottom'" :showArrow="false"/>
            </Column>
        </Row>
        <!-- TODO: Add link to other articles-->
    </Container>
</main>
</template>

<script>
import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';
import Author from '@/Scripts/components/molecules/Author.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import { mapState } from 'vuex';

import Datetime from '@/Scripts/components/atoms/Datetime.vue';
import NewsComponentList from '@/Scripts/components/molecules/NewsComponents/NewsComponentList.vue';


export default {
    props: ['model'],
    components: {
        XhtmlField,
        Author,
        Datetime,
        NewsComponentList,
        ContentArea
    },
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    }),
    mounted: function () {
        // eslint-disable-next-line no-console
        console.log(this.model);
    },
};

</script>

<style lang="scss">
@import '@/Styles/Tools/_font-mixin.scss';

.c-article-page {
    &__title {
        margin-bottom: 3rem;
    }
    &__date {
        @include fontSize(18px);
        color: $gray;
        margin-bottom: 2rem;
    }
    &__categories {
        margin-bottom: 1rem;
    }
    &__main-body {
        margin-bottom: 3rem;

        .c-two-column-block__body2, .c-two-column-block__heading2 {
            margin-left: 0;
        }
        .c-two-column-block__body1, .c-two-column-block__body2, .c-two-column-block__heading1, .c-two-column-block__heading2 {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
    &__share-block {
        margin-bottom: 3rem;
    }
    &__author {
        margin-bottom: 2rem;

        &\@md {
            margin-top: 2rem;
            margin-bottom: 3rem;
        }
    }
    &__news-block {
        border-top: 1px solid $gray;
        padding-top: 1.5em;
    }
    &__right-content {
        border-left: 1px solid $gray;
        padding-left: 20px;
    }
    &__content-area {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
