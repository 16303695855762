var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-lang-selector",
      class: { "c-lang-selector--open": _vm.isOpen },
    },
    [
      _c(
        "Container",
        { staticClass: "c-lang-selector__container" },
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                { attrs: { align: "center" } },
                [
                  _c(
                    "Heading",
                    {
                      staticClass: "c-lang-selector__title",
                      attrs: { isCategory: "" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.getLabel("languageSelector", "chooseLanguage")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            {
              staticClass: "c-lang-selector__list o-flat-list",
              attrs: { wrapper: "ul" },
            },
            _vm._l(_vm.availableLanguages, function (item, index) {
              return _c(
                "Column",
                {
                  key: `language__${index}`,
                  staticClass: "c-lang-selector__item",
                  class: {
                    "c-lang-selector__item--selected": item.isCurrentLanguage,
                  },
                  attrs: { align: "center", md: 8, sm: 12, wrapper: "li" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: item.url },
                      on: { click: _vm.languageChanged },
                    },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(item.name) +
                          "\r\n                "
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }