var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-software-partner-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-software-partner-page__top" },
            [
              _vm.model.label || _vm.isEditable
                ? _c(
                    "Column",
                    [
                      _c(
                        "Heading",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "Label",
                              expression: "'Label'",
                            },
                          ],
                          attrs: { isCategory: "" },
                        },
                        [_vm._v(_vm._s(_vm.model.label))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 11 } },
                [
                  _vm.model.title || _vm.isEditable
                    ? _c(
                        "Heading",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "title",
                              expression: "'title'",
                            },
                          ],
                          staticClass: "c-software-partner-page__top__title",
                          attrs: { noMarginTop: "", level: 1 },
                        },
                        [_vm._v(_vm._s(_vm.model.title))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 11, "offset-md": 2 } },
                [
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "introText",
                        expression: "'introText'",
                      },
                    ],
                    staticClass: "o-ingress c-software-partner-page__intro",
                    attrs: { items: _vm.model.introText },
                  }),
                  _vm._v(" "),
                  _vm.notEmptyObject(_vm.model.form && _vm.model.form.content)
                    ? _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#form",
                              expression: "'#form'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLabel(
                                "softwarePartnerPage",
                                "topBannerButtonText"
                              )
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.media.content) || _vm.isEditable
            ? _c(
                "Row",
                { staticClass: "c-software-partner-page__media" },
                [
                  _c(
                    "MediaBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Media",
                            expression: "'Media'",
                          },
                        ],
                      },
                      "MediaBlock",
                      _vm.model.media.content,
                      false
                    )
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.categories.length
        ? _c(
            "Container",
            { attrs: { isFluid: "", background: "beige" } },
            [
              _c(
                "Container",
                [
                  _c("ProductList", {
                    attrs: {
                      products: _vm.model.categories,
                      title: _vm.getLabel(
                        "softwarePartnerPage",
                        "productsHeading"
                      ),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.notEmptyObject(_vm.model.twoColumnBlock.content) || _vm.isEditable
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "TwoColumnBlock",
                        expression: "'TwoColumnBlock'",
                      },
                    ],
                    staticClass: "c-software-partner-page__two-column-block",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoColumnBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.pageListBottom.content) || _vm.isEditable
            ? _c(
                "PageListBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "pageListBottom",
                        expression: "'pageListBottom'",
                      },
                    ],
                    staticClass: "c-software-partner-page__page-list",
                  },
                  "PageListBlock",
                  _vm.model.pageListBottom.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.blocks || _vm.isEditable
            ? _c("ContentArea", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Blocks",
                    expression: "'Blocks'",
                  },
                ],
                staticClass: "c-software-partner-page__content-area",
                attrs: { model: _vm.model.blocks || _vm.isEditable },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.form && _vm.model.form.content) ||
          _vm.isEditable
            ? _c(
                "FormBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Form",
                        expression: "'Form'",
                      },
                    ],
                    staticClass: "c-software-partner-page__form",
                    attrs: { id: "form" },
                  },
                  "FormBlock",
                  _vm.model.form.content,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.newsBlock.content || _vm.isEditable
        ? _c(
            "Container",
            { attrs: { isFluid: "", background: "beige-light" } },
            [
              _c(
                "Container",
                [
                  _c(
                    "NewsBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "NewsBlock",
                            expression: "'NewsBlock'",
                          },
                        ],
                      },
                      "NewsBlock",
                      _vm.model.newsBlock.content,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.isEditable || _vm.model.images
            ? _c(
                "Row",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "Images",
                      expression: "'Images'",
                    },
                  ],
                  staticClass: "c-software-partner-page__images",
                },
                _vm._l(_vm.model.images, function (image, index) {
                  return _c(
                    "Column",
                    {
                      key: `${_vm._uid}_${index}`,
                      staticClass:
                        "c-software-partner-page__images__image-wrapper",
                      attrs: { md: 6 },
                    },
                    [_c("ResponsiveImage", { attrs: { src: image.url } })],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }