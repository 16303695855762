var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapper,
    { tag: "component", staticClass: "c-content-area" },
    _vm._l(_vm.model, function (block, index) {
      return _c(
        _vm.childWrapper,
        _vm._b(
          {
            key: index,
            tag: "component",
            class: [
              _vm.getDisplayOption(block.displayOption),
              _vm.childClass,
              "c-content-area__item",
            ],
          },
          "component",
          _vm.childConfig,
          false
        ),
        [
          _c("BlockComponentSelector", {
            attrs: {
              "data-epi-block-id": _vm.isEditable ? block.contentLink.id : null,
              model: block,
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }