<template>
    <div class = "c-speaker-list-block">
        <h2 class="c-speaker-list__heading">{{heading}}</h2>
        <Row class="c-speaker-list">
            <Column :sm="12" :md="6" v-for="(speaker, index) in speakers" :key="`speaker_${_uid}_${index}`" class="c-speaker-list__item">
                <Speaker v-bind="speaker" />
            </Column>
        </Row>
    </div>
</template>

<script>
import Speaker from '@/Scripts/components/molecules/Speaker.vue';
import Column from '@/Scripts/components/grid/Column.vue';
import Row from '@/Scripts/components/grid/Row.vue';

export default {
    props: {
        speakers: Array,
        heading: String
    },
    components: {
        Speaker,
        Column,
        Row
    }
};
</script>

<style lang="scss">
.c-speaker-list-block {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
</style>
