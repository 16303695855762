<template>
    <div class="practicearea--vendors">
        <Row>
            <Column :md="12">
                <Heading :level="2" class="practicearea--global__title">{{ model.vendorsHeading }}</Heading>
                <p v-if="model.vendorsPreamble !== null" class="practicearea--vendors__preamble">{{ model.vendorsPreamble }}</p>
                <p>
                    <Anchor isFancy :href="model.vendorsPageUrl" >{{ model.vendorsPageLinkLabel }}</Anchor>
                </p>
            </Column>
            <Column :md="12">
                <HorizontalSlider :items="items" :scroll="1" :noOfItemsToShow="1" autoScroll>
                    <template  v-slot:default="{item}">
                        <Row>
                            <Column v-for="(vendor, index) in item" :key="`vendor_${index}`" :sm="8" :xs="12" class="practicearea--vendors__item">
                                <div class="practicearea--vendors__thumbnail">
                                    <a :href="vendor.pageUrl" :aria-label="vendor.name">
                                        <ResponsiveImage v-if="item.imageUrl !== null" :src="vendor.imageUrl" :alt="vendor.imageTitle" v-bind="{xs: {w: 120, h:120}, sm: {w: 120, h:120}, md: {w: 120, h:120}, lg: {w: 120, h:120}}"/>
                                    </a>
                                </div>
                            </Column>
                        </Row>
                    </template>
                </HorizontalSlider>
            </Column>
        </Row>
    </div>
</template>

<script>
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';
import HorizontalSlider from '@/Scripts/components/molecules/HorizontalSlider.vue';

export default {
    props: ['model'],
    components: {
        Anchor,
        Heading,
        HorizontalSlider
    },
    computed: {
        items() {
            var filteredItems = this.model.vendors.filter(x => x.imageUrl !== null);
            return this.splitToChunks(filteredItems, 6);
        }
    },
    methods: {
        splitToChunks(array, size) {
            let result = [];
            while (array.length > 0) {
                result.push(array.splice(0, size));
            }
            return result;
        }
    }
};
</script>

<style lang="scss">
    @import '@/Styles/Tools/_breakpoints.scss';

    .c-vendors-block {
        $self: &;

        .c-horizontal-slider__dot-wrapper {
            margin-right: 50px;
        }
        &__text {
            margin-top: 0;
        }
        &__partner {
            #{$self}__image-container {
                max-width: 145px;
            }
            margin-bottom: 2rem;
        }
    }
</style>
