<template>
<footer class="c-footer">
    <HeaderTop class="c-footer__header" v-bind="headerTop" :isWhite="true" scrollToTopOnClick/>
    <Container class="c-footer__content">
        <Row>
            <Column :md="20" class="c-footer__text" >
                <div class="c-footer__text__section">
                    <span class="c-footer__text__small">{{getLabel('footer', 'contactHeading')}}</span>
                    <div v-if="overwriteContactLink">
                        <Anchor :href="overwriteContactLink" class="c-footer__text__big">
                            {{overwriteContactLinkText}}
                        </Anchor>
                    </div>
                    <div v-else>
                        <a class="c-footer__text__big" :href="`mailto:${mail}`">{{mail}}</a>
                        <a class="c-footer__text__big" :href="`tel:${phoneNumber}`">{{phoneNumber}}</a>
                    </div>
                </div>

                <div class="c-footer__text__section">
                    <span class="c-footer__text__small">{{getLabel('footer', 'visitHeading')}}</span>
                    <Anchor :href="singleLink" class="c-footer__text__big">
                        {{singleLinkText}}
                    </Anchor>
                </div>

                <div class="c-footer__text__section">
                    <span class="c-footer__text__small">{{getLabel('footer', 'socialMediaHeading')}}</span>
                    <div class="c-footer__icon-wrapper">
                        <Icon icon="facebook" url="https://www.facebook.com/CrayonITGroup/" target="_blank"/>
                        <Icon icon="linkedin" url="https://www.linkedin.com/company/crayon-group" target="_blank"/>
                        <Icon icon="twitter" url="https://twitter.com/crayonit" target="_blank"/>
                    </div>
                </div>
            </Column>
            <Column :md="4">
                <Row wrapper="ul" class="c-footer_link-list o-flat-list">
                    <Column  wrapper="li" :sm="8" :md="24" v-for="(item, index) in links" :key="`links_${_uid}${index}`">
                        <Anchor :href="item.url">
                            {{item.text}}
                        </Anchor>
                    </Column>
                </Row>
            </Column>
        </Row>
        <Row class="c-footer__bottom">
            <Column :md="12">
                <Anchor :href="cookiesPageLink">
                    {{cookiesPageLinkText}}
                </Anchor>
                <Anchor :href="integrityAndComplianceLink">
                    {{integrityAndComplianceLinkText}}
                </Anchor>
            </Column>
            <Column :md="12" class="c-footer__bottom__right">
                {{address}}
            </Column>
        </Row>
    </Container>
</footer>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';
import HeaderTop from '@/Scripts/components/molecules/HeaderTop.vue';
import Anchor from '../atoms/Anchor.vue';

export default {
    props: {
        headerTop: Object,
        cookiesPageLink: String,
        cookiesPageLinkText: String,
        integrityAndComplianceLink: String,
        integrityAndComplianceLinkText: String,
        address: String,
        links: Array,
        phoneNumber: String,
        mail: String,
        singleLinkText: String,
        singleLink: String,
        overwriteContactLink: String,
        overwriteContactLinkText: String,
        socialMedia: Object,
    },
    components: {
        Icon,
        HeaderTop,
        Anchor
    },
    computed: {
        pathName() {
            return this.$route.path;
        },
    },
};
</script>

<style lang="scss">
.c-footer {
    color: white; // TODO
    //height: 600px;
    background-color: $darkgreen;
    padding: 2rem;

    a {
        display: block;
        text-decoration: none;

        &:hover,&.router-link-exact-active {
            color: $white;
            text-decoration: underline;
        }
    }
    & &__icon-wrapper {

        .c-icon {
            a {
                display: inline;
            }
            svg {
                fill: white;
            }
            margin-right: 1em;
        }
    }
    &__logo {
        width: 117px;
        height: 24px;
    }
    &__content {
        margin-top: 7rem;
    }
    &__text {
        &__small {
            color: $gray;
            display: block;
        }
        & &__big {
            color: $white;
            font-size: 22px;
            line-height: 1.5em;

            @include media-md {
                font-size: 34px;
            }
            @include media-lg {
                font-size: 50px;
            }
        }
        &__section {
            margin-bottom: 2rem;
        }
    }

    &_link-list {
        a {
            color: white;
            margin-bottom: 2rem;
        }
    }
    &__bottom {
        margin-top: 7rem;
        a {
            color: $gray;
        }
        &__right {
            color: $gray;

            @include media-md {
                text-align: right;
            }
        }
    }
    &__header {
        padding-top: 0;
    }
}
</style>
