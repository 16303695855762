var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.wrapper,
    {
      tag: "div",
      class: [
        "o-col",
        _vm.className,
        _vm.computedClass,
        _vm.computedOffset,
        _vm.auto,
        _vm.equal,
        _vm.alignment,
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }