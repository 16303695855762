var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-order-items" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.orders, function (order, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("OrderItem", {
              attrs: { order: order, maxVolume: _vm.biggestVolume },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-order-items-header" }, [
      _c("div", { staticClass: "c-order-item-number" }, [_vm._v("Volume")]),
      _vm._v(" "),
      _c("div", { staticClass: "c-order-item-number" }, [_vm._v("Bid")]),
      _vm._v(" "),
      _c("div", { staticClass: "c-order-item-empty" }, [_vm._v(" ")]),
      _vm._v(" "),
      _c("div", { staticClass: "c-order-item-right c-order-item-number" }, [
        _vm._v("Ask"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-order-item-right c-order-item-number" }, [
        _vm._v("Volume"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }