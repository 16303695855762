<template>
  <div>
    <div v-once id="linewidget">
      <!-- The widget will be injected here -->
    </div>
  </div>
</template>

<script>
export default {
    name: 'GartnerWidgetComponent',
    mounted() {
        this.initializeGartnerWidget();
    },
    methods: {
        initializeGartnerWidget() {
        // Initialize the widget here
        //no-undef is disabled because GartnerPI_Widget is a global function
        // eslint-disable-next-line no-undef
            GartnerPI_Widget({
                size: 'line',
                theme: 'light',
                sourcingLink: '',
                widget_id: 'NTcxMzc4NTctOWJjNC00NWI0LTgxMTctY2ZkMTY0NzQ5ZGFi',
                version: '2',
                container: document.querySelector('#linewidget')
            });
        }
    }
};
</script>
