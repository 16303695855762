<template>
    <div class='c-chart-options'>
       <div class="c-chart-option" v-for="(item , index) in this.timeRange" v-bind:key="index" @click="onSelect(item)"  >
           <div :class="selected === index ? 'c-chart-option-inner-selected' : 'c-chart-option-inner'">
               {{item}}
           </div>
       </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selected: 1,
            timeRange: ['1D','1W','1M','3M','6M','1Y','3Y','All']
        };
    },
    methods: {
        indexOfString(timeValue) {
            for (var i = 0; i < this.timeRange.length; i++) {
                if (this.timeRange[i] === timeValue) {
                    return i;
                }
            }
            return -1;
        },
        onSelect(value) {
            this.selected = this.indexOfString(value) ;
            this.$emit('selected', this.selected);
        }
    }
};
</script>
<style lang="scss">
    .c-chart-options{
        display: flex;
        justify-content: right;
        flex-direction: row;
    }
    .c-chart-option {
        padding: 0 0.5em 0 0.5em;
    }
    .c-chart-option-inner {
        cursor: pointer;
            &-selected {
                border-bottom: 0.2em solid #FF370F;
                cursor: pointer;
            }
    }
</style>
