<template>
    <div :is="wrapper" class="row">
        <slot/>
    </div>
</template>

<script>
export default {
    props: {
        wrapper: {
            default: 'div' // eslint-disable-line
        }
    }
};
</script>
