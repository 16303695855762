var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "epi-link",
    { staticClass: "c-news-item o-link--no-style", attrs: { href: _vm.url } },
    [
      _vm.showImage
        ? _c(
            "ResponsiveImage",
            _vm._b(
              { attrs: { src: _vm.imageUrl, alt: _vm.imageTitle } },
              "ResponsiveImage",
              _vm.imageConfig,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-news-item__text" },
        [
          !_vm.hideDate
            ? _c("Datetime", {
                attrs: {
                  date: _vm.published,
                  value: _vm.published,
                  useUsDateFormat: _vm.useUsDateFormat,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "o-ingress" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("v-clamp", { attrs: { autoresize: "", "max-lines": 4 } }, [
            _vm._v(_vm._s(_vm.intro)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Anchor",
        {
          staticClass: "u-display-md-up",
          attrs: { isFancy: "", href: _vm.url },
        },
        [_vm._v(_vm._s(_vm.getLabel("global", "readMore")))]
      ),
      _vm._v(" "),
      _c("Icon", { attrs: { icon: "arrow_right" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }