<!--
    Not all IR modules supports auto-height. If they dont you have to set the height manually.
-->
<template>
<div v-if="src">
    <iframe :name="name" :id="name" :src="src" :height="`${_height}px`" frameborder="0" scrolling="no" @load="postMessage"></iframe>
  </div>
</template>

<script>
export default {
    props: {
        src: String,
        height: Number,
        getHeightFromFrame: {
            type: Boolean
        }
    },
    data() {
        return {
            responseHeight: 0,
            name: 'irembed_' + this._uid
        };
    },
    computed: {
        _height() {
            if (this.getHeightFromFrame) {
                return this.responseHeight;
            }
            return this.height;
        }
    },
    mounted() {
        if (this.getHeightFromFrame) {
            const childWindow = document.getElementById(this.name).contentWindow;
            childWindow.postMessage('Info', '*');

            window.addEventListener('message', (e) => {
                /* console.log('fikk en melding');
                console.log(e.data); */

                if (e.origin === 'https://ir.asp.manamind.com' && e.source === childWindow) {
                    this.responseHeight = e.data;
                }
            });
        }
    },
    methods: {
        postMessage() {
            if (this.getHeightFromFrame) {
                // console.log('postMessage');
                const iframe = window.frames[this.name];
                iframe.postMessage('Info', '*');
            }
        }
    }
};
</script>
