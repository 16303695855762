var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "t-default",
      class: [{ "t-default--no-scroll": !_vm.isBodyScrollable }],
    },
    [
      _c(
        "Header",
        _vm._b(
          {
            attrs: {
              isWhite: _vm.isStartPage,
              isOverlapping: _vm.isStartPage,
              showBreadcrumbs: !_vm.isStartPage,
              loginPage: _vm.loginPage,
              isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
              isMenuOpen: _vm.isMenuOpen,
              currentLanguage: _vm.currentLanguage,
            },
          },
          "Header",
          _vm.model.header,
          false
        )
      ),
      _vm._v(" "),
      _vm.model.gated && !_vm.isValidated
        ? _c("GatedContentPage", {
            attrs: {
              pageId: _vm.model.contentLink.id,
              form: _vm.model.siteSettings.embededGateForm,
            },
            on: { validated: _vm.validated },
          })
        : _vm._t("default", null, { model: _vm.model }),
      _vm._v(" "),
      _c(
        "Footer",
        _vm._b(
          {},
          "Footer",
          {
            ..._vm.model.footer,
            headerTop: {
              isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
              isMenuOpen: _vm.isMenuOpen,
              currentLanguage: _vm.currentLanguage,
              searchPage: _vm.searchPage,
              loginPage: _vm.loginPage,
            },
          },
          false
        )
      ),
      _vm._v(" "),
      _vm.redirected
        ? _c("redirect-alert", {
            attrs: { text: _vm.model.siteSettings.redirectText },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }