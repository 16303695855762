var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-search-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                [
                  _vm.model.title || _vm.isEditable
                    ? _c(
                        "Heading",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "Title",
                              expression: "'Title'",
                            },
                          ],
                          staticClass: "u-add-colored-period",
                        },
                        [_vm._v(_vm._s(_vm.model.title))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("Column", { attrs: { md: 8, sm: 15 } }, [
                _c(
                  "div",
                  { staticClass: "c-search-page__input-wrapper" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.query,
                          expression: "query",
                        },
                      ],
                      staticClass: "c-search-page__input",
                      domProps: { value: _vm.query },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getSearchResults(_vm.query)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.query = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("Button", {
                      staticClass: "c-search-page__input-icon",
                      attrs: { buttonStyle: "no-style", icon: "search" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.getSearchResults(_vm.query)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.results === null
            ? _c("div", { staticClass: "c-search-page__no__results" }, [
                _vm._v('No results for "' + _vm._s(_vm.queryAfterSearch) + '"'),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", { staticClass: "u-spinner" }, [_vm._v("Loading...")])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading && _vm.dataLoaded
            ? _c("List", {
                staticClass: "c-search-page__results",
                attrs: { items: _vm.results, itemClass: "c-search-page__item" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "Anchor",
                            {
                              staticClass: "c-search-page__item__url",
                              attrs: { href: item.url },
                            },
                            [_vm._v(_vm._s(item.displayUrl))]
                          ),
                          _vm._v(" "),
                          _c(
                            "Heading",
                            {
                              staticClass: "u-no-margins",
                              attrs: { level: 5 },
                            },
                            [_vm._v(_vm._s(item.name))]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(item.snippet))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1317733577
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }