<template>
    <div class="c-date">
        <template v-if="type === 'date'">
            {{ value | formatDate(language,useUsDateFormat)}}
        </template>
        <template v-if="type === 'time'">
            {{ value | formatTime(language)}}
        </template>
    </div>
</template>

<script>
export default {
    props: {
        useUsDateFormat: Boolean,
        value: String,
        type: {
            type: String,
            default: 'date', // eslint-disable-line
            validator: function (value) {
                // The value must match one of these strings
                return ['time', 'date'].indexOf(value) !== -1;
            }
        }
    },
    computed: {
        language: function () {
            return this.$store.state.epiDataModel.language;
        }
    },
    filters: {
        // Filter for formatting dates based on culture.
        formatDate(value, culture, useUsDateFormat) {
            if (!value) {
                return '';
            }
            var datetime = new Date(value);
            var options = {
                locale: culture,
                timeZone: 'UTC',
            };
            if (useUsDateFormat) {
                return datetime.toLocaleDateString('en-US', options).replace(/\./g, '/');
            }
            return datetime.toLocaleDateString(options).replace(/\./g, '/');
        },
        formatTime(value, culture) {
            if (!value) {
                return '';
            }
            var datetime = new Date(value);
            var options = { timeZone: 'UTC', timeZoneName: 'short' };
            return datetime.toLocaleTimeString(culture, options);
        }
    }
};
</script>
