var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-file-download-block o-bg o-bg--beige-light" },
    [
      _c(
        "Column",
        { attrs: { md: 10 } },
        [
          _c("Heading", { attrs: { level: 3, noMarginTop: "" } }, [
            _vm._v(_vm._s(_vm.text)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        { attrs: { offsetMd: 4, md: 8 } },
        [
          _c("Heading", { attrs: { isCategory: "" } }, [
            _vm._v(_vm._s(_vm.file.type)),
          ]),
          _vm._v(" "),
          _c("Heading", { attrs: { level: 4, noMarginTop: "" } }, [
            _vm._v(_vm._s(_vm.file.name)),
          ]),
          _vm._v(" "),
          _c(
            "Anchor",
            {
              attrs: {
                isFancy: "",
                download: _vm.file.name,
                href: _vm.file.url,
              },
            },
            [_vm._v(_vm._s(_vm.file.linkText))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }