<template>
<main class="c-ir-share-information-page">
    <Container>
         <div class="c-share-information__heading">
            <Heading :level="3">{{'Share Information'}}</Heading>
        </div>
        <ShareInformation :snapShot="model.snapShot"/>
        <ShareDetails :snapShot="model.snapShot" />
        <div v-if="loadingGraphData">
            <div class="u-spinner">Loading... </div>
        </div>
        <SharePriceChart v-show="!loadingGraphData"  @loaded="showGraph" @loading="hideGraph" :lastPrice="model.snapShot.last" />
        <TradingActivity :orders="model.orderDepth" :latestTrades="model.trades" :seeAllLabel="model.seeAllTradesLabel" :seeAllUrl="model.seeAllTrades"/>
        <NewsStream :calendar="model.financialCalendarEntries" :notifications="model.companyDisclosures" :seeAllLabel="model.seeAllNewsLabel" :seeAllUrl="model.seeAllNews" class ="o-bg o-bg--beige-light"/>
        <TopShareHolders :shareholders="model.shareHolders" />
        <AnalystCoverage :content="model.analystCoverageList.content"/>
        <FileListBlock v-bind="model.fileList.content" v-epi-edit="'FileList'" class="c-share-information__files"/>
    </Container>
</main>
</template>

<script>
import IREmbed from '@/Scripts/components/organisms/IREmbed.vue';
import ShareInformation from '@/Scripts/components/molecules/investorRelations/ShareInformation.vue';
import ShareDetails from '@/Scripts/components/molecules/investorRelations/ShareDetails.vue';
import SharePriceChart from '@/Scripts/components/molecules/investorRelations/SharePriceChart.vue';
import TradingActivity from '@/Scripts/components/molecules/investorRelations/TradingActivity.vue';
import NewsStream from '@/Scripts/components/molecules/investorRelations/NewsStream.vue';
import TopShareHolders from '@/Scripts/components/molecules/investorRelations/TopShareHolders.vue';
import AnalystCoverage from '@/Scripts/components/molecules/investorRelations/AnalystCoverage.vue';
import FileListBlock from '../../blocks/FileListBlock.vue';

export default {
    props: {
        model: Object
    },
    data() {
        return {
            shareholdersOpen: false,
            loadingGraphData: true,
        };
    },
    methods: {
        toggleShareholders() {
            this.shareholdersOpen = !this.shareholdersOpen;
        },
        showGraph() {
            this.loadingGraphData = false;
        },
        hideGraph() {
            this.loadingGraphData = true;
        }
    },
    components: {
        IREmbed,
        ShareInformation,
        ShareDetails,
        SharePriceChart,
        TradingActivity,
        NewsStream,
        TopShareHolders,
        AnalystCoverage,
        FileListBlock
    }
};
</script>

<style lang="scss">
.c-share-information{
    margin-top: 5rem;
    margin-bottom: 5rem;
    &__loading{
        text-align: center;
        font-size: 5em;
        padding: 4em;
    }
    &__heading{
        margin-bottom: 2em;
    }
    &__files{
        margin-top: 5em;
        margin-bottom: 5em;
    }
}
</style>
