// TODO: Set size for images
<template>
    <epi-link :href="url" class="c-news-component--vertical o-link--no-style">
        <Row>
            <Column :xs="12" :class="{'flip' : flip}">
                <ResponsiveImage :src="imageUrl" :xs="{h: 135, w: 167}" :sm="{h: 135, w: 202}" :md="{h: 116, w: 150}" :lg="{h: 116, w: 150}"/>
            </Column>
            <Column :xs="12">
                <div>
                    <Row>
                        <Column v-if="!hideDate">
                            <Datetime :date="published" :value="published" :useUsDateFormat="useUsDateFormat"/>
                        </Column>
                        <Column>
                            <span>{{title}}</span>
                        </Column>
                    </Row>
                </div>
            </Column>
        </Row>
    </epi-link>
</template>

<script>
import Row from '@/Scripts/components/grid/Row.vue';
import Column from '@/Scripts/components/grid/Column.vue';
import EpiLink from '@/Scripts/components/atoms/EpiLink.vue';
import Datetime from '@/Scripts/components/atoms/Datetime.vue';

export default {
    props: {
        imageUrl: String,
        imageTitle: String,
        published: String,
        title: String,
        intro: String,
        label: String,
        linkTarget: String,
        url: String,
        flip: Boolean,
        hideDate: Boolean,
        useUsDateFormat: Boolean,
        showImage: {
            type: Boolean,
            default: true // eslint-disable-line
        },
        showArrow: {
            type: Boolean,
            default: true // eslint-disable-line
        },
    },
    components: {
        Row,
        Column,
        EpiLink,
        Datetime
    }
};
</script>

<style lang="scss">
.c-news-component {
    $self: &;
    &--vertical {
        word-wrap: break-word;
        overflow-wrap: break-word;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        display: block;

        .flip {
            order: 1;
        }
    }
}
</style>
