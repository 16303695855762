<template>
<main class="c-not-found-page">
    <Container>
        <Heading v-epi-edit="'Title'" class="c-not-found-page__title u-no-margins">{{model.title}}</Heading>
        <XhtmlField v-epi-edit="'mainBody'" class="c-not-found-page__body" :items="model.mainBody"/>
        <Button :href="model.parentLink.url">{{getLabel('notFound404Page', 'returnToFrontpageButtonText')}}</Button>
    </Container>
</main>
</template>

<script>
import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop) || '',
});

let value = params.url;

if (value) {
    value = decodeURIComponent(value);
}

export default {
    props: {
        model: Object
    },
    components: {
        XhtmlField
    },
    mounted: function () {
        if (value) {
            history.pushState({}, '', value);
        }
    },
};
</script>

<style lang="scss">
.c-not-found-page {
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 10rem;

    @include media-sm {
        margin-top: 10rem;
        margin-bottom: 14rem;
    }
    &__title {
        font-size: 80px;
        margin-bottom: 2rem;
        line-height: 1em;

        @include media-sm {
            font-size: 150px;
        }
        @include media-md {
            font-size: 400px;
            margin-bottom: 0;
        }
    }
    &__body {
        font-size: 18px;
        margin-bottom: 3rem;

        @include media-sm {
            font-size: 24px;
        }
        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
