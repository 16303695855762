var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-product-list" },
    [
      _vm.title
        ? _c(
            "Column",
            [
              _c(
                "Heading",
                { staticClass: "u-add-colored-period u-no-margin-top" },
                [_vm._v(_vm._s(_vm.title))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Column",
        [
          _vm.products
            ? _c(
                "Row",
                { staticClass: "c-product-list__row" },
                _vm._l(_vm.products, function (category, index) {
                  return _c(
                    "Column",
                    {
                      key: `category_item${_vm._uid}${index}`,
                      staticClass: "c-product-list__category",
                      attrs: { md: _vm.md, sm: 12 },
                    },
                    [
                      _c(
                        "Heading",
                        {
                          staticClass: "c-product-list__category__title",
                          attrs: { isCategory: "" },
                        },
                        [_vm._v(_vm._s(category.title))]
                      ),
                      _vm._v(" "),
                      _c("List", { attrs: { items: category.children } }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }