<template>
<main class="c-ir-corporate-governance-page">
    <Container>
        <Row class="c-ir-corporate-governance-page__top">
            <Column>
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
            <Column :md="10">
                <Heading noMarginTop :level="4" v-epi-edit="'Title'">{{model.title}}</Heading>
                <XhtmlField :items="model.introLeft" v-epi-edit="'IntroLeft'"/>
            </Column>
            <Column :md="10" :offsetMd="4">
                <XhtmlField :items="model.introRight" v-epi-edit="'IntroRight'"/>
            </Column>
        </Row>
        <AdvancedFileListBlock v-epi-edit="'AdvancedFileListBlock'" class="c-ir-corporate-governance-page__files" v-bind="model.advancedFileListBlock.content"/>
        <ExpandableListBlock v-epi-edit="'ExpandableListBlock'" class="c-ir-corporate-governance-page__expand" v-bind="model.expandableListBlock.content"/>
    </Container>
</main>
</template>

<script>
export default {
    props: {
        model: Object
    }
};
</script>

<style lang="scss">
.c-ir-corporate-governance-page {
    &__top, &__files, &__expand {
        margin-bottom: 2rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
