<template>
<div>
    <!-- Mobile and Tablet -->
    <Row wrapper="ul" class="o-flat-list c-offices-block c-offices-block@xs c-offices-block@sm">
        <Column>
            <Heading isCategory>{{label}}</Heading>
            <Heading :level="1" class="c-offices-block__title u-add-colored-period">{{title}}</Heading>
        </Column>
         <Column wrapper="li" v-for="(region, index) in offices" :key="region.regionName + index">
                <Collapse :border="false">
                    <template #header>
                        <Heading class="u-no-margins" :level="3">{{region.regionName}}</Heading>
                    </template>
                    <template #content>
                        <Row wrapper="ul" class="o-flat-list c-offices-block__offices">
                            <Column class="c-offices-block__offices-wrapper" wrapper="li" :md="12" v-for="(country, index) in region.countries" :key="country.country + index">
                                <Collapse>
                                    <template #header>
                                        {{country.country}}
                                    </template>
                                    <template #content>
                                        <template v-for="(office, index) in country.offices">
                                            <span class="u-block o-large-body" :key="`office_name@sm_${_uid}_${index}`">{{office.officeName}}</span>
                                            <p class="o-small-body" :key="`office_body1@sm_${_uid}_${index}`">
                                                <span class="u-block">{{office.addressLineOne}}</span>
                                                <span class="u-block">{{office.addressLineTwo}}</span>
                                                <span class="u-block">{{office.addressLineThree}}</span>
                                                <span class="u-block">{{office.addressLineFour}}</span>
                                            </p>
                                            <p class="o-small-body" :key="`office_body2@sm_${_uid}_${index}`">
                                                <span class="u-block">{{office.phoneNumber}}</span>
                                                <span class="u-block">{{office.email}}</span>
                                                <span class="u-block">{{office.websiteUrl}}</span>
                                            </p>
                                        </template>
                                    </template>
                                </Collapse>
                            </Column>
                        </Row>
                    </template>
                </Collapse>
            </Column>
    </Row>
    <!-- Desktop and widescreen -->
    <Row class="c-offices-block c-offices-block@md c-offices-block@lg">
        <Column :md="8"  :offset-md="2" class="c-offices-block__col1">
            <Heading isCategory>{{label}}</Heading>
            <Heading :level="4" class="c-offices-block__title u-add-colored-period">{{title}}</Heading>
            <ul class="c-offices-block__regions o-flat-list">
                <li @click="toggleRegion(region)" class="c-offices-block__region" :class="{'c-offices-block__region--selected' : region === selectedRegion}" v-for="(region, index) in offices" :key="region.regionName + index">
                    <Row>
                        <Column :xs="16">
                            <Heading :level="5" class="c-offices-block__region__title u-no-margins">{{region.regionName}}</Heading>
                        </Column>
                        <Column :xs="4" align="right">
                            <Icon :icon="'chevron_right'"/>
                        </Column>
                    </Row>
                </li>
            </ul>
        </Column>
        <!-- Show map if no region is selected -->
        <Column v-if="!selectedRegion" :md="12" :offset-md="2" class="c-offices-block__col2">
            <ResponsiveImage :src="image" />
        </Column>
        <!-- Displays the selected region -->
        <Column v-else :md="12" :offset-md="2" class="c-offices-block__col2">
            <Heading :level="5">{{selectedRegion.regionName}}</Heading>

            <Row wrapper="ul" class="o-flat-list c-offices-block__offices">
                <Column wrapper="li" :md="12" v-for="(country, index) in selectedRegion.countries" :key="country + index">
                    <Collapse>
                        <template #header>
                            {{country.country}}
                        </template>
                        <template #content>
                            <template v-for="(office, index) in country.offices">
                                <span class="u-block o-large-body" :key="`office_name_${_uid}_${index}`">{{office.officeName}}</span>
                                <p class="o-small-body" :key="`office_body1_${_uid}_${index}`">
                                    <span class="u-block">{{office.addressLineOne}}</span>
                                    <span class="u-block">{{office.addressLineTwo}}</span>
                                    <span class="u-block">{{office.addressLineThree}}</span>
                                    <span class="u-block">{{office.addressLineFour}}</span>
                                </p>
                                <p class="o-small-body" :key="`office_body2_${_uid}_${index}`">
                                    <span class="u-block">{{office.phoneNumber}}</span>
                                    <span class="u-block">{{office.email}}</span>
                                    <span class="u-block">{{office.websiteUrl}}</span>
                                </p>
                            </template>
                        </template>
                    </Collapse>
                </Column>
            </Row>
        </Column>
    </Row>
</div>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';
import Collapse from '@/Scripts/components/molecules/Collapse.vue';

export default {
    data() {
        return {
            selectedRegion: null
        };
    },
    props: ['label', 'offices', 'title', 'image'],
    components: {
        Heading,
        Icon,
        Collapse
    },
    methods: {
        toggleRegion: function (item) {
            if (item === this.selectedRegion) {
                this.selectedRegion = null;
            } else {
                this.selectedRegion = item;
            }
        }
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_breakpoints.scss';

.c-offices-block {
    $self: &;

    &\@xs {
        @include media-md {
            display: none !important;
        }
        #{$self}__offices-wrapper {
            padding: 0 1em;
        }
    }
    &\@md {
        display: none;

        @include media-md {
            display: flex;
        }
    }
    &__title {
        margin-top: 0;
    }
    &__region {
        $region: &;
        font-size: 27px;
        margin-top: 50px;
        margin-bottom: 50px;
        cursor: pointer;

        .c-icon {
            fill: $orange;
        }

        &--selected {
            #{$region}__title {
                &:before {
                    content: '_ ';
                    color: $orange;
                }
            }
        }
    }
    &__col1, &__col2, &\@xs {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    &__col1 {
        z-index: 1;
        position: relative;
        background-color: $beige-light;

        &:before {
            content: '';
            background: $beige-light;
            position: absolute;
            width: 300%;
            top: 0px;
            height: 100%;
            left: -200%;
            z-index: -1;
        }
    }
    .c-collapse__content-wrapper {
        padding-top: .5em;
        padding-bottom: .5em;
    }
}
</style>
