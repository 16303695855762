<template>
      <img
        class="c-responsive-image"
        v-if="src"
        :alt="alt"
        :src="src"
        :srcset="computedSrcset"
        :sizes="computedSizes"
    >
</template>

<script>

export default {
    props: {
        /**
        * Image description
        */
        alt: {
            type: String,
            required: false,
        },
        /**
         * Base url before image resizer.
         */
        src: {
            type: String,
            default: null, // eslint-disable-line quote-props
        },
        /*  // Max size for image in different breakpoints
        xs.w: {
            type: Number,
            default: 345 // eslint-disable-line
        },
        sm.w: {
            type: Number,
            default: 642 // eslint-disable-line
        },
        md.w: {
            type: Number,
            default: 1154 // eslint-disable-line
        },
        lg.w: {
            type: Number,
            default: 1358 // eslint-disable-line
        }, */
        xs: {
            type: Object,
            // eslint-disable-next-line quote-props
            default: () => (
                { w: 345, h: null }
            ),
        },
        sm: {
            type: Object,
            // eslint-disable-next-line quote-props
            default: () => (
                { w: 642, h: null }
            ),
        },
        md: {
            type: Object,
            // eslint-disable-next-line quote-props
            default: () => (
                { w: 1154, h: null }
            ),
        },
        lg: {
            type: Object,
            // eslint-disable-next-line quote-props
            default: () => (
                { w: 1358, h: null }
            ),
        },
    },
    data() {
        return {
            screenSmMin: 688,
            screenMdMin: 1194,
            screenLgMin: 1398,
        };
    },
    computed: {
        computedSizes() {
            var sizeXs = `(max-width: ${this.screenSmMin - 1}px) ${this.xs.w}px`;
            var sizeSm = `(max-width: ${this.screenMdMin - 1}px) ${this.sm.w}px`;
            var sizeMd = `(max-width: ${this.screenLgMin - 1}px) ${this.md.w}px`;
            var sizeLg = `(min-width: ${this.screenLgMin}px) ${this.lg.w}px`;

            return `${sizeXs}, ${sizeSm}, ${sizeMd}, ${sizeLg}`;
        },
        computedSrcset() {
            var xs = `${this.src}?w=${this.xs.w}&h=${this.xs.h} ${this.xs.w}w`;
            var sm = `${this.src}?w=${this.sm.w}&h=${this.sm.h} ${this.sm.w}w`;
            var md = `${this.src}?w=${this.md.w}&h=${this.md.h} ${this.md.w}w`;
            var lg = `${this.src}?w=${this.lg.w}&h=${this.lg.h} ${this.lg.w}w`;
            return `${xs}, ${sm}, ${md}, ${lg}`;
            //return this.src + '?w=345 550w, ' + this.src + '?w=642 700w, ' + this.src + '?w=1358 950w';
            //return `${this.src}?w=${this.xs.w} ${this.xs.w}w, ${this.src}?w=${this.sm.w} ${this.sm.w}w, ${this.src}?w=${this.md.w} ${this.md.w}w, ${this.src}?w=${this.lg.w} ${this.lg.w}w,`;
        },
    }
};
</script>

<style lang="scss">
.c-responsive-image {
    width: 100%;
}
</style>
