var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "vs--multiple": _vm.multiple } },
    [
      _c(
        "v-select",
        _vm._b(
          {
            on: { input: _vm.updateSelected },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function ({ search, searching }) {
                  return [
                    searching
                      ? [
                          _vm._v("\r\n                Ingen resultater "),
                          _c("em", [_vm._v(_vm._s(search))]),
                          _vm._v(".\r\n            "),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.localValue,
              callback: function ($$v) {
                _vm.localValue = $$v
              },
              expression: "localValue",
            },
          },
          "v-select",
          _vm.$props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }