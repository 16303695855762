<template>
<main class="c-about-page">
    <Container>
        <Row>
            <Column>
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
        </Row>
        <Row class="c-about-page__intro" v-if="model.leftTopIntro">
            <Column :md="11">
                <Heading noMarginTop :level="4" v-epi-edit="'LeftTopIntroText'">
                    <XhtmlField :items="model.leftTopIntroText"/>
                </Heading>
            </Column>
            <Column :md="11" :offset-md="2">
                <XhtmlField :items="model.rightTopIntroText" v-epi-edit="'RightTopIntroText'"/>
            </Column>
        </Row>
        <Row v-if="notEmptyObject(model.media.content)">
            <Column>
                <MediaBlock v-bind="model.media.content" v-epi-edit="'Media'" class="c-about-page__media"/>
            </Column>
        </Row>
        <TwoColumnBlock v-epi-edit="'TwoColumnBlock'" v-if="model.twoColumnBlock.content" v-bind="model.twoColumnBlock.content" class="c-about-page__two-column"/>
        <QuoteBlock :isSimple="false" v-if="model.quote && model.quote.content && model.quote.content.quote" v-bind="model.quote.content" class="c-about-page__hero"/>
    </Container>

    <Container isFluid background="beige" v-if="model.partners && notEmptyObject(model.partners.content)">
        <Container>
            <Row>
                <Column :offsetMd="2">
                    <PartnersBlock v-bind="model.partners.content" v-epi-edit="'Partners'"/>
                </Column> <!-- TODO -->
            </Row>
        </Container>
    </Container>
    <Container>
        <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-about-page__content-area" :model="model.blocks  || isEditable"/>
    </Container>
    <Container v-if="model.offices.content && model.offices.content.offices.length > 0">
        <OfficesBlock v-epi-edit="'Offices'" v-bind="model.offices.content"/>
    </Container>
</main>
</template>

<script>
import ContentArea from '@/Scripts/components/ContentArea.vue';

import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';

export default {
    props: ['model'],
    components: {
        XhtmlField,
        Heading,
        ContentArea
    }
};
</script>

<style lang="scss">
.c-about-page {
    &__intro, &__two-column, &__media {
        margin-bottom: 3rem;
    }
    &__hero {
        margin-bottom: 6rem;
    }
    &__content-area {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
