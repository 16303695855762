var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-person-list" },
    _vm._l(_vm.people, function (person, index) {
      return _c(
        "Column",
        {
          key: `person_${_vm._uid}_${index}`,
          attrs: { md: 10, offsetMd: index % 2 === 0 ? 0 : 4 },
        },
        [
          _c(
            "Person",
            _vm._b(
              { staticClass: "c-person-list__item" },
              "Person",
              person,
              false
            )
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }