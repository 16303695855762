var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-contact-us-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-contact-us-page__top" },
            [
              _c(
                "Column",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "Title",
                      expression: "'Title'",
                    },
                  ],
                  attrs: { offsetMd: 2 },
                },
                [
                  _c(
                    "Heading",
                    { staticClass: "u-no-margin-top", attrs: { level: 2 } },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "IntroText",
                      expression: "'IntroText'",
                    },
                  ],
                  attrs: { md: 10, offsetMd: 2 },
                },
                [
                  _c("XhtmlField", {
                    staticClass: "o-ingress",
                    attrs: { items: _vm.model.introText },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "SmallIntroText",
                      expression: "'SmallIntroText'",
                    },
                  ],
                  attrs: { md: 8, offsetMd: 2 },
                },
                [
                  _c("XhtmlField", {
                    attrs: { items: _vm.model.smallIntroText },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 20, offsetMd: 2 } },
                [
                  _vm.model.blocks || _vm.isEditable
                    ? _c("ContentArea", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Blocks",
                            expression: "'Blocks'",
                          },
                        ],
                        staticClass: "c-contact-us-page__content-area",
                        attrs: { model: _vm.model.blocks || _vm.isEditable },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            [
              _c(
                "Column",
                {
                  staticClass: "u-flex c-contact-us-page__tabs",
                  attrs: { offsetMd: 2 },
                },
                [
                  _c(
                    "Button",
                    {
                      staticClass: "c-contact-us-page__button",
                      class: {
                        "c-contact-us-page__button--selected":
                          _vm.selectedTab === 1,
                      },
                      attrs: { buttonStyle: "no-style" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.selectedTab = 1
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(
                            _vm.getLabel("contactUsPage", "contactUsButtonText")
                          ) +
                          "\r\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      staticClass: "c-contact-us-page__button",
                      class: {
                        "c-contact-us-page__button--selected":
                          _vm.selectedTab === 2,
                      },
                      attrs: { buttonStyle: "no-style" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.selectedTab = 2
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(
                            _vm.getLabel(
                              "contactUsPage",
                              "supportCenterButtonText"
                            )
                          ) +
                          "\r\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            { class: { "u-hidden": _vm.selectedTab !== 1 } },
            [
              _c(
                "Column",
                [
                  _c(
                    "OfficesBlock",
                    _vm._b(
                      {},
                      "OfficesBlock",
                      _vm.model.offices && _vm.model.offices.content,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            { class: { "u-hidden": _vm.selectedTab !== 2 } },
            [
              _c(
                "Column",
                { attrs: { offsetMd: 2, md: 20 } },
                [
                  _c(
                    "SupportCentersBlock",
                    _vm._b(
                      { staticClass: "c-contact-us-page__support-centers" },
                      "SupportCentersBlock",
                      _vm.model.supportCenters &&
                        _vm.model.supportCenters.content,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }