var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-right-shifted-content-block o-bg o-bg--beige-light" },
    [
      _c(
        "BreakoutColumn",
        {
          staticClass: "c-right-shifted-content-block__image-wrapper",
          attrs: {
            md: 10,
            offsetMd: 2,
            sm: 18,
            offsetSm: 6,
            breakoutSide: "right",
          },
        },
        [
          _c(
            "ResponsiveImage",
            _vm._b(
              { attrs: { src: _vm.image, imageSizeLg: 715, imageSizeXs: 515 } },
              "ResponsiveImage",
              _vm.imageConfig,
              false
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        {
          staticClass: "c-right-shifted-content-block__content",
          attrs: { offsetMd: 2, md: 10, sm: 22 },
        },
        [
          _c("Heading", { attrs: { level: 3 } }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("p", { staticClass: "o-ingress-large" }, [
            _vm._v(_vm._s(_vm.intro)),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.text))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }