<!-- TODO: FIX FORM -->
<template>
<main class="c-software-partner-page">
    <Container>
        <Row class="c-software-partner-page__top">
            <Column v-if="model.label || isEditable">
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
            <Column :md="11">
                <!-- eslint-disable-next-line no-irregular-whitespace -->
                <Heading class="c-software-partner-page__top__title" noMarginTop :level="1" v-epi-edit="'title'" v-if="model.title || isEditable">{{model.title}}</Heading>
            </Column>
            <Column :md="11" :offset-md="2">
                <XhtmlField class="o-ingress c-software-partner-page__intro" :items="model.introText" v-epi-edit="'introText'" />
                <Button v-scroll-to="'#form'" v-if="notEmptyObject(model.form && model.form.content)">{{getLabel('softwarePartnerPage','topBannerButtonText')}}</Button>
            </Column>
        </Row>
        <Row class="c-software-partner-page__media" v-if="notEmptyObject(model.media.content) || isEditable">
            <MediaBlock v-bind="model.media.content" v-epi-edit="'Media'" />
        </Row>
    </Container>
    <Container isFluid background="beige" v-if="model.categories.length">
        <Container>
            <ProductList :products="model.categories" :title="getLabel('softwarePartnerPage', 'productsHeading')" />
        </Container>
    </Container>
    <Container>
        <TwoColumnBlock v-if="notEmptyObject(model.twoColumnBlock.content) || isEditable" v-bind="model.twoColumnBlock.content" v-epi-edit="'TwoColumnBlock'" class="c-software-partner-page__two-column-block" />
        <PageListBlock class="c-software-partner-page__page-list" v-if="notEmptyObject(model.pageListBottom.content) || isEditable" v-bind="model.pageListBottom.content" v-epi-edit="'pageListBottom'" />
        <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-software-partner-page__content-area" :model="model.blocks  || isEditable"/>
        <FormBlock v-epi-edit="'Form'" id="form" class="c-software-partner-page__form" v-if="notEmptyObject(model.form && model.form.content) || isEditable" v-bind="model.form.content"/>
    </Container>
    <Container isFluid background="beige-light" v-if="model.newsBlock.content || isEditable">
        <Container>
            <NewsBlock v-bind="model.newsBlock.content" v-epi-edit="'NewsBlock'" />
        </Container>
    </Container>
    <Container>
        <!-- eslint-disable-next-line no-irregular-whitespace -->
        <Row class="c-software-partner-page__images" v-if="isEditable || model.images" v-epi-edit="'Images'">
            <Column :md="6" v-for="(image, index) in model.images" :key="`${_uid}_${index}`" class="c-software-partner-page__images__image-wrapper">
                <ResponsiveImage :src="image.url" />
            </Column>
        </Row>
    </Container>
</main>
</template>

<script>
import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';
import ProductList from '@/Scripts/components/molecules/ProductList.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';

export default {
    props: {
        model: Object
    },
    components: {
        XhtmlField,
        ProductList,
        ContentArea
    }
};
</script>

<style lang="scss">
.c-software-partner-page {
    &__top {
        margin-bottom: 4rem;

        @include media-md {
            margin-bottom: 5rem;
        }

        &__title {
            //margin-bottom: 0;
        }
    }

    &__intro {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__media {
        margin-bottom: 5rem;
    }

    &__two-column-block {
        margin-top: 5rem;
        margin-bottom: 7rem;
    }

    &__images {
        margin-top: 5rem;
        margin-bottom: 5rem;

        & &__image-wrapper {
            padding-right: 3rem;
        }
    }
    &__content-area, &__page-list {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }

    .c-product-list {
        &__row {
            @include media-sm {
                justify-content: space-between;
            }

            @include media-md {
                justify-content: initial;
            }
        }

        &__category {
            @include media-sm {
                flex-basis: 45%;
                max-width: 45%;
            }

            @include media-md {
                flex-basis: 25%;
                max-width: 25%;
            }
        }
    }
}
</style>
