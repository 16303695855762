var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-hero-block" },
    [
      _c(
        "Row",
        { staticClass: "c-hero-block__background" },
        [
          _c(
            "BreakoutColumn",
            {
              staticClass: "c-hero-block__col1",
              attrs: { sm: 10, md: 10, breakoutSide: "left" },
            },
            [
              _c(
                "ResponsiveImage",
                _vm._b(
                  { attrs: { src: _vm.image } },
                  "ResponsiveImage",
                  _vm.imageConfig,
                  false
                )
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            {
              staticClass: "c-hero-block__col2",
              attrs: {
                sm: 12,
                md: 10,
                offsetMd: 2,
                offsetSm: 2,
                align: "center",
              },
            },
            [
              _c(
                "Heading",
                {
                  staticClass: "c-hero-block__category",
                  attrs: { isCategory: "" },
                },
                [_vm._v(_vm._s(_vm.label))]
              ),
              _vm._v(" "),
              _c(
                "Heading",
                {
                  staticClass: "u-add-colored-period u-no-margins",
                  attrs: { level: 2 },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _vm._v(" "),
              _c("XhtmlField", {
                staticClass: "o-large-body",
                attrs: { items: _vm.introText },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-hero-block__links" },
                [
                  _vm.linkOne
                    ? _c(
                        "Anchor",
                        { attrs: { href: _vm.linkOne, isFancy: "" } },
                        [_vm._v(_vm._s(_vm.linkOneText))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.linkTwo
                    ? _c(
                        "Anchor",
                        { attrs: { href: _vm.linkTwo, isFancy: "" } },
                        [_vm._v(_vm._s(_vm.linkTwoText))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }