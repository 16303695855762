var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-news-stream" }, [
    _c(
      "div",
      { staticClass: "c-news-stream-notifications" },
      [
        _c(
          "div",
          { staticClass: "c-news-stream-notifications-heading" },
          [
            _c("Heading", { attrs: { level: 5 } }, [
              _vm._v(_vm._s("Investor News")),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("NotificationList", {
          attrs: {
            notifications: _vm.notifications,
            seeAllLabel: _vm.seeAllLabel,
            seeAllUrl: _vm.seeAllUrl,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-news-stream-financial-calendar" },
      [
        _c(
          "div",
          { staticClass: "c-news-stream-financial-calendar-heading" },
          [
            _c("Heading", { attrs: { level: 5 } }, [
              _vm._v(_vm._s("Financial Calendar")),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("FinancialCalendar", { attrs: { calendar: _vm.calendar } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }