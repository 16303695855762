<template>
<div class="c-page-list-list">
    <Heading isCategory class="c-page-list-list__label" v-if="label">{{label}}</Heading>
    <Heading :level="4" v-if="title" class="c-page-list-list__title u-no-margins">{{title}}</Heading>
    <p class="c-page-list-list__intro o-large-body" v-if="intro && !introHtml">{{intro}}</p>
    <XhtmlField class="o-large-body" v-if="introHtml" :items="introHtml"/>
    <List :items="items"/>
    <Anchor v-if="url" :href="url" isFancy>{{getLabel('global', 'viewAll')}}</Anchor>
</div>
</template>

<script>
import List from '@/Scripts/components/molecules/List.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';

export default {
    props: {
        label: String,
        title: String,
        intro: String,
        introHtml: String,
        items: Array,
        url: String
    },
    components: {
        List,
        Anchor
    }
};
</script>

<style lang="scss">
.c-page-list-list {
    &__label {
       /*  margin-bottom: 1.5em;
        display: inline-block;
        color: $gray; */
    }
}
</style>
