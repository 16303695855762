var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.href ? "a" : "button",
    {
      tag: "component",
      staticClass: "o-button",
      class: [{ "o-button--large": _vm.isLarge }, _vm.buttonClass],
      attrs: { href: _vm.href, target: _vm.target },
    },
    [
      _vm.icon && !_vm.iconRight
        ? _c("Icon", {
            staticClass: "o-button__icon",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.icon && _vm.iconRight
        ? _c("Icon", {
            staticClass: "o-button__icon",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }