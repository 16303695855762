<template>
    <Container>
        <div class="practicearea--header">
            <Column v-if="model.illustration > -1" class="practicearea--header__illustration">
                <img :src="'/Assets/Images/practiceareas/' + model.illustration + '.png'" alt="" class="practicearea--header__illustration__img"/>
            </Column>
            <Column>
                <div class="practicearea--header__titleorder">
                    <Heading v-if="model.title !== ''" :level="1" noMarginTop=true noMarginBottom=true class="u-add-colored-period practicearea--header__title">{{model.title}}</Heading>
                    <Heading v-if="model.categoryLabel" :level="2" noMarginTop=true noMarginBottom=true class="practicearea--header__subtitle">{{ model.categoryLabel }}</Heading>
                </div>
                <p class="practicearea--preamble">
                    {{ model.preamble }}
                    {{ model.categoryTitle }}
                </p>
                <button v-if="model.buttonLabel && model.buttonLabel !==''" v-on:click="scrollToContactform">{{ model.buttonLabel }}</button>
            </Column>
        </div>
    </Container>
</template>

<script>
export default {
    props: ['model'],
    methods: {
        scrollToContactform() {
            this.$parent.$refs.contactform.$el.scrollIntoView({ behavior: 'smooth' });
        }
    },
};
</script>
