var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "c-breadcrumbs", attrs: { "aria-label": "breadcrumb" } },
    [
      _c(
        "ul",
        { staticClass: "o-flat-list" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "li",
            {
              key: `breadcrumb__${index}`,
              staticClass: "c-breadcrumbs__item",
              class: { "c-breadcrumbs__item--unclickable": item.unclickable },
            },
            [
              _c(
                "Anchor",
                { attrs: { href: item.url, clickable: !item.unclickable } },
                [
                  _vm._v(
                    _vm._s(
                      index === 0
                        ? _vm.getLabel("breadcrumbs", "home")
                        : item.text
                    )
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }