<template>
    <Row class="c-right-shifted-content-block o-bg o-bg--beige-light">
        <BreakoutColumn :md="10" :offsetMd="2" :sm="18" :offsetSm="6" breakoutSide="right" class="c-right-shifted-content-block__image-wrapper">
            <ResponsiveImage :src="image" :imageSizeLg="715" :imageSizeXs="515" v-bind="imageConfig"/>
        </BreakoutColumn>
        <Column :offsetMd="2" :md="10" :sm="22" class="c-right-shifted-content-block__content">
            <Heading :level="3">{{title}}</Heading>
            <p class="o-ingress-large">{{intro}}</p>
            <p>{{text}}</p>
        </Column>
    </Row>
</template>

<script>

export default {
    props: {
        title: String,
        intro: String,
        text: String,
        image: String
    },
    data() {
        return {
            imageConfig: {
                xs: {w: 355, h: 321},
                sm: {w: 546, h: 370},
                md: {w: 715, h: 684},
                lg: {w: 715, h: 684}
            }
        };
    }
};
</script>

<style lang="scss">
.c-right-shifted-content-block {
    @include media-md {
        &:before {
            right: -200vw;
            top: 0px;
            left: 0;
        }
    }
    &__content {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include media-md {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }
    &__image-wrapper {
        @include media-md {
            order: 2;
        }
         .o-col--breakout__content {
            max-width: 715px;

            @include media-md {
                margin-bottom: 4rem;
            }
        }
    }
}
</style>
