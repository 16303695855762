<!--
To use the phone input bind a variable with v-model like this:

<PhoneInput :defaultCountry="'no'" v-model="phone"/>


The component is a wrapper around vue-select (https://vue-select.org/). Vue Select supports a lot of features.

// TODO: User input validation.
// TODO: Perhaps implement auto detection of country? Using something like this: https://ip2c.org/s
-->

<template>
<div class="c-phone-input">
    <v-select v-bind="$props" @input="updateSelected" v-model="localValue" :options="filteredCountries" label="name" :clearable="false" :searchable="false">
        <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
                <!-- TODO: Translate-->
                Ingen resultater <em>{{ search }}</em>.
            </template>
        </template>
        <template v-slot:option="option">
            +{{ option.dialCode }} {{ option.name }}
        </template>
    </v-select>
    <input :disabled="disabled" @input="updateUserInput" class="c-phone-input__userinput" v-model="userInput"/>
</div>
</template>

<script>
import vSelect from 'vue-select';
import allCountires from '@/Scripts/all-countries.js';

export default {
    props: {
        value: {},
        /*  options: {
            type: Array,
            default: [] // eslint-disable-line
        }, */
        disabled: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        /**
         * Used for filtering only a few selected countries.
         * Use the iso2 code in an array.
         */
        onlyCountries: {
            type: Array,
            default: () => [] // eslint-disable-line
        },
        defaultCountry: null
    },
    data: function () {
        return {
            userInput: '',
            localValue: this.value,
            countries: allCountires
        };
    },
    computed: {
        phoneNumber() {
            return this.localValue + ' ' + this.userInput;
        },
        filteredCountries() {
            // List countries after filtered
            if (this.onlyCountries.length) {
                return this.countries
                    .filter(({ iso2 }) => this.onlyCountries.some((c) => c.toUpperCase() === iso2));
            }
            return this.countries;
        },
    },
    created: function () {
        this.init();
    },
    methods: {
        init: function () {
            // If default country is set update value in dropdown.
            if (this.defaultCountry) {
                this.localValue = this.formatCountryNumber(this.findCountry(this.defaultCountry));
            }
        },
        formatCountryNumber(value) {
            return '+' + value.dialCode;
        },
        updateSelected: function (value) {
            this.localValue = this.formatCountryNumber(value);
            this.$emit('input', this.phoneNumber);
        },
        updateUserInput: function (value) {
            this.$emit('input', this.phoneNumber);
        },
        findCountry(iso = '') {
            return this.countries.find((country) => country.iso2 === iso.toUpperCase());
        },
    },
    components: {
        vSelect
    }
};
</script>

<style lang="scss">
.c-phone-input {
    display: flex;
    position: relative;

    .vs--unsearchable .vs__search {
        padding: 0;
    }
    .v-select {
        cursor: pointer;
        position: static;
        width: 90px;
    }
    .vs__dropdown-toggle {
        border-right: none;
        position: relative;

        &:after {
            content: "";
            height: 80%;
            width: 1px;
            background-color: lightgray;
            position: absolute;
            right: -6px;
            top: 10%;
        }
    }
    .vs__open-indicator {
        fill: gray;
    }
    &__userinput {
        width: 100%;
        padding-left: 1em;
        border-left: none;
    }

}
</style>
