<template>
<div class="c-news-stream">
    <div class="c-news-stream-notifications">
        <div class="c-news-stream-notifications-heading">
            <Heading :level="5">{{'Investor News'}}</Heading>
        </div>
        <NotificationList :notifications="notifications" :seeAllLabel="seeAllLabel" :seeAllUrl="seeAllUrl"/>
    </div>
    <div class="c-news-stream-financial-calendar">
        <div class="c-news-stream-financial-calendar-heading">
            <Heading :level="5">{{'Financial Calendar'}}</Heading>
        </div>
        <FinancialCalendar :calendar="calendar"/>
    </div>
</div>
</template>

<script>

import NotificationList from '@/Scripts/components/molecules/InvestorRelations/NotificationList.vue';
import FinancialCalendar from '@/Scripts/components/molecules/InvestorRelations/FinancialCalendar.vue';

export default {
    props: {
        calendar: {
            type: Array,
        },
        seeAllLabel: {
            type: String,
            required: false
        },
        seeAllUrl: {
            type: String,
            required: false
        },
        notifications: {
            type: Array,
        }
    },
    components: {
        NotificationList,
        FinancialCalendar
    }
};
</script>

<style lang="scss">
.c-news-stream {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom:5em;
        background-color: #f8f6ed;
        padding-bottom:2em;
        @include media-sm{
            flex-direction: row;
        }
}
.c-news-stream-main-content {
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.c-news-stream-notification-heading {
    width:100%;
}
.c-news-stream-notifications{
    width:100%;
    margin-bottom: 2em;
    @include media-sm{
        padding-right: 18px;
        width: 50%;
    }
}
.c-news-stream-financial-calendar{
    width:100%;
    @include media-sm{
        padding-left: 18px;
        width: 50%;
    }
}
.c-news-stream-financial-calendar-heading {
    width:100%;
}
</style>
