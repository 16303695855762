var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-phone-input" },
    [
      _c(
        "v-select",
        _vm._b(
          {
            attrs: {
              options: _vm.filteredCountries,
              label: "name",
              clearable: false,
              searchable: false,
            },
            on: { input: _vm.updateSelected },
            scopedSlots: _vm._u([
              {
                key: "no-options",
                fn: function ({ search, searching }) {
                  return [
                    searching
                      ? [
                          _vm._v("\r\n                Ingen resultater "),
                          _c("em", [_vm._v(_vm._s(search))]),
                          _vm._v(".\r\n            "),
                        ]
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "option",
                fn: function (option) {
                  return [
                    _vm._v(
                      "\r\n            +" +
                        _vm._s(option.dialCode) +
                        " " +
                        _vm._s(option.name) +
                        "\r\n        "
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.localValue,
              callback: function ($$v) {
                _vm.localValue = $$v
              },
              expression: "localValue",
            },
          },
          "v-select",
          _vm.$props,
          false
        )
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userInput,
            expression: "userInput",
          },
        ],
        staticClass: "c-phone-input__userinput",
        attrs: { disabled: _vm.disabled },
        domProps: { value: _vm.userInput },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.userInput = $event.target.value
            },
            _vm.updateUserInput,
          ],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }