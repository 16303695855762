<template>
<div class="c-nav-menu" :class="{'c-nav-menu--open' : isOpen}" @mouseleave="isHovering = false">
    <div class="c-nav-menu__scroll-container">
        <Container class="c-nav-menu__container">
            <Row>
                <Column :md="9" class="c-nav-menu__level-1">
                    <ul class="o-flat-list">
                        <li v-for="(item, index) in items" :key="`level1_${index}`"
                            class="c-nav-menu__item" :class="{'c-nav-menu__item--hovering': isHovering && hover1 === item, 'c-nav-menu__item--unclickable' : item.unclickable}"
                            @mouseover="isHovering = true; hover1 = item; hover2 = null;">
                            <Anchor :href="item.url" v-on:click.native="locationChanged" :isClickable="!item.unclickable">
                                <span class="c-nav-menu__item-text">{{item.name}}<Icon v-if="item.menuItems.length > 0" icon="chevron_right"/></span>
                            </Anchor>
                        </li>
                    </ul>
                </Column>
                <Column :md="7" class="c-nav-menu__level-2" v-if="items2 && items2.menuItems.length > 0">
                    <Heading class="c-nav-menu__heading" isCategory>{{items2.name}}</Heading>
                    <ul class="o-flat-list">
                        <li v-for="(item,index) in items2.menuItems" :key="`level2_${index}`"
                            class="c-nav-menu__item" :class="{'c-nav-menu__item--hovering': isHovering && hover2 === item, 'c-nav-menu__item--unclickable' : item.unclickable}"
                            @mouseover="isHovering = true; hover2 = item">
                            <Anchor :href="item.url" v-on:click.native="locationChanged" :isClickable="!item.unclickable">
                                <span class="c-nav-menu__item-text">{{item.name}} <Icon v-if="item.menuItems.length > 0" icon="chevron_right"/></span>
                            </Anchor>
                        </li>
                    </ul>
                </Column>
                <Column :md="8" class="c-nav-menu__level-3" v-if="items3 && items3.menuItems.length > 0">
                    <Heading class="c-nav-menu__heading" isCategory>{{items3.name}}</Heading>
                    <ul class="o-flat-list">
                        <li v-for="(item, index) in items3.menuItems" :key="`level3_${index}`" class="c-nav-menu__item" :class="{'c-nav-menu__item--unclickable' : item.unclickable}">
                            <Anchor :href="item.url" v-on:click.native="locationChanged" :isClickable="!item.unclickable">
                                <span class="c-nav-menu__item-text">{{item.name}}<Icon v-if="item.menuItems.length > 0" icon="chevron_right"/></span>
                            </Anchor>
                        </li>
                    </ul>
                </Column>
            </Row>
        </Container>
    </div>
</div>
</template>

<script>
import Container from '@/Scripts/components/grid/Container.vue';
import Row from '@/Scripts/components/grid/Row.vue';
import Column from '@/Scripts/components/grid/Column.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';

export default {
    data: function () {
        return {
            isHovering: false,
            hover1: null,
            hover2: null
        };
    },
    props: {
        items: {
            type: Array,
            default: () => [] // eslint-disable-line
        },
        isOpen: {
            type: Boolean,
            default: false // eslint-disable-line
        }
    },
    methods: {
        locationChanged() {
            this.$emit('locationChanged');
        }
    },
    computed: {
        selected1: function () {
            return this.items.find(x => x.isCurrentPage);
        },
        selected2: function () {
            if (this.selected1) {
                return this.selected1.menuItems.find(x => x.isCurrentPage);
            }
            return null;
        },
        selected3: function () {
            if (this.selected2) {
                return this.selected2.menuItems.find(x => x.isCurrentPage);
            }
            return null;
        },
        items2: function () {
            return this.isHovering ? this.hover1 : this.selected1;
        },
        items3: function () {
            return this.isHovering ? this.hover2 : this.selected2;
        }
    },
    components: {
        Container,
        Row,
        Column,
        Anchor,
        Icon,
        Heading
    }
};
</script>

<style lang="scss" scoped>
@import '@/Styles/tools/_font-mixin.scss';
.c-nav-menu {
    $self: &;
    $menuColor: #394245; // Darker menu text to please Lighthouse
    //top: 68px;
    position: absolute;
    top: 0;
    color: black;
    height: 100vh;
    max-height: 0px;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    width: 100%;
    background-color: $white;
    z-index: -1;

    &__scroll-container {
        overflow-y: scroll;
        height: 100%;
        padding-top: 100px;
    }
    &__container {
        padding-bottom: 50px;
    }
    &__level-1, &__level-2 {
        border-right: 1px solid; // TODO: Color
    }
    &__level-1 {
        #{$self}__item {
            @include fontSize(32px);
            margin-bottom: em(59, 38);
        }
    }
     &__level-2, &__level-3 {
        padding-left: 60px;
        padding-right: 60px;
        #{$self}__item {
            @include fontSize(18px);
            margin-bottom: em(28, 18);
        }
        #{$self}__heading {
            margin-bottom: em(28, 18);
        }
    }

    &__item {
        span, .c-icon {
            font-size: inherit;
            line-height: 1em;
        }

        a, a:hover {
            text-decoration: none;
        }
        &-text {
            display: inline-block;
            margin: 0;
            color: $menuColor;
        }
        .c-icon {
            fill: $menuColor;
            margin-left: 1em;
            width: .5em;
        }
        .router-link-active {
            #{$self}__item-text {
                color: $black;
            }
            .c-icon {
                fill: $black;
            }
        }
        &:hover, &--hovering {
            #{$self}__item-text {
                color: $orange;
            }
            .c-icon {
                fill: $orange;
            }
        }
        &--unclickable {
            a {
                cursor: default;
            }
        }
    }

    &--open {
        //max-height: calc(100vh - 68px);
        max-height: 100vh;
        //overflow-y: scroll;
    }
}
</style>
