<template>
  <div class="event-block">
      <div v-for="(item,index) in model.eventRows" :key="index">
        <div v-if="item.heading" class="event-block__headingrow">
           <XhtmlField class="heading" :items="item.text" />
        </div>
        <div v-else :class="item.break? 'event-block__breakrow' : 'event-block__standardrow'">
             <div class="event-block__time">
                <div class="time">{{ item.time }}</div>
            </div>
            <div class="event-block__text">
                <XhtmlField :items="item.text" />
            </div>
        </div>
     </div>
  </div>
</template>

<script>

export default {
    props: ['model'],
};
</script>

<style lang="scss">
.event-block {
  text-align: left;
  width:100%;
  @include media-md {
    width: 50%;
  }
  &__standardrow {
    display: flex;
    padding: 1rem;
    background-color: #F2F1ED;
    flex-direction: row;
    border-bottom:#E6E4D9 solid 2px;
  }
  &__breakrow{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background-color: #E6E4D9;
    margin-bottom: 2rem;
  }
  &__headingrow{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background-color: #094E5D;
  }
  &__time {
    width: 20%;
  }
  &__text {
    width: 100%;
  }
}

.heading {
  color:white;
  height: fit-content;
}
.green-background {
  background-color: #094E5D;
}

.gray-background {
  background-color: #F2F1ED;
}
.text-white {
  color: #fff;
}

.time {
  font-weight: bold;
}
.c-xhtml-string > *:first-child {
  margin-top: 0;
  margin-bottom:0;
}
</style>
