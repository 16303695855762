<template>
<Row class="o-block o-custom-block o-bg" :class="{'o-bg' : backgroundColor}" :style="style">
    <Column :md="12" class="c-text-block">
        <Heading :level="3" v-if="title">{{title}}</Heading>
        <XhtmlField :items="text" v-if="text"/>
    </Column>
</Row>
</template>

<script>
export default {
    props: {
        text: Array,
        title: String,
        color2: Object,
        color: Object
    },
    computed: {
        style() {
            return {
                backgroundColor: this.color2 !== null ? this.color2.value : null,
                color: this.color !== null ? this.color.value : null
            };
        }
    }
};
</script>

<style lang="scss">
.c-custom-text-block {
    &__heading1 {
        order: 0;
    }
    &__heading2 {
        order: 2;
    }
    &__body1 {
        order: 1;
    }
    &__body2 {
        order: 3;
    }
    @include media-md {
        &__heading1, &__heading2, &__body1, &__body2 {
            order: 0;
        }
    }
}
</style>
