var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "practicearea--vendors" },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            { attrs: { md: 12 } },
            [
              _c(
                "Heading",
                {
                  staticClass: "practicearea--global__title",
                  attrs: { level: 2 },
                },
                [_vm._v(_vm._s(_vm.model.vendorsHeading))]
              ),
              _vm._v(" "),
              _vm.model.vendorsPreamble !== null
                ? _c("p", { staticClass: "practicearea--vendors__preamble" }, [
                    _vm._v(_vm._s(_vm.model.vendorsPreamble)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c(
                    "Anchor",
                    { attrs: { isFancy: "", href: _vm.model.vendorsPageUrl } },
                    [_vm._v(_vm._s(_vm.model.vendorsPageLinkLabel))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { attrs: { md: 12 } },
            [
              _c("HorizontalSlider", {
                attrs: {
                  items: _vm.items,
                  scroll: 1,
                  noOfItemsToShow: 1,
                  autoScroll: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "Row",
                          _vm._l(item, function (vendor, index) {
                            return _c(
                              "Column",
                              {
                                key: `vendor_${index}`,
                                staticClass: "practicearea--vendors__item",
                                attrs: { sm: 8, xs: 12 },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "practicearea--vendors__thumbnail",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: vendor.pageUrl,
                                          "aria-label": vendor.name,
                                        },
                                      },
                                      [
                                        item.imageUrl !== null
                                          ? _c(
                                              "ResponsiveImage",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    src: vendor.imageUrl,
                                                    alt: vendor.imageTitle,
                                                  },
                                                },
                                                "ResponsiveImage",
                                                {
                                                  xs: { w: 120, h: 120 },
                                                  sm: { w: 120, h: 120 },
                                                  md: { w: 120, h: 120 },
                                                  lg: { w: 120, h: 120 },
                                                },
                                                false
                                              )
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }