var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-latest-trades" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.trades, function (trade, index) {
        return _c(
          "div",
          { key: index },
          [_c("TradeItem", { attrs: { trade: trade } })],
          1
        )
      }),
      _vm._v(" "),
      _vm.seeAllUrl
        ? _c("div", { staticClass: "c-latest-trades-link" }, [
            _c("a", { attrs: { href: _vm.seeAllUrl } }, [
              _vm._v(_vm._s(_vm.seeAllLabel)),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-latest-trades-header" }, [
      _c("div", { staticClass: "c-latest-trades-header-left" }, [
        _vm._v("Time"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-latest-trades-header-right" }, [
        _vm._v("Price"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-latest-trades-header-right" }, [
        _vm._v("Volume"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }