<template>
<component :is="href ? 'a' : 'button'" class="o-button" :class="[{'o-button--large' : isLarge}, buttonClass]" :href="href" :target="target">
    <Icon class="o-button__icon" v-if="icon && !iconRight" :icon="icon"/>
    <slot/>
    <Icon class="o-button__icon" v-if="icon && iconRight" :icon="icon"/>
</component>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';

export default {
    components: {
        Icon
    },
    props: {
        className: {
            type: String
        },
        /**
         * Url. When set an anchor-tag will be used.
         */
        href: {
            type: String
        },
        /**
         * How to open an url. Only used when 'href' is set.
         */
        target: {
            type: String
        },
        icon: {
            type: String
        },
        iconRight: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        isLarge: {
            type: Boolean,
            default: false //eslint-disable-line
        },
        noStyle: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        buttonStyle: {
            type: String,
            validator: function (value) {
                return ['filled', 'no-style'].indexOf(value) !== -1;
            }
        }
    },
    computed: {
        buttonClass() {
            if (this.buttonStyle) {
                return 'o-button--' + this.buttonStyle;
            }
            return null;
        }
    }
};
</script>

<!-- Button styles are located inside the styles folder to keep all button styles global -->
