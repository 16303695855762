<!--
    Renders a ContentArea by iterating through all the blocks and using the
    `BlockComponentSelector` to render the corresponding Vue component.

    By setting the `data-epi-block-id` attribute the block becomes editable
    during On-Page Edit. It will not be set in View mode to not leak out too
    much info about Episerver to visitors.

    The model property is provided by the page or block that owns the
    ContentArea.
-->

<template>
    <component class="c-content-area" :is="wrapper">
        <component :is="childWrapper" v-bind="childConfig" :key="index" v-for="(block, index) in model" :class="[getDisplayOption(block.displayOption), childClass, 'c-content-area__item']">
            <BlockComponentSelector :data-epi-block-id="isEditable ? block.contentLink.id : null" :model="block"></BlockComponentSelector>
        </component>
    </component>
</template>

<script>
import * as CONSTANTS from '@/Scripts/constants';
import BlockComponentSelector from '@/Scripts/components/BlockComponentSelector.vue';
import { mapState } from 'vuex';

export default {
    props: {
        model: Array,
        wrapper: {
            type: String,
            default: 'section', // eslint-disable-line
        },
        childWrapper: {
            type: String,
            default: 'div', // eslint-disable-line
        },
        childClass: {
            type: String
        },
        childConfig: {
            type: Object
        }
    },
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    }),
    components: {
        BlockComponentSelector
    },
    methods: {
        getDisplayOption(value) {
            let displayoption = value;
            for (var key in CONSTANTS.DISPLAY_OPTIONS) {
                if (CONSTANTS.DISPLAY_OPTIONS.hasOwnProperty(key)) {
                    if (displayoption === key) {
                        return CONSTANTS.DISPLAY_OPTIONS[key];
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss">
.c-content-area {
    &__item {
        &:not(:last-child) {
            margin-bottom: 3rem;

            @include media-md {
                margin-bottom: 5rem;
            }
        }
    }
}
</style>
