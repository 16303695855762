var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "event-program container" },
    _vm._l(_vm.model, function (event, index) {
      return _c("EventBlock", { key: index, attrs: { model: event } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }