var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Container",
    {
      staticClass: "c-header-top",
      class: [
        {
          "c-header-top--white":
            _vm.isWhite && !_vm.isMenuOpen && !_vm.isLanguageSelectorOpen,
        },
      ],
      attrs: { isFluid: "" },
    },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            { attrs: { md: 8, xs: 12 } },
            [
              _c("Anchor", {
                staticClass: "c-header-top__logo",
                attrs: { href: _vm.currentLanguage.url, icon: "crayon_logo" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            {
              staticClass: "u-hidden-sm u-hidden-xs",
              attrs: {
                md: 8,
                align: "center",
                className: "c-header-top__links",
              },
            },
            [
              _c(
                "Button",
                {
                  attrs: {
                    buttonStyle: "no-style",
                    iconRight: "",
                    icon: _vm.isLanguageSelectorOpen ? "cross" : "chevron_down",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm._toggleLanguageSelector.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.languageSelectorText))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            {
              attrs: {
                md: 8,
                xs: 12,
                align: "right",
                className: "c-header-top__links",
              },
            },
            [
              _vm.loginPage
                ? _c(
                    "Button",
                    {
                      staticClass: "u-hidden-xs",
                      attrs: {
                        href: _vm.loginPage,
                        target: "_blank",
                        buttonStyle: "no-style",
                        icon: "social",
                      },
                    },
                    [_vm._v(_vm._s(_vm.getLabel("menu", "logIn")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Button",
                {
                  staticClass: "u-hidden-xs",
                  attrs: {
                    href: _vm.searchPage,
                    buttonStyle: "no-style",
                    icon: "search",
                  },
                },
                [_vm._v(_vm._s(_vm.getLabel("menu", "searchButton")))]
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  attrs: {
                    buttonStyle: "no-style",
                    icon: _vm.isMenuOpen ? "cross" : "menu",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm._toggleMenu.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.isMenuOpen
                          ? _vm.getLabel("global", "close")
                          : _vm.getLabel("menu", "menu")
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }