<template>
<Row class="c-collapse" :class="{'c-collapse--open' : isOpen, 'c-collapse--border' : border}">
    <!-- Header -->
    <Column @click.native="isOpen = !isOpen">
        <div class="c-collapse__header-wrapper">
            <Row>
                <Column :xs="20" class="c-collapse__header">
                        <slot name="header"/>
                </Column>
                <Column :xs="4" class="c-collapse__header c-collapse__icon-wrapper">
                    <Icon :icon="isOpen ? iconOpen : iconClosed"/>
                </Column>
            </Row>
        </div>
    </Column>

    <!-- Content -->
    <Column class="c-collapse__content">
        <transition
            name="c-collapse__expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
        >
            <div v-if="isOpen" class="c-collapse__content-wrapper">
                <slot name="content"/>
            </div>
        </transition>
    </Column>
</Row>

</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';

export default {
    props: {
        border: {
            type: Boolean,
            default: true // eslint-disable-line
        },
        iconOpen: {
            type: String,
            default: 'chevron_down' // eslint-disable-line
        },
        iconClosed: {
            type: String,
            default: 'chevron_right' // eslint-disable-line
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    components: {
        Icon
    },
    methods: {
        enter(element) {
            //This function will measure the height of the content before setting a height accordingly

            // Requred step to animate the height. Don't ask
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            // Get element height
            const height = getComputedStyle(element).height;

            // Reset styling
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element);

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            // After animation the height is set to autp
            element.style.height = 'auto';
        },
        leave(element) {
            // When leaving (collapsing) the height is recalculate, set and then set to 0
            const height = getComputedStyle(element).height;

            element.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element);

            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        }
    },
};
</script>

<style lang="scss" scope>
@import '@/Styles/tools/_font-mixin.scss';

.c-collapse {
    $self: &;
    cursor: pointer;

    &__header {
        padding-bottom: .5em;
        padding-top: .5em;
    }

    &, .c-icon {
        @include fontSize(18px);
    }
    .c-icon {
        fill: $orange;
        margin-right: .5em;
    }
    &__icon-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__content {
        overflow: hidden;

        &-wrapper {
            padding-top: 2em;
            padding-bottom: 2em;
        }
    }
    &--border {
        #{$self}__header-wrapper, #{$self}__content-wrapper {
            border-bottom: 1px solid $gray;
        }
    }

    #{$self}__expand-enter-active,
    #{$self}__expand-leave-active {
        transition: height 400ms ease, border-bottom-color 350ms linear;
        overflow: hidden;
        border-bottom: 1px solid white;
    }

    #{$self}__expand-enter,
    #{$self}__expand-leave-to {
        height: 0;
    }
}
</style>
