<template>
<div class="c-latest-trades">
    <div class="c-latest-trades-header">
        <div class="c-latest-trades-header-left">Time</div>
        <div class="c-latest-trades-header-right">Price</div>
        <div class="c-latest-trades-header-right">Volume</div>
    </div>
    <div v-for="(trade, index) in trades" :key="index">
            <TradeItem :trade="trade" />
    </div>
    <div v-if="seeAllUrl" class="c-latest-trades-link">
        <a :href="seeAllUrl">{{seeAllLabel}}</a>
    </div>
</div>
</template>

<script>

import TradeItem from '@/Scripts/components/atoms/TradeItem.vue';

export default {
    props: {
        trades: {
            type: Array,
            required: true,
        },
        seeAllLabel: {
            type: String,
            required: false
        },
        seeAllUrl: {
            type: String,
            required: false
        }
    },
    components: {
        TradeItem,
    },
};
</script>

<style lang="scss">
.c-latest-trades-header{
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    justify-content: space-around;
    border-bottom: 2px solid black;
    &-left{
        width: 100%;
        text-align: left;
    }
    &-right{
        width: 100%;
        text-align: right;
    }
}
.c-latest-trades-link{
    margin-top: 2em;
}

</style>
