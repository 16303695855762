var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Anchor",
    {
      staticClass: "u-flex c-simple-link-block",
      attrs: { href: _vm.link, noStyle: "" },
    },
    [
      _c("ResponsiveImage", {
        staticClass: "c-simple-link-block__image",
        attrs: { src: _vm.image },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "c-simple-link-block__text-wrapper" }, [
        _c("span", { staticClass: "c-simple-link-block__label o-small-body" }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "c-simple-link-block__title u-no-margins" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }