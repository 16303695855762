<template>
    <div class="row c-partnerscustom-block o-custom-block o-bg" :style="style">
        <Column :md="24">
            <Heading v-if="title" :level="3" class="u-no-margin-top">{{title}}</Heading>
        </Column>
        <Column :md="24">
            <div class="c-partnerscustom-block__list">
                <div v-for="(logo, index) in softwarePartners" :key="index">
                    <img :src="logo" />
                </div>
            </div>
        </Column>
    </div>
</template>

<script>
export default {
    props: ['intro', 'label', 'title', 'softwarePartners', 'backgroundColor', 'textColor', 'color', 'color2'],
    computed: {
        style() {
            return {
                backgroundColor: this.color2 ? this.color2.value : this.backgroundColor,
                color: this.color ? this.color.value : this.textColor
            };
        }
    },
};
</script>

<style lang="scss">
@import '@/Styles/Tools/_breakpoints.scss';

.c-partnerscustom-block {

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: -10px;

        @include media-md {
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        div {
            display: flex;
            padding: 10px;
            width: 50%;

            @include media-sm {
                width: 33%
            }


            @include media-md {
                width: auto
            }

            @include media-lg {
                width: auto
            }

            img {
                align-self: center;
            }
        }
    }

}
</style>
