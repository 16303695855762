var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content.analysts && _vm.content.analysts.length > 0
    ? _c(
        "div",
        { staticClass: "c-analysts" },
        [
          _c(
            "div",
            { staticClass: "c-analysts-heading" },
            [
              _c("Heading", { attrs: { level: 5 } }, [
                _vm._v(_vm._s(_vm.content.title)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.content.analysts, function (analyst, index) {
            return _c(
              "div",
              { key: index },
              [_c("AnalystItem", { attrs: { analyst: analyst } })],
              1
            )
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "c-analysts-header" }, [
      _c("div", { staticClass: "u-hidden-sm u-hidden-xs" }, [
        _vm._v("Company"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "u-hidden-sm u-hidden-xs c-analysts-header-label" },
        [_vm._v("Analyst")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "u-hidden-sm u-hidden-xs c-analysts-header-label" },
        [_vm._v("Phone")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "u-hidden-sm u-hidden-xs c-analysts-header-label" },
        [_vm._v("Email")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }