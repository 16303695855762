<template>
<div class="t-ir-layout" :class="[{'t-ir-layout--no-scroll' : !isBodyScrollable}]">
    <Header :isWhite="isStartPage" :isOverlapping="isStartPage" :showBreadcrumbs="!isStartPage" v-bind="model.header" :isLanguageSelectorOpen="isLanguageSelectorOpen" :isMenuOpen="isMenuOpen" :currentLanguage="currentLanguage"/>
    <GatedContentPage v-if="model.gated && !isValidated" :pageId="model.contentLink.id" :form="model.siteSettings.embededGateForm" @validated="validated" />
    <div v-else>
        <Container>
            <Row>
                <Column><Heading isCategory>{{model.parentLabel}}</Heading></Column>
                <Column :md="10">
                    <Heading :level="3" noMarginTop>{{model.parentTitle}}</Heading>
                </Column>
                <Column :offsetMd="4" :md="10">
                    <XhtmlField :items="model.parentIntro"/>
                </Column>
            </Row>
        </Container>
        <SiblingNavigation :siblings="model.siblings && model.siblings.siblings" nameProperty="label" class="t-ir-layout__nav"/>

        <slot :model="model"></slot>

        <Container class="o-bg o-bg--beige-light">
            <TwoColumnBlock class="t-ir-layout__footer" v-if="model.footerBlock && model.footerBlock.content" v-bind="model.footerBlock.content"/>
            <Subscription class="t-ir-layout__footer" v-if="!model.disableCision" :heading="model.cisionText"/>
        </Container>
    </div>
    <Footer v-bind="{...model.footer, headerTop: {isLanguageSelectorOpen: isLanguageSelectorOpen, isMenuOpen: isMenuOpen, currentLanguage: currentLanguage, searchPage: searchPage, loginPage: loginPage}}"/>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/Scripts/components/organisms/Header.vue';
import Footer from '@/Scripts/components/organisms/Footer.vue';
import GatedContentPage from '@/Scripts/components/pages/GatedContentPage.vue';
import SiblingNavigation from '@/Scripts/components/molecules/SiblingNavigation.vue';
import Subscription from '../molecules/Subscription.vue';

export default {
    components: {
        Header,
        Footer,
        GatedContentPage,
        SiblingNavigation,
        Subscription
    },
    props: ['model'],
    data() {
        return {
            unsubscribe: null,
            isValidated: false,
        };
    },
    methods: {
        validated: function () {
            this.isValidated = true;
        }
    },
    computed: {
        ...mapState({
            isLanguageSelectorOpen: state => state.appContext.isLanguageSelectorOpen,
            isMenuOpen: state => state.appContext.isMenuOpen,
            isBodyScrollable: state => state.appContext.isBodyScrollable
        }),
        currentLanguage: function () {
            if (this.model.header && this.model.header.languageLinks) {
                return this.model.header.languageLinks.find(x => x.isCurrentLanguage);
            }
            return {
                name: ''
            };
        },
        isStartPage: function () {
            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'StartPage') {
                return true;
            }
            return false;
        },
        searchPage: function () {
            return this.model.header ? this.model.header.searchPage : '/';
        },
        loginPage: function () {
            return this.model.header ? this.model.header.loginPage : '/';
        }
    },
    mounted() {
        // Updating title on page change
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'epiDataModel/UPDATE_MODEL') {
                document.title = mutation.payload.metaData && mutation.payload.metaData.metaTitle + ' - Crayon';
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};
</script>

<style lang="scss">
.t-ir-layout {
    position: relative;
    overflow: hidden;

    &__nav {
        margin-bottom: 5rem;
    }
    &__footer {
        padding-top: 5rem;
        padding-bottom: 2rem;
    }
    &--no-scroll {
        height: 100vh;
        overflow-y: hidden;
    }
}
</style>
