<template>
<main class="c-ir-people-page">
    <Container>
        <Row>
            <Column>
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
            <Column :md="10">
                <Heading :level="3" noMarginTop v-epi-edit="'Title'">{{model.title}}</Heading>
            </Column>
        </Row>
        <PersonList v-epi-edit="'People'" class="c-ir-people-page__list" :people="model.people"/>
    </Container>
</main>
</template>

<script>
import PersonList from '@/Scripts/components/molecules/PersonList.vue';

export default {
    props: {
        model: Object
    },
    components: {
        PersonList
    }
};
</script>

<style lang="scss">
.c-ir-people-page {
    &__list {
        margin-bottom: 0;
    }
}
</style>
