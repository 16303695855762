var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-page-list--small" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "Column",
        {
          key: index,
          staticClass: "c-page-list--small__item",
          attrs: { sm: 10, offsetSm: index % 2 === 0 ? 0 : 3 },
        },
        [
          _c("Heading", { attrs: { isCategory: "" } }, [
            _vm._v(_vm._s(item.label)),
          ]),
          _vm._v(" "),
          item.icon
            ? _c("Icon", {
                staticClass: "o-link__icon c-page-list--small__icon",
                attrs: { icon: item.icon },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Heading",
            {
              staticClass: "c-page-list--small__heading u-no-margin-top",
              attrs: { level: 5 },
            },
            [_vm._v(_vm._s(item.title))]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "o-large-body" }, [
            _vm._v(_vm._s(item.intro)),
          ]),
          _vm._v(" "),
          _c("Anchor", { attrs: { isFancy: "", href: item.url } }, [
            _vm._v(_vm._s(_vm.getLabel("global", "readMore"))),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }