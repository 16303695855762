<template>
<main class="c-custom-campaign-page">
    <Header v-if="showHeaderFooter" :isWhite="isStartPage" :isOverlapping="isStartPage" :showBreadcrumbs="!isStartPage" v-bind="model.header" :isLanguageSelectorOpen="isLanguageSelectorOpen" :isMenuOpen="isMenuOpen" :currentLanguage="currentLanguage"/>
    <Hero v-if="model.heroImage && model.heroImage.url || (model.heroVideo && model.heroVideo.content && model.heroVideo.content.videoEmbedCode != null)"
        :buttonText="model.heroLinkText" :title="model.title" :subtitle="model.heroSubtitle" :heroImage="model.heroImage && model.heroImage.url" :heroVideo="model.heroVideo" :overlay="model.heroOverlay"
        :url="model.heroLink" :changeTitleColor="model.changeTitleColor" :changeLinkColor="model.changeLinkColor"
        :url2="model.heroLink2" :buttonText2="model.heroLink2Text"/>
    <Container>
        <XhtmlField v-epi-edit="'Body'" v-if="model.body || isEditable" :items="model.body"/>
    </Container>
    <div v-if="model.showBrightTalkForm" class="iframe-container">
            <div class="jsBrightTALKEmbedWrapper" ref="brighttalkWrapper" style="width:100%; height:100%; position:relative;background: #ffffff;">
            </div>
    </div>
    <Footer v-if="showHeaderFooter" v-bind="{...model.footer, headerTop: {isLanguageSelectorOpen: isLanguageSelectorOpen, isMenuOpen: isMenuOpen, currentLanguage: currentLanguage, searchPage: searchPage, loginPage: loginPage}}"/>
</main>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/Scripts/components/organisms/Header.vue';
import Footer from '@/Scripts/components/organisms/Footer.vue';
import Hero from '@/Scripts/components/molecules/Hero.vue';

export default {
    data() {
        return {
            unsubscribe: null,
            isValidated: false,
            redirected: false
        };
    },
    props: {
        model: Object
    },
    components: {
        Hero,
        Header,
        Footer
    },
    computed: {
        ...mapState({
            isLanguageSelectorOpen: state => state.appContext.isLanguageSelectorOpen,
            isMenuOpen: state => state.appContext.isMenuOpen,
            isBodyScrollable: state => state.appContext.isBodyScrollable
        }),
        currentLanguage: function () {
            if (this.model.header && this.model.header.languageLinks) {
                return this.model.header.languageLinks.find(x => x.isCurrentLanguage);
            }
            return {
                name: ''
            };
        },
        isStartPage: function () {
            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'StartPage') {
                return true;
            }
            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'EbookPage') {
                return true;
            }

            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'CustomCampaignPage' && (this.model.heroImage && this.model.heroImage.url)) {
                return true;
            }

            return false;
        },
        showHeaderFooter: function () {
            return this.model.showHeaderFooter;
        },
        searchPage: function () {
            return this.model.header ? this.model.header.searchPage : '/';
        },
        loginPage: function () {
            return this.model.header ? this.model.header.loginPage : '/';
        },
    },
    mounted: function () {
        if (this.model.showBrightTalkForm) {
            this.addBrightTalkScript();
        }
    },
    methods: {
        addBrightTalkScript() {
            const configScript = document.createElement('script');
            const embedScript = document.createElement('script');

            configScript.type = 'application/json';
            configScript.classList.add('jsBrightTALKEmbedConfig');
            configScript.textContent = JSON.stringify({
                channelId: 20237,
                language: 'en-US',
                commId: 600083,
                displayMode: 'standalone',
                height: '1000px',
                width: '100%',
            });
            embedScript.src = 'https://www.brighttalk.com/clients/js/player-embed/player-embed.js';
            embedScript.classList.add('jsBrightTALKEmbed');
            embedScript.type = 'application/javascript';
            embedScript.async = true;
            this.$refs.brighttalkWrapper.appendChild(configScript);
            this.$refs.brighttalkWrapper.appendChild(embedScript);
        }
    }
};
</script>

<style lang="scss">
.c-custom-campaign-page {
    overflow: hidden;

    table._table-hack {
        tr {
            border-bottom: 0;
        }
    }
    .c-hero {
        height: 55vh;
        @include media-md {
            height: 70vh;
        }
    }
    .o-xhtml-field .o-block {
        margin-bottom: initial;
        margin-top: initial;
    }
    .o-custom-block.o-bg {
        padding-top: 3rem;
        padding-bottom: 3rem;

        @include media-md {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
}
</style>
