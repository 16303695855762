<template>
<div class="c-person">
    <Row class="c-person__top">
        <Column :xs="10" :sm="6" :md="7" v-if="image">
            <ResponsiveImage :alt="name" :xs="{w: 150, h: 155}" :sm="{w: 150, h: 155}" :md="{w: 150, h: 155}" :lg="{w: 150, h: 155}" :src="image"/>
        </Column>
        <Column :sm="image? 18 : 24" :md="image? 17 : 24" class="c-person__text-wrapper">
            <Heading :level="3" noMarginBottom noMarginTop>{{name}}</Heading>
            <span class="u-block o-small-body c-person__title">{{title}}</span>
            <span class="u-block o-small-body">{{email}}</span>
        </Column>
    </Row>
    <XhtmlField :items="description"/>
</div>
</template>

<script>
export default {
    props: {
        image: String,
        name: String,
        title: String,
        email: String,
        description: Array
    }
};
</script>

<style lang="scss">
.c-person {
    &__top {
        margin-bottom: 2rem;
    }
    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
    }
    &__title {
        color: $gray;
    }
}
</style>
