<template>
<div class="c-calendar-item">
    <div>{{formatDate(calendarItem.date)}}</div>
    <div>{{calendarItem.heading}}</div>
</div>
</template>

<script>

export default {
    props: {
        calendarItem: {
            type: Object,
        },
    },
    methods: {
        formatDate(date) {
            var formattedDate = '';
            formattedDate = date.slice(6) + '.' + date.slice(4,6) + '.' + date.slice(0,4);
            return formattedDate;
        }
    }
};
</script>

<style lang="scss">
.c-calendar-item{
 display: flex;
 flex-direction: row;
 padding: 12px;
 border-bottom: 1px solid black;
 }
.c-calendar-item > div{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
}
.c-calendar-item > div:first-child{
    width:30%;
    padding-right:1em;
    @include media-md{
    padding: 0;
    }
}
.c-calendar-item > a{
    width: 100%;
    text-align: left;
}
</style>
