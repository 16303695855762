<template>
    <epi-link :href="url" class="c-news-component--fullsize o-link--no-style">
        <Row>
            <Column class="c-news-component__image-container">
                <ResponsiveImage :src="imageUrl" class="c-news-component__image" :lg="{h: 461, w: 1358}" :md="{h: 392, w: 1154 }" :sm="{h: 206, w: 642 }" :xs="{h: 184, w:345}" />
            </Column>
            <Column>
                <div class="c-news-component__text-container">
                    <Row>
                        <Column :offsetSm="2" :sm="22" v-if="!hideDate">
                            <Datetime class="u-block c-news-component__text-container__date" :date="published" :value="published" :useUsDateFormat="useUsDateFormat"/>
                        </Column>
                        <Column :offsetSm="2" :sm="8">
                            <Heading :level="3" noMarginTop noMarginBottom class="u-block c-news-component__text-container__title">{{title}}</Heading>
                        </Column>
                        <Column :offsetSm="1" :sm="11">
                            <v-clamp autoresize :max-lines="5" class="u-block o-ingress c-news-component__text-container__text">{{intro}}</v-clamp>
                            <Anchor isFancy :href="url" class="u-hidden-sm u-hidden-xs">{{getLabel('global', 'readMore')}}</Anchor>
                        </Column>
                        <Column :xs="22" align="right" class="c-news-component__arrow">
                            <Icon icon="arrow_right"/>
                        </Column>
                    </Row>
                </div>
            </Column>
        </Row>
    </epi-link>
</template>

<script>
import EpiLink from '@/Scripts/components/atoms/EpiLink.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Datetime from '@/Scripts/components/atoms/Datetime.vue';
import VClamp from 'vue-clamp';

export default {
    props: {
        imageUrl: String,
        imageTitle: String,
        published: String,
        title: String,
        intro: String,
        label: String,
        linkTarget: String,
        url: String,
        hideDate: Boolean,
        useUsDateFormat: Boolean,
        showArrow: {
            type: Boolean,
            default: true // eslint-disable-line
        },
    },
    components: {
        VClamp,
        EpiLink,
        Icon,
        Datetime
    }
};
</script>

<style lang="scss">
.c-news-component {
    $self: &;
    $padding-md: 72px;
    $padding-sm: 1rem;
    $padding-xs: 1rem;
    &--fullsize {
        display: block;
        background-color: $beige-light;

        #{$self}__text-container {
            padding-top: $padding-xs;
            padding-bottom: $padding-xs;
            position: relative;

            @include media-md {
                padding-top: $padding-md;
                padding-bottom: $padding-md;
            }

            &__text {
                margin-bottom: 2rem;
            }
        }
        #{$self}__arrow {
            .c-icon {
                width: 17px;
            }
            @include media-md {
                display: none;
            }
        }
    }
}
</style>
