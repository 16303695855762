<template>
<main class="c-contact-us-page">
    <Container>
        <Row class="c-contact-us-page__top">
            <Column :offsetMd="2" v-epi-edit="'Title'">
                <Heading :level="2" class="u-no-margin-top">{{model.title}}</Heading>
            </Column>
            <Column :md="10" :offsetMd="2" v-epi-edit="'IntroText'">
                <XhtmlField class="o-ingress" :items="model.introText"/>
            </Column>
            <Column :md="8" :offsetMd="2" v-epi-edit="'SmallIntroText'">
                <XhtmlField :items="model.smallIntroText"/>
            </Column>
            <Column :md="20" :offsetMd="2">
                <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-contact-us-page__content-area" :model="model.blocks  || isEditable"/>
            </Column>
        </Row>
        <Row>
            <Column :offsetMd="2" class="u-flex c-contact-us-page__tabs">
                <Button buttonStyle="no-style" @click.native="selectedTab = 1" class="c-contact-us-page__button" :class="{'c-contact-us-page__button--selected' : selectedTab === 1}">
                    {{getLabel('contactUsPage', 'contactUsButtonText')}}
                </Button>
                <Button buttonStyle="no-style" @click.native="selectedTab = 2" class="c-contact-us-page__button" :class="{'c-contact-us-page__button--selected' : selectedTab === 2}">
                    {{getLabel('contactUsPage', 'supportCenterButtonText')}}
                </Button>
            </Column>
        </Row>
        <Row :class="{'u-hidden': selectedTab !== 1}">
            <Column>
                <OfficesBlock v-bind="model.offices && model.offices.content"/>
            </Column>
        </Row>
        <Row :class="{'u-hidden': selectedTab !== 2}">
            <Column :offsetMd="2" :md="20" >
                <SupportCentersBlock v-bind="model.supportCenters && model.supportCenters.content" class="c-contact-us-page__support-centers"/>
            </Column>
        </Row>
    </Container>
</main>
</template>

<script>
import ContentArea from '@/Scripts/components/ContentArea.vue';

export default {
    props: {
        model: Object
    },
    data() {
        var supportSelected = this.$route.path.includes('support');
        return {
            selectedTab: supportSelected ? 2 : 1
        };
    },
    components: {
        ContentArea
    }
};
</script>

<style lang="scss">
.c-contact-us-page {
    &__top {
        margin-bottom: 3rem;
        @include media-sm {
            margin-bottom: 5rem;
        }
    }
    &__tabs {
        justify-content: space-between;
        margin-bottom: 3rem;

        @include media-sm {
            justify-content: initial;
            margin-bottom: 5rem;
        }
    }
    &__support-centers {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 10rem;
        }
    }
    &__button {
        color: #04242D;
        display: inline-block;
        text-decoration: none;
        padding-bottom: 0.2em;
        border-bottom: 0.125em solid transparent;

        &--selected, &:focus, &:hover {
            border-bottom: 0.125em solid #FF370F;
            padding-bottom: 0.2em;
        }
        @include media-sm {
            margin-right: 4rem;
        }
    }
}
</style>
