<template>
<nav aria-label="breadcrumb" class="c-breadcrumbs">
  <ul class="o-flat-list">
      <li class="c-breadcrumbs__item" v-for="(item, index) in items" :key="`breadcrumb__${index}`" :class="{'c-breadcrumbs__item--unclickable' : item.unclickable}">
        <Anchor :href="item.url" :clickable="!item.unclickable">{{index === 0 ? getLabel('breadcrumbs', 'home') : item.text}}</Anchor>
    </li>
  </ul>
</nav>
</template>

<script>
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
export default {
    components: {
        Anchor
    },
    props: {
        items: {
            type: Array
        }
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_font-mixin.scss';
@import '@/Styles/tools/_breakpoints.scss';

.c-breadcrumbs {
    &__item {
        display: inline-block;
        @include fontSize(12px);

        &:not(:last-child):after {
            content: '/';
            margin-left: 0.3em;
            margin-right: 0.3em;
            color: $darkorange;
        }
        a {
             color: $text-color;
             text-decoration: none;
        }
        &--unclickable {
            a {
                pointer-events: none;
                text-decoration: none;
            }
        }
    }
}
</style>
