var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-order-item" }, [
    _c("div", { staticClass: "c-order-item-number" }, [
      _vm._v(_vm._s(_vm.order.bidvol !== 0 ? _vm.order.bidvol : "-")),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-order-item-number" }, [
      _vm._v(_vm._s(_vm.order.bid !== 0 ? _vm.order.bid.toFixed(2) : "-")),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-order-item-depth-bar" }, [
      _c(
        "div",
        {
          staticClass:
            "c-orde-item-depth-bar-placement c-orde-item-depth-bar-placement-bid",
        },
        [
          _c("div", {
            staticClass: "c-order-item-depth-bar-bid",
            style: { width: _vm.bidVolumePercentage + "%" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "c-orde-item-depth-bar-placement c-orde-item-depth-bar-placement-ask",
        },
        [
          _c("div", {
            staticClass: "c-order-item-depth-bar-ask",
            style: { width: _vm.askVolumePercentage + "%" },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-order-item-right c-order-item-number" }, [
      _vm._v(_vm._s(_vm.order.ask !== 0 ? _vm.order.ask.toFixed(2) : "-")),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-order-item-right c-order-item-number" }, [
      _vm._v(_vm._s(_vm.order.askvol !== 0 ? _vm.order.askvol : "-") + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }