<template>
<div class="c-partners-block">
    <Row>
        <Column :md="9">
            <Heading isCategory>{{label}}</Heading>
            <Heading :level="4" class="c-partners-block__text">{{intro}}</Heading>
            <Anchor v-if="url" isFancy :href="url">{{linkText ? linkText : getLabel('global', 'readMore')}}</Anchor>
        </Column>
        <Column :md="13" :offset-md="2">
            <Heading isCategory>{{title}}</Heading>
            <HorizontalSlider :items="items" :scroll="1" :noOfItemsToShow="1" autoScroll>
                <template  v-slot:default="{item}">
                    <Row>
                        <Column v-for="(partner, index) in item" :key="`partner_${index}`" :sm="8" :xs="12" class="c-partners-block__partner">
                        <div class="c-partners-block__image-container">
                            <ResponsiveImage :src="partner.imageUrl" :alt="partner.imageTitle" v-bind="{xs: {w: 145, h:125}, sm: {w: 145, h:125}, md: {w: 145, h:125}, lg: {w: 145, h:125}}"/>
                        </div>
                        </Column>
                    </Row>
                </template>
            </HorizontalSlider>
        </Column>
    </Row>
</div>
</template>

<script>
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';
import HorizontalSlider from '@/Scripts/components/molecules/HorizontalSlider.vue';

export default {
    props: ['intro', 'label', 'title', 'partners', 'url','linkText'],
    components: {
        Anchor,
        Heading,
        HorizontalSlider
    },
    computed: {
        items() {
            var filteredItems = this.partners.filter(x => x.imageUrl !== null);
            return this.splitToChunks(filteredItems, 6);
        }
    },
    methods: {
        splitToChunks(array, size) {
            let result = [];
            while (array.length > 0) {
                result.push(array.splice(0, size));
            }
            return result;
        }
    }
};
</script>

<style lang="scss">
@import '@/Styles/Tools/_breakpoints.scss';

.c-partners-block {
    $self: &;

    .c-horizontal-slider__dot-wrapper {
        margin-right: 50px;
    }
    &__text {
        margin-top: 0;
    }
    &__partner {
        #{$self}__image-container {
            max-width: 145px;
        }
        margin-bottom: 2rem;
    }
}
</style>
