import { render, staticRenderFns } from "./SocialMediaShareBlock.vue?vue&type=template&id=2d93fa8f"
import script from "./SocialMediaShareBlock.vue?vue&type=script&lang=js"
export * from "./SocialMediaShareBlock.vue?vue&type=script&lang=js"
import style0 from "./SocialMediaShareBlock.vue?vue&type=style&index=0&id=2d93fa8f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports