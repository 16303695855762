/**
 * The directive `v-epi-edit` is used similarly to @Html.EditAttributes() in
 * Razor views. It enables On-Page Editing on elements using the `data-epi-edit`
 * property (introduced in Episerver CMS UI 11.X.0) and disables the DOM
 * updating from the CMS so that Vue can keep the responsibility over the DOM.
 *
 * It's enabled by the `isEditable` value that is stored in the Vuex store, but
 * can be overwritten by a component having a property named
 * `epiDisableEditing` being true.
 *
 * Usage can be found on most Vue components, such as ArtistDetailsPage.vue.
 */

import store from '@/Scripts/store';
import { UPDATE_CONTEXT } from '@/Scripts/store/modules/epiContext';

function toggleEditAttributes(el, binding, vnode) {
    const siteIsEditable = store.state.epiContext.isEditable;
    const componentIsEditable = !vnode.context.epiDisableEditing;

    // This is a fix that always makes sure live edit works.
    store.subscribe((mutation, state) => {
        if (mutation.type === UPDATE_CONTEXT) {
            /* console.log(mutation.type)
            console.log(mutation.payload) */
            if (mutation.payload.isEditable && mutation.payload.inEditMode) {
                el.setAttribute('data-epi-edit', binding.value);
            } else {
                el.removeAttribute('data-epi-edit');
            }
        }
    });

    if (siteIsEditable && componentIsEditable) {
        el.setAttribute('data-epi-edit', binding.value);
    } else {
        el.removeAttribute('data-epi-edit');
    }
}

export default {
    bind: toggleEditAttributes,
    update: toggleEditAttributes
};
