var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-analyst-item" }, [
    _c("div", { staticClass: "u-hidden-sm u-hidden-xs" }, [
      _vm._v(_vm._s(_vm.analyst.company)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-hidden-sm u-hidden-xs" }, [
      _vm._v(_vm._s(_vm.analyst.name)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-hidden-sm u-hidden-xs" }, [
      _c("a", { attrs: { href: "tel:" + _vm.analyst.phone } }, [
        _vm._v(_vm._s(_vm.analyst.phone)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-hidden-sm u-hidden-xs" }, [
      _c("a", { attrs: { href: "mailto:" + _vm.analyst.email } }, [
        _vm._v(_vm._s(_vm.analyst.email)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-display-sm-down c-analyst-item-mobile" }, [
      _c("p", {}, [_vm._v(_vm._s(_vm.analyst.company))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.analyst.name))]),
      _vm._v(" "),
      _c("p", [
        _c("a", { attrs: { href: "tel:" + _vm.analyst.phone } }, [
          _vm._v(_vm._s(_vm.analyst.phone)),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("a", { attrs: { href: "mailto:" + _vm.analyst.email } }, [
          _vm._v(_vm._s(_vm.analyst.email)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }