var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-advanced-file-list-block" },
    [
      _c(
        "Column",
        { attrs: { md: 8 } },
        [
          _c("Heading", { attrs: { level: 3, noMarginTop: "" } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("Column", { attrs: { md: 16 } }),
      _vm._v(" "),
      _vm._l(_vm.files, function (file, index) {
        return _c(
          "Column",
          {
            key: `${_vm._uid}_${index}`,
            staticClass: "c-advanced-file-list-block__item-wrapper",
            attrs: { md: 6, sm: 12 },
          },
          [
            _c(
              "div",
              { staticClass: "c-advanced-file-list-block__item" },
              [
                _c(
                  "Row",
                  [
                    _c(
                      "Column",
                      { attrs: { md: 24, xs: 11 } },
                      [
                        _c("ResponsiveImage", {
                          attrs: {
                            src: file.image,
                            xs: { h: 127, w: 138 },
                            sm: { h: 127, w: 138 },
                            md: { h: 285, w: 295 },
                            lg: { h: 285, w: 295 },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "Column",
                      {
                        staticClass: "c-advanced-file-list-block__item__text",
                        attrs: { md: 24, xs: 13 },
                      },
                      [
                        _c(
                          "Heading",
                          {
                            staticClass:
                              "c-advanced-file-list-block__item__title",
                            attrs: { level: 5, noMarginTop: "" },
                          },
                          [_vm._v(_vm._s(file.title))]
                        ),
                        _vm._v(" "),
                        _c(
                          "Button",
                          {
                            staticClass:
                              "c-advanced-file-list-block__item__button",
                            attrs: { href: file.file, download: "" },
                          },
                          [
                            _vm._v(
                              "\r\n                        " +
                                _vm._s(file.buttonLabel) +
                                "\r\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }