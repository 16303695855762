var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row c-partnerscustom-block o-custom-block o-bg",
      style: _vm.style,
    },
    [
      _c(
        "Column",
        { attrs: { md: 24 } },
        [
          _vm.title
            ? _c(
                "Heading",
                { staticClass: "u-no-margin-top", attrs: { level: 3 } },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("Column", { attrs: { md: 24 } }, [
        _c(
          "div",
          { staticClass: "c-partnerscustom-block__list" },
          _vm._l(_vm.softwarePartners, function (logo, index) {
            return _c("div", { key: index }, [
              _c("img", { attrs: { src: logo } }),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }