<template>
<Row class="c-expandable-list-block">
    <Column :md="10" v-if="!hideIntro">
        <Heading isCategory v-if="label">{{label}}</Heading>
        <Heading :level="3" noMarginTop>{{title}}</Heading>
        <XhtmlField :items="intro" class="o-ingress"/>
    </Column>
    <template v-if="hideIntro">
        <Column :md="10" :offsetMd="index % 2 === 0 ? 0 : 4" class="c-expandable-list-block__item-wrapper--hide-intro" v-for="(item, index) in items" :key="`${_uid}_${index}`">
            <div class="c-expandable-list-block__item">
                <Collapse :border="true" iconOpen="minus" iconClosed="plus">
                    <template #header>
                        <Heading noMarginTop noMarginBottom :level="4">
                            {{item.title}}
                        </Heading>
                    </template>
                    <template #content>
                        <XhtmlField :items="item.content"/>
                    </template>
                </Collapse>
            </div>
        </Column>
    </template>
     <template v-else >
         <Column :md="10" :offsetMd="4" class="c-expandable-list-block__item-wrapper">
            <div class="c-expandable-list-block__item" v-for="(item, index) in items" :key="`${_uid}_${index}`">
                <Collapse :border="true" iconOpen="minus" iconClosed="plus">
                    <template #header>
                        <Heading noMarginTop noMarginBottom :level="4">
                            {{item.title}}
                        </Heading>
                    </template>
                    <template #content>
                        <XhtmlField :items="item.content"/>
                    </template>
                </Collapse>
            </div>
        </Column>
     </template>
</Row>
</template>

<script>
import Collapse from '@/Scripts/components/molecules/Collapse.vue';

export default {
    props: {
        label: String,
        title: String,
        hideIntro: Boolean,
        intro: String,
        items: Array
    },
    components: {
        Collapse
    }
};
</script>

<style lang="scss">
.c-expandable-list-block {
    $self: &;

    &__item-wrapper {
        #{$self}__item {
            &:first-of-type {
                .c-collapse__header-wrapper {
                    border-top: 1px solid $gray;
                }
            }
        }
    }
    &__item-wrapper--hide-intro:nth-of-type(-n+2) {
        #{$self}__item {
            &:first-of-type {
                .c-collapse__header-wrapper {
                    border-top: 1px solid $gray;
                }
            }
        }
    }
    &__item {
        margin-bottom: 1px;

        .c-collapse__header-wrapper {
            padding-top: .5em;
            padding-bottom: .5em;
        }
    }
}
</style>
