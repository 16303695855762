var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-date" },
    [
      _vm.type === "date"
        ? [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("formatDate")(
                    _vm.value,
                    _vm.language,
                    _vm.useUsDateFormat
                  )
                ) +
                "\n    "
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "time"
        ? [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("formatTime")(_vm.value, _vm.language)) +
                "\n    "
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }