var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Row",
        {
          staticClass:
            "o-flat-list c-offices-block c-offices-block@xs c-offices-block@sm",
          attrs: { wrapper: "ul" },
        },
        [
          _c(
            "Column",
            [
              _c("Heading", { attrs: { isCategory: "" } }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
              _vm._v(" "),
              _c(
                "Heading",
                {
                  staticClass: "c-offices-block__title u-add-colored-period",
                  attrs: { level: 1 },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.offices, function (region, index) {
            return _c(
              "Column",
              { key: region.regionName + index, attrs: { wrapper: "li" } },
              [
                _c("Collapse", {
                  attrs: { border: false },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "Heading",
                              {
                                staticClass: "u-no-margins",
                                attrs: { level: 3 },
                              },
                              [_vm._v(_vm._s(region.regionName))]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "Row",
                              {
                                staticClass:
                                  "o-flat-list c-offices-block__offices",
                                attrs: { wrapper: "ul" },
                              },
                              _vm._l(
                                region.countries,
                                function (country, index) {
                                  return _c(
                                    "Column",
                                    {
                                      key: country.country + index,
                                      staticClass:
                                        "c-offices-block__offices-wrapper",
                                      attrs: { wrapper: "li", md: 12 },
                                    },
                                    [
                                      _c("Collapse", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _vm._v(
                                                    "\r\n                                        " +
                                                      _vm._s(country.country) +
                                                      "\r\n                                    "
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                            {
                                              key: "content",
                                              fn: function () {
                                                return [
                                                  _vm._l(
                                                    country.offices,
                                                    function (office, index) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            key: `office_name@sm_${_vm._uid}_${index}`,
                                                            staticClass:
                                                              "u-block o-large-body",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                office.officeName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "p",
                                                          {
                                                            key: `office_body1@sm_${_vm._uid}_${index}`,
                                                            staticClass:
                                                              "o-small-body",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "u-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    office.addressLineOne
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "u-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    office.addressLineTwo
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "u-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    office.addressLineThree
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "u-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    office.addressLineFour
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "p",
                                                          {
                                                            key: `office_body2@sm_${_vm._uid}_${index}`,
                                                            staticClass:
                                                              "o-small-body",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "u-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    office.phoneNumber
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "u-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    office.email
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "u-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    office.websiteUrl
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "Row",
        {
          staticClass: "c-offices-block c-offices-block@md c-offices-block@lg",
        },
        [
          _c(
            "Column",
            {
              staticClass: "c-offices-block__col1",
              attrs: { md: 8, "offset-md": 2 },
            },
            [
              _c("Heading", { attrs: { isCategory: "" } }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
              _vm._v(" "),
              _c(
                "Heading",
                {
                  staticClass: "c-offices-block__title u-add-colored-period",
                  attrs: { level: 4 },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "c-offices-block__regions o-flat-list" },
                _vm._l(_vm.offices, function (region, index) {
                  return _c(
                    "li",
                    {
                      key: region.regionName + index,
                      staticClass: "c-offices-block__region",
                      class: {
                        "c-offices-block__region--selected":
                          region === _vm.selectedRegion,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleRegion(region)
                        },
                      },
                    },
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Column",
                            { attrs: { xs: 16 } },
                            [
                              _c(
                                "Heading",
                                {
                                  staticClass:
                                    "c-offices-block__region__title u-no-margins",
                                  attrs: { level: 5 },
                                },
                                [_vm._v(_vm._s(region.regionName))]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "Column",
                            { attrs: { xs: 4, align: "right" } },
                            [_c("Icon", { attrs: { icon: "chevron_right" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.selectedRegion
            ? _c(
                "Column",
                {
                  staticClass: "c-offices-block__col2",
                  attrs: { md: 12, "offset-md": 2 },
                },
                [_c("ResponsiveImage", { attrs: { src: _vm.image } })],
                1
              )
            : _c(
                "Column",
                {
                  staticClass: "c-offices-block__col2",
                  attrs: { md: 12, "offset-md": 2 },
                },
                [
                  _c("Heading", { attrs: { level: 5 } }, [
                    _vm._v(_vm._s(_vm.selectedRegion.regionName)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "Row",
                    {
                      staticClass: "o-flat-list c-offices-block__offices",
                      attrs: { wrapper: "ul" },
                    },
                    _vm._l(
                      _vm.selectedRegion.countries,
                      function (country, index) {
                        return _c(
                          "Column",
                          {
                            key: country + index,
                            attrs: { wrapper: "li", md: 12 },
                          },
                          [
                            _c("Collapse", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\r\n                            " +
                                            _vm._s(country.country) +
                                            "\r\n                        "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _vm._l(
                                          country.offices,
                                          function (office, index) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  key: `office_name_${_vm._uid}_${index}`,
                                                  staticClass:
                                                    "u-block o-large-body",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(office.officeName)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  key: `office_body1_${_vm._uid}_${index}`,
                                                  staticClass: "o-small-body",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "u-block" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          office.addressLineOne
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "u-block" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          office.addressLineTwo
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "u-block" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          office.addressLineThree
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "u-block" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          office.addressLineFour
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  key: `office_body2_${_vm._uid}_${index}`,
                                                  staticClass: "o-small-body",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "u-block" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          office.phoneNumber
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "u-block" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(office.email)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "u-block" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          office.websiteUrl
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }