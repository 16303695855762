var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "t-ir-layout",
      class: [{ "t-ir-layout--no-scroll": !_vm.isBodyScrollable }],
    },
    [
      _c(
        "Header",
        _vm._b(
          {
            attrs: {
              isWhite: _vm.isStartPage,
              isOverlapping: _vm.isStartPage,
              showBreadcrumbs: !_vm.isStartPage,
              isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
              isMenuOpen: _vm.isMenuOpen,
              currentLanguage: _vm.currentLanguage,
            },
          },
          "Header",
          _vm.model.header,
          false
        )
      ),
      _vm._v(" "),
      _vm.model.gated && !_vm.isValidated
        ? _c("GatedContentPage", {
            attrs: {
              pageId: _vm.model.contentLink.id,
              form: _vm.model.siteSettings.embededGateForm,
            },
            on: { validated: _vm.validated },
          })
        : _c(
            "div",
            [
              _c(
                "Container",
                [
                  _c(
                    "Row",
                    [
                      _c(
                        "Column",
                        [
                          _c("Heading", { attrs: { isCategory: "" } }, [
                            _vm._v(_vm._s(_vm.model.parentLabel)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "Column",
                        { attrs: { md: 10 } },
                        [
                          _c(
                            "Heading",
                            { attrs: { level: 3, noMarginTop: "" } },
                            [_vm._v(_vm._s(_vm.model.parentTitle))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "Column",
                        { attrs: { offsetMd: 4, md: 10 } },
                        [
                          _c("XhtmlField", {
                            attrs: { items: _vm.model.parentIntro },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("SiblingNavigation", {
                staticClass: "t-ir-layout__nav",
                attrs: {
                  siblings: _vm.model.siblings && _vm.model.siblings.siblings,
                  nameProperty: "label",
                },
              }),
              _vm._v(" "),
              _vm._t("default", null, { model: _vm.model }),
              _vm._v(" "),
              _c(
                "Container",
                { staticClass: "o-bg o-bg--beige-light" },
                [
                  _vm.model.footerBlock && _vm.model.footerBlock.content
                    ? _c(
                        "TwoColumnBlock",
                        _vm._b(
                          { staticClass: "t-ir-layout__footer" },
                          "TwoColumnBlock",
                          _vm.model.footerBlock.content,
                          false
                        )
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.disableCision
                    ? _c("Subscription", {
                        staticClass: "t-ir-layout__footer",
                        attrs: { heading: _vm.model.cisionText },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
      _vm._v(" "),
      _c(
        "Footer",
        _vm._b(
          {},
          "Footer",
          {
            ..._vm.model.footer,
            headerTop: {
              isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
              isMenuOpen: _vm.isMenuOpen,
              currentLanguage: _vm.currentLanguage,
              searchPage: _vm.searchPage,
              loginPage: _vm.loginPage,
            },
          },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }