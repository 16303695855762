var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-form-block" },
    [
      _c(
        "Column",
        { staticClass: "o-col col-xs-24" },
        [
          _c("Heading", { attrs: { noMarginTop: "" } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c(_vm.dynamicComponent, { tag: "component" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }