var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-notification-item" }, [
    _c("div", [_vm._v(_vm._s(_vm.notification.time))]),
    _vm._v(" "),
    _c("div", [
      _c("a", { attrs: { href: _vm.notification.url } }, [
        _vm._v(_vm._s(_vm.notification.headline)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }