<template>
<header class="c-header" :class="[{'c-header--overlap' : isOverlapping }]">
    <HeaderTop
    :isMenuOpen="isMenuOpen"
    :isLanguageSelectorOpen="isLanguageSelectorOpen"
    :currentLanguage="currentLanguage"
    :isWhite="isWhite"
    :loginPage="loginPage"
    :searchPage="searchPage"/>
    <Container isFluid class="c-header__breadcrumbs">
        <Breadcrumbs v-if="showBreadcrumbs" :items="breadcrumbs"/>
    </Container>
    <LanguageSelector :isOpen="isLanguageSelectorOpen" :items="languageLinks" @languageChanged="toggleLanguageSelector"/>
    <NavMenu :isOpen="isMenuOpen" :items="mainMenu" class="u-hidden u-display-md u-display-lg"/>
    <NavMenuSmall
        v-on:close-menu="closeMenuEnableScrolling"
        v-on:open-language-selector="toggleLanguageSelector"
        :isOpen="isMenuOpen"
        :items="mainMenu"
        class="u-hidden-md u-hidden-lg"
        :currentLanguage="currentLanguage"
        :searchPage="searchPage"/>
</header>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';

import Breadcrumbs from '@/Scripts/components/molecules/Breadcrumbs.vue';
import HeaderTop from '@/Scripts/components/molecules/HeaderTop.vue';
import NavMenu from '@/Scripts/components/molecules/Menu/NavMenu.vue';
import NavMenuSmall from '@/Scripts/components/molecules/Menu/NavMenuSmall.vue';
import LanguageSelector from '@/Scripts/components/molecules/LanguageSelector.vue';

import { TOGGLE_LANGUAGESELECTOR, CLOSE_MENU, ENABLE_SCROLLING } from '@/Scripts/store/modules/appContext';
import { mapMutations } from 'vuex';

export default {
    props: {
        showBreadcrumbs: {
            type: Boolean,
            default: true // eslint-disable-line
        },
        breadcrumbs: {
            type: Array
        },
        languageLinks: Array,
        currentLanguage: Object,
        isLanguageSelectorOpen: Boolean,
        isMenuOpen: Boolean,
        mainMenu: {
            type: Array
        },
        isWhite: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        isOverlapping: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        loginPage: {
            type: String
        },
        searchPage: {
            type: String
        }
    },
    methods: {
        closeMenuEnableScrolling() {
            this.enableScrolling();
            this.closeMenu();
        },
        ...mapMutations({
            toggleLanguageSelector: TOGGLE_LANGUAGESELECTOR,
            closeMenu: CLOSE_MENU,
            enableScrolling: ENABLE_SCROLLING
        })
    },
    components: {
        Icon,
        Breadcrumbs,
        NavMenu,
        NavMenuSmall,
        HeaderTop,
        LanguageSelector
    }
};
</script>

<style lang="scss">
.c-header {
    margin-bottom: 4em;
    z-index: 2;
    position: relative;

    &__breadcrumbs {
        position: relative;
        z-index: -1;
    }
    &--overlap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
</style>
