<template>
<div class="o-block c-fact-box-block">
    <Heading isCategory class="c-fact-box-block__label">{{model.label}}</Heading>
    <p class="o-ingress u-no-margins">
        {{model.text}}
    </p>
    <span class="c-fact-box-block__source o-small-body" v-if="model.source">{{getLabel('factBoxBlock', 'source')}}: {{model.source}}</span>
    <XhtmlField v-epi-edit="'mainBody'" :items="model.mainBody"/>
</div>
</template>

<script>
import Heading from '@/Scripts/components/atoms/Heading.vue';
export default {
    props: {
        model: {
            type: Object
        }
    },
    components: {
        Heading
    }
};
</script>

<style lang="scss">
.c-fact-box-block {
    border-top: 2px solid $orange;
    background-color: $offwhite;
    padding: 30px 25px 40px;

    &__label {
        margin-bottom: .5rem;
    }
    &__source {
        color: $gray;
    }
}
</style>
