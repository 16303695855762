var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "epi-link",
    {
      staticClass: "c-news-component--vertical o-link--no-style",
      attrs: { href: _vm.url },
    },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            { class: { flip: _vm.flip }, attrs: { xs: 12 } },
            [
              _c("ResponsiveImage", {
                attrs: {
                  src: _vm.imageUrl,
                  xs: { h: 135, w: 167 },
                  sm: { h: 135, w: 202 },
                  md: { h: 116, w: 150 },
                  lg: { h: 116, w: 150 },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("Column", { attrs: { xs: 12 } }, [
            _c(
              "div",
              [
                _c(
                  "Row",
                  [
                    !_vm.hideDate
                      ? _c(
                          "Column",
                          [
                            _c("Datetime", {
                              attrs: {
                                date: _vm.published,
                                value: _vm.published,
                                useUsDateFormat: _vm.useUsDateFormat,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("Column", [_c("span", [_vm._v(_vm._s(_vm.title))])]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }