<template>
<Container isFluid class="c-sibling-navigation" v-if="notEmptyObject(siblings)">
    <Container class="u-hidden-xs u-hidden-sm c-sibling-navigation__wrapper">
        <span v-for="(item, index) in siblings" :key="`${_uid}${index}`" class="c-sibling-navigation__service">
            <Anchor :href="item.url">{{getName(item)}}</Anchor>
        </span>
    </Container>
    <Dropdown class="u-display-xs u-display-sm" v-model="dropdownSelection" @input="changeRoute" :options="dropdownValues" :clearable="false"/>
</Container>
</template>

<script>
import Dropdown from '@/Scripts/components/atoms/Dropdown.vue';

export default {
    props: {
        siblings: Array,
        // Used to decide which property of each item to display as name in navigation
        // Default is to use item.title
        nameProperty: {
            type: String,
            default: 'title' // eslint-disable-line
        }
    },
    components: {
        Dropdown
    },
    data() {
        return {
            dropdownSelection: null,
        };
    },
    mounted() {
        this.dropdownSelection = this.currentSibling;
    },
    computed: {
        currentSibling() {
            if (this.siblings) {
                var current =  this.siblings.find(x => x.url === this.$route.path);
                if (current === undefined) {
                    current = this.siblings[0];
                }
                return {label: this.getName(current), url: current.url };
            }
        },
        dropdownValues() {
            var values = [];
            if  (this.siblings) {
                values = this.siblings.map(x => {
                    return {label: this.getName(x), url: x.url};
                });
            }
            return values;
        }
    },
    methods: {
        getName(item) {
            return item[this.nameProperty];
        },
        changeRoute(current) {
            this.$router.push(current.url);
        }
    },
};
</script>

<style lang="scss">
.c-sibling-navigation {
        background-color: $beige_light;

        &__wrapper {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
        &__service {
            padding: 1rem;
            display: inline-block;

            a {
                color: $gray;
                text-decoration: none;

                &:hover, &.router-link-exact-active.router-link-active {
                    text-decoration: none;
                    position: relative;

                    &:before, &:after {
                        content: "";
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        background-color: $orange;
                        position: absolute;
                        top: 40%;
                    }
                    &:before {
                        left: -15px;
                    }
                    &:after {
                        right: -15px;
                    }
                }
            }
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
        .vs__dropdown-toggle {
            border: none;
            padding-top: .5em;
            padding-bottom: .5em;
        }
    }
</style>
