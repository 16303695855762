var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-vacancy-page" },
    [
      _c(
        "Container",
        [
          _vm.loading
            ? _c("div", { staticClass: "u-spinner" }, [_vm._v("Loading...")])
            : _c(
                "Row",
                [
                  _c(
                    "Column",
                    { attrs: { offsetMd: 8 } },
                    [
                      _c("Heading", { attrs: { level: 2, noMarginTop: "" } }, [
                        _vm._v(_vm._s(_vm.model.Title)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("Column", { attrs: { md: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "c-vacancy-page__left" },
                      [
                        _c(
                          "Heading",
                          {
                            staticClass: "c-vacancy-page__left__title",
                            attrs: { isCategory: "" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getLabel(
                                  "vacancyPage",
                                  "positionDetailsHeadingText"
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.model.Role
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "c-vacancy-page__left__text-wrapper",
                              },
                              [
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabel(
                                        "vacancyPage",
                                        "positionRoleText"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(_vm._s(_vm.model.Role.Label)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.Title
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "c-vacancy-page__left__text-wrapper",
                              },
                              [
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabel(
                                        "vacancyPage",
                                        "jobTitleText"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(_vm._s(_vm.model.Title)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.ExperienceLevel.Label
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "c-vacancy-page__left__text-wrapper",
                              },
                              [
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabel(
                                        "vacancyPage",
                                        "experienceLevelText"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.ExperienceLevel.Label)
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.model.Location
                      ? _c(
                          "div",
                          [
                            _c(
                              "Heading",
                              {
                                staticClass: "c-vacancy-page__left__title",
                                attrs: { isCategory: "" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getLabel(
                                      "vacancyPage",
                                      "locationHeadingText"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "c-vacancy-page__left__text-wrapper",
                              },
                              [
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabel(
                                        "vacancyPage",
                                        "locationText"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "u-block" }, [
                                  _vm._v(_vm._s(_vm.model.Location)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("Column", { attrs: { offsetMd: 4, md: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "c-vacancy-page__right" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "c-vacancy-page__right__text-wrapper",
                          },
                          [
                            _vm.model.Intro
                              ? _c("p", { staticClass: "o-ingress" }, [
                                  _vm._v(
                                    "\r\n                            " +
                                      _vm._s(_vm.model.Intro) +
                                      "\r\n                        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.model.Url
                              ? _c(
                                  "Button",
                                  {
                                    attrs: {
                                      href: _vm.model.Url,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getLabel(
                                          "vacancyPage",
                                          "applyButtonText"
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.model.AboutRole
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "c-vacancy-page__right__text-wrapper",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "c-vacancy-page__right__small-title o-large-body",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getLabel(
                                          "vacancyPage",
                                          "aboutRoleHeadingText"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("pre", [
                                  _vm._v(_vm._s(_vm.model.AboutRole)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.Competancy
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "c-vacancy-page__right__text-wrapper",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "c-vacancy-page__right__small-title o-large-body",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getLabel(
                                          "vacancyPage",
                                          "competancyHeadingText"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("pre", [
                                  _vm._v(_vm._s(_vm.model.Competancy)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.AboutCompany
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "c-vacancy-page__right__text-wrapper",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "c-vacancy-page__right__small-title o-large-body",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getLabel(
                                          "vacancyPage",
                                          "aboutCompanyHeadingText"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("pre", [
                                  _vm._v(_vm._s(_vm.model.AboutCompany)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.model.Url
                          ? _c(
                              "Button",
                              {
                                staticClass: "c-vacancy-page__right__button",
                                attrs: {
                                  href: _vm.model.Url,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getLabel(
                                      "vacancyPage",
                                      "applyButtonText"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }