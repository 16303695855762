var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-chart-options" },
    _vm._l(this.timeRange, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "c-chart-option",
          on: {
            click: function ($event) {
              return _vm.onSelect(item)
            },
          },
        },
        [
          _c(
            "div",
            {
              class:
                _vm.selected === index
                  ? "c-chart-option-inner-selected"
                  : "c-chart-option-inner",
            },
            [_vm._v("\n           " + _vm._s(item) + "\n       ")]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }