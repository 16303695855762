<!-- TODO: FIX FORM-->
<template>
    <main class="c-service-page">
        <!-- Hero -->
        <PracticeAreaHero :model="model" />
        <!-- // Hero -->

        <!-- Navigation -->
        <Container isFluid class="practicearea--navigation" v-if="notEmptyObject(model.siblings) || isEditable">
            <Container class="u-hidden-xs u-hidden-sm">
                <ul class="o-flat-list practicearea--navigation__list">
                    <li v-for="(item, index) in model.siblings.siblings" :key="`${_uid}${index}`" class="practicearea--navigation__list--element">
                        <Anchor :class="compareGuid(item.guid) ? 'practicearea--navigation__list--selected' : ''" :href="item.url" :ariaLabel="item.title">{{item.title}}</Anchor>
                    </li>
                </ul>
            </Container>
            <Dropdown class="u-display-xs u-display-sm" v-model="dropdownSelection" @input="changeRoute" :options="dropdownValues" :clearable="false"/>
        </Container>
        <!-- // Navigation -->
        <Container>
            <BubblesBlock class="c-service-page__bubbles" v-if="notEmptyObject(model.bubblesBlock.content) || isEditable" v-bind="model.bubblesBlock.content" v-epi-edit="'BubblesBlock'"/>
            <MediaBlock class="c-service-page__media-block" v-if="notEmptyObject(model.media && model.media.content) || isEditable" v-bind="model.media.content" v-epi-edit="'Media'"/>
            <TwoColumnBlock class="c-service-page__twocol1" v-if="notEmptyObject(model.twoClColumnBlock && model.twoClColumnBlock.content) || isEditable" v-bind="model.twoClColumnBlock.content" v-epi-edit="'twoClColumnBlock'"/>
            <SingleTeaserBlock class="c-service-page__teaserblock" v-if="notEmptyObject(model.teaserBlock.content) || isEditable" v-epi-edit="'teaserBlock'" v-bind="model.teaserBlock.content"/>
            <TwoColumnBlock class="c-service-page__twocol2" v-epi-edit="'secondTwoColumnBlock'" v-if="notEmptyObject(model.secondTwoColumnBlock && model.secondTwoColumnBlock.content)" v-bind="model.secondTwoColumnBlock.content"/>
            <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-service-page__content-area" :model="model.blocks  || isEditable"/>
            <PageListBlock class="c-service-page__page-list" v-epi-edit="'pageListBottom'" v-bind="model.pageListBottom && model.pageListBottom.content"/>
            <FormBlock v-epi-edit="'Form'" id="form" class="c-service-page__form" v-if="notEmptyObject(model.form && model.form.content) || isEditable" v-bind="model.form.content" ref="contactform"/>
        </Container>
        <Container isFluid background="beige-light" v-if="notEmptyObject(model.newsBlock) || isEditable" v-epi-edit="'NewsBlock'">
            <Container>
                <NewsBlock v-bind="model.newsBlock.content" :useUsDateFormat="model.siteSettings.useUsDateFormat"/>
            </Container>
        </Container>
    </main>
</template>

<script>
import Dropdown from '@/Scripts/components/atoms/Dropdown.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import PracticeAreaHero from '@/Scripts/components/molecules/PracticeAreaHero.vue';

export default {
    props: ['model'],
    data() {
        return {
            dropdownSelection: null,
            selected: '',
        };
    },
    mounted() {
        this.dropdownSelection = this.currentSibling;
    },
    computed: {
        currentSibling() {
            if (this.model.siblings && this.model.siblings.siblings) {
                var current =  this.model.siblings.siblings.find(x => x.url === this.$route.path);
                return {label: current.title, url: current.url };
            }
        },
        dropdownValues() {
            var values = [];
            if  (this.model.siblings && this.model.siblings.siblings) {
                values = this.model.siblings.siblings.map(x => {
                    return {label: x.title, url: x.url};
                });
            }
            return values;
        }
    },
    methods: {
        changeRoute(current) {
            this.$router.push(current.url)
                .then(() => location.reload());
        },
        changeTab(item) {
            this.selected = item.title;
            this.$router.push(item.url)
                .then(() => {
                    if (this.model.form && this.model.form !== null) {
                        location.reload();
                    }
                });
        },
        isInPath(item) {
            var pathName = window.location.pathname;
            return (pathName.toLowerCase().trim().includes(item.title.replace(/\s+/g, '-').toLowerCase().trim())
                    || pathName.toLowerCase().trim() === item.url.toLowerCase().trim());
        },
        compareGuid(guid) {
            if (this.model.contentLink.guidValue === guid) {
                return true;
            }
            return false;
        },
    },
    components: {
        Dropdown,
        ContentArea,
        PracticeAreaHero
    }
};
</script>

<style lang="scss">
.c-service-page {
    &__top {
        text-align: center;

        &__title {
            margin-bottom: 1.3em;
        }
    }
    /* &__media-block {
        margin-bottom: 7rem;
    } */
    &__other-services {
        background-color: $beige_light;

        &__wrapper {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
        &__service {
            padding: 1rem;
            display: inline-block;

            a {
                color: $gray;
                text-decoration: none;

                &:hover, &.router-link-exact-active.router-link-active {
                    text-decoration: none;
                    position: relative;

                    &:before, &:after {
                        content: "";
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        background-color: $orange;
                        position: absolute;
                        top: 40%;
                    }
                    &:before {
                        left: -15px;
                    }
                    &:after {
                        right: -15px;
                    }
                }
            }
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
        .vs__dropdown-toggle {
            border: none;
            padding-top: .5em;
            padding-bottom: .5em;
        }
    }
    &__teaserblock {
        @include media-sm-only {
            .o-bg.o-bg--right:before {
                left: -100vw;
            }
        }
    }
    /* Margins */
    &__top {
        margin-bottom: 4rem;

        @include media-md {
            margin-bottom: 6rem;
        }
    }
    &__content-area, &__other-services, &__bubbles, &__media-block, &__twocol1, &__page-list {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
    &__teaserblock, &__twocol2 {
        margin-bottom: 5rem;

        @include media-md {
            margin-bottom: 7rem;
        }
    }
    &__selected-tab {
        color: $black;
        text-decoration: none;
        position: relative;
        cursor: pointer;
            &:before, &:after {
                content: "";
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $orange;
                position: absolute;
                top: 40%;
            }
            &:before {
                left: -15px;
            }
            &:after {
                right: -15px;
            }
        &:first-of-type {
            margin-left: 0;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__unselected-tab{
        color: $gray;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            text-decoration: none;
            position: relative;
            &:before, &:after {
                content: "";
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: $orange;
                position: absolute;
                top: 40%;
            }
            &:before {
                left: -15px;
            }
            &:after {
                right: -15px;
            }
        }
    }
}
</style>
