// TODO: Set size for images
<template>
<epi-link :href="url" class="c-news-item o-link--no-style">
    <ResponsiveImage v-if="showImage" :src="imageUrl" :alt="imageTitle" v-bind="imageConfig"/>
    <div class="c-news-item__text">
        <Datetime :date="published" :value="published" v-if="!hideDate" :useUsDateFormat="useUsDateFormat"/>
        <div class="o-ingress">{{title}}</div>
        <v-clamp autoresize :max-lines="4">{{intro}}</v-clamp>
    </div>
    <Anchor isFancy :href="url" class="u-display-md-up">{{getLabel('global', 'readMore')}}</Anchor>
    <Icon icon="arrow_right"/>
</epi-link>
</template>

<script>
import EpiLink from '@/Scripts/components/atoms/EpiLink.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Datetime from '@/Scripts/components/atoms/Datetime.vue';
import VClamp from 'vue-clamp';

export default {
    props: {
        imageConfig: Object,
        imageUrl: String,
        imageTitle: String,
        published: String,
        title: String,
        intro: String,
        label: String,
        linkTarget: String,
        url: String,
        hideDate: Boolean,
        useUsDateFormat: Boolean,
        showImage: {
            type: Boolean,
            default: true // eslint-disable-line
        },
        showArrow: {
            type: Boolean,
            default: true // eslint-disable-line
        },
    },
    components: {
        Icon,
        Datetime,
        VClamp,
        EpiLink
    }
};
</script>

<style lang="scss">
.c-news-item {
    display: block;
    background-color: $beige-light;
    height: 100%;
    position: relative;
    padding-bottom: 3rem;

    &__text {
        padding: 2rem;
    }
    a {
        position: absolute;
        left: 2rem;
        bottom: 2rem;
    }
    .c-icon {
        margin-left: 2rem;
        width: 17px;

        @include media-md {
            display: none;
        }
    }
}
</style>
