var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.videoEmbedCode
    ? _c(
        "figure",
        [
          _vm.video
            ? _c(
                "video",
                { staticClass: "c-video-block", attrs: { controls: "" } },
                [
                  _c("source", {
                    attrs: { src: _vm.video, type: "video/mp4" },
                  }),
                  _vm._v(
                    "\n        Your browser does not support the video tag.\n    "
                  ),
                ]
              )
            : _vm.image
            ? _c(
                "ResponsiveImage",
                _vm._b(
                  { attrs: { alt: _vm.subtitle, src: _vm.image } },
                  "ResponsiveImage",
                  _vm.imageConfig,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.subtitle
            ? _c("figcaption", [_vm._v(_vm._s(_vm.subtitle))])
            : _vm._e(),
        ],
        1
      )
    : _c(
        "figure",
        [
          _vm.videoEmbedCode
            ? _c("div", {
                staticClass: "c-video-block",
                domProps: { innerHTML: _vm._s(_vm.videoEmbedCode) },
              })
            : _vm.image
            ? _c(
                "ResponsiveImage",
                _vm._b(
                  { attrs: { alt: _vm.subtitle, src: _vm.image } },
                  "ResponsiveImage",
                  _vm.imageConfig,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.subtitle
            ? _c("figcaption", [_vm._v(_vm._s(_vm.subtitle))])
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }