<template>
 <Row class="c-bubbles-block o-custom-block" :class="{'o-bg' : backgroundColor}" :style="style">
    <Column><Heading isCategory>{{label}}</Heading></Column>
    <Column :md="10">
        <Heading :level="3" class="u-no-margin-top">{{title}}</Heading>
        <XhtmlField class="o-ingress" v-if="text || isEditable" :items="text"/>
    </Column>
    <Column :md="12" :offsetMd="2">
        <Row>
            <Column v-for="(item, index) in bubbles" :key="`${_uid}${index}`" :sm="8" class="c-bubbles-block__bubble">
                <div v-if="item.image" class="c-bubbles-block__bubble__image-wrapper">
                    <AspectRatioBox>
                        <ResponsiveImage :src="item.image" :alt="item.text" class="c-bubbles-block__bubble__image"/>
                    </AspectRatioBox>
                </div>
                <p class="c-bubbles-block__bubble__text">{{item.text}}</p>
            </Column>
        </Row>
    </Column>
</Row>
</template>

<script>
import AspectRatioBox from '@/Scripts/components/atoms/AspectRatioBox.vue';

export default {
    props: {
        label: String,
        title: String,
        bubbles: Array,
        backgroundColor: String,
        textColor: String,
        text: Array,
        color: Object,
        color2: Object
    },
    components: {
        AspectRatioBox
    },
    computed: {
        style() {
            return {
                backgroundColor: this.color2 ? this.color2.value : this.backgroundColor,
                color: this.color ? this.color.value : this.textColor
            };
        }
    }
};
</script>

<style lang="scss">
.c-bubbles-block {
    &__bubble {
        &__text {
            text-align: center;
            margin-left: 7%;
            max-width: 86%;
        }
        &__image-wrapper {
            margin-left: 32%;
            max-width: 38%;

            @include media-sm {
                margin-left: 14%;
                max-width: 72%;
            }
        }
        &__image {
            border-radius: 50%;
            background-color: $beige-light;
        }
    }
}
</style>
