var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.getComponentTypeForBlock(_vm.model),
    _vm._b(
      { tag: "component", attrs: { model: _vm.model } },
      "component",
      _vm.model.content,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }