var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "loader",
    },
    [
      _c("img", {
        attrs: { src: "/Assets/Images/gifs/loading.gif", alt: "Loading..." },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }