<template>
  <div class="loader" v-show="isLoading">
    <img src='/Assets/Images/gifs/loading.gif' alt="Loading..." />
  </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            'default': true,
            required: true,
        }
    }
};
</script>

<style lang="scss">
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  min-width: 300px;
  background-color: rgb(255, 255, 255); // Optional: Add a background color with transparency
  z-index: 9999; // Ensure the loader is on top of other elements
}
</style>
