<template>
<main>
    <Container>
        <Row>
            <Column :md="10">
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
                <XhtmlField :items="model.intro" class="o-ingress" v-epi-edit="'Intro'"/>
            </Column>
            <Column>
                <XhtmlField :items="model.body" v-epi-edit="'Body'"/>
            </Column>
        </Row>
    </Container>
</main>
</template>

<script>
export default {
    props: {
        model: Object
    }
};
</script>
