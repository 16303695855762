var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "o-redirect--alert" },
          [
            _c("Button", {
              class: "o-redirect--alert__btnClose",
              attrs: { buttonStyle: "no-style", iconRight: "", icon: "cross" },
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("p", [
              _vm._v("\r\n           " + _vm._s(_vm.text) + "\r\n        "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "Button",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm._toggleLanguageSelector.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\r\n                " +
                        _vm._s(_vm.getLabel("global", "changeCountry")) +
                        "\r\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "Button",
                  {
                    class: "u-hidden-xs",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\r\n                " +
                        _vm._s(_vm.getLabel("global", "close")) +
                        "\r\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "o-disclaimer-text" }, [
              _vm._v(
                "\r\n            This product includes GeoLite2 data created by MaxMind, available from\r\n            "
              ),
              _c("a", { attrs: { href: "https://www.maxmind.com" } }, [
                _vm._v("MaxMind"),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }