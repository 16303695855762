<template>
<div class="c-author">
    <Row>
        <Column>
            <Heading isCategory>{{model.heading ?  model.heading : ''}}</Heading>
        </Column>
        <Column :xs="6" :sm="4" :md="24">
            <ResponsiveImage class="c-author__image" :src="model.image" :xs="{w: 116}" :sm="{w: 116}" :md="{w: 116}" :lg="{w: 116}"/>
        </Column>
        <Column :xs="18" :sm="20" :md="24">
            <div>
                <span class="c-author__name">{{model.name}}</span>
                <span class="c-author__title">{{model.jobTitle}}</span>

            </div>
            <Anchor v-if="model.url && model.linkTitle "
                    :href="model.url"
                    :isExternal="this.isExternalURL(model.url)"
                    class="c-author__link">
                    {{model.linkTitle}}
            </Anchor>
        </Column>
    </Row>
</div>
</template>

<script>
import Heading from '@/Scripts/components/atoms/Heading.vue';
import Row from '@/Scripts/components/grid/Row.vue';
import Column from '@/Scripts/components/grid/Column.vue';
import Anchor from '../atoms/Anchor.vue';
export default {
    props: ['model'],
    components: {
        Anchor,
        Heading,
        Row,
        Column
    },
    methods: {
        /**
         * Compare url host to local host to determin if it external or internal.
         * @param {string} url url you want to check.
         */
        isExternalURL: (url) => {
            const tmp = document.createElement('a');
            tmp.href = url;
            return tmp.host !== window.location.host;
        }
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_breakpoints.scss';

.c-author {
    &__name, &__title {
        display: block;
    }
    &__title {
        color: $gray;
    }
    &__image {
        width: auto;
    }
}
.preserve-line-breaks {
    white-space: pre-line;
}
</style>
