var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tagType,
    {
      tag: "component",
      staticClass: "EPiLink",
      attrs: {
        event: _vm.isClickable ? "click" : "",
        to: _vm.href,
        href: _vm.href,
        target: _vm.target,
        title: _vm.title,
        "aria-label": _vm.ariaLabel,
        download: _vm.download,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }