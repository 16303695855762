var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-equity-prospectus-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-equity-prospectus-page__top" },
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { level: 3, noMarginTop: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "TwoLinkBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "TwoLinkBlock",
                    expression: "'TwoLinkBlock'",
                  },
                ],
                staticClass: "c-equity-prospectus-page__links",
              },
              "TwoLinkBlock",
              _vm.model.twoLinkBlock.content,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }