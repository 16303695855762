var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-pagination" },
    [
      _c(
        "Button",
        {
          staticClass: "c-pagination__button",
          attrs: { disabled: _vm.currentPage <= 1, buttonStyle: "no-style" },
          nativeOn: {
            click: function ($event) {
              return _vm.$emit("change", _vm.currentPage - 1)
            },
          },
        },
        [_vm._v(_vm._s(_vm.getLabel("pagination", "previousButton")))]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "o-flat-list" },
        [
          _c("li", [
            _c(
              "a",
              {
                class: { active: _vm.currentPage === 1 },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("change", 1)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(1) + "\n      ")]
            ),
          ]),
          _vm._v(" "),
          _vm.currentPage > 3 && _vm.maxPages > 3
            ? _c("li", [_vm._v("\n      ...\n    ")])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.listItems, function (i) {
            return _c(
              "li",
              { key: i, class: { "u-hidden": i <= 1 || i >= _vm.maxPages } },
              [
                _c(
                  "a",
                  {
                    class: { active: _vm.currentPage === i },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("change", i)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(i) + "\n      ")]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm.currentPage < _vm.maxPages - 2
            ? _c("li", [_vm._v("\n      ...\n    ")])
            : _vm._e(),
          _vm._v(" "),
          _vm.maxPages > 1
            ? _c("li", [
                _c(
                  "a",
                  {
                    class: { active: _vm.currentPage === _vm.maxPages },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("change", _vm.maxPages)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.maxPages) + "\n      ")]
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "Button",
        {
          staticClass: "c-pagination__button",
          attrs: {
            disabled: _vm.currentPage >= _vm.maxPages,
            buttonStyle: "no-style",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.$emit("change", _vm.currentPage + 1)
            },
          },
        },
        [_vm._v(_vm._s(_vm.getLabel("pagination", "nextButton")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }