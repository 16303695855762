var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "o-block c-fact-box-block" },
    [
      _c(
        "Heading",
        { staticClass: "c-fact-box-block__label", attrs: { isCategory: "" } },
        [_vm._v(_vm._s(_vm.model.label))]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "o-ingress u-no-margins" }, [
        _vm._v("\r\n        " + _vm._s(_vm.model.text) + "\r\n    "),
      ]),
      _vm._v(" "),
      _vm.model.source
        ? _c("span", { staticClass: "c-fact-box-block__source o-small-body" }, [
            _vm._v(
              _vm._s(_vm.getLabel("factBoxBlock", "source")) +
                ": " +
                _vm._s(_vm.model.source)
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("XhtmlField", {
        directives: [
          {
            name: "epi-edit",
            rawName: "v-epi-edit",
            value: "mainBody",
            expression: "'mainBody'",
          },
        ],
        attrs: { items: _vm.model.mainBody },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }