<template>
<main class="c-career-page" v-if="!$route.query.id">
    <Container>
        <Row class="c-career-page__top">
            <Column v-epi-edit="'Label'">
                <Heading isCategory>{{model.label}}</Heading>
            </Column>
            <Column :md="12" v-epi-edit="'Title'">
                <Heading :level="1" noMarginTop>{{model.title}}</Heading>
            </Column>
            <Column :md="10" :offsetMd="2">
                <XhtmlField v-epi-edit="'IntroText'" class="o-ingress" v-if="model.introText || isEditable" :items="model.introText"/>
                <Button v-if="!model.openPositionsUrl" v-scroll-to="'#openpositions'">{{getLabel('careerPage', 'openPositionButtonText')}}</Button>
                <Button v-if="model.openPositionsUrl" :href="model.openPositionsUrl">{{getLabel('careerPage', 'openPositionButtonText')}}</Button>
            </Column>
        </Row>
        <MediaBlock v-bind="model.media && model.media.content" class="c-career-page__media" v-epi-edit="'Media'" :imageConfig="mediaImageConfig"/>
        <Row class="c-career-page__quote" v-epi-edit="'Quote'" v-if="notEmptyObject(model.quote && model.quote.content) || isEditable">
            <Column :md="12">
                <QuoteBlock v-bind="model.quote && model.quote.content"/>
            </Column>
        </Row>
        <RightShiftedContentBlock v-if="notEmptyObject(model.rightShiftedContentBlock) || isEditable" v-bind="model.rightShiftedContentBlock" class="c-career-page__content-block" v-epi-edit="'RightShiftedContentBlock'"/>
        <TwoColumnBlock v-if="notEmptyObject(model.twoColumnBlock && model.twoColumnBlock.content) || isEditable" v-bind="model.twoColumnBlock && model.twoColumnBlock.content" class="c-career-page__two-columns" v-epi-edit="'TwoColumnBlock'"/>
        <EmployeesBlock v-if="notEmptyObject(model.employeesBlock && model.employeesBlock.content) || isEditable" v-bind="model.employeesBlock && model.employeesBlock.content" v-epi-edit="'EmployeesBlock'"/>
        <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-career-page__content-area" :model="model.blocks  || isEditable"/>
    </Container>
    <Container v-if="!model.openPositionsUrl" isFluid background="beige-light" id="openpositions">
        <Container>
            <OpenPositionsBlock v-bind="model.openPositionsBlock" v-epi-edit="'OpenPositionsBlock'" :detailedBaseUrl="model.siteSettings.careerPageLink"/>
        </Container>
    </Container>
</main>
<VacancyPage v-else/>
</template>

<script>
import OpenPositionsBlock from '@/Scripts/components/blocks/OpenPositionsBlock.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import { mapState } from 'vuex';

export default {
    props: ['model'],
    components: {
        OpenPositionsBlock,
        ContentArea
    },
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    }),
    data() {
        return {
            mediaImageConfig: {
                xs: {h: 220, w: 334},
                sm: {h: 368, w: 648},
                md: {h: 650, w: 1358},
                lg: {h: 650, w: 1358}
            }
        };
    }
};
</script>

<style lang="scss">
.c-career-page {
    &__top {
        margin-bottom: 4rem;
    }
    &__media, &__quote, &__content-block, &__two-columns {
        margin-bottom: 4rem;
    }
    &__two-columns {
        @include media-md {
            margin-bottom: 7rem;
        }
    }
    &__content-area {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
