var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-service-list-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-service-list-page__top" },
            [
              _vm.model.label || _vm.model.title || _vm.isEditable
                ? _c(
                    "Column",
                    { attrs: { md: 12 } },
                    [
                      _c(
                        "Heading",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "Label",
                              expression: "'Label'",
                            },
                          ],
                          attrs: { isCategory: "" },
                        },
                        [_vm._v(_vm._s(_vm.model.label))]
                      ),
                      _vm._v(" "),
                      _c(
                        "Heading",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "Title",
                              expression: "'Title'",
                            },
                          ],
                          staticClass: "u-add-colored-period u-no-margin-top",
                          attrs: { level: 1 },
                        },
                        [_vm._v(_vm._s(_vm.model.title))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 8, offsetMd: 2 } },
                [
                  _vm.model.introText || _vm.isEditable
                    ? _c("XhtmlField", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "IntroText",
                            expression: "'IntroText'",
                          },
                        ],
                        staticClass: "o-ingress",
                        attrs: { items: _vm.model.introText },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.notEmptyObject(_vm.model.form && _vm.model.form.content)
                    ? _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#form",
                              expression: "'#form'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLabel(
                                "serviceListPage",
                                "topBannerButtonText"
                              )
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.notEmptyObject(
            _vm.model.twoColumnBlock && _vm.model.twoColumnBlock.content
          ) || _vm.isEditable
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "TwoColumnBlock",
                        expression: "'TwoColumnBlock'",
                      },
                    ],
                    staticClass: "c-service-list-page__two-column",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoColumnBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.categories.length > 0 || _vm.isEditable
            ? _c(
                "Row",
                {
                  staticClass:
                    "c-service-list-page__services o-bg o-bg--beige-light",
                },
                _vm._l(_vm.model.categories, function (category, index) {
                  return _c(
                    "Column",
                    {
                      key: `service_list${_vm._uid}${index}`,
                      staticClass:
                        "o-bg o-bg--beige-light c-service-list-page__services__service",
                      attrs: { sm: 12, md: 8, offsetMd: 6 },
                    },
                    [
                      _c("PageListBlock", {
                        attrs: {
                          introHtml: category.intro,
                          label: category.label,
                          title: category.title,
                          teasers: category.children,
                          viewMode: "List",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.form && _vm.model.form.content) ||
          _vm.isEditable
            ? _c(
                "FormBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Form",
                        expression: "'Form'",
                      },
                    ],
                    staticClass: "c-service-list-page__form",
                    attrs: { id: "form" },
                  },
                  "FormBlock",
                  _vm.model.form.content,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.notEmptyObject(_vm.model.news) || _vm.isEditable
        ? _c(
            "Container",
            {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "News",
                  expression: "'News'",
                },
              ],
              attrs: { isFluid: "", background: "beige-light" },
            },
            [
              _c(
                "Container",
                [
                  _c(
                    "NewsBlock",
                    _vm._b(
                      {
                        attrs: {
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      },
                      "NewsBlock",
                      _vm.model.news && _vm.model.news.content,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }