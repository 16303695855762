<!-- Component used for making a column ignore container max-width. Only to be used as the leftmost or rightmost column -->
<template>
<div :is="wrapper" :class="['o-col o-col--breakout', computedClass, computedOffset, auto, equal, alignment, {'o-col--breakout__left' : breakoutSide === 'left'}]" class="o-col--breakout__wrapper" ref="column">
    <div class="o-col--breakout__content" v-if="$screen.breakpoint === 'xs'" :style="styleXs">
        <slot></slot>
    </div>
     <div class="o-col--breakout__content" v-if="$screen.breakpoint === 'sm'" :style="styleSm">
        <slot></slot>
    </div>
     <div class="o-col--breakout__content" v-if="$screen.breakpoint === 'md'" :style="styleMd">
        <slot></slot>
    </div>
     <div class="o-col--breakout__content" v-if="$screen.breakpoint === 'lg'" :style="styleLg">
        <slot></slot>
    </div>
</div>
</template>

<script>
import columnMixin from '@/Scripts/components/grid/columnMixin.js';

export default {
    mixins: [columnMixin],
    props: {
        size: Number,
        breakoutSide: {
            required: true,
            validator: function (value) {
                // The value must match one of these strings
                return ['left', 'right'].indexOf(value) !== -1;
            }
        }
    },
    data() {
        return {
            width: 0,
            containerXs: 0,
            containerSm: 0,
            containerMd: 0,
            containerLg: 0
        };
    },
    methods: {
        updateWidth() {
            this.width = this.$refs.column && this.$refs.column.clientWidth;
            this.containerXs = (this.width * 24 / (this.xs ? this.xs : 24));
            this.containerSm = (this.width * 24 / this.sm);
            this.containerMd = (this.width * 24 / this.md);
            this.containerLg = (this.width * 24 / this.lg);
        }
    },
    mounted() {
        this.updateWidth();

        window.addEventListener('resize', this.updateWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWidth);
    },
    computed: {
        styleXs() {
            return {
                width: `calc(${this.width}px + (100vw - ${this.containerXs}px)/2)`
            };
        },
        styleSm() {
            if (!this.sm) {
                return this.styleXs;
            }
            return {
                width: `calc(${this.width}px + (100vw - ${this.containerSm}px)/2)`
            };
        },
        styleMd() {
            if (!this.md) {
                return this.styleSm;
            }
            return {
                width: `calc(${this.width}px + (100vw - ${this.containerMd}px)/2)`
            };
        },
        styleLg() {
            if (!this.lg) {
                return this.styleMd;
            }
            return {
                width: `calc(${this.width}px + (100vw - ${this.containerLg}px)/2)`
            };
        },
    }
};
</script>

<style lang="scss">
.o-col--breakout {
    $self: &;
    img {
        min-width: 100%;
        display: block;
    }
    &__wrapper {
        #{$self}__content {
            max-width: none;
        }
    }
    &__left {
        #{$self}__content {
            float: right;
        }
    }
}
</style>
