var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-share-details" }, [
    _c("table", { staticClass: "c-share-details-table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("tr", { staticClass: "c-share-details-table-row" }, [
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.snapShot.item)),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.decimalFormatter(_vm.snapShot.change, 2))),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.decimalFormatter(_vm.snapShot.changePct, 2))),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.snapShot.bid)),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.snapShot.ask)),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.snapShot.high)),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.snapShot.low)),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.nFormatter(_vm.snapShot.marketCap, 2))),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "c-share-details-table-data" }, [
          _vm._v(_vm._s(_vm.snapShot.currency)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-share-details-table-mobile u-display-xs" }, [
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Ticker"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.snapShot.item))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Change (NOK)"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(_vm._s(_vm.decimalFormatter(_vm.snapShot.change, 2))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Change (%)"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(_vm._s(_vm.decimalFormatter(_vm.snapShot.changePct, 2))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Bid"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.snapShot.bid))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Ask"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.snapShot.ask))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("High"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.snapShot.high))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Low"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.snapShot.low))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Market Cap"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.nFormatter(_vm.snapShot.marketCap, 2)))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-share-details-table-mobile-item" }, [
        _c("div", { staticClass: "c-share-details-table-mobile-item-label" }, [
          _vm._v("Currency"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.snapShot.currency))]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "c-share-details-table-row" }, [
      _c("th", [_vm._v("Ticker")]),
      _vm._v(" "),
      _c("th", [_vm._v("Change (NOK)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Change (%)")]),
      _vm._v(" "),
      _c("th", [_vm._v("Bid")]),
      _vm._v(" "),
      _c("th", [_vm._v("Ask")]),
      _vm._v(" "),
      _c("th", [_vm._v("High")]),
      _vm._v(" "),
      _c("th", [_vm._v("Low")]),
      _vm._v(" "),
      _c("th", [_vm._v("Market Cap")]),
      _vm._v(" "),
      _c("th", [_vm._v("Currency")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }