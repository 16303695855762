<template>
<Row class="c-file-list-block">
    <Column :md="10">
        <Heading :level="3" noMarginTop>{{title}}</Heading>
    </Column>
    <Column :md="14"/>
    <Column :md="10" :offsetMd="index % 2 === 0 ? 0 : 4" v-for="(item, index) in files" :key="index" class="c-file-list-block__item-wrapper">
        <Anchor :href="item.url" :download="item.name">
            <Row>
                <Column :xs="20">
                    <span class="o-ingress">{{item.name}}</span>
                </Column>
                <Column :xs="4" class="c-list__icon-wrapper">
                    <Icon icon="arrow_down" class="c-file-list-block__icon"/>
                </Column>
            </Row>
        </Anchor>
    </Column>
</Row>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Collapse from '@/Scripts/components/molecules/Collapse.vue';
import FileListItem from '@/Scripts/components/molecules/FileListItem.vue';
import List from '@/Scripts/components/molecules/List.vue';

export default {
    props: {
        title: String,
        files: Array
    },
    components: {
        Icon,
        Collapse,
        FileListItem,
        List
    }
};
</script>

<style lang="scss">
.c-file-list-block {
    &__item-wrapper {
        border-bottom: 1px solid $black;
        padding-top: 1em;
        padding-bottom: 1em;
        margin-bottom: 1px;

        &:nth-of-type(-n+3) {
            border-top: 1px solid $black;
        }
        @include media-md {
            &:nth-of-type(-n+4) {
                border-top: 1px solid $black;
            }
        }
    }
    &__icon {
        height: 2em;
        width: 2em;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
}
</style>
