/**
 * Registers all of the content components representing pages and blocks. They
 * need to be globally registered because they are dynamically rendered by the
 * component selectors (`PageComponentSelector` and `BlockComponentSelector`).
 */
import '@/Styles/main.scss';
import Vue from 'vue';
import mixins from '@/Scripts/mixins.js';
import pageMixin from '@/Scripts/components/pages/pageMixin.js';
import router from '@/Scripts/router';
import store from '@/Scripts/store';
import { sync } from 'vuex-router-sync';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import vueScrollto from 'vue-scrollto';
import VueScreen from 'vue-screen';


sync(store, router);

// `epiMessages` does not export anything but registers the `beta/contentSaved`
// and `beta/epiReady` message handlers that updates the vuex store.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#Import_a_module_for_its_side_effects_only
import '@/Scripts/epiMessages';

// generate svg sprite from all files in /Assets/Images/SVG
const files = require.context('@/Images/svg-sprites', true, /.*\.svg$/);
files.keys().forEach(files);

// Episerver helpers
import EpiEdit from '@/Scripts/directives/epiEdit';
Vue.directive('epi-edit', EpiEdit);

// Components
import Container from '@/Scripts/components/grid/Container.vue';
import Row from '@/Scripts/components/grid/Row.vue';
import Column from '@/Scripts/components/grid/Column.vue';
import BreakoutColumn from '@/Scripts/components/grid/BreakoutColumn.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Button from '@/Scripts/components/atoms/Button.vue';
import ResponsiveImage from '@/Scripts/components/atoms/ResponsiveImage.vue';
import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';
import Loader from '@/Scripts/components/atoms/Loader.vue';

// Import all blocks
const requireComponent = require.context(
    // The relative path of the components folder
    '@/Scripts/components/blocks',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /[A-Z]\w+Block\.(vue|js)$/
);
// Register all blocks
requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);
    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
        // Gets the file name regardless of folder depth
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    );
    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});

// Import all pages
const pageComponents = require.context(
    // The relative path of the components folder
    '@/Scripts/components/pages',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /[A-Z]\w+Page\.(vue|js)$/
);
// Register all pages
pageComponents.keys().forEach(fileName => {
    // Get component config
    const componentConfig = pageComponents(fileName);
    // Get PascalCase name of component
    const componentName = upperFirst(
        // Gets the file name regardless of folder depth
        fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
    );
    componentConfig.default.mixins = [...componentConfig.default.mixins || [], pageMixin];
    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});

// Views
import Preview from '@/Scripts/components/Preview.vue';
import DefaultPage from '@/Scripts/components/layouts/DefaultPage.vue';
// Templates
import DefaultLayout from '@/Scripts/components/layouts/DefaultLayout.vue';
import InvestorRelationsLayout from '@/Scripts/components/layouts/InvestorRelationsLayout.vue';

// Components
Vue.component('Container', Container);
Vue.component('Row', Row);
Vue.component('Column', Column);
Vue.component('BreakoutColumn', BreakoutColumn);
Vue.component('Heading', Heading);
Vue.component('Anchor', Anchor);
Vue.component('Button', Button);
Vue.component('ResponsiveImage', ResponsiveImage);
Vue.component('XhtmlField', XhtmlField);
Vue.component('LoaderComponent', Loader);

// Views
Vue.component('Preview', Preview);
Vue.component('DefaultPage', DefaultPage);
// Layout templates
Vue.component('DefaultLayout', DefaultLayout);
Vue.component('InvestorRelationsLayout', InvestorRelationsLayout);

// Globally available methods and props
Vue.mixin(mixins);

Vue.use(vueScrollto);
Vue.use(VueScreen, {
    xs: 0,
    sm: 688, // will be converted to 480px
    md: 1194,
    lg: 1398,
});

Vue.directive('spacing', (el, binding) => {
    if (binding.value.xs) {
        el.classList.add(`o-spacing--${binding.value.xs}@xs`);
    }
    if (binding.value.sm) {
        el.classList.add(`o-spacing--${binding.value.sm}@sm`);
    }
    if (binding.value.md) {
        el.classList.add(`o-spacing--${binding.value.md}@md`);
    }
    if (binding.value.lg) {
        el.classList.add(`o-spacing--${binding.value.lg}@lg`);
    }
});

/* eslint-disable-next-line no-unused-vars */
let App = new Vue({
    el: '#App',
    store,
    router,
});
