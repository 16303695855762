<template>
<main class="c-ir-agm-page">
    <Container>
        <Row class="c-ir-agm-page__top">
            <Column>
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
            <Column :md="10">
                <Heading :level="4" noMarginTop v-epi-edit="'Title'">{{model.title}}</Heading>
            </Column>
            <Column :md="10" :offsetMd="4">
                <XhtmlField :items="model.intro" v-epi-edit="'Intro'"/>
            </Column>
        </Row>
        <FileListBlock v-bind="model.fileListBlock.content" v-epi-edit="'FileListBlock'" class="o-bg o-bg--beige-light c-ir-agm-page__files"/>
    </Container>
</main>
</template>

<script>
export default {
    props: {
        model: Object
    },
    components: {}
};
</script>

<style lang="scss">
.c-ir-agm-page {
    &__top {
        margin-bottom: 2rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
    &__files {
        padding-top: 3rem;
        padding-bottom: 3rem;
        margin-bottom: 1rem;

        @include media-md {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
}
</style>
