<template>
<div class="c-subscription">
    <h4>{{heading}}</h4>
    <form @submit.prevent="submitForm" class="form" method="post" name="PageForm" action="https://publish.ne.cision.com/Subscription/SubscribeWithCaptcha">
        <input type="hidden" name="subscriptionUniqueIdentifier" value="53ebfd8bb5" />
        <input type="hidden" name="Replylanguage" value="en" />
        <input type="hidden" name="regulatoryOption" value="all" />
        <input class="input textarea c-subscription-item" type="text" name="Name" placeholder="Your name">
        <br/>
        <input class="input textarea c-subscription-item" type="text" name="Email" placeholder="Your e-mail">
        <br/>
        <div class="g-recaptcha" data-sitekey="6LcqjxwiAAAAAPIRQJ3pZIBq5dzADjVbD3juhRls" data-size="normal" data-theme="light"></div>
        <Button :disabled="submitButtonDisabled" type="submit" value="Subscribe" >Subscribe</Button>
        <div v-if="statusSubscribedMessage">
            <div v-if="subscribeIsSuccess">
                <p class="c-subscription__statusTextSuccess">{{statusSubscribedMessage}}</p>
            </div>
            <div v-else>
                <p class="c-subscription__statusTextError">{{statusSubscribedMessage}}</p>
            </div>
        </div>
    </form>
</div>
</template>

<script>
import Button from '@/Scripts/components/atoms/Button.vue';
export default {
    props: ['heading'],
    data: function () {
        return {
            statusSubscribedMessage: '',
            subscribeIsSuccess: false,
            submitButtonDisabled: false,
        };
    },
    components: {
        Button
    },
    mounted() {
        //adding recaptcha script
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
        document.head.appendChild(recaptchaScript);
    },
    methods:
    {
        hideStatusMessageafterDelay() {
            let self = this;
            setTimeout(() => {
                self.statusSubscribedMessage = '';
            }, 8000);
        },
        disableSubmitButton() {
            this.submitButtonDisabled = true;
        },
        submitForm() {
            let form = document.forms.PageForm;
            let formData = new FormData(form);
            let xhr = new XMLHttpRequest();
            const self = this;
            xhr.open('POST', 'https://publish.ne.cision.com/Subscription/SubscribeWithCaptcha', true);
            xhr.onload = function () {
                if (xhr.status === 200) {
                    self.statusSubscribedMessage = 'Succsess! You will recive an email to verify your email address and activate the subscription.';
                    self.subscribeIsSuccess = true;
                    self.disableSubmitButton();
                } else {
                    self.statusSubscribedMessage = 'Error! Something went wrong';
                    self.subscribeIsSuccess = false;
                }
            };
            xhr.send(formData);
            this.hideStatusMessageafterDelay();
        }
    }

};
</script>

<style lang="scss">
.c-subscription {
        &-item {
            margin-bottom: 1em;
        }
        &__statusTextSuccess {
            color: green;
        }
        &__statusTextError {
            color: $orange;
        }
    }
</style>
