<template>
<Row class="c-product-list">
    <Column v-if="title">
        <Heading class="u-add-colored-period u-no-margin-top">{{title}}</Heading>
    </Column>

    <Column>
        <Row v-if="products" class="c-product-list__row">
            <Column :md="md" :sm="12" v-for="(category, index) in products" class="c-product-list__category" :key="`category_item${_uid}${index}`">
                <Heading isCategory class="c-product-list__category__title">{{category.title}}</Heading>
                <List :items="category.children"/>
            </Column>
        </Row>
    </Column>
</Row>
</template>

<script>
import List from '@/Scripts/components/molecules/List.vue';

export default {
    props: {
        title: String,
        products: Array,
        md: {
            type: Number,
            default: 6 // eslint-disable-line
        }
    },
    components: {
        List
    }
};
</script>

<style lang="scss">
.c-product-list {
    & &__category {
        margin-bottom: 3rem;

        &__title {
            margin-bottom: 1em;
        }
        @include media-sm {
            margin-bottom: 0;
            padding-right: 1rem;

            &:not(:nth-last-child(-n+2)) {
                margin-bottom: 3rem;
            }
        }
        @include media-md {
            margin-bottom: 0;
            padding-right: 4rem;

            &:not(:nth-last-child(-n+4)) {
                margin-bottom: 5rem;
            }
        }
    }
}
</style>
