var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-trade-item" }, [
    _c("div", { staticClass: "c-trade-item-left" }, [
      _vm._v(_vm._s(_vm.formatTime(new Date(_vm.trade.time)))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-trade-item-right" }, [
      _vm._v(_vm._s(_vm.trade.price.toFixed(2))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "c-trade-item-right" }, [
      _vm._v(_vm._s(_vm.trade.volume)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }