<template>
<main class="c-our-platform-page">
    <Container v-epi-edit="'SoftwarePartner'" isFluid class="c-our-platform-page__other-services" v-if="model.categories.length > 0 || isEditable">
        <Container class="u-hidden-xs u-hidden-sm c-our-platform-page__other-services__wrapper" v-if="model.categories.length > 0">
            <span v-for="(item, index) in model.categories[0].children" :key="`${_uid}${index}`" class="c-our-platform-page__other-services__service">
                <Anchor :href="item.url">{{item.title}}</Anchor>
            </span>
        </Container>
        <Dropdown class="u-display-xs u-display-sm" v-model="dropdownSelection" @input="changeRoute" :options="dropdownValues" :clearable="false"/>
    </Container>
    <Container class="c-our-platform-page__content">
        <Row v-epi-edit="'ImageRef'" v-if="model.image || isEditable" class="c-our-platform-page__media">
            <ResponsiveImage :src="model.image" />
        </Row>
        <BubblesBlock class="c-our-platform-page__bubbles" v-if="notEmptyObject(model.keyFeatures.content) || isEditable" v-bind="model.keyFeatures.content" v-epi-edit="'KeyFeatures'"/>
        <TwoColumnBlock v-epi-edit="'TwoColBlock'" v-if="notEmptyObject(model.twoColBlock && model.twoColBlock.content) || isEditable" class="c-our-platform-page__two-column" v-bind="model.twoColBlock.content"/>
        <TwoColumnBlock v-epi-edit="'ModulesTwoColBlock'" v-if="notEmptyObject(model.modulesTwoColBlock && model.modulesTwoColBlock.content) || isEditable" class="c-our-platform-page__modules c-our-platform-page__modules__two-column" v-bind="model.modulesTwoColBlock.content"/>
        <PageListBlock v-epi-edit="'PageSlider'" v-bind="model.pageSlider && model.pageSlider.content" class="c-our-platform-page__slider"/>
    </Container>
</main>
</template>

<script>
import ResponsiveImage from '../atoms/ResponsiveImage.vue';
import { mapState } from 'vuex';

export default {
    props: {
        model: Object
    },
    components: {
        ResponsiveImage
    },
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    }),
};
</script>

<style lang="scss">
.c-our-platform-page {
    &__top, &__media, &__two-column, &__bubbles, &__modules {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }

    &__modules {
            padding-top: 3rem;

        &__two-column{
            background: $beige;

            .o-heading {
                color: $orange;
            }
        }
    }

    &__other-services {
        background-color: $beige_light;

        &__wrapper {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
        &__service {
            padding: 1rem;
            display: inline-block;

            a {
                color: $gray;
                text-decoration: none;

                &:hover, &.router-link-exact-active.router-link-active {
                    text-decoration: none;
                    position: relative;

                    &:before, &:after {
                        content: "";
                        height: 8px;
                        width: 8px;
                        border-radius: 50%;
                        background-color: $orange;
                        position: absolute;
                        top: 40%;
                    }
                    &:before {
                        left: -15px;
                    }
                    &:after {
                        right: -15px;
                    }
                }
            }
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
        .vs__dropdown-toggle {
            border: none;
            padding-top: .5em;
            padding-bottom: .5em;
        }
    }
}
</style>
