<!-- A component used for preserving aspect ratio for content. By default it will preserve 1:1 ratio -->
<template>
<div class="c-aspect-box" :style="style">
    <slot/>
</div>
</template>

<script>
export default {
    props: {
        height: {
            type: Number,
            default: 100 // eslint-disable-line
        },
        width: {
            type: Number,
            default: 100 // eslint-disable-line
        }
    },
    computed: {
        style() {
            return {
                paddingTop: `${this.height / this.width * 100}%`
            };
        }
    }
};
</script>

<style lang="scss">
.c-aspect-box {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    background: white;
    position: relative;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
