<template>
<div :is="isCategory ? 'div' : `h${level}`" class="o-heading"
     :class="{'o-heading--category' : isCategory, 'u-no-margin-bottom' : noMarginBottom, 'u-no-margin-top' : noMarginTop}">
    <slot/>
</div>
</template>

<script>
export default {
    props: {
        level: {
            type: Number,
            default: 1 // eslint-disable-line
        },
        isCategory: {
            type: Boolean
        },
        noMarginTop: {
            type: Boolean
        },
        noMarginBottom: {
            type: Boolean
        }
    }
};
</script>
