var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-software-partner-row" },
    [
      _vm._l(_vm.partners, function (partner, index) {
        return _c("Column", { key: index, attrs: { sm: 11, md: 6 } }, [
          _c(
            "div",
            {
              staticClass: "c-software-partner-row__partner",
              on: {
                click: function ($event) {
                  return _vm.partnerSelected(partner, index)
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "c-software-partner-row__partner__name o-ingress",
                },
                [_vm._v(_vm._s(partner.label))]
              ),
              _vm._v(" "),
              _c("Icon", {
                staticClass: "c-software-partner-row__partner__icon",
                attrs: {
                  icon:
                    _vm.selected &&
                    _vm.selected.row == _vm.currentRow &&
                    _vm.selected.index === index
                      ? "chevron_down"
                      : "chevron_right",
                },
              }),
            ],
            1
          ),
        ])
      }),
      _vm._v(" "),
      _vm.selected && _vm.selected.row == _vm.currentRow
        ? _c("Column", [
            _c(
              "div",
              { staticClass: "c-software-partner-row__content" },
              [
                _c("Icon", {
                  staticClass:
                    "c-software-partner-row__content__cross u-hidden-xs",
                  attrs: { icon: "cross" },
                  on: {
                    click: function ($event) {
                      return _vm.deselect()
                    },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.deselect()
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "o-ingress c-software-partner-row__content__name",
                  },
                  [_vm._v(_vm._s(_vm.selected.partner.label))]
                ),
                _vm._v(" "),
                _c("XhtmlField", {
                  staticClass: "c-software-partner-row__intro",
                  attrs: { items: _vm.selected.partner.intro },
                }),
                _vm._v(" "),
                _c(
                  "Anchor",
                  {
                    staticClass: "c-software-partner-row__content__anchor",
                    attrs: { isFancy: "", href: _vm.selected.partner.url },
                  },
                  [_vm._v(_vm._s(_vm.getLabel("global", "readMore")))]
                ),
                _vm._v(" "),
                _c("ProductList", {
                  attrs: { products: _vm.selected.partner.categories, md: 8 },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }