var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-gated-content-page" },
    [
      _c(
        "Container",
        [
          _c("row", [
            _c(
              "div",
              { staticClass: "o-col col-xs-24 col-md-14 col-md-offset-4" },
              [
                _c("h2", { staticClass: "c-gated-content-page__top" }, [
                  _vm._v("Please enter your name to see this content"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputName,
                        expression: "inputName",
                      },
                    ],
                    staticClass: "c-gated-content-page__centered-item",
                    attrs: { placeholder: "name" },
                    domProps: { value: _vm.inputName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.inputName = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "Button",
                      {
                        staticClass: "c-gated-content-page__centered-item",
                        attrs: { isLarge: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.buttonClicked.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Submit")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }