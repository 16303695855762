<template>
<div class="c-financial-calendar">
    <div class="c-financial-calendar-headers">
        <div>Date</div>
        <div>Headline</div>
   </div>
   <div v-for="(item, index) in calendar" :key="index">
            <CalendarItem :calendarItem="item" />
    </div>
    <div v-if="seeAllUrl" class="c-financial-calendar-link">
        <a :href="seeAllUrl">{{seeAllLabel}}</a>
    </div>
</div>
</template>

<script>

import CalendarItem from '@/Scripts/components/atoms/CalendarItem.vue';

export default {
    props: {
        calendar: {
            type: Array,
            required: true,
        },
        seeAllLabel: {
            type: String,
            required: false
        },
        seeAllUrl: {
            type: String,
            required: false
        }
    },
    components: {
        CalendarItem,
    },
};
</script>

<style lang="scss">
.c-financial-calendar-headers{
    display: flex;
    flex-direction: row;
    padding: 12px;
    border-bottom: 2px solid black;
}
.c-financial-calendar > div{
    width: 100%;
    text-align: left;
}
.c-financial-calendar-headers > div{
    width: 100%;
    text-align: left;
}
.c-financial-calendar-headers > div:first-child{
    width: 30%;
}
.c-financial-calendar-link{
    margin-top: 2em;
}


</style>
