<template>
<main class="c-gated-content-page">
    <Container>
        <row>
            <div class="o-col col-xs-24 col-md-14  col-md-offset-4">
                <h2 class="c-gated-content-page__top">Please enter your name to see this content</h2>
                <div>
                    <input class="c-gated-content-page__centered-item" v-model="inputName" placeholder="name">
                </div>
                <div>
                    <Button class="c-gated-content-page__centered-item" isLarge v-on:click.native="buttonClicked">Submit</Button>
                </div>
            </div>
        </row>
    </Container>
</main>
</template>

<script>
import Button from '@/Scripts/components/atoms/Button.vue';
import api from '@/Scripts/api/api';

export default {
    props: ['pageId', 'form'],
    components: {
        Button
    },
    data: function () {
        return {
            inputName: ''
        };
    },
    methods: {
        buttonClicked: function (e) {
            let params = {
                name: this.inputName,
                pageId: this.pageId
            };
            api.getGatedValidation(params).then(this.$emit('validated'));
        }
    }
};
</script>

<style lang="scss">
.c-gated-content-page {
    &__top {
        text-align: center;
    }
    &__centered-item {
        margin: 0px auto 50px auto;
        display: block;
    }
}
</style>
