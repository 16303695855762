<template>
<div class="c-trading-activity">
    <div class="c-trading-activity-order-depth">
        <div class="c-trading-activity-heading">
            <Heading :level="5">{{'Order Depth '}}</Heading>
        </div>
        <OrderItems :orders="orders"/>
    </div>
    <div class="c-trading-activity-latest-trades">
        <div class="c-trading-activity-heading">
            <Heading :level="5">{{'Latest Trades '}}</Heading>
        </div>
        <LatestTrades :trades="latestTrades" :seeAllLabel="seeAllLabel" :seeAllUrl="seeAllUrl"/>
    </div>
</div>
</template>

<script>

import OrderItems from '@/Scripts/components/molecules/InvestorRelations/OrderItems.vue';
import LatestTrades from '@/Scripts/components/molecules/InvestorRelations/LatestTrades.vue';

export default {
    props: {
        orders: {
            type: Array,
        },
        seeAllLabel: {
            type: String,
            required: false
        },
        seeAllUrl: {
            type: String,
            required: false
        },
        latestTrades: {
            type: Array,
        }
    },
    components: {
        OrderItems,
        LatestTrades
    }
};
</script>

<style lang="scss">
.c-trading-activity {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom:5em;
        @include media-sm{
            flex-direction: row;
        }
}
.c-trading-activity-heading {
    width:100%;
}
.c-trading-activity-order-depth{
    width:100%;
    margin-bottom: 2em;
    @include media-sm{
        padding-right: 18px;
        width: 50%
    }
}
.c-trading-activity-latest-trades{
    width:100%;
    @include media-sm{
        padding-left: 18px;
        width: 50%;
    }
}
.c-trading-activity-main-info {
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>
