var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "event-page" },
    [
      _vm.showHeaderFooter
        ? _c(
            "Header",
            _vm._b(
              {
                attrs: {
                  isWhite: _vm.isStartPage,
                  isOverlapping: _vm.isStartPage,
                  showBreadcrumbs: !_vm.isStartPage,
                  isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
                  isMenuOpen: _vm.isMenuOpen,
                  currentLanguage: _vm.currentLanguage,
                },
              },
              "Header",
              _vm.model.header,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.model.heroImage && _vm.model.heroImage.url
        ? _c("Hero", {
            attrs: {
              buttonText: _vm.model.heroLinkText,
              title: _vm.model.title,
              subtitle: _vm.model.heroSubtitle,
              heroImage: _vm.model.heroImage && _vm.model.heroImage.url,
              overlay: _vm.model.heroOverlay,
              url: _vm.model.heroLink,
              changeTitleColor: _vm.model.changeTitleColor,
              changeLinkColor: _vm.model.changeLinkColor,
              url2: _vm.model.heroLink2,
              buttonText2: _vm.model.heroLinkText2,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.model.topContentArea || _vm.isEditable
            ? _c("ContentArea", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Blocks",
                    expression: "'Blocks'",
                  },
                ],
                staticClass: "c-event-page__content-area",
                attrs: { model: _vm.model.topContentArea || _vm.isEditable },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "event-blocks" },
        [_c("EventProgramBlock", { attrs: { model: _vm.model.eventDays } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "speaker-list container" },
        [
          _c("SpeakerList", {
            attrs: {
              speakers: _vm.model.speakersList,
              heading: _vm.model.speakersHeading,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.mainContentArea || _vm.isEditable
        ? _c("ContentArea", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "Blocks",
                expression: "'Blocks'",
              },
            ],
            staticClass: "c-event-page__content-area",
            attrs: { model: _vm.model.mainContentArea || _vm.isEditable },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.model.showHeaderFooter
        ? _c(
            "Footer",
            _vm._b(
              {},
              "Footer",
              {
                ..._vm.model.footer,
                headerTop: {
                  isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
                  isMenuOpen: _vm.isMenuOpen,
                  currentLanguage: _vm.currentLanguage,
                  searchPage: _vm.searchPage,
                  loginPage: _vm.loginPage,
                },
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }