// TODO: Fix form
<template>
<main class="c-campaign-page">
    <Container>
        <Row class="c-campaign-page__top">
            <Column v-epi-edit="'Label'" v-if="model.label || isEditable"><Heading isCategory>{{model.label}}</Heading></Column>
            <Column v-epi-edit="'Title'" v-if="model.title || isEditable" :md="12"><Heading :level="1" noMarginTop noMarginBottom>{{model.title}}</Heading></Column>
            <Column :md="10" :offsetMd="2">
                <XhtmlField v-epi-edit="'IntroText'" class="o-ingress" v-if="model.introText || isEditable" :items="model.introText"/>
                <Button v-if="notEmptyObject(model.form && model.form.content)" v-scroll-to="'#form'">{{model.buttonText ? model.buttonText : getLabel('campaignPage', 'topButtonText')}}</Button>
            </Column>
        </Row>
        <MediaBlock v-epi-edit="'Media'" v-if="notEmptyObject(model.media && model.media.content) || isEditable" class="c-campaign-page__media" v-bind="model.media.content"/>
        <TwoColumnBlock v-epi-edit="'TwoColumnBlock'" v-if="notEmptyObject(model.twoColumnBlock && model.twoColumnBlock.content) || isEditable" class="c-campaign-page__two-column" v-bind="model.twoColumnBlock.content"/>
        <FileDownloadBlock v-epi-edit="'File'" class="c-campaign-page__file-download" v-if="notEmptyObject(model.file && model.file.content) || isEditable" v-bind="model.file.content"/>
        <FormBlock v-epi-edit="'Form'" id="form" class="c-campaign-page__form" v-if="notEmptyObject(model.form && model.form.content) || isEditable" v-bind="model.form.content"/>
        <ContentArea v-epi-edit="'Blocks'" v-if="model.blocks || isEditable" class="c-campaign-page__content-area" :model="model.blocks  || isEditable"/>
    </Container>
</main>
</template>

<script>
import ContentArea from '@/Scripts/components/ContentArea.vue';
import { mapState } from 'vuex';

export default {
    props: {
        model: Object
    },
    components: {
        ContentArea
    },
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    })
};
</script>

<style lang="scss">
.c-campaign-page {
    &__top, &__media, &__two-column, &__file-download, &__content-area {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
