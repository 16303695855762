var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-product-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            { staticClass: "c-product-page__intro" },
            [
              _c(
                "Column",
                { attrs: { md: 11 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "title",
                          expression: "'title'",
                        },
                      ],
                      staticClass: "c-product-page__title",
                      attrs: { noMarginTop: "", level: 1 },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "LargeIntroText",
                          expression: "'LargeIntroText'",
                        },
                      ],
                      attrs: { noMarginTop: "", level: 4 },
                    },
                    [
                      _c("XhtmlField", {
                        attrs: { items: _vm.model.largeIntroText },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                {
                  staticClass: "c-product-page__left-intro",
                  attrs: { md: 11, "offset-md": 2 },
                },
                [
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "SmallIntroText",
                        expression: "'SmallIntroText'",
                      },
                    ],
                    staticClass: "o-ingress",
                    attrs: { items: _vm.model.smallIntroText },
                  }),
                  _vm._v(" "),
                  _vm.notEmptyObject(_vm.model.form && _vm.model.form.content)
                    ? _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "scroll-to",
                              rawName: "v-scroll-to",
                              value: "#form",
                              expression: "'#form'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getLabel("productPage", "topBannerButtonText")
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.model.media.content || _vm.isEditable
            ? _c(
                "MediaBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Media",
                        expression: "'Media'",
                      },
                    ],
                    staticClass: "c-product-page__media",
                  },
                  "MediaBlock",
                  _vm.model.media.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "TwoColumnBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "TwoColumnBlock",
                    expression: "'TwoColumnBlock'",
                  },
                ],
                staticClass: "c-product-page__body1",
              },
              "TwoColumnBlock",
              _vm.model.twoColumnBlock.content,
              false
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.relatedServices1
        ? _c(
            "Container",
            { attrs: { isFluid: "", background: "beige-light" } },
            [
              _c(
                "Container",
                [
                  _c(
                    "Heading",
                    {
                      staticClass: "u-add-colored-period",
                      attrs: { level: 2, noMarginTop: "" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.getLabel("productPage", "relatedServicesText")
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "Row",
                    [
                      _c(
                        "Column",
                        { attrs: { md: 10 } },
                        [
                          _c("List", {
                            attrs: { items: _vm.relatedServices1 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "Heading",
                                        { attrs: { isCategory: "" } },
                                        [_vm._v(_vm._s(item.label))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "Heading",
                                        {
                                          attrs: { noMarginTop: "", level: 6 },
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-clamp",
                                        {
                                          attrs: {
                                            autoresize: "",
                                            "max-lines": 2,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.intro))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              206974929
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "Column",
                        { attrs: { md: 10, offsetMd: 4 } },
                        [
                          _c("List", {
                            attrs: { items: _vm.relatedServices2 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "Heading",
                                        { attrs: { isCategory: "" } },
                                        [_vm._v(_vm._s(item.label))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "Heading",
                                        {
                                          attrs: { noMarginTop: "", level: 6 },
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-clamp",
                                        {
                                          attrs: {
                                            autoresize: "",
                                            "max-lines": 2,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.intro))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              206974929
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _c(
            "TwoColumnBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "SecondTwoColumnBlock",
                    expression: "'SecondTwoColumnBlock'",
                  },
                ],
                staticClass: "c-product-page__body2",
              },
              "TwoColumnBlock",
              _vm.model.secondTwoColumnBlock.content,
              false
            )
          ),
          _vm._v(" "),
          _vm.model.blocks || _vm.isEditable
            ? _c("ContentArea", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Blocks",
                    expression: "'Blocks'",
                  },
                ],
                staticClass: "c-product-page__content-area",
                attrs: { model: _vm.model.blocks || _vm.isEditable },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Container",
        { attrs: { isFluid: "", background: "beige-light", marginHack: "" } },
        [
          _c(
            "Container",
            [
              _vm.notEmptyObject(_vm.model.form && _vm.model.form.content) ||
              _vm.isEditable
                ? _c(
                    "FormBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Form",
                            expression: "'Form'",
                          },
                        ],
                        staticClass: "c-product-page__form",
                        attrs: { id: "form" },
                      },
                      "FormBlock",
                      _vm.model.form.content,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.relatedProducts && _vm.model.relatedProducts.length > 0
        ? _c(
            "Container",
            { staticClass: "c-product-page__other-products" },
            [
              _c("ProductList", {
                attrs: {
                  products: _vm.model.relatedProducts,
                  title: `${_vm.getLabel("productPage", "otherProducts1")} ${
                    _vm.model.label
                  } ${_vm.getLabel("productPage", "otherProducts2")}`,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.model.newsBlock.content || _vm.isEditable
        ? _c(
            "Container",
            { attrs: { isFluid: "", background: "beige-light" } },
            [
              _c(
                "Container",
                [
                  _c(
                    "NewsBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "NewsBlock",
                            expression: "'NewsBlock'",
                          },
                        ],
                        attrs: {
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      },
                      "NewsBlock",
                      _vm.model.newsBlock.content,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }