/**
 * The module responsible for handling app-wide context state that is
 * interesting for several components that otherwise doesn't share state.
 */

//mutations for the appContext module
export const SHOW_MODAL = 'appContext/SHOW_MODAL';
export const HIDE_MODAL = 'appContext/HIDE_MODAL';

export const TOGGLE_LANGUAGESELECTOR = 'appContext/TOGGLE_LANGUAGESELECTOR';
export const TOGGLE_MENU = 'appContext/TOGGLE_MENU';
export const CLOSE_MENU = 'appContext/CLOSE_MENU';

export const DISABLE_SCROLLING = 'appContext/DISABLE_SCROLLING';
export const ENABLE_SCROLLING = 'appContext/ENABLE_SCROLLING';

export const RELOADPAGE = 'appContext/RELOADPAGE';
export const RESET_RELOADPAGE = 'appContext/RESET_RELOADPAGE';

function unflatten(data) {
    var result = {};
    for (var i in data) {
        var keys = i.split('.');
        keys.reduce((r, e, j) => {
            return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? data[i] : {}) : []);
        }, result);
    }
    return result;
}

const state = {
    isBodyScrollable: true,
    isLanguageSelectorOpen: false,
    isMenuOpen: false,
    modalShowing: false,
    labels: unflatten(window.LANGUAGESTRINGS),
    reloadFor: location.pathname,
    lastMoveFrom: '',
    lastMoveTo: ''
};

const mutations = {
    [SHOW_MODAL](state) {
        state.modalShowing = true;
    },
    [HIDE_MODAL](state) {
        state.modalShowing = false;
    },
    [TOGGLE_LANGUAGESELECTOR](state) {
        state.isMenuOpen = false;
        state.isLanguageSelectorOpen = !state.isLanguageSelectorOpen;
    },
    [TOGGLE_MENU](state) {
        state.isLanguageSelectorOpen = false;
        state.isMenuOpen = !state.isMenuOpen;
    },
    [CLOSE_MENU](state) {
        state.isMenuOpen = false;
    },
    [ENABLE_SCROLLING](state) {
        state.isBodyScrollable = true;
    },
    [DISABLE_SCROLLING](state) {
        state.isBodyScrollable = false;
    },
    //we need to reload the page when viewing forms, or microsoft dynamic thinks we are loading their scripts multiple times on the same page
    [RELOADPAGE](state) {
        if (location.pathname !== state.reloadFor) {
            state.reloadFor = location.pathname;
        }
    },
    //RELOADPAGE only works when going from formX => formY.
    //this resets it so it also works if the user goes from formX => other page => formX
    [RESET_RELOADPAGE](state, payload) {
        //avoids loop if apps first loaded page has a form
        if (payload.from === '/') {
            return;
        }
        if (payload.to !== state.lastMoveTo || payload.from !== state.lastMoveFrom) {
            state.lastMoveTo = payload.to;
            state.lastMoveFrom = payload.from;
            state.reloadFor = '';
        }
    }
};

export default {
    state,
    mutations
};
