var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["c-icon", _vm.className, `c-icon--${_vm.icon}`] },
    [
      _vm.url
        ? _c(
            "epi-link",
            {
              attrs: {
                href: _vm.url,
                target: _vm.target,
                title: _vm.title,
                "aria-label": _vm.ariaLabel,
              },
            },
            [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": _vm.computedIcon } }),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.url
        ? _c("svg", [_c("use", { attrs: { "xlink:href": _vm.computedIcon } })])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }