var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-our-platform-page" },
    [
      _vm.model.categories.length > 0 || _vm.isEditable
        ? _c(
            "Container",
            {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "SoftwarePartner",
                  expression: "'SoftwarePartner'",
                },
              ],
              staticClass: "c-our-platform-page__other-services",
              attrs: { isFluid: "" },
            },
            [
              _vm.model.categories.length > 0
                ? _c(
                    "Container",
                    {
                      staticClass:
                        "u-hidden-xs u-hidden-sm c-our-platform-page__other-services__wrapper",
                    },
                    _vm._l(
                      _vm.model.categories[0].children,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: `${_vm._uid}${index}`,
                            staticClass:
                              "c-our-platform-page__other-services__service",
                          },
                          [
                            _c("Anchor", { attrs: { href: item.url } }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("Dropdown", {
                staticClass: "u-display-xs u-display-sm",
                attrs: { options: _vm.dropdownValues, clearable: false },
                on: { input: _vm.changeRoute },
                model: {
                  value: _vm.dropdownSelection,
                  callback: function ($$v) {
                    _vm.dropdownSelection = $$v
                  },
                  expression: "dropdownSelection",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        { staticClass: "c-our-platform-page__content" },
        [
          _vm.model.image || _vm.isEditable
            ? _c(
                "Row",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "ImageRef",
                      expression: "'ImageRef'",
                    },
                  ],
                  staticClass: "c-our-platform-page__media",
                },
                [_c("ResponsiveImage", { attrs: { src: _vm.model.image } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.keyFeatures.content) || _vm.isEditable
            ? _c(
                "BubblesBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "KeyFeatures",
                        expression: "'KeyFeatures'",
                      },
                    ],
                    staticClass: "c-our-platform-page__bubbles",
                  },
                  "BubblesBlock",
                  _vm.model.keyFeatures.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(
            _vm.model.twoColBlock && _vm.model.twoColBlock.content
          ) || _vm.isEditable
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "TwoColBlock",
                        expression: "'TwoColBlock'",
                      },
                    ],
                    staticClass: "c-our-platform-page__two-column",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoColBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(
            _vm.model.modulesTwoColBlock && _vm.model.modulesTwoColBlock.content
          ) || _vm.isEditable
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "ModulesTwoColBlock",
                        expression: "'ModulesTwoColBlock'",
                      },
                    ],
                    staticClass:
                      "c-our-platform-page__modules c-our-platform-page__modules__two-column",
                  },
                  "TwoColumnBlock",
                  _vm.model.modulesTwoColBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "PageListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "PageSlider",
                    expression: "'PageSlider'",
                  },
                ],
                staticClass: "c-our-platform-page__slider",
              },
              "PageListBlock",
              _vm.model.pageSlider && _vm.model.pageSlider.content,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }