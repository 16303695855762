var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-news-component" },
    [
      _vm.viewMode === "Vertical"
        ? [
            _c(
              "VerticalItem",
              _vm._b(
                {
                  attrs: {
                    flip: _vm.flip,
                    published: _vm.model.published,
                    hideDate: _vm.model.hideDate,
                    useUsDateFormat: _vm.useUsDateFormat,
                  },
                },
                "VerticalItem",
                _vm.model,
                false
              )
            ),
          ]
        : _vm.viewMode === "FullSize"
        ? [
            _c(
              "FullSizeItem",
              _vm._b(
                {
                  attrs: {
                    published: _vm.model.published,
                    hideDate: _vm.model.hideDate,
                    useUsDateFormat: _vm.useUsDateFormat,
                  },
                },
                "FullSizeItem",
                _vm.model,
                false
              )
            ),
          ]
        : _vm.viewMode === "Compact"
        ? [
            _c(
              "NewsItem",
              _vm._b(
                {
                  attrs: {
                    showImage: false,
                    published: _vm.model.published,
                    hideDate: _vm.model.hideDate,
                    useUsDateFormat: _vm.useUsDateFormat,
                  },
                },
                "NewsItem",
                _vm.model,
                false
              )
            ),
          ]
        : [
            _c(
              "NewsItem",
              _vm._b(
                {
                  attrs: {
                    imageConfig: _vm.imageConfig,
                    published: _vm.model.published,
                    hideDate: _vm.model.hideDate,
                    useUsDateFormat: _vm.useUsDateFormat,
                  },
                },
                "NewsItem",
                _vm.model,
                false
              )
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }