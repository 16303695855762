var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-author" },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            [
              _c("Heading", { attrs: { isCategory: "" } }, [
                _vm._v(
                  _vm._s(
                    _vm.heading
                      ? _vm.heading
                      : _vm.getLabel("authorComponent", "writtenBy")
                  )
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { attrs: { xs: 6, sm: 4, md: 24 } },
            [
              _c("ResponsiveImage", {
                staticClass: "c-author__image",
                attrs: {
                  src: _vm.image,
                  xs: { w: 116 },
                  sm: { w: 116 },
                  md: { w: 116 },
                  lg: { w: 116 },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { attrs: { xs: 18, sm: 20, md: 24 } },
            [
              _c("div", [
                _c("span", { staticClass: "c-author__name" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "c-author__title" }, [
                  _vm._v(_vm._s(_vm.jobTitle)),
                ]),
              ]),
              _vm._v(" "),
              _vm.link && _vm.linkTitle
                ? _c(
                    "Anchor",
                    {
                      staticClass: "c-author__link",
                      attrs: {
                        href: _vm.link,
                        isExternal: this.isExternalURL(_vm.link),
                      },
                    },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(_vm.linkTitle) +
                          "\r\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }