<template>
<Anchor class="u-flex c-simple-link-block" :href="link" noStyle>
    <ResponsiveImage :src="image" class="c-simple-link-block__image"/>
    <div class="c-simple-link-block__text-wrapper">
        <span class="c-simple-link-block__label o-small-body">{{label}}</span>
        <p class="c-simple-link-block__title u-no-margins">{{title}}</p>
    </div>
</Anchor>
</template>

<script>
export default {
    props: {
        image: String,
        label: String,
        title: String,
        link: String,
    }
};
</script>

<style lang="scss">
.c-simple-link-block {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        background-color: $orange;
    }
    &__image {
        width: 135px;
        height: 115px;
        margin-left: 10px;
    }
    &__text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        width: 300px;
    }
    &__label {
        color: $gray;
    }
}
</style>
