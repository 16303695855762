var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "event-block" },
    _vm._l(_vm.model.eventRows, function (item, index) {
      return _c("div", { key: index }, [
        item.heading
          ? _c(
              "div",
              { staticClass: "event-block__headingrow" },
              [
                _c("XhtmlField", {
                  staticClass: "heading",
                  attrs: { items: item.text },
                }),
              ],
              1
            )
          : _c(
              "div",
              {
                class: item.break
                  ? "event-block__breakrow"
                  : "event-block__standardrow",
              },
              [
                _c("div", { staticClass: "event-block__time" }, [
                  _c("div", { staticClass: "time" }, [
                    _vm._v(_vm._s(item.time)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "event-block__text" },
                  [_c("XhtmlField", { attrs: { items: item.text } })],
                  1
                ),
              ]
            ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }