var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$route.query.id
    ? _c(
        "main",
        { staticClass: "c-career-page" },
        [
          _c(
            "Container",
            [
              _c(
                "Row",
                { staticClass: "c-career-page__top" },
                [
                  _c(
                    "Column",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                    },
                    [
                      _c("Heading", { attrs: { isCategory: "" } }, [
                        _vm._v(_vm._s(_vm.model.label)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "Column",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { md: 12 },
                    },
                    [
                      _c("Heading", { attrs: { level: 1, noMarginTop: "" } }, [
                        _vm._v(_vm._s(_vm.model.title)),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "Column",
                    { attrs: { md: 10, offsetMd: 2 } },
                    [
                      _vm.model.introText || _vm.isEditable
                        ? _c("XhtmlField", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "IntroText",
                                expression: "'IntroText'",
                              },
                            ],
                            staticClass: "o-ingress",
                            attrs: { items: _vm.model.introText },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.model.openPositionsUrl
                        ? _c(
                            "Button",
                            {
                              directives: [
                                {
                                  name: "scroll-to",
                                  rawName: "v-scroll-to",
                                  value: "#openpositions",
                                  expression: "'#openpositions'",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getLabel(
                                    "careerPage",
                                    "openPositionButtonText"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.model.openPositionsUrl
                        ? _c(
                            "Button",
                            { attrs: { href: _vm.model.openPositionsUrl } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getLabel(
                                    "careerPage",
                                    "openPositionButtonText"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "MediaBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Media",
                        expression: "'Media'",
                      },
                    ],
                    staticClass: "c-career-page__media",
                    attrs: { imageConfig: _vm.mediaImageConfig },
                  },
                  "MediaBlock",
                  _vm.model.media && _vm.model.media.content,
                  false
                )
              ),
              _vm._v(" "),
              _vm.notEmptyObject(_vm.model.quote && _vm.model.quote.content) ||
              _vm.isEditable
                ? _c(
                    "Row",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Quote",
                          expression: "'Quote'",
                        },
                      ],
                      staticClass: "c-career-page__quote",
                    },
                    [
                      _c(
                        "Column",
                        { attrs: { md: 12 } },
                        [
                          _c(
                            "QuoteBlock",
                            _vm._b(
                              {},
                              "QuoteBlock",
                              _vm.model.quote && _vm.model.quote.content,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.notEmptyObject(_vm.model.rightShiftedContentBlock) ||
              _vm.isEditable
                ? _c(
                    "RightShiftedContentBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "RightShiftedContentBlock",
                            expression: "'RightShiftedContentBlock'",
                          },
                        ],
                        staticClass: "c-career-page__content-block",
                      },
                      "RightShiftedContentBlock",
                      _vm.model.rightShiftedContentBlock,
                      false
                    )
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.notEmptyObject(
                _vm.model.twoColumnBlock && _vm.model.twoColumnBlock.content
              ) || _vm.isEditable
                ? _c(
                    "TwoColumnBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "TwoColumnBlock",
                            expression: "'TwoColumnBlock'",
                          },
                        ],
                        staticClass: "c-career-page__two-columns",
                      },
                      "TwoColumnBlock",
                      _vm.model.twoColumnBlock &&
                        _vm.model.twoColumnBlock.content,
                      false
                    )
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.notEmptyObject(
                _vm.model.employeesBlock && _vm.model.employeesBlock.content
              ) || _vm.isEditable
                ? _c(
                    "EmployeesBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "EmployeesBlock",
                            expression: "'EmployeesBlock'",
                          },
                        ],
                      },
                      "EmployeesBlock",
                      _vm.model.employeesBlock &&
                        _vm.model.employeesBlock.content,
                      false
                    )
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.blocks || _vm.isEditable
                ? _c("ContentArea", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Blocks",
                        expression: "'Blocks'",
                      },
                    ],
                    staticClass: "c-career-page__content-area",
                    attrs: { model: _vm.model.blocks || _vm.isEditable },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.model.openPositionsUrl
            ? _c(
                "Container",
                {
                  attrs: {
                    isFluid: "",
                    background: "beige-light",
                    id: "openpositions",
                  },
                },
                [
                  _c(
                    "Container",
                    [
                      _c(
                        "OpenPositionsBlock",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "OpenPositionsBlock",
                                expression: "'OpenPositionsBlock'",
                              },
                            ],
                            attrs: {
                              detailedBaseUrl:
                                _vm.model.siteSettings.careerPageLink,
                            },
                          },
                          "OpenPositionsBlock",
                          _vm.model.openPositionsBlock,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c("VacancyPage")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }