<template>
<div class="c-order-item">
    <div class="c-order-item-number">{{order.bidvol !== 0 ? order.bidvol : '-'}}</div>
    <div class="c-order-item-number">{{order.bid !== 0 ? order.bid.toFixed(2): '-'}}</div>
    <div class="c-order-item-depth-bar">
        <div class=" c-orde-item-depth-bar-placement c-orde-item-depth-bar-placement-bid">
            <div class="c-order-item-depth-bar-bid" v-bind:style="{ width: bidVolumePercentage + '%'}"></div>
        </div>
        <div class="c-orde-item-depth-bar-placement c-orde-item-depth-bar-placement-ask">
            <div class="c-order-item-depth-bar-ask" v-bind:style="{ width: askVolumePercentage + '%'}"></div>
        </div>
    </div>
    <div class="c-order-item-right c-order-item-number">{{order.ask !== 0 ? order.ask.toFixed(2): '-'}}</div>
    <div class="c-order-item-right c-order-item-number">{{order.askvol !== 0 ? order.askvol : '-'}} </div>
</div>
</template>

<script>

export default {
    data() {
        return {
            bidVolumePercentage: 50,
            askVolumePercentage: 50,
        };
    },
    props: {
        order: {
            type: Object,
        },
        maxVolume: {
            type: Number
        }
    },
    methods: {
        calculateOrderdepthBalance() {
            const askVolume = this.order.askvol;
            const bidVolume = this.order.bidvol;
            const referenceNumber = this.maxVolume;
            this.bidVolumePercentage = bidVolume / referenceNumber * 100;
            this.askVolumePercentage = askVolume / referenceNumber * 100;
        },

        bidStyle() {
            return {
                width: this.bidVolumePercentage + '%',
            };
        },
        askStyle() {
            return {
                width: this.askVolumePercentage + '%',
            };
        }
    },
    mounted() {
        this.calculateOrderdepthBalance();
    }
};
</script>

<style lang="scss" scoped>
.c-order-item{
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 align-items: center;
 border-bottom: 1px solid black;
 padding: 1em 0;
}
.c-order-item-right{
    text-align: right !important;
}
.c-order-item-depth-bar{
    display: flex;
    flex-grow: 1;
    padding: 0 0.25em 0 0.25em;
    flex-direction: row;
    justify-content: center;
}
.c-order-item-depth-bar-bid{
    background-color: #0b4657;
    margin-right: 2px;
    height: 12px;
    display:inline-block;
}
.c-order-item-depth-bar-ask{
    background-color: #ff694b;
    margin-left: 2px;
    height: 12px;
    display:inline-block;
}
.c-orde-item-depth-bar-placement{
    &-bid{
        text-align: right;
    }
    &-ask{
        text-align: left;
    }
    width:50%;
}
.c-order-item-number{
    width:80px;
    @media screen and ( max-width: 1200px) {
        width:50px;
    }
}

</style>
