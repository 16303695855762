<template>
<Row class="c-two-link-block">
    <Column :sm="12" :offsetMd="3" :md="8" class="c-two-link-block__item">
        <Anchor :href="url1.uri" target="_blank">
            <ResponsiveImage :src="image1" :xs="{w:345, h: 139}" :sm="{w:345, h: 139}" :md="{w:440, h: 190}" :lg="{w:440, h: 190}"/>
        </Anchor>
    </Column>
    <Column :sm="12" :offsetMd="2" :md="8" class="c-two-link-block__item">
        <Anchor :href="url2.uri" target="_blank">
            <ResponsiveImage :src="image2" :xs="{w:345, h: 139}" :sm="{w:345, h: 139}" :md="{w:440, h: 190}" :lg="{w:440, h: 190}"/>
        </Anchor>
    </Column>
</Row>
</template>

<script>
export default {
    props: {
        url1: Object,
        image1: String,
        url2: Object,
        image2: String
    }
};
</script>

<style lang="scss">
.c-two-link-block {
    &__item {
        &:not(:last-of-type) {
            margin-bottom: 3rem;

            @include media-sm {
                margin-bottom: 0;
            }
        }
    }
}
</style>
