var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Container", [
    _c(
      "div",
      { staticClass: "practicearea--header" },
      [
        _vm.model.illustration > -1
          ? _c(
              "Column",
              { staticClass: "practicearea--header__illustration" },
              [
                _c("img", {
                  staticClass: "practicearea--header__illustration__img",
                  attrs: {
                    src:
                      "/Assets/Images/practiceareas/" +
                      _vm.model.illustration +
                      ".png",
                    alt: "",
                  },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("Column", [
          _c(
            "div",
            { staticClass: "practicearea--header__titleorder" },
            [
              _vm.model.title !== ""
                ? _c(
                    "Heading",
                    {
                      staticClass:
                        "u-add-colored-period practicearea--header__title",
                      attrs: {
                        level: 1,
                        noMarginTop: "true",
                        noMarginBottom: "true",
                      },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.model.categoryLabel
                ? _c(
                    "Heading",
                    {
                      staticClass: "practicearea--header__subtitle",
                      attrs: {
                        level: 2,
                        noMarginTop: "true",
                        noMarginBottom: "true",
                      },
                    },
                    [_vm._v(_vm._s(_vm.model.categoryLabel))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "practicearea--preamble" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.model.preamble) +
                "\n                " +
                _vm._s(_vm.model.categoryTitle) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _vm.model.buttonLabel && _vm.model.buttonLabel !== ""
            ? _c("button", { on: { click: _vm.scrollToContactform } }, [
                _vm._v(_vm._s(_vm.model.buttonLabel)),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }