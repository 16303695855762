var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-shareholder-item" }, [
    _c("div", [_vm._v(_vm._s(_vm.shareholder.topInvestor))]),
    _vm._v(" "),
    _c("div", [
      _vm._v(_vm._s(_vm.numberWithCommas(_vm.shareholder.topPositionShares))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-hidden-xs" }, [
      _vm._v(_vm._s(_vm.getPercentageTopTwenty())),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-hidden-xs" }, [
      _vm._v(_vm._s(_vm.getPercentage())),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-hidden-xs" }, [
      _vm._v(_vm._s(_vm.shareholder.topInvestorAccountType)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "u-hidden-xs" }, [
      _vm._v(_vm._s(_vm.shareholder.topInvestorCountry)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }