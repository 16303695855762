var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-external-content-page" },
    [
      _c("Container", [
        _c("iframe", { attrs: { src: _vm.model.externalContent } }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }