var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.viewMode === "Slider"
        ? [
            _c(
              "PageListSlider",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListSlider",
                _vm.$props,
                false
              )
            ),
          ]
        : _vm.viewMode === "Large"
        ? [
            _c(
              "PageListLarge",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListLarge",
                _vm.$props,
                false
              )
            ),
          ]
        : _vm.viewMode === "Small"
        ? [
            _c(
              "PageListSmall",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListSmall",
                _vm.$props,
                false
              )
            ),
          ]
        : [
            _c(
              "PageListList",
              _vm._b(
                { attrs: { items: _vm.teasers } },
                "PageListList",
                _vm.$props,
                false
              )
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }