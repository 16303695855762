<!--
    Will select a page component based on the page name.

    * Maps Models/Pages/*.cs to Assets/Scripts/components/pages/*.vue

    It gets its `model` from the vuex stores `epiDataModel` module that's
    automatically updated in OPE when data is edited (through the
    `beta/contentSaved` event). The store also includes `modelLoaded` that's
    set to true when the model has been populated in the store. This flag
    toggles the rendering of the page with `v-if`.
-->

<template>
    <component :model="model" :is="getLayoutForPage(model)" v-if="modelLoaded">
        <component v-if="modelLoaded" :is="getComponentTypeForPage(model)" :model="model"></component>
    </component>
</template>

<script>
import getComponentTypeForContent from '@/Scripts/api/getComponentTypeForContent';
import { mapState } from 'vuex';

export default {
    computed: mapState({
        model: state => state.epiDataModel.model,
        modelLoaded: state => state.epiDataModel.modelLoaded
    }),
    methods: {
        getComponentTypeForPage(model) {
            // this.$options.components will contain all globally registered components from main.js
            return getComponentTypeForContent(model, this.$options.components);
        },
        getLayoutForPage(model) {
            switch (model.contentType[1]) {
                case 'IRGroupFactsAndFiguresPage': return 'InvestorRelationsLayout';
                case 'IRAgmPage': return 'InvestorRelationsLayout';
                case 'IRDebtInvestorsPage': return 'InvestorRelationsLayout';
                case 'IRCorporateGovernancePage': return 'InvestorRelationsLayout';
                case 'IRReportsAndPresentationsPage': return 'InvestorRelationsLayout';
                case 'IREquityProspectusPage': return 'InvestorRelationsLayout';
                case 'IRContactUsPage': return 'InvestorRelationsLayout';
                case 'IRPeoplePage': return 'InvestorRelationsLayout';
                case 'IRShareInformationPage': return 'InvestorRelationsLayout';
                case 'CustomCampaignPage': return 'div';
                case 'EventPage': return 'div';
                default: return 'DefaultLayout';
            }
        }
    }

};
</script>
