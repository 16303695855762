var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-share-information" }, [
    _c("div", { staticClass: "c-share-information-main-info" }, [
      _c(
        "div",
        { staticClass: "c-share-information-box" },
        [
          _c(
            "Heading",
            { attrs: { level: 1, noMarginBottom: "", noMarginTop: "" } },
            [_vm._v(_vm._s(_vm.snapShot.last))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-share-information-box-name" }, [
            _vm._v(
              _vm._s(`Last (${_vm.formatTime(new Date(_vm.snapShot.time))})`)
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-share-information-box" },
        [
          _c(
            "Heading",
            { attrs: { level: 1, noMarginBottom: "", noMarginTop: "" } },
            [
              _vm._v(
                _vm._s(_vm.decimalFormatter(_vm.snapShot.changePct, 2) + "%")
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-share-information-box-name" }, [
            _vm._v("Change"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-share-information-box" },
        [
          _c(
            "Heading",
            { attrs: { level: 1, noMarginBottom: "", noMarginTop: "" } },
            [_vm._v(_vm._s(_vm.numberWithCommas(_vm.snapShot.volume)))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-share-information-box-name" }, [
            _vm._v("Volume"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-share-information-box" },
        [
          _c(
            "Heading",
            { attrs: { level: 1, noMarginBottom: "", noMarginTop: "" } },
            [_vm._v(_vm._s(_vm.snapShot.item))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-share-information-box-name" }, [
            _vm._v("Ticker (Oslo Børs)"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }