var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    { class: { "edit-mode": _vm.inEditMode }, attrs: { to: _vm.url } },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }