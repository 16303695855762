<template>
<main class="c-ir-figures-page">
    <Container>
        <Row class="c-ir-figures-page__top">
            <div class="c-ir-figures-page__top__iframe-wrapper" v-html="model.iframeQuarterlyResults" v-if="model.iframeQuarterlyResults"></div>
            <Column>
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
            </Column>
            <Column :md="10">
                <Heading :level="3" noMarginTop v-epi-edit="'Title'">{{model.title}}</Heading>
                <XhtmlField v-epi-edit="'IntroLeft'" :items="model.introLeft"/>
            </Column>
            <Column :offsetMd="4" :md="10">
                <XhtmlField v-epi-edit="'IntroRight'" :items="model.introRight"/>
            </Column>
        </Row>

        <TwoColumnBlock class="c-ir-figures-page__twocol1" v-epi-edit="'TwoColumn1'" v-if="model.twoColumn1.content" v-bind="model.twoColumn1.content"/>
        <TwoColumnBlock class="c-ir-figures-page__twocol2 o-bg o-bg--beige-light" v-epi-edit="'TwoColumn2'" v-if="model.twoColumn2.content" v-bind="model.twoColumn2.content"/>

        <Row class="c-ir-figures-page__financial-numbers o-bg o-bg--beige-light" v-epi-edit="'FinancialFigures'">
            <Column>
                <Heading isCategory>{{getLabel('irGroupFactsAndFiguresPage', 'financialFigures')}}</Heading>
            </Column>
            <Column :md="6" v-for="(item, index) in model.financialFigures" :key="'finFig_' + index">
                <Heading noMarginTop noMarginBottom>{{item.figure}}</Heading>
                <p>{{item.label}}</p>
            </Column>
        </Row>

        <FileListBlock v-epi-edit="'FileListBlock'" class="c-ir-figures-page__reports o-bg o-bg--beige-light" v-bind="model.fileListBlock.content"/>

        <Row class="c-ir-figures-page__accentuated-text o-bg o-bg--beige-light">
            <Column>
                <Heading :level="1" v-epi-edit="'AccentuatedText1'">
                    {{model.accentuatedText1}}
                </Heading>
            </Column>
        </Row>
        <BubblesBlock class="c-ir-figures-page__bubbles" v-bind="model.bubblesBlock.content" v-epi-edit="'BubblesBlock'"/>
        <Row class="c-ir-figures-page__accentuated-text o-bg o-bg--beige-light">
            <Column>
                <Heading :level="2" v-epi-edit="'AccentuatedText2'">
                    {{model.accentuatedText2}}
                </Heading>
            </Column>
        </Row>
        <OfficesBlock class="c-ir-figures-page__offices" v-bind="model.officesBlock.content" v-epi-edit="'OfficesBlock'"/>
    </Container>
</main>
</template>

<script>
export default {
    props: {
        model: Object
    }
};
</script>

<style lang="scss">
.c-ir-figures-page {
    &__top, &__twocol1, &__accentuated-text, &__bubbles, &__offices {
        margin-bottom: 5rem;
    }
    &__top {
        &__iframe-wrapper {
            margin-bottom: 4rem;
            width: 100%;
            iframe {
                width: 100%;
                max-height: 500px;

                @include media-md {
                    max-height: 800px;
                }
            }
        }
    }
    &__twocol2, &__reports {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    &__financial-numbers {
        padding-top: 3rem;
        padding-bottom: 2rem;
    }
    &__financial-numbers, &__twocol2, &__reports {
        margin-bottom: 1rem;
    }
    &__accentuated-text {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
}
</style>
