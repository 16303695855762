var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "c-heroEbook__container" },
    [
      _c(
        "div",
        {
          staticClass: "c-heroEbook",
          class: { "c-heroEbook--edit": _vm.inEditMode },
          style: _vm.style,
        },
        [
          _c(
            "div",
            { staticClass: "c-heroEbook__content" },
            [
              _c(
                "Container",
                { staticClass: "c-heroEbook__content-wrapper" },
                [
                  _c(
                    "Row",
                    [
                      _c(
                        "Column",
                        {
                          staticClass: "c-ebook-page__left-content",
                          attrs: { md: 13 },
                        },
                        [
                          _vm.subtitle || _vm.isEditable
                            ? _c("h2", {
                                staticClass: "c-heroEbook__subtitle",
                                domProps: { textContent: _vm._s(_vm.subtitle) },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("h1", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Title",
                                expression: "'Title'",
                              },
                            ],
                            staticClass: "c-heroEbook__content-title",
                            class: {
                              "c-heroEbook__content-title--colored":
                                _vm.changeTitleColor,
                              "": "",
                            },
                            domProps: { innerHTML: _vm._s(_vm.title) },
                          }),
                          _vm._v(" "),
                          _vm.introText || _vm.isEditable
                            ? _c("XhtmlField", {
                                directives: [
                                  {
                                    name: "epi-edit",
                                    rawName: "v-epi-edit",
                                    value: "IntroText",
                                    expression: "'IntroText'",
                                  },
                                ],
                                staticClass: "o-ingress",
                                attrs: { items: _vm.introText },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("epi-property", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.epiDisableEditing,
                                expression: "!epiDisableEditing",
                              },
                            ],
                            attrs: { "property-name": "HeroImage" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "Column",
                        {
                          staticClass: "c-ebook-page__right-content",
                          attrs: { md: 10, offsetMd: 1, id: "embed" },
                        },
                        [
                          _vm.embed
                            ? _c(
                                "div",
                                {
                                  staticClass: "c-heroEbook__embed",
                                  style: _vm.cssVariables,
                                },
                                [
                                  _c(_vm.dynamicComponent, {
                                    tag: "component",
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.overlayImage
        ? _c(
            "Container",
            { staticClass: "c-heroEbook__additionalImage container" },
            [
              _c(
                "Row",
                [
                  _c(
                    "Column",
                    { attrs: { md: 10, offsetMd: 1 } },
                    [
                      _vm.overlayImage
                        ? _c("ResponsiveImage", {
                            attrs: {
                              alt: _vm.subtitle,
                              src: _vm.overlayImage.url,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }