<template>
<Row class="c-software-partner-row">
    <Column v-for="(partner, index) in partners" :sm="11" :md="6" :key="index">
        <div class="c-software-partner-row__partner" @click="partnerSelected(partner, index)">
            <span class="c-software-partner-row__partner__name o-ingress">{{partner.label}}</span>
            <Icon
                :icon="selected && selected.row == currentRow && selected.index === index ? 'chevron_down' : 'chevron_right'"
                class="c-software-partner-row__partner__icon"
            />
        </div>
    </Column>
    <Column v-if="selected && selected.row == currentRow">
        <div class="c-software-partner-row__content">
            <Icon class="c-software-partner-row__content__cross u-hidden-xs" icon="cross" @click="deselect()" @click.native="deselect()"/>
            <div class="o-ingress c-software-partner-row__content__name">{{selected.partner.label}}</div>
            <XhtmlField class="c-software-partner-row__intro" :items="selected.partner.intro" />
            <Anchor isFancy :href="selected.partner.url" class="c-software-partner-row__content__anchor">{{getLabel('global', 'readMore')}}</Anchor>
            <ProductList :products="selected.partner.categories" :md="8"/>
        </div>
    </Column>
</Row>
</template>

<script>
import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';
import ProductList from '@/Scripts/components/molecules/ProductList.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';

export default {
    props: {
        currentRow: Number,
        partners: Array,
        selected: null
    },
    components: {
        ProductList,
        Icon,
        XhtmlField
    },
    methods: {
        deselect() {
            this.$emit('select', null);
        },
        partnerSelected(partner, index) {
            this.$emit('select', {partner: partner, row: this.currentRow, index: index});
        }
    }
};
</script>

<style lang="scss">
.c-software-partner-row {
    justify-content: space-between;

    &__partner {
        cursor: pointer;
        border-bottom: 1px solid $black;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-md {
            margin-bottom: 3rem;
        }

        &__icon {
            fill: $orange;
            width: 1.2em;
            height: 1.2em;
        }
    }

    &__content {
        background-color: $beige-light;
        padding: 3rem;
        margin-bottom: 2rem;
        position: relative;

        &__cross {
            fill: $orange;
            position: absolute;
            top: 1em;
            right: 1em;
            height: 1.2em;
            width: 1.2em;
            cursor: pointer;
        }
        &__name {
            margin-bottom: 2rem;
        }
        &__anchor {
            margin-bottom: 3rem;
        }
    }
    & .c-product-list .c-product-list__category{
        .o-heading--category {
            margin-bottom: 1em;
        }
        @include media-md {
            margin-bottom: 0;
            padding-right: 4rem;

            &:not(:nth-last-child(-n+3)) {
                margin-bottom: 5rem;
            }
        }
    }
}
</style>
