<template>
    <div>
        <h4 v-if="model.title !== ''" class="accordion-title">
            {{ model.title }}
            <strong style="color: rgb(255, 106, 76);" aria-hidden="hidden">.</strong>
        </h4>
        <div class="episerver-accordion" :class="{'o-bg' : backgroundColor, 'color': textColor}" :style="style">
            <div v-for="(item, index) in model.content.content" :key="index" class="accordion-item">
                <div class="accordion-header" @click="toggleItem(index)">
            <span>
            <div class="accordion-header">
                <XhtmlField :items="item.heading"></XhtmlField>
            </div>
            </span>
                    <svg class="toggle-sign">
                        <use :xlink:href="activeIndex === index ? '#minus' : '#plus'"></use>
                    </svg>
                </div>
                <transition name="accordion">
                    <div v-if="activeIndex === index" class="accordion-content">
                        <XhtmlField :items="item.content"></XhtmlField>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        model: Object,
        backgroundColor: Object,
        textColor: Object,
    },
    data() {
        return {
            activeIndex: null,
        };
    },
    methods: {
        toggleItem(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        },
    },
    computed: {
        style() {
            return {
                backgroundColor: this.backgroundColor ? this.backgroundColor.value : this.backgroundColor,
                color: this.textColor ? this.textColor.value : this.textColor,
            };
        },
    },
};
</script>

<style lang="scss">
.episerver-accordion {
  font-family: Arial, sans-serif;
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #8F9B9E;
  &:nth-child(1) {
    border-top: 1px solid #8F9B9E;
  }
}

.accordion-title {
    margin-bottom: -0.3rem;
}

.accordion-item {
  border-bottom: 1px solid #8F9B9E;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.accordion-header span {
  font-size: 16px;
}

.toggle-sign {
  width: 16px; /* adjust size to match the second image */
  height: 16px; /* adjust size to match the second image */
  stroke: #FF6F61; /* match the color */
}

.accordion-content {
  padding: 10px;
  overflow: hidden;
}

.accordion-enter-active, .accordion-leave-active {
  transition: max-height 0.4s ease, opacity 0.4s ease;
}

.accordion-enter, .accordion-leave-to {
  max-height: 0;
  opacity: 0;
}

.accordion-enter-to, .accordion-leave {
  max-height: 1000px;
  opacity: 1;
}

.o-bg:before {
  height: 101%;
}
</style>
