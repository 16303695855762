var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-about-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.model.leftTopIntro
            ? _c(
                "Row",
                { staticClass: "c-about-page__intro" },
                [
                  _c(
                    "Column",
                    { attrs: { md: 11 } },
                    [
                      _c(
                        "Heading",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "LeftTopIntroText",
                              expression: "'LeftTopIntroText'",
                            },
                          ],
                          attrs: { noMarginTop: "", level: 4 },
                        },
                        [
                          _c("XhtmlField", {
                            attrs: { items: _vm.model.leftTopIntroText },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "Column",
                    { attrs: { md: 11, "offset-md": 2 } },
                    [
                      _c("XhtmlField", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "RightTopIntroText",
                            expression: "'RightTopIntroText'",
                          },
                        ],
                        attrs: { items: _vm.model.rightTopIntroText },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.media.content)
            ? _c(
                "Row",
                [
                  _c(
                    "Column",
                    [
                      _c(
                        "MediaBlock",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Media",
                                expression: "'Media'",
                              },
                            ],
                            staticClass: "c-about-page__media",
                          },
                          "MediaBlock",
                          _vm.model.media.content,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.twoColumnBlock.content
            ? _c(
                "TwoColumnBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "TwoColumnBlock",
                        expression: "'TwoColumnBlock'",
                      },
                    ],
                    staticClass: "c-about-page__two-column",
                  },
                  "TwoColumnBlock",
                  _vm.model.twoColumnBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.quote &&
          _vm.model.quote.content &&
          _vm.model.quote.content.quote
            ? _c(
                "QuoteBlock",
                _vm._b(
                  {
                    staticClass: "c-about-page__hero",
                    attrs: { isSimple: false },
                  },
                  "QuoteBlock",
                  _vm.model.quote.content,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.partners && _vm.notEmptyObject(_vm.model.partners.content)
        ? _c(
            "Container",
            { attrs: { isFluid: "", background: "beige" } },
            [
              _c(
                "Container",
                [
                  _c(
                    "Row",
                    [
                      _c(
                        "Column",
                        { attrs: { offsetMd: 2 } },
                        [
                          _c(
                            "PartnersBlock",
                            _vm._b(
                              {
                                directives: [
                                  {
                                    name: "epi-edit",
                                    rawName: "v-epi-edit",
                                    value: "Partners",
                                    expression: "'Partners'",
                                  },
                                ],
                              },
                              "PartnersBlock",
                              _vm.model.partners.content,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.model.blocks || _vm.isEditable
            ? _c("ContentArea", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Blocks",
                    expression: "'Blocks'",
                  },
                ],
                staticClass: "c-about-page__content-area",
                attrs: { model: _vm.model.blocks || _vm.isEditable },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.model.offices.content && _vm.model.offices.content.offices.length > 0
        ? _c(
            "Container",
            [
              _c(
                "OfficesBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "Offices",
                        expression: "'Offices'",
                      },
                    ],
                  },
                  "OfficesBlock",
                  _vm.model.offices.content,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }