<template>
 <div class="c-page-list-slider__item" >
    <AspectRatioBox :width="503" :height="338">
        <ResponsiveImage :src="imageUrl" class="c-page-list-slider__item__image" v-bind="{lg: {h: 338,w: 503}, md: {h: 338,w: 503}, sm: {h: 176, w: 235}, xs: {h: 176, w: 235}}"/>
    </AspectRatioBox>
    <Heading isCategory class="c-page-list-slider__item__label">{{label}}</Heading>
    <Heading :level="5" class="c-page-list-slider__item__title">{{title}}</Heading>
    <v-clamp autoresize :max-lines="3" class="c-page-list-slider__item__intro">{{intro}}</v-clamp>
    <Anchor isFancy :href="url">{{getLabel('global', 'readMore')}}</Anchor>
</div>
</template>

<script>
import AspectRatioBox from '@/Scripts/components/atoms/AspectRatioBox.vue';
import VClamp from 'vue-clamp';

export default {
    props: {
        imageUrl: String,
        label: String,
        title: String,
        intro: String,
        url: String
    },
    components: {
        AspectRatioBox,
        VClamp
    }
};
</script>

<style lang="scss">
.c-page-list-slider {
      &__item {
        background-color: $beige-light;
        height: 100%;
        padding-left: 3rem;
        padding-bottom: 2rem;
        position: relative;

        &__label {
            margin-top: 1em;
            margin-bottom: 1em;
        }
        &__title {
            margin-top: .5rem;
            margin-bottom: 1.7rem;
        }
        &__image {
            margin-bottom: 1.5rem;
        }
        &__intro {
            margin-bottom: 3rem;
        }
        a {
            position: absolute;
            bottom: 1.5rem;
        }
    }
}
</style>
