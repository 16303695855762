var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-open-positions-block" },
    [
      _c(
        "Row",
        [
          _c(
            "Column",
            { attrs: { md: 12 } },
            [
              _vm.title
                ? _c(
                    "Heading",
                    {
                      staticClass: "u-add-colored-period",
                      attrs: { noMarginTop: "" },
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.intro
                ? _c("p", { staticClass: "c-open-positions-block__intro" }, [
                    _vm._v(_vm._s(_vm.intro)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Row",
        { staticClass: "c-open-positions-block__categories" },
        [
          _c(
            "Column",
            { attrs: { xs: 11, sm: 5, md: 5 } },
            [
              _c("Dropdown", {
                attrs: {
                  options: _vm.categoriesDropdown,
                  placeholder: "Categories",
                },
                on: { input: _vm.updateData },
                model: {
                  value: _vm.category,
                  callback: function ($$v) {
                    _vm.category = $$v
                  },
                  expression: "category",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { staticClass: "col-no-gutter", attrs: { xs: 1 } },
            [
              _vm.category
                ? _c("Button", {
                    staticClass: "c-clear-button",
                    attrs: { buttonStyle: "no-style", icon: "cross" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.reset("category")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { attrs: { xs: 12, sm: 6, md: 5 } },
            [
              _c("Dropdown", {
                attrs: {
                  options: _vm.countriesDropdown,
                  placeholder: "Countries",
                },
                on: { input: _vm.updateData },
                model: {
                  value: _vm.country,
                  callback: function ($$v) {
                    _vm.country = $$v
                  },
                  expression: "country",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { staticClass: "col-no-gutter", attrs: { xs: 1 } },
            [
              _vm.country
                ? _c("Button", {
                    staticClass: "c-clear-button",
                    attrs: { buttonStyle: "no-style", icon: "cross" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.reset("country")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Row",
        { staticClass: "c-vacancy-table-header" },
        [
          _c(
            "Column",
            { attrs: { xs: 12, md: 15 } },
            [
              _c(
                "Button",
                {
                  attrs: { buttonStyle: "no-style", icon: "toggle_sort" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.sortyByTitle()
                    },
                  },
                },
                [
                  _vm._v(
                    "\r\n                " +
                      _vm._s(_vm.getLabel("vacancyPage", "jobTitleText")) +
                      "\r\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Column",
            { attrs: { xs: 12, md: 9 } },
            [
              _c(
                "Button",
                {
                  attrs: { buttonStyle: "no-style", icon: "toggle_sort" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.sortByCountry()
                    },
                  },
                },
                [
                  _vm._v(
                    "\r\n                " +
                      _vm._s(
                        _vm.getLabel("vacancyPage", "locationHeadingText")
                      ) +
                      "\r\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("List", {
        attrs: { items: _vm.vacancyList.vacancies, disableUrl: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c(
                  "Row",
                  { staticClass: "c-open-positions-block__vacancy" },
                  [
                    _c(
                      "Column",
                      {
                        staticClass: "c-open-positions-block__vacancy__col1",
                        attrs: { md: 10, sm: 14 },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "u-block u-display-sm-down c-open-positions-block__vacancy__country@sm",
                          },
                          [_vm._v(_vm._s(item.country))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "u-block c-open-positions-block__vacancy__title",
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "Column",
                      { staticClass: "u-hidden-xs", attrs: { md: 8, sm: 10 } },
                      [_c("span", [_vm._v(_vm._s(item.category.label))])]
                    ),
                    _vm._v(" "),
                    _c(
                      "Column",
                      { staticClass: "u-display-md-up", attrs: { md: 6 } },
                      [_c("span", [_vm._v(_vm._s(item.country))])]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "icon",
            fn: function ({ item }) {
              return [
                _c(
                  "Anchor",
                  {
                    staticClass: "c-open-positions-block__vacancy__icon@md",
                    attrs: {
                      isFancy: "",
                      href: _vm.detailedBaseUrl + "?id=" + item.id,
                    },
                  },
                  [_vm._v(_vm._s(_vm.getLabel("global", "readMore")))]
                ),
                _vm._v(" "),
                _c("Icon", {
                  staticClass: "c-open-positions-block__vacancy__icon@sm",
                  attrs: {
                    icon: "arrow_right",
                    url: _vm.detailedBaseUrl + "?id=" + item.id,
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }