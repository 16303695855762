<template>
<div class="c-share-information">
    <div class="c-share-information-main-info">
        <div class="c-share-information-box">
            <Heading :level="1" noMarginBottom noMarginTop>{{snapShot.last}}</Heading>
            <div class="c-share-information-box-name">{{`Last (${formatTime(new Date (snapShot.time))})`}}</div>
        </div>
        <div class="c-share-information-box">
            <Heading :level="1" noMarginBottom noMarginTop>{{decimalFormatter(snapShot.changePct, 2) + '%'}}</Heading>
            <div class="c-share-information-box-name">Change</div>
        </div>
        <div class="c-share-information-box">
            <Heading :level="1" noMarginBottom noMarginTop>{{numberWithCommas(snapShot.volume)}}</Heading>
            <div class="c-share-information-box-name">Volume</div>
        </div>
        <div class="c-share-information-box">
            <Heading :level="1" noMarginBottom noMarginTop>{{snapShot.item}}</Heading>
            <div class="c-share-information-box-name">Ticker (Oslo Børs)</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        snapShot: {
            type: Object,
        }
    },
    methods: {
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        formatTime(date) {
            var timeString = date.getHours() + ':' + ('00' + date.getMinutes()).slice(-2);
            return timeString;
        }
    }
};
</script>

<style lang="scss">
.c-share-information {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom:5em;
}
.c-share-information-heading {
    width:100%;
}
.c-share-information-main-info {
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.c-share-information-box {
    width:50%;
    @include media-md {
        width: 25%;
    }

}
.c-share-information-box-name {
    padding-top:1em;
    font-size: medium;
}
</style>
