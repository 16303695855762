var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "practicearea" },
    [
      _c("PracticeAreaHero", { attrs: { model: _vm.model } }),
      _vm._v(" "),
      _vm.notEmptyObject(_vm.model.siblings) || _vm.isEditable
        ? _c(
            "Container",
            { staticClass: "practicearea--navigation", attrs: { isFluid: "" } },
            [
              _c("Container", { staticClass: "u-hidden-xs u-hidden-sm" }, [
                _c(
                  "ul",
                  { staticClass: "o-flat-list practicearea--navigation__list" },
                  _vm._l(_vm.model.siblings.siblings, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: `${_vm._uid}${index}`,
                        staticClass: "practicearea--navigation__list--element",
                      },
                      [
                        _c(
                          "Anchor",
                          {
                            class: _vm.compareGuid(item.guid)
                              ? "practicearea--navigation__list--selected"
                              : "",
                            attrs: { href: item.url, ariaLabel: item.title },
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("Dropdown", {
                staticClass: "u-display-xs u-display-sm",
                attrs: { options: _vm.dropdownValues, clearable: false },
                on: { input: _vm.changeRoute },
                model: {
                  value: _vm.dropdownSelection,
                  callback: function ($$v) {
                    _vm.dropdownSelection = $$v
                  },
                  expression: "dropdownSelection",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        { staticClass: "practicearea--description" },
        [
          _c(
            "Row",
            { staticClass: "o-block c-two-column-block" },
            [
              _c(
                "Column",
                {
                  staticClass: "practicearea--description__text",
                  attrs: { md: 12 },
                },
                [
                  _vm.model.descriptionHeading
                    ? _c(
                        "h2",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "TitleCol1",
                              expression: "'TitleCol1'",
                            },
                          ],
                          staticClass: "practicearea--global__title",
                        },
                        [_vm._v(_vm._s(_vm.model.descriptionHeading))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-two-column-block__body1" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.model.descriptionText.replace(/\u200B/g, "</p>")
                        ),
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "Column",
                { staticClass: "c-two-column-block__body2", attrs: { md: 12 } },
                [
                  _vm.notEmptyObject(
                    _vm.model.media && _vm.model.media.content
                  ) || _vm.isEditable
                    ? _c(
                        "MediaBlock",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Media",
                                expression: "'Media'",
                              },
                            ],
                            staticClass: "c-service-page__media-block",
                          },
                          "MediaBlock",
                          _vm.model.media.content,
                          false
                        )
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "practicearea--benefits" },
        [
          _c(
            "Container",
            [
              _c(
                "Row",
                [
                  _c(
                    "Column",
                    [
                      _c(
                        "Heading",
                        {
                          staticClass: "practicearea--global__title",
                          attrs: { level: 2 },
                        },
                        [_vm._v(_vm._s(_vm.model.benefitsHeading))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                { staticClass: "practicearea--benefits__list" },
                _vm._l(_vm.model.benefits, function (item, index) {
                  return _c(
                    "Column",
                    { key: `${_vm._uid}${index}`, attrs: { md: 8 } },
                    [
                      _c(
                        "div",
                        { staticClass: "practicearea--benefits__card" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "practicearea--benefits__card__image-wrapper",
                            },
                            [
                              _c(
                                "AspectRatioBox",
                                [
                                  _c("ResponsiveImage", {
                                    staticClass:
                                      "practicearea--benefits__card__image",
                                    attrs: {
                                      src: item.image,
                                      alt: item.benefitTitle,
                                      xs: { h: 120, w: 120 },
                                      sm: { h: 120, w: 120 },
                                      md: { h: 150, w: 150 },
                                      lg: { h: 150, w: 150 },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "Heading",
                            {
                              staticClass:
                                "practicearea--benefits__card__header",
                              attrs: { level: 3 },
                            },
                            [_vm._v(_vm._s(item.benefitTitle))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "practicearea--benefits__card__text",
                            },
                            [_vm._v(_vm._s(item.paragraph))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Container",
        { staticClass: "practicearea--expertise" },
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      staticClass: "practicearea--global__title",
                      attrs: { level: 2 },
                    },
                    [_vm._v(_vm._s(_vm.model.expertiseAreasHeading))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                [
                  _vm.model.expertiseAreas || _vm.isEditable
                    ? _c("ContentArea", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Blocks",
                            expression: "'Blocks'",
                          },
                        ],
                        staticClass: "practicearea--expertise__single",
                        attrs: {
                          model: _vm.model.expertiseAreas || _vm.isEditable,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "practicearea--articlelist" },
        [
          _c(
            "Container",
            [
              _c(
                "Row",
                [
                  _c(
                    "Column",
                    { attrs: { md: 24 } },
                    [
                      _c(
                        "Heading",
                        {
                          staticClass: "practicearea--global__title",
                          attrs: { level: 2 },
                        },
                        [_vm._v(_vm._s(_vm.model.successStoriesHeading))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.model.successStoriesPreamble !== ""
                ? _c(
                    "Row",
                    [
                      _c("Column", { attrs: { md: 16 } }, [
                        _c(
                          "p",
                          {
                            staticClass: "practicearea--articlelist__preamble",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.model.successStoriesPreamble) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Row",
                { staticClass: "c-page-list--large" },
                _vm._l(_vm.model.successStories, function (item, index) {
                  return _c(
                    "Column",
                    {
                      key: index,
                      staticClass: "c-page-list--large__item",
                      attrs: { md: 6 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "c-page-list--large__content" },
                        [
                          _c(
                            "AspectRatioBox",
                            { attrs: { width: 323, height: 193 } },
                            [
                              _c(
                                "ResponsiveImage",
                                _vm._b(
                                  { attrs: { src: item.imageUrl } },
                                  "ResponsiveImage",
                                  _vm.imageConfig,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "c-page-list--large__content__text",
                            },
                            [
                              _c("Heading", { attrs: { isCategory: "" } }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "Heading",
                                {
                                  staticClass:
                                    "c-page-list--large__heading u-no-margin-top",
                                  attrs: { level: 5 },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-clamp",
                                {
                                  staticClass: "o-large-body",
                                  attrs: {
                                    autoresize: "",
                                    "max-lines": 3,
                                    tag: "p",
                                  },
                                },
                                [_vm._v(_vm._s(item.intro))]
                              ),
                              _vm._v(" "),
                              _c(
                                "Anchor",
                                {
                                  staticClass: "c-page-list--large__link",
                                  attrs: {
                                    isFancy: "",
                                    href: item.url,
                                    ariaLabel: item.title,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getLabel("global", "readMore"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                [_c("PracticeAreaVendors", { attrs: { model: _vm.model } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "practicearea--accordion o-bg" },
        [
          _c(
            "Container",
            [
              _c(
                "Row",
                [
                  _c(
                    "Column",
                    {
                      staticClass: "practicearea--accordion__block",
                      attrs: { md: 10 },
                    },
                    [
                      _c(
                        "Heading",
                        {
                          staticClass: "practicearea--global__title",
                          attrs: { level: 2 },
                        },
                        [_vm._v(_vm._s(_vm.model.whyCrayonHeading))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.model.whyCrayonItems, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "accordion-item practicearea--accordion__item",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "practicearea--accordion__header",
                          on: {
                            click: function ($event) {
                              return _vm.toggleItem(index)
                            },
                          },
                        },
                        [
                          _c("span", [
                            _c(
                              "div",
                              {
                                staticClass: "practicearea--accordion__header",
                              },
                              [_c("div", [_vm._v(" " + _vm._s(item.heading))])]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("svg", { staticClass: "toggle-sign" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  _vm.activeIndex === index
                                    ? "#minus"
                                    : "#plus",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "accordion" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeIndex === index,
                                expression: "activeIndex === index",
                              },
                            ],
                            staticClass: "practicearea--accordion__content",
                          },
                          [
                            _c("XhtmlField", {
                              attrs: { items: item.content },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Container",
        { ref: "contactform", staticClass: "practicearea--contact" },
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                {
                  staticClass: "practicearea--contact__textarea",
                  attrs: { md: 12 },
                },
                [
                  _c(
                    "Heading",
                    {
                      staticClass: "practicearea--global__title",
                      attrs: { level: 2 },
                    },
                    [_vm._v(_vm._s(_vm.model.contactFormHeading))]
                  ),
                  _vm._v(" "),
                  _c("XhtmlField", {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "model.contactFormIntro",
                        expression: "'model.contactFormIntro'",
                      },
                    ],
                    attrs: { items: _vm.model.contactFormIntro },
                  }),
                  _vm._v(" "),
                  _c(
                    "ResponsiveImage",
                    _vm._b(
                      { attrs: { src: _vm.model.contactFormIllustration } },
                      "ResponsiveImage",
                      _vm.imageConfig,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                {
                  staticClass: "practicearea--contact__form",
                  attrs: { md: 12 },
                },
                [
                  _vm.notEmptyObject(_vm.model.formData) || _vm.isEditable
                    ? _c(
                        "PracticeAreaFormBlock",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Form",
                                expression: "'Form'",
                              },
                            ],
                            attrs: { id: "form" },
                          },
                          "PracticeAreaFormBlock",
                          _vm.model.formData,
                          false
                        )
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "practicearea--articlelist" },
        [
          _c(
            "Container",
            [
              _c(
                "Row",
                [
                  _c(
                    "Column",
                    { attrs: { md: 24 } },
                    [
                      _c(
                        "Heading",
                        {
                          staticClass: "practicearea--global__title",
                          attrs: { level: 2 },
                        },
                        [_vm._v(_vm._s(_vm.model.exploreMoreHeading))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.model.exploreMorePreamble !== ""
                ? _c(
                    "Row",
                    [
                      _c("Column", { attrs: { md: 16 } }, [
                        _c(
                          "p",
                          {
                            staticClass: "practicearea--articlelist__preamble",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.model.exploreMorePreamble) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Row",
                { staticClass: "c-page-list--large" },
                _vm._l(_vm.model.exploreMore, function (item, index) {
                  return _c(
                    "Column",
                    {
                      key: index,
                      staticClass: "c-page-list--large__item",
                      attrs: { md: 6 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "c-page-list--large__content" },
                        [
                          _c(
                            "AspectRatioBox",
                            { attrs: { width: 323, height: 193 } },
                            [
                              _c(
                                "ResponsiveImage",
                                _vm._b(
                                  { attrs: { src: item.imageUrl } },
                                  "ResponsiveImage",
                                  _vm.imageConfig,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "c-page-list--large__content__text",
                            },
                            [
                              _c("Heading", { attrs: { isCategory: "" } }, [
                                _vm._v(_vm._s(item.label)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "Heading",
                                {
                                  staticClass:
                                    "c-page-list--large__heading u-no-margin-top",
                                  attrs: { level: 5 },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-clamp",
                                {
                                  staticClass: "o-large-body",
                                  attrs: {
                                    autoresize: "",
                                    "max-lines": 3,
                                    tag: "p",
                                  },
                                },
                                [_vm._v(_vm._s(item.intro))]
                              ),
                              _vm._v(" "),
                              _c(
                                "Anchor",
                                {
                                  staticClass: "c-page-list--large__link",
                                  attrs: {
                                    isFancy: "",
                                    href: item.url,
                                    ariaLabel: item.title,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getLabel("global", "readMore"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }