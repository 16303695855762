var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "practicearea--expertise__single" },
    [
      _vm.illustration > 0
        ? _c(
            "Column",
            {
              staticClass:
                "practicearea--expertise__single--illustration__wrapper",
              attrs: { md: 8 },
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "/Assets/Images/practiceareas/expertise/" +
                    _vm.illustration +
                    ".png",
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Column", { attrs: { md: _vm.illustration > 0 ? "16" : "24" } }, [
        _c("h3", { staticClass: "practicearea--expertise__single--header" }, [
          _vm._v(_vm._s(_vm.heading)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "practicearea--expertise__single--preamble" }, [
          _vm._v(_vm._s(_vm.preamble)),
        ]),
        _vm._v(" "),
        _vm.readMore.length > 0
          ? _c(
              "div",
              {
                staticClass: "practicearea--expertise__single--wrapper",
                class: _vm.more ? "is-open" : "",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "practicearea--expertise__single--wrapper__inner",
                  },
                  [_c("XhtmlField", { attrs: { items: _vm.readMore } })],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.readMore.length > 0
          ? _c(
              "button",
              {
                staticClass: "practicearea--expertise__single--more",
                attrs: { type: "button" },
                on: { click: _vm.toggle },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.getLabel("global", _vm.more ? "readLess" : "readMore")
                    ) +
                    "\n            "
                ),
                _c("Icon", {
                  staticClass: "c-news-block__item__chevron2",
                  class: [
                    _vm.more
                      ? "practicearea--expertise__single--more__rotate-180"
                      : "",
                  ],
                  attrs: { icon: "chevron_down" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }