var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-page-list-list" },
    [
      _vm.label
        ? _c(
            "Heading",
            {
              staticClass: "c-page-list-list__label",
              attrs: { isCategory: "" },
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c(
            "Heading",
            {
              staticClass: "c-page-list-list__title u-no-margins",
              attrs: { level: 4 },
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.intro && !_vm.introHtml
        ? _c("p", { staticClass: "c-page-list-list__intro o-large-body" }, [
            _vm._v(_vm._s(_vm.intro)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.introHtml
        ? _c("XhtmlField", {
            staticClass: "o-large-body",
            attrs: { items: _vm.introHtml },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("List", { attrs: { items: _vm.items } }),
      _vm._v(" "),
      _vm.url
        ? _c("Anchor", { attrs: { href: _vm.url, isFancy: "" } }, [
            _vm._v(_vm._s(_vm.getLabel("global", "viewAll"))),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }