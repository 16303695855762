<template>
<Row class="o-block c-two-column-block o-custom-block" :class="{'o-bg' : backgroundColor || color2}" :style="style">
    <Column :md="10" class="c-two-column-block__heading1">
        <Heading v-if="titleCol1" :level="3" v-epi-edit="'TitleCol1'" class="u-no-margin-top">{{titleCol1}}</Heading>
    </Column>
    <Column :md="10" :offsetMd="4" class="c-two-column-block__heading2">
        <Heading v-if="titleCol2" :level="3" v-epi-edit="'TitleCol2'" class="u-no-margin-top">{{titleCol2}}</Heading>
    </Column>
    <Column :md="10" class="c-two-column-block__body1">
        <XhtmlField v-epi-edit="'mainBodyCol1'" :items="mainBodyCol1"/>
    </Column>
        <Column :md="10" :offsetMd="4" class="c-two-column-block__body2">
        <XhtmlField v-epi-edit="'mainBodyCol2'" :items="mainBodyCol2"/>
    </Column>
</Row>
</template>

<script>
import XhtmlField from '@/Scripts/components/atoms/XhtmlField.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';

export default {
    props: {
        mainBodyCol1: Array,
        mainBodyCol2: Array,
        titleCol1: '',
        titleCol2: '',
        backgroundColor: String,
        textColor: String,
        color: Object,
        color2: Object
    },
    components: {
        XhtmlField,
        Heading
    },
    computed: {
        style() {
            return {
                backgroundColor: this.color2 ? this.color2.value : this.backgroundColor,
                color: this.color ? this.color.value : this.textColor
            };
        }
    }
};
</script>

<style lang="scss">
.c-two-column-block {
    &__heading1 {
        order: 0;
    }
    &__heading2 {
        order: 2;
        padding-top:auto;
    }
    &__body1 {
        order: 1;
    }
    &__body2 {
        order: 3;
    }
    @include media-md {
        &__heading1, &__heading2, &__body1, &__body2 {
            order: 0;
        }
    }
}
</style>
