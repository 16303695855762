<template>
<main class="c-service-list-page">
    <Container>
        <Row class="c-service-list-page__top">
            <!-- eslint-disable-next-line no-irregular-whitespace -->
            <Column :md="12" v-if="model.label || model.title || isEditable">
                <Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading>
                <Heading v-epi-edit="'Title'" :level="1" class="u-add-colored-period u-no-margin-top">{{model.title}}</Heading>
            </Column>
            <Column :md="8" :offsetMd="2">
                <XhtmlField class="o-ingress" v-epi-edit="'IntroText'" :items="model.introText" v-if="model.introText || isEditable"/>
                <Button v-scroll-to="'#form'" v-if="notEmptyObject(model.form && model.form.content)">{{getLabel('serviceListPage','topBannerButtonText')}}</Button>
            </Column>
        </Row>
    </Container>
    <Container>
        <TwoColumnBlock v-epi-edit="'TwoColumnBlock'" v-if="notEmptyObject(model.twoColumnBlock && model.twoColumnBlock.content) || isEditable" class="c-service-list-page__two-column" v-bind="model.twoColumnBlock.content" />
        <Row class="c-service-list-page__services o-bg o-bg--beige-light" v-if="model.categories.length > 0 || isEditable">
            <Column class="o-bg o-bg--beige-light c-service-list-page__services__service" :sm="12" :md="8" :offsetMd="6" v-for="(category, index) in model.categories" :key="`service_list${_uid}${index}`">
                <PageListBlock :introHtml="category.intro" :label="category.label" :title="category.title" :teasers="category.children" viewMode="List" />
            </Column>
        </Row>
        <FormBlock v-epi-edit="'Form'" id="form" class="c-service-list-page__form" v-if="notEmptyObject(model.form && model.form.content) || isEditable" v-bind="model.form.content"/>
    </Container>
    <Container isFluid background="beige-light" v-if="notEmptyObject(model.news) || isEditable" v-epi-edit="'News'">
        <Container>
            <NewsBlock v-bind="model.news && model.news.content" :useUsDateFormat="model.siteSettings.useUsDateFormat" />
        </Container>
    </Container>
</main>
</template>

<script>
export default {
    props: {
        model: Object
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_grid-mixin.scss';

.c-service-list-page {
    &__top, &__two-column {
        margin-bottom: 3rem;

        @include media-sm {
            margin-bottom: 4rem;
        }

        @include media-md {
            margin-bottom: 6rem;
        }
    }

    // Part of PageListBlock in List-mode
    .c-page-list-list {
        &__title {
            width: 101%;
        }

        &__intro {
            margin-bottom: 3em;
        }
    }

    &__services {
        margin-bottom: 3rem;
        @include media-md {
            margin-bottom: 5rem;
        }
        &>div.o-col {
            &:nth-child(2n - 1) {
                margin-left: 0;
            }
        }

        &.o-bg {
            background-color: transparent;

            &:before {
                content: none;
            }

            @include media-sm {
                background-color: $beige-light;
                padding-bottom: 5rem;

                &:before {
                    content: "";
                }
            }
        }

        // Each item in content area with background
        .o-bg {
            padding-top: 3rem;
            padding-bottom: 3rem;

            @include media-sm {
                padding-top: 5rem;
                padding-bottom: 0;
            }

            // dont display background unless xs.
            &:before {
                @include media-sm {
                    content: none
                }
            }

            // Only every other item should have background-color.
            &:nth-child(2n) {
                background-color: transparent;

                &:before {
                    background-color: transparent;
                }
            }
        }
    }
}
</style>
