<template>
<main class="c-equity-prospectus-page">
    <Container>
        <Row class="c-equity-prospectus-page__top">
            <Column><Heading isCategory v-epi-edit="'Label'">{{model.label}}</Heading></Column>
            <Column><Heading :level="3" noMarginTop v-epi-edit="'Title'">{{model.title}}</Heading></Column>
        </Row>
        <TwoLinkBlock v-epi-edit="'TwoLinkBlock'" class="c-equity-prospectus-page__links" v-bind="model.twoLinkBlock.content"/>
    </Container>
</main>
</template>

<script>
export default {
    props: {
        model: Object
    }
};
</script>

<style lang="scss">
.c-equity-prospectus-page {
    &__links {
        margin-bottom: 3rem;

        @include media-md {
            margin-bottom: 5rem;
        }
    }
}
</style>
