<template>
<div class="c-speaker">
    <div v-if="image" class="c-speaker__image">
        <ResponsiveImage :alt="name" :xs="{w: 200, h: 200}" :sm="{w: 200, h: 200}" :md="{w: 200, h: 200}" :lg="{w: 200, h: 200}" :src="image" class="circular-image"/>
    </div>
    <div class="o-ingress">
    <p class="c-speaker__name">{{ name }}</p>
    </div>
    <div class="c-speaker__job-title-and-company">
        <div>
            {{jobTitle}} - {{companyName}}
        </div>
    </div>
    <div class="c-speaker__biography">
        <div class="c-speaker__dropdown" @click="toggleBio">
            <Button :buttonStyle="'no-style'" :icon="'chevron_down'" :class="isBioVisible ? 'rotate-icon' : ''"></Button>
            <div>{{ isBioVisible ? 'Show Less' : 'Read Bio' }}</div>
        </div>
        <div v-if="isBioVisible">
            <XhtmlField :items="biography"/>
        </div>
    </div>
</div>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';

export default {
    props: {
        biography: Array,
        companyName: String,
        image: String,
        jobTitle: String,
        name: String
    },
    data() {
        return {
            // Add a new data property to control the visibility of the biography

            isBioVisible: false,
        };
    },
    components: {
        Icon
    },
    methods: {
        // Add a method to toggle the biography visibility
        toggleBio() {
            this.isBioVisible = !this.isBioVisible;
        }
    }
};
</script>

<style lang="scss">
.c-speaker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &__top {
        margin-bottom: 2rem;
    }
    &__text-wrapper {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
    }
    &__job-title-and-company {
        color:#04242D;
        opacity: 0.8;
    }
    &__name{
        font-weight: 500;
        margin-bottom: 0.25rem;
    }
    &__image {
        padding-bottom: 1.5rem;
        max-width: 200px;
        margin: auto;
    }
    &__dropdown{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        gap: 1rem;
        color:#FF6A4C;
        .c-icon {
            fill: #FF6A4C;
        }
    }
}

.rotate-icon {
    transform: rotate(180deg);
}
.circular-image {
    border-radius: 50%;
}
details > summary {
    list-style-type: none;
    cursor: pointer;
}
</style>
