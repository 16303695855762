var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-page-list-page" },
    [
      _c(
        "Container",
        [
          _vm.model.showGartnerWidget
            ? _c(
                "div",
                { staticClass: "c-page-list-page__widget-container" },
                [_c("GartnerWidgetLineComponent")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "Row",
            [
              _c(
                "Column",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "Title",
                      expression: "'Title'",
                    },
                  ],
                },
                [
                  _c("Heading", { attrs: { noMarginTop: "" } }, [
                    _vm._v(_vm._s(_vm.model.title)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            { staticClass: "c-page-list-page__categories u-display-md-up" },
            [
              _c(
                "Column",
                [
                  _c(
                    "Heading",
                    {
                      staticClass: "c-page-list-page__categories__heading",
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.getLabel("pageListPage", "categories")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                [
                  _c(
                    "Button",
                    {
                      class: { active: _vm.filter.id === null },
                      attrs: { buttonStyle: "no-style" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.changeCategory(_vm.CATEGORY_START_ITEM)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.CATEGORY_START_ITEM.name))]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.model.selectableCategories, function (category) {
                    return _c(
                      "Button",
                      {
                        key: category.id,
                        class: { active: _vm.filter.id === category.id },
                        attrs: { buttonStyle: "no-style" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.changeCategory(category)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\r\n                    " +
                            _vm._s(category.name) +
                            "\r\n                "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            {
              staticClass:
                "c-page-list-page__categories u-display-xs u-display-sm",
            },
            [
              _c("Dropdown", {
                staticClass: "c-page-list-page__categories__dropdown",
                attrs: {
                  label: "name",
                  options: [
                    _vm.CATEGORY_START_ITEM,
                    ..._vm.model.selectableCategories,
                  ],
                  clearable: false,
                },
                on: { input: _vm.changeCategory },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "u-display-md-up" },
            [
              _c(
                "Row",
                _vm._l(_vm.getTeasers(0, 1), function (item, index) {
                  return _c(
                    "Column",
                    { key: index },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          viewMode: "FullSize",
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(1, 5), function (item, index) {
                  return _c(
                    "Column",
                    { key: index, attrs: { md: 6, sm: 12 } },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          viewMode: "Compact",
                          model: item,
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(5, 7), function (item, index) {
                  return _c(
                    "Column",
                    { key: index, attrs: { md: 12 } },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          imageConfig: {
                            lg: { h: 334, w: 668 },
                            md: { h: 334, w: 569 },
                          },
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(7, 10), function (item, index) {
                  return _c(
                    "Column",
                    { key: index, attrs: { md: 8 } },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          imageConfig: {
                            lg: { h: 193, w: 438 },
                            md: { h: 193, w: 374 },
                          },
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "u-display-sm" },
            [
              _c(
                "Row",
                _vm._l(_vm.getTeasers(0, 1), function (item, index) {
                  return _c(
                    "Column",
                    { key: index },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          viewMode: "FullSize",
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(1, 5), function (item, index) {
                  return _c(
                    "Column",
                    { key: index, attrs: { sm: 12 } },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          imageConfig: { sm: { h: 176, w: 313 } },
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(5, 6), function (item, index) {
                  return _c(
                    "Column",
                    { key: index },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          viewMode: "FullSize",
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(6, 10), function (item, index) {
                  return _c(
                    "Column",
                    { key: index, attrs: { sm: 12 } },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          imageConfig: { sm: { h: 176, w: 313 } },
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "u-display-xs" },
            [
              _c(
                "Row",
                _vm._l(_vm.getTeasers(0, 1), function (item, index) {
                  return _c(
                    "Column",
                    { key: index },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          imageConfig: { xs: { h: 184, w: 345 } },
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(1, 5), function (item, index) {
                  return _c(
                    "Column",
                    { key: index },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          viewMode: "Vertical",
                          flip: "",
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(5, 6), function (item, index) {
                  return _c(
                    "Column",
                    { key: index },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          imageConfig: { sm: { h: 184, w: 345 } },
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "Row",
                _vm._l(_vm.getTeasers(6, 10), function (item, index) {
                  return _c(
                    "Column",
                    { key: index },
                    [
                      _c("NewsComponent", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Teasers",
                            expression: "'Teasers'",
                          },
                        ],
                        attrs: {
                          model: item,
                          viewMode: "Vertical",
                          flip: "",
                          useUsDateFormat:
                            _vm.model.siteSettings.useUsDateFormat,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.totalPages > 0
            ? _c(
                "Row",
                { staticClass: "c-page-list-page__pagination-wrapper" },
                [
                  _c(
                    "Column",
                    { staticClass: "u-flex", attrs: { align: "right" } },
                    [
                      _c("Pagination", {
                        attrs: {
                          "current-page": _vm.page,
                          "max-pages": _vm.totalPages,
                        },
                        on: { change: _vm.changePage },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }