<template>
<Row class="c-file-download-block o-bg o-bg--beige-light">
    <Column :md="10">
        <Heading :level="3" noMarginTop>{{text}}</Heading>
    </Column>
    <Column :offsetMd="4" :md="8">
        <Heading isCategory>{{file.type}}</Heading>
        <Heading :level="4" noMarginTop>{{file.name}}</Heading>
        <Anchor isFancy :download="file.name" :href="file.url">{{file.linkText}}</Anchor>
    </Column>
</Row>
</template>

<script>
export default {
    props: {
        text: String,
        file: Object
    }
};
</script>

<style lang="scss">
.c-file-download-block {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
</style>
