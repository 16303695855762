var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-bubbles-block" },
    [
      _c(
        "Column",
        [
          _c("Heading", { attrs: { isCategory: "" } }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        { attrs: { md: 10 } },
        [
          _c(
            "Heading",
            { staticClass: "u-no-margin-top", attrs: { level: 3 } },
            [_vm._v(_vm._s(_vm.title))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        { attrs: { md: 12, offsetMd: 2 } },
        [
          _c(
            "Row",
            _vm._l(_vm.bubbles, function (item, index) {
              return _c(
                "Column",
                {
                  key: `${_vm._uid}${index}`,
                  staticClass: "c-bubbles-block__bubble",
                  attrs: { sm: 8 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "c-bubbles-block__bubble__image-wrapper" },
                    [
                      _c(
                        "AspectRatioBox",
                        [
                          _c("ResponsiveImage", {
                            staticClass: "c-bubbles-block__bubble__image",
                            attrs: { src: item.image, alt: item.text },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "c-bubbles-block__bubble__text" }, [
                    _vm._v(_vm._s(item.text)),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }