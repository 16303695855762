<template>
  <div class="c-xhtml-string" :is="dynamicComponent"></div>
</template>

<script>
export default {
    props: ['string'],
    computed: {
        dynamicComponent() {
            return {
                template: '<div>' + this.string + '</div>',
            };
        },
    },
};
</script>

<style lang="scss">
.c-xhtml-string {
    img {
        max-width: 100%;
        height: auto;
    }
    & > *:first-child {
        margin-top: 0;
    }
}
</style>
