var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    {
      staticClass: "c-collapse",
      class: {
        "c-collapse--open": _vm.isOpen,
        "c-collapse--border": _vm.border,
      },
    },
    [
      _c(
        "Column",
        {
          nativeOn: {
            click: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "c-collapse__header-wrapper" },
            [
              _c(
                "Row",
                [
                  _c(
                    "Column",
                    { staticClass: "c-collapse__header", attrs: { xs: 20 } },
                    [_vm._t("header")],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "Column",
                    {
                      staticClass:
                        "c-collapse__header c-collapse__icon-wrapper",
                      attrs: { xs: 4 },
                    },
                    [
                      _c("Icon", {
                        attrs: {
                          icon: _vm.isOpen ? _vm.iconOpen : _vm.iconClosed,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "Column",
        { staticClass: "c-collapse__content" },
        [
          _c(
            "transition",
            {
              attrs: { name: "c-collapse__expand" },
              on: {
                enter: _vm.enter,
                "after-enter": _vm.afterEnter,
                leave: _vm.leave,
              },
            },
            [
              _vm.isOpen
                ? _c(
                    "div",
                    { staticClass: "c-collapse__content-wrapper" },
                    [_vm._t("content")],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }