var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-software-partners-overview-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            { staticClass: "c-software-partners-overview-page__top" },
            [
              _c(
                "Column",
                { attrs: { md: 14, offsetMd: 4 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      attrs: { noMarginTop: "", level: 1 },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "IntroText",
                          expression: "'IntroText'",
                        },
                      ],
                      attrs: { level: 4 },
                    },
                    [
                      _c("XhtmlField", {
                        attrs: { items: _vm.model.introText },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            { staticClass: "c-software-partners-overview-page__partners" },
            [
              _c(
                "Column",
                { attrs: { md: 20, offsetMd: 2 } },
                [
                  _c("SoftwarePartnerList", {
                    attrs: { partners: _vm.model.partners },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Container",
        { attrs: { isFluid: "", background: "beige-light", marginHack: "" } },
        [
          _c(
            "Container",
            [
              _vm.notEmptyObject(_vm.model.form && _vm.model.form.content) ||
              _vm.isEditable
                ? _c(
                    "FormBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Form",
                            expression: "'Form'",
                          },
                        ],
                        staticClass: "c-service-page__form",
                        attrs: { id: "form" },
                      },
                      "FormBlock",
                      _vm.model.form.content,
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }