var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "c-footer" },
    [
      _c(
        "HeaderTop",
        _vm._b(
          {
            staticClass: "c-footer__header",
            attrs: { isWhite: true, scrollToTopOnClick: "" },
          },
          "HeaderTop",
          _vm.headerTop,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "Container",
        { staticClass: "c-footer__content" },
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                { staticClass: "c-footer__text", attrs: { md: 20 } },
                [
                  _c("div", { staticClass: "c-footer__text__section" }, [
                    _c("span", { staticClass: "c-footer__text__small" }, [
                      _vm._v(_vm._s(_vm.getLabel("footer", "contactHeading"))),
                    ]),
                    _vm._v(" "),
                    _vm.overwriteContactLink
                      ? _c(
                          "div",
                          [
                            _c(
                              "Anchor",
                              {
                                staticClass: "c-footer__text__big",
                                attrs: { href: _vm.overwriteContactLink },
                              },
                              [
                                _vm._v(
                                  "\r\n                            " +
                                    _vm._s(_vm.overwriteContactLinkText) +
                                    "\r\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "c-footer__text__big",
                              attrs: { href: `mailto:${_vm.mail}` },
                            },
                            [_vm._v(_vm._s(_vm.mail))]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "c-footer__text__big",
                              attrs: { href: `tel:${_vm.phoneNumber}` },
                            },
                            [_vm._v(_vm._s(_vm.phoneNumber))]
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "c-footer__text__section" },
                    [
                      _c("span", { staticClass: "c-footer__text__small" }, [
                        _vm._v(_vm._s(_vm.getLabel("footer", "visitHeading"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "Anchor",
                        {
                          staticClass: "c-footer__text__big",
                          attrs: { href: _vm.singleLink },
                        },
                        [
                          _vm._v(
                            "\r\n                        " +
                              _vm._s(_vm.singleLinkText) +
                              "\r\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-footer__text__section" }, [
                    _c("span", { staticClass: "c-footer__text__small" }, [
                      _vm._v(
                        _vm._s(_vm.getLabel("footer", "socialMediaHeading"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "c-footer__icon-wrapper" },
                      [
                        _c("Icon", {
                          attrs: {
                            icon: "facebook",
                            url: "https://www.facebook.com/CrayonITGroup/",
                            target: "_blank",
                          },
                        }),
                        _vm._v(" "),
                        _c("Icon", {
                          attrs: {
                            icon: "linkedin",
                            url: "https://www.linkedin.com/company/crayon-group",
                            target: "_blank",
                          },
                        }),
                        _vm._v(" "),
                        _c("Icon", {
                          attrs: {
                            icon: "twitter",
                            url: "https://twitter.com/crayonit",
                            target: "_blank",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 4 } },
                [
                  _c(
                    "Row",
                    {
                      staticClass: "c-footer_link-list o-flat-list",
                      attrs: { wrapper: "ul" },
                    },
                    _vm._l(_vm.links, function (item, index) {
                      return _c(
                        "Column",
                        {
                          key: `links_${_vm._uid}${index}`,
                          attrs: { wrapper: "li", sm: 8, md: 24 },
                        },
                        [
                          _c("Anchor", { attrs: { href: item.url } }, [
                            _vm._v(
                              "\r\n                            " +
                                _vm._s(item.text) +
                                "\r\n                        "
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            { staticClass: "c-footer__bottom" },
            [
              _c(
                "Column",
                { attrs: { md: 12 } },
                [
                  _c("Anchor", { attrs: { href: _vm.cookiesPageLink } }, [
                    _vm._v(
                      "\r\n                    " +
                        _vm._s(_vm.cookiesPageLinkText) +
                        "\r\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "Anchor",
                    { attrs: { href: _vm.integrityAndComplianceLink } },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(_vm.integrityAndComplianceLinkText) +
                          "\r\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { staticClass: "c-footer__bottom__right", attrs: { md: 12 } },
                [
                  _vm._v(
                    "\r\n                " +
                      _vm._s(_vm.address) +
                      "\r\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }