var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    {
      staticClass: "o-block c-two-column-block o-custom-block",
      class: { "o-bg": _vm.backgroundColor || _vm.color2 },
      style: _vm.style,
    },
    [
      _c(
        "Column",
        { staticClass: "c-two-column-block__heading1", attrs: { md: 10 } },
        [
          _vm.titleCol1
            ? _c(
                "Heading",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "TitleCol1",
                      expression: "'TitleCol1'",
                    },
                  ],
                  staticClass: "u-no-margin-top",
                  attrs: { level: 3 },
                },
                [_vm._v(_vm._s(_vm.titleCol1))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        {
          staticClass: "c-two-column-block__heading2",
          attrs: { md: 10, offsetMd: 4 },
        },
        [
          _vm.titleCol2
            ? _c(
                "Heading",
                {
                  directives: [
                    {
                      name: "epi-edit",
                      rawName: "v-epi-edit",
                      value: "TitleCol2",
                      expression: "'TitleCol2'",
                    },
                  ],
                  staticClass: "u-no-margin-top",
                  attrs: { level: 3 },
                },
                [_vm._v(_vm._s(_vm.titleCol2))]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        { staticClass: "c-two-column-block__body1", attrs: { md: 10 } },
        [
          _c("XhtmlField", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "mainBodyCol1",
                expression: "'mainBodyCol1'",
              },
            ],
            attrs: { items: _vm.mainBodyCol1 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        {
          staticClass: "c-two-column-block__body2",
          attrs: { md: 10, offsetMd: 4 },
        },
        [
          _c("XhtmlField", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "mainBodyCol2",
                expression: "'mainBodyCol2'",
              },
            ],
            attrs: { items: _vm.mainBodyCol2 },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }