var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-infoWithHighlights-block", style: _vm.style },
    [
      _c(
        "div",
        { staticClass: "c-infoWithHighlights-block__top" },
        [
          _c(
            "Heading",
            {
              staticClass: "c-infoWithHighlights-block__title u-block",
              attrs: { noMarginBottom: "", noMarginTop: "", level: 2 },
            },
            [_vm._v(_vm._s(_vm.heading))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "c-infoWithHighlights-block__title u-block o-ingress",
              attrs: { noMarginTop: "", level: 4 },
            },
            [_vm._v(_vm._s(_vm.subHeading))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Row",
        { staticClass: "c-infoWithHighlights-block__bottom" },
        [
          _vm._l(_vm.columnData, function (column, index) {
            return _c(
              "Column",
              {
                key: index,
                staticClass: "c-infoWithHighlights-block__item",
                attrs: { sm: 12, md: 6 },
              },
              [
                index > 0
                  ? _c("div", {
                      staticClass: "c-infoWithHighlights-block__borderLeft",
                    })
                  : _vm._e(),
                _vm._v(" "),
                column.image
                  ? _c(
                      "div",
                      {
                        staticClass: "c-infoWithHighlights-block__imageWrapper",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "c-infoWithHighlights-block__imageBackground",
                          },
                          [
                            _c(
                              "ResponsiveImage",
                              _vm._b(
                                {
                                  staticClass:
                                    "c-infoWithHighlights-block__image",
                                  attrs: { src: column.image && column.image },
                                },
                                "ResponsiveImage",
                                _vm.imageConfig,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "c-infoWithHighlights-block__subHeader",
                    attrs: { align: "center" },
                  },
                  [
                    _vm._v(
                      "\r\n                " +
                        _vm._s(column.title) +
                        "\r\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("XhtmlField", {
                  staticClass: "c-infoWithHighlights-block__textField",
                  attrs: { items: column.text },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "c-infoWithHighlights-block__smallButtonContainer",
                  },
                  [
                    column.buttonUrl
                      ? _c(
                          "Button",
                          {
                            staticClass:
                              "c-infoWithHighlights-block__smallbutton",
                            attrs: { href: column.buttonUrl },
                          },
                          [_vm._v(" " + _vm._s(column.buttonText))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "Column",
            {
              staticClass: "c-infoWithHighlights-block__buttoncontainer",
              attrs: { align: "center" },
            },
            [
              _vm.bottomButtonUrl && _vm.bottomButtonText
                ? _c(
                    "Button",
                    {
                      staticClass: "c-infoWithHighlights-block__button",
                      attrs: { href: _vm.bottomButtonUrl },
                    },
                    [_vm._v(" " + _vm._s(_vm.bottomButtonText) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }