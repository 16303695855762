var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass: "c-header",
      class: [{ "c-header--overlap": _vm.isOverlapping }],
    },
    [
      _c("HeaderTop", {
        attrs: {
          isMenuOpen: _vm.isMenuOpen,
          isLanguageSelectorOpen: _vm.isLanguageSelectorOpen,
          currentLanguage: _vm.currentLanguage,
          isWhite: _vm.isWhite,
          loginPage: _vm.loginPage,
          searchPage: _vm.searchPage,
        },
      }),
      _vm._v(" "),
      _c(
        "Container",
        { staticClass: "c-header__breadcrumbs", attrs: { isFluid: "" } },
        [
          _vm.showBreadcrumbs
            ? _c("Breadcrumbs", { attrs: { items: _vm.breadcrumbs } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("LanguageSelector", {
        attrs: { isOpen: _vm.isLanguageSelectorOpen, items: _vm.languageLinks },
        on: { languageChanged: _vm.toggleLanguageSelector },
      }),
      _vm._v(" "),
      _c("NavMenu", {
        staticClass: "u-hidden u-display-md u-display-lg",
        attrs: { isOpen: _vm.isMenuOpen, items: _vm.mainMenu },
      }),
      _vm._v(" "),
      _c("NavMenuSmall", {
        staticClass: "u-hidden-md u-hidden-lg",
        attrs: {
          isOpen: _vm.isMenuOpen,
          items: _vm.mainMenu,
          currentLanguage: _vm.currentLanguage,
          searchPage: _vm.searchPage,
        },
        on: {
          "close-menu": _vm.closeMenuEnableScrolling,
          "open-language-selector": _vm.toggleLanguageSelector,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }