<template>
<div class="c-news-block">
    <Row>
        <Column :md="12" class="c-news-block__col1">
            <Heading isCategory>{{label}}</Heading>
            <Heading :level="1" class="u-no-margin-top u-add-colored-period">{{title}}</Heading>
            <Button class="c-news-block__view-all-button u-hidden-xs" :href="url">{{getLabel('global', 'viewAllArticles')}}</Button>
        </Column>
        <Column :md="10" :offsetMd="2">
            <ul class="o-flat-list">
                <li class="c-news-block__item" v-for="(item, index) in teasers" :key="`newsblock_item_${index}`">
                    <Datetime v-if="!item.hideDate" class="c-news-block__item__date" :value="item.published" :useUsDateFormat="useUsDateFormat"/>
                    <Heading class="c-news-block__item__title u-no-margin-top" :level="4">{{item.title}}</Heading>
                    <v-clamp autoresize :max-lines="3" tag="p">{{item.intro}}</v-clamp>
                    <Anchor class="c-news-block__item__readmore" isFancy :href="item.url">{{getLabel('global', 'readMore')}}</Anchor>
                    <Anchor class="c-news-block__item__chevron" :href="item.url">
                        <Icon class="c-news-block__item__chevron2" icon="chevron_right"/>
                    </Anchor>

                </li>
            </ul>
        </Column>
        <Column class="u-display-xs">
            <Anchor isFancy :href="url">{{getLabel('global', 'viewAllArticles')}}</Anchor>
        </Column>
    </Row>
</div>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Datetime from '@/Scripts/components/atoms/Datetime.vue';
import VClamp from 'vue-clamp';

export default {
    props: {
        label: String,
        title: String,
        teasers: Array,
        url: String,
        useUsDateFormat: Boolean,
    },
    components: {
        Icon,
        Datetime,
        VClamp
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_breakpoints.scss';

.c-news-block {
    &__view-all-button {
        margin-top: 1.5em;
    }
    &__col1 {
        margin-bottom: 2rem;

        @include media-sm {
            margin-bottom: 4rem;
        }
        @include media-md {
            margin-bottom: 0;
        }
    }
    &__item {
        position: relative;
        margin-bottom: 3rem;

         @include media-sm {
            margin-bottom: 4rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &__title {
            margin-bottom: .4em;
        }
        &__date {
            color: $gray;
            margin-bottom: .5em;
        }
        &__readmore {
            display: none;

            @include media-sm {
                display: inline-block;
            }
        }
        &__chevron {
            position: absolute;
            right: -10px;
            fill: $orange;
            height: 20px;
            width: 20px;
            top: 50%;
            transform: translate(0%, -50%);

            @include media-sm {
                display: none;
            }
        }
    }
}
</style>
