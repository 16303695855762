<template>
<div class="c-nav-menu-small" :class="{'c-nav-menu-small--open' : isOpen}">
    <Container isFluid class="c-nav-menu-small__container">
        <Row class="c-nav-menu-small__head">
            <Column align="space-between">
                <Button buttonStyle="no-style" icon="back" v-if="currentLevel !== 1" @click.native="currentLevel--">{{getLabel('global', 'back')}}</Button>
                <Button buttonStyle="no-style" icon="search" v-if="currentLevel === 1" :href="searchPage">{{getLabel('menu', 'searchButton')}}</Button>
                <Button buttonStyle="no-style" @click.native="$emit('close-menu')" icon="cross">{{getLabel('global', 'close')}}</Button>
            </Column>
        </Row>
        <Row class="c-nav-menu-small__content">
            <Column class="c-nav-menu-small__level-1" v-if="currentLevel === 1">
                    <ul class="o-flat-list">
                        <li v-for="(item, index) in items" :key="`level1_${index}`"
                            class="c-nav-menu-small__item"
                            @click="onItemClick(item)">
                            <Anchor :href="item.url" :isClickable="!hasChildren(item)">
                                <span class="c-nav-menu-small__item-text">{{item.name}}</span>
                                <Icon v-if="hasChildren(item)" icon="chevron_right" />
                            </Anchor>
                        </li>
                    </ul>
                </Column>
                <Column class="c-nav-menu-small__level-2" v-if="currentLevel === 2">
                    <Heading isCategory>{{items2.name}}</Heading>
                    <ul class="o-flat-list">
                        <li v-for="(item,index) in items2.menuItems" :key="`level2_${index}`"
                            class="c-nav-menu-small__item"
                            @click="onItemClick(item)">
                            <Anchor :href="item.url" :isClickable="!hasChildren(item)">
                                <span class="c-nav-menu-small__item-text">{{item.name}}</span>
                                <Icon v-if="hasChildren(item)" icon="chevron_right" />
                            </Anchor>
                        </li>
                        <li v-if="currentItem1.url"
                            class="c-nav-menu-small__item">
                            <Anchor :href="currentItem1.url">
                                <span class="c-nav-menu-small__item-text">{{currentItem1.name}}</span>
                            </Anchor>
                        </li>
                    </ul>
                </Column>
                <Column class="c-nav-menu-small__level-3" v-if="currentLevel === 3">
                    <Heading isCategory>{{items3.name}}</Heading>
                    <ul class="o-flat-list">
                        <li v-for="(item, index) in items3.menuItems" :key="`level3_${index}`" class="c-nav-menu-small__item">
                            <Anchor :href="item.url">
                                <span class="c-nav-menu-small__item-text">{{item.name}}</span>
                            </Anchor>
                        </li>
                    </ul>
                </Column>
        </Row>
        <Row class="c-nav-menu-small__footer">
            <Column align="space-between">
                <Button buttonStyle="no-style" iconRight icon="chevron_down" @click.native="$emit('open-language-selector')">{{getLabel('languageSelector', 'country')}}: <span class="c-nav-menu-small__footer__language">{{currentLanguage.name}}</span></Button>
                <Button buttonStyle="no-style" class="u-hidden" iconRight href="test" icon="icon">{{getLabel('menu', 'logIn')}}</Button>
            </Column>
        </Row>
    </Container>
</div>
</template>

<script>
import Container from '@/Scripts/components/grid/Container.vue';
import Row from '@/Scripts/components/grid/Row.vue';
import Column from '@/Scripts/components/grid/Column.vue';
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Button from '@/Scripts/components/atoms/Button.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';
import Heading from '@/Scripts/components/atoms/Heading.vue';

export default {
    data: function () {
        return {
            currentLevel: 1,
            currentItem1: null,
            currentItem2: null
        };
    },
    props: {
        currentLanguage: {
            type: Object
        },
        items: {
            type: Array,
            default: () => [] // eslint-disable-line
        },
        isOpen: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        searchPage: String
    },
    computed: {
        selected1: function () {
            return this.items.find(x => x.isCurrentPage);
        },
        selected2: function () {
            if (this.selected1) {
                return this.selected1.menuItems.find(x => x.isCurrentPage);
            }
            return null;
        },
        selected3: function () {
            if (this.selected2) {
                return this.selected2.menuItems.find(x => x.isCurrentPage);
            }
            return null;
        },
        items2: function () {
            return this.currentItem1 ? this.currentItem1 : this.selected1;
        },
        items3: function () {
            return this.currentItem2 ? this.currentItem2 : this.selected2;
        },
    },
    methods: {
        hasChildren(item) {
            return item.menuItems.length > 0;
        },
        onItemClick(item) {
            switch (this.currentLevel) {
                case 1: this.currentItem1 = item; break;
                case 2: this.currentItem2 = item; break;
                default: break;
            }
            if (this.hasChildren(item)) {
                ++this.currentLevel;
            }
        }
    },
    components: {
        Container,
        Row,
        Column,
        Anchor,
        Icon,
        Heading,
        Button
    }
};
</script>

<style lang="scss">
@import '@/Styles/tools/_font-mixin.scss';

.c-nav-menu-small {
    $self: &;
    $menuColor: #000; // Darker menu text to please Lighthouse

    &__container {
        background-color: $beige;
        height: 100%;
        position: fixed;
        top: 0;
        width: 100%;

        max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    }
    &__head, &__footer {
        height: 75px;
        background-color: white;
    }
    &__head {
        // width: calc(100% + 40px);
        background-color: $white;
        margin-right: -20px;
        margin-left: -20px;
        height: 75px;
        padding: 25px 20px;

        @include media-sm {
            margin-right: -60px;
            margin-left: -60px;
            padding: 25px 60px;
        }
    }
    &__content {
        padding-top: 20px;
        padding-bottom: 20px;
        height: calc(100% - 150px);
        overflow: scroll;
    }
    &__footer {
        background-color: $beige;
        border-top: 1px solid $orange;
        bottom: 0;
        padding: 25px 0px;

        &__language {
            color: $menuColor;
        }
    }

    &__item {

        &:not(:last-child) {
            margin-bottom: em(43, 28);

            @include media-sm {
                margin-bottom: em(62, 32);
            }
        }
        @include fontSize(28px);

        @include media-sm {
            @include fontSize(32px);
        }

        span, .c-icon {
            font-size: inherit;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        a, a:hover {
            text-decoration: none;
        }
        &-text {
            display: inline-block;
            margin: 0;
            color: $menuColor;
        }
        .c-icon {
            fill: $menuColor;
        }
        .router-link-active {
            #{$self}__item-text {
                color: $black;
            }
            .c-icon {
                fill: $black;
            }
        }
        &:hover, &--hovering {
            #{$self}__item-text {
                color: $orange;
            }
            .c-icon {
                fill: $orange;
            }
        }
    }

     &--open {
        .c-nav-menu-small__container {
            max-height: 100%;
        }
    }
}
</style>
