<template>
  <main class="event-page">
  <Header v-if="showHeaderFooter" :isWhite="isStartPage" :isOverlapping="isStartPage" :showBreadcrumbs="!isStartPage" v-bind="model.header" :isLanguageSelectorOpen="isLanguageSelectorOpen" :isMenuOpen="isMenuOpen" :currentLanguage="currentLanguage"/>
  <Hero v-if="model.heroImage && model.heroImage.url"
        :buttonText="model.heroLinkText" :title="model.title" :subtitle="model.heroSubtitle" :heroImage="model.heroImage && model.heroImage.url" :overlay="model.heroOverlay"
        :url="model.heroLink" :changeTitleColor="model.changeTitleColor" :changeLinkColor="model.changeLinkColor"
        :url2="model.heroLink2" :buttonText2="model.heroLinkText2"/>
  <Container>
    <ContentArea v-epi-edit="'Blocks'" v-if="model.topContentArea || isEditable" class="c-event-page__content-area" :model="model.topContentArea  || isEditable"/>
  </Container>
  <div class="event-blocks">
      <EventProgramBlock :model="model.eventDays" />
    </div>
    <div class="speaker-list container">
      <SpeakerList :speakers="model.speakersList" :heading="model.speakersHeading" />
    </div>
    <ContentArea v-epi-edit="'Blocks'" v-if="model.mainContentArea || isEditable" class="c-event-page__content-area" :model="model.mainContentArea  || isEditable"/>
  <Footer v-if="model.showHeaderFooter" v-bind="{...model.footer, headerTop: {isLanguageSelectorOpen: isLanguageSelectorOpen, isMenuOpen: isMenuOpen, currentLanguage: currentLanguage, searchPage: searchPage, loginPage: loginPage}}"/>
</main>
</template>

<script>
import ContentArea from '@/Scripts/components/ContentArea.vue';
import Hero from '@/Scripts/components/molecules/Hero.vue';
import EventProgramBlock from '@/Scripts/components/blocks/EventProgramBlock.vue';
import SpeakerList from '@/Scripts/components/molecules/SpeakerList.vue';
import Footer from '@/Scripts/components/organisms/Footer.vue';
import Header from '@/Scripts/components/organisms/Header.vue';
import { mapState } from 'vuex';

export default {
    props: ['model'],
    components: {
        Hero,
        EventProgramBlock,
        SpeakerList,
        ContentArea,
        Header,
        Footer
    },
    computed: {
        ...mapState({
            isLanguageSelectorOpen: state => state.appContext.isLanguageSelectorOpen,
            isMenuOpen: state => state.appContext.isMenuOpen,
            isBodyScrollable: state => state.appContext.isBodyScrollable
        }),
        currentLanguage: function () {
            if (this.model.header && this.model.header.languageLinks) {
                return this.model.header.languageLinks.find(x => x.isCurrentLanguage);
            }
            return {
                name: ''
            };
        },
        showHeaderFooter: function () {
            return this.model.showHeaderFooter;
        },
        searchPage: function () {
            return this.model.header ? this.model.header.searchPage : '/';
        },
        loginPage: function () {
            return this.model.header ? this.model.header.loginPage : '/';
        },
        isStartPage: function () {
            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'StartPage') {
                return true;
            }
            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'EbookPage') {
                return true;
            }
            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'EventPage' && (this.model.heroImage && this.model.heroImage.url)) {
                return true;
            }
            if (this.model.contentType.length > 1 && this.model.contentType[1] === 'CustomCampaignPage' && (this.model.heroImage && this.model.heroImage.url)) {
                return true;
            }

            return false;
        },
    }
};
</script>

<style lang="scss">
.event-page{
  overflow: hidden;
}
.event-blocks {
  /* Add your styles for the event blocks here */
  text-align: left;
}

.speaker-list {
  /* Add your styles for the speaker list here */
  text-emphasis: none;
}
.c-hero {
  height: 70vh;
}
</style>
