var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-article-page" },
    [
      _c(
        "Container",
        [
          _c(
            "Row",
            [
              _c(
                "Column",
                { attrs: { md: 12, offsetMd: 4, sm: 14, offsetSm: 2 } },
                [
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Label",
                          expression: "'Label'",
                        },
                      ],
                      attrs: { isCategory: "" },
                    },
                    [_vm._v(_vm._s(_vm.model.label))]
                  ),
                  _vm._v(" "),
                  _c(
                    "Heading",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "Title",
                          expression: "'Title'",
                        },
                      ],
                      staticClass: "c-article-page__title",
                      attrs: { noMarginBottom: "", noMarginTop: "", level: 2 },
                    },
                    [_vm._v(_vm._s(_vm.model.title))]
                  ),
                  _vm._v(" "),
                  _vm.model.siteSettings != null
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "HideDate",
                              expression: "'HideDate'",
                            },
                          ],
                        },
                        [
                          !_vm.model.hideDate
                            ? _c("Datetime", {
                                staticClass: "c-article-page__date",
                                attrs: {
                                  value: _vm.model.startPublish,
                                  useUsDateFormat:
                                    _vm.model.siteSettings.useUsDateFormat,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            [
              _c(
                "Column",
                { attrs: { md: 4, sm: 20, offsetSm: 2, offsetMd: 0 } },
                [
                  _vm.model.categories || _vm.isEditable
                    ? _c(
                        "Row",
                        {
                          directives: [
                            {
                              name: "epi-edit",
                              rawName: "v-epi-edit",
                              value: "Categories",
                              expression: "'Categories'",
                            },
                          ],
                          staticClass: "c-article-page__categories",
                        },
                        [
                          _c(
                            "Column",
                            [
                              _c(
                                "Heading",
                                {
                                  directives: [
                                    {
                                      name: "epi-edit",
                                      rawName: "v-epi-edit",
                                      value: "Label",
                                      expression: "'Label'",
                                    },
                                  ],
                                  attrs: { isCategory: "" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getLabel("articlePage", "category")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.model.categories, function (item) {
                            return _c(
                              "Column",
                              { key: item.id, attrs: { xs: 12, md: 24 } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.notEmptyObject(_vm.model.author) || _vm.isEditable
                    ? _c(
                        "Author",
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "Author",
                                expression: "'Author'",
                              },
                            ],
                            staticClass:
                              "c-article-page__author@md u-hidden-sm u-hidden-xs",
                          },
                          "Author",
                          _vm.model.author,
                          false
                        )
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.additionalAuthors || _vm.isEditable
                    ? _c("ContentArea", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "Blocks",
                            expression: "'Blocks'",
                          },
                        ],
                        staticClass:
                          "c-article-page__content-area c-article-page__author@md u-hidden-sm u-hidden-xs",
                        attrs: {
                          model: _vm.model.additionalAuthors || _vm.isEditable,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("SocialMediaShareBlock", {
                    staticClass: "u-hidden-sm u-hidden-xs",
                    attrs: { noBorder: "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { attrs: { md: 12, sm: 20, offsetSm: 2, offsetMd: 0 } },
                [
                  _c(
                    "main",
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Column",
                            [
                              _c("XhtmlField", {
                                directives: [
                                  {
                                    name: "epi-edit",
                                    rawName: "v-epi-edit",
                                    value: "IntroText",
                                    expression: "'IntroText'",
                                  },
                                ],
                                staticClass: "o-ingress",
                                attrs: { items: _vm.model.introText },
                              }),
                              _vm._v(" "),
                              _vm.notEmptyObject(_vm.model.media.content) ||
                              _vm.isEditable
                                ? _c(
                                    "MediaBlock",
                                    _vm._b(
                                      {
                                        directives: [
                                          {
                                            name: "epi-edit",
                                            rawName: "v-epi-edit",
                                            value: "Media",
                                            expression: "'Media'",
                                          },
                                        ],
                                      },
                                      "MediaBlock",
                                      _vm.model.media.content,
                                      false
                                    )
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("XhtmlField", {
                                directives: [
                                  {
                                    name: "epi-edit",
                                    rawName: "v-epi-edit",
                                    value: "MainBody",
                                    expression: "'MainBody'",
                                  },
                                ],
                                staticClass: "c-article-page__main-body",
                                attrs: { items: _vm.model.mainBody },
                              }),
                              _vm._v(" "),
                              _vm.model.blocks || _vm.isEditable
                                ? _c("ContentArea", {
                                    directives: [
                                      {
                                        name: "epi-edit",
                                        rawName: "v-epi-edit",
                                        value: "Blocks",
                                        expression: "'Blocks'",
                                      },
                                    ],
                                    staticClass: "c-article-page__content-area",
                                    attrs: {
                                      model: _vm.model.blocks || _vm.isEditable,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "Author",
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "epi-edit",
                                        rawName: "v-epi-edit",
                                        value: "Author",
                                        expression: "'Author'",
                                      },
                                    ],
                                    staticClass:
                                      "c-article-page__author u-hidden-md u-hidden-lg",
                                  },
                                  "Author",
                                  _vm.model.author,
                                  false
                                )
                              ),
                              _vm._v(" "),
                              _vm.model.additionalAuthors || _vm.isEditable
                                ? _c("ContentArea", {
                                    directives: [
                                      {
                                        name: "epi-edit",
                                        rawName: "v-epi-edit",
                                        value: "Blocks",
                                        expression: "'Blocks'",
                                      },
                                    ],
                                    staticClass:
                                      "c-article-page__content-area c-article-page__author u-hidden-md u-hidden-lg",
                                    attrs: {
                                      model:
                                        _vm.model.additionalAuthors ||
                                        _vm.isEditable,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("SocialMediaShareBlock", {
                                staticClass: "c-article-page__share-block",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.notEmptyObject(
                _vm.model.newsBlockRight &&
                  _vm.model.newsBlockRight.content &&
                  _vm.model.newsBlockRight.content.teasers
              ) || _vm.isEditable
                ? _c(
                    "Column",
                    {
                      staticClass: "u-hidden-xs u-hidden-sm",
                      attrs: { md: 6, offsetMd: 2 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "c-article-page__right-content" },
                        [
                          _c("Heading", { attrs: { isCategory: "" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.model.newsBlockRight.content.label
                                  ? _vm.model.newsBlockRight.content.label
                                  : _vm.getLabel("articlePage", "otherNews")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("NewsComponentList", {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "newsBlockRight",
                                expression: "'newsBlockRight'",
                              },
                            ],
                            staticClass: "c-article-page__news-block",
                            attrs: {
                              isVertical: "",
                              flip: "",
                              items:
                                _vm.model.newsBlockRight &&
                                _vm.model.newsBlockRight.content &&
                                _vm.model.newsBlockRight.content.teasers,
                              showArrow: false,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Row",
            [
              _c(
                "Column",
                { staticClass: "u-display-xs" },
                [
                  _vm.notEmptyObject(
                    _vm.model.pageListBottom &&
                      _vm.model.pageListBottom.content &&
                      _vm.model.pageListBottom.content.teasers
                  ) || _vm.isEditable
                    ? _c("NewsComponentList", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "pageListBottom",
                            expression: "'pageListBottom'",
                          },
                        ],
                        staticClass: "c-article-page__news-block",
                        attrs: {
                          flip: "",
                          isVertical: "",
                          items:
                            _vm.model.pageListBottom.content &&
                            _vm.model.pageListBottom.content.teasers,
                          showArrow: false,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Column",
                { staticClass: "u-hidden-xs" },
                [
                  _vm.notEmptyObject(
                    _vm.model.pageListBottom &&
                      _vm.model.pageListBottom.content &&
                      _vm.model.pageListBottom.content.teasers
                  ) || _vm.isEditable
                    ? _c("NewsComponentList", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "pageListBottom",
                            expression: "'pageListBottom'",
                          },
                        ],
                        staticClass: "c-article-page__news-block",
                        attrs: {
                          sm: 12,
                          md: 6,
                          items:
                            _vm.model.pageListBottom.content &&
                            _vm.model.pageListBottom.content.teasers,
                          showArrow: false,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }