<template>
    <div>
        <Heading :level="3" v-if="title">{{ title }}</Heading>
        <XhtmlField :items="text" v-if="text"/>
    </div>

</template>

<script>
function jiraHelpdesk(callback) {
    var jhdScript = document.createElement('script');
    jhdScript.type = 'text/javascript';
    jhdScript.setAttribute('data-jsd-embedded', null);
    jhdScript.setAttribute('data-key', 'c7929108-89ba-4b19-ac2f-9596a28549da');
    jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
    if (jhdScript.readyState) { // old IE support
        jhdScript.onreadystatechange = function () {
            if (jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete') {
                jhdScript.onreadystatechange = null;
                callback();
            }
        };
    } else { //modern browsers
        jhdScript.onload = function () {
            callback();
        };
    }
    document.head.appendChild(jhdScript);
}
export default {
    props: {
        text: String,
        title: String
    },
    mounted() {
        jiraHelpdesk(() => {
            var DOMContentLoaded_event = document.createEvent('Event');
            DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
            window.document.dispatchEvent(DOMContentLoaded_event);
        });
    }
};
</script>
