<template>
  <div class="event-program container">
    <EventBlock v-for="(event,index) in model" :key="index" :model="event" />
  </div>
</template>

<script>
import EventBlock from '@/Scripts/components/blocks/EventBlock.vue';
export default {
    props: ['model'],
    components: {
        EventBlock
    },
};
</script>

<style lang="scss">
.event-program {
  /* Add your styles for the event program here */
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding-top:3rem;
  padding-bottom:3rem;
  @include media-md {
    flex-direction: row;
  }
}
</style>
