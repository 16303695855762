var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "c-start-page" },
    [
      _vm.model.heroImage && _vm.model.heroImage.url
        ? _c("Hero", {
            attrs: {
              buttonText: _vm.model.heroLinkText,
              buttonText2: _vm.model.heroLink2Text,
              title: _vm.model.title,
              subtitle: _vm.model.subtitle,
              heroImage: _vm.model.heroImage && _vm.model.heroImage.url,
              url: _vm.model.heroLink && _vm.model.heroLink.url,
              url2: _vm.model.heroLink2 && _vm.model.heroLink2.url,
              changeTitleColor: _vm.model.changeTitleColor,
              changeLinkColor: _vm.model.changeLinkColor,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.model.keyFigures &&
          _vm.notEmptyObject(_vm.model.keyFigures.content)
            ? _c("KeyFigures", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "KeyFigures",
                    expression: "'KeyFigures'",
                  },
                ],
                staticClass: "c-start-page__key-figures",
                attrs: { model: _vm.model.keyFigures },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h2", { staticClass: "u-add-colored-period" }, [
            _vm._v(_vm._s(_vm.getLabel("startPage", "whatWeDo"))),
          ]),
          _vm._v(" "),
          _c(
            "Row",
            [
              (_vm.model.leftPageList && _vm.model.leftPageList.content) ||
              _vm.isEditable
                ? _c(
                    "Column",
                    {
                      directives: [
                        {
                          name: "epi-edit",
                          rawName: "v-epi-edit",
                          value: "leftPageList",
                          expression: "'leftPageList'",
                        },
                      ],
                      attrs: { md: 14 },
                    },
                    [
                      _c(
                        "PageListBlock",
                        _vm._b(
                          {},
                          "PageListBlock",
                          _vm.model.leftPageList &&
                            _vm.model.leftPageList.content,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "Column",
                {
                  staticClass: "c-start-page__right-content",
                  attrs: { md: 6, offsetMd: 4 },
                },
                [
                  _c(
                    "PageListBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "RightProductList",
                            expression: "'RightProductList'",
                          },
                        ],
                        staticClass: "c-start-page__product-list",
                      },
                      "PageListBlock",
                      _vm.model.rightProductList &&
                        _vm.model.rightProductList.content,
                      false
                    )
                  ),
                  _vm._v(" "),
                  _c(
                    "PageListBlock",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "SecondRightProductList",
                            expression: "'SecondRightProductList'",
                          },
                        ],
                        staticClass: "c-start-page__product-list",
                      },
                      "PageListBlock",
                      _vm.model.secondRightProductList &&
                        _vm.model.secondRightProductList.content,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          (_vm.model.rightCampaign &&
            _vm.notEmptyObject(_vm.model.rightCampaign.content)) ||
          _vm.isEditable
            ? _c(
                "SingleTeaserBlock",
                _vm._b(
                  {
                    attrs: {
                      leftEdit: "leftCampaign",
                      rightEdit: "rightCampaign",
                      extraTeaser:
                        _vm.model.leftCampaign &&
                        _vm.model.leftCampaign.content,
                    },
                  },
                  "SingleTeaserBlock",
                  _vm.model.rightCampaign && _vm.model.rightCampaign.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(
            _vm.model.fullWidthHero && _vm.model.fullWidthHero.content
          )
            ? _c(
                "HeroBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "FullWidthHero",
                        expression: "'FullWidthHero'",
                      },
                    ],
                  },
                  "HeroBlock",
                  _vm.model.fullWidthHero && _vm.model.fullWidthHero.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.notEmptyObject(_vm.model.newsBlock.content)
            ? _c(
                "NewsBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "NewsBlock",
                        expression: "'NewsBlock'",
                      },
                      {
                        name: "spacing",
                        rawName: "v-spacing",
                        value: {
                          xs: "small",
                          sm: "medium",
                          md: "large",
                          lg: "large",
                        },
                        expression:
                          "{xs: 'small', sm: 'medium', md: 'large', lg: 'large'}",
                      },
                    ],
                  },
                  "NewsBlock",
                  _vm.model.newsBlock.content,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.showGartnerWidget
            ? _c("div", [_c("GartnerWidgetBlock")], 1)
            : _vm._e(),
          _vm._v(" "),
          _c(
            "PageListBlock",
            _vm._b(
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "PageSlider",
                    expression: "'PageSlider'",
                  },
                ],
                staticClass: "c-start-page__slider",
                style: {
                  "padding-top": _vm.model.showGartnerWidget ? "32px" : "0px",
                },
              },
              "PageListBlock",
              _vm.model.pageSlider && _vm.model.pageSlider.content,
              false
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      (!_vm.model.hidePartners &&
        _vm.model.partnersBlock &&
        _vm.notEmptyObject(_vm.model.partnersBlock.content)) ||
      _vm.isEditable
        ? _c(
            "Container",
            {
              staticClass: "c-start-page__partners",
              attrs: { isFluid: "", background: "beige" },
            },
            [
              _c(
                "Container",
                [
                  _c(
                    "Row",
                    [
                      _c(
                        "Column",
                        { attrs: { offsetMd: 2 } },
                        [
                          _c(
                            "PartnersBlock",
                            _vm._b(
                              {
                                directives: [
                                  {
                                    name: "epi-edit",
                                    rawName: "v-epi-edit",
                                    value: "PartnersBlock",
                                    expression: "'PartnersBlock'",
                                  },
                                ],
                              },
                              "PartnersBlock",
                              _vm.model.partnersBlock.content,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.model.bottomRightCampaign &&
        _vm.notEmptyObject(_vm.model.bottomRightCampaign.content)) ||
      _vm.isEditable
        ? _c(
            "Container",
            [
              _c(
                "SingleTeaserBlock",
                _vm._b(
                  {
                    staticClass: "c-start-page__bottom-campaign",
                    attrs: {
                      leftEdit: "bottomLeftCampaign",
                      rightEdit: "bottomRightCampaign",
                      extraTeaser:
                        _vm.model.bottomLeftCampaign &&
                        _vm.model.bottomLeftCampaign.content,
                    },
                  },
                  "SingleTeaserBlock",
                  _vm.model.bottomRightCampaign &&
                    _vm.model.bottomRightCampaign.content,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Container",
        [
          _vm.notEmptyObject(_vm.model.officesBlock.content)
            ? _c(
                "OfficesBlock",
                _vm._b(
                  {
                    directives: [
                      {
                        name: "epi-edit",
                        rawName: "v-epi-edit",
                        value: "OfficesBlock",
                        expression: "'OfficesBlock'",
                      },
                    ],
                  },
                  "OfficesBlock",
                  _vm.model.officesBlock.content,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }