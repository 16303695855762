var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-trading-activity" }, [
    _c(
      "div",
      { staticClass: "c-trading-activity-order-depth" },
      [
        _c(
          "div",
          { staticClass: "c-trading-activity-heading" },
          [
            _c("Heading", { attrs: { level: 5 } }, [
              _vm._v(_vm._s("Order Depth ")),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("OrderItems", { attrs: { orders: _vm.orders } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-trading-activity-latest-trades" },
      [
        _c(
          "div",
          { staticClass: "c-trading-activity-heading" },
          [
            _c("Heading", { attrs: { level: 5 } }, [
              _vm._v(_vm._s("Latest Trades ")),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("LatestTrades", {
          attrs: {
            trades: _vm.latestTrades,
            seeAllLabel: _vm.seeAllLabel,
            seeAllUrl: _vm.seeAllUrl,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }