var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-speaker-list-block" },
    [
      _c("h2", { staticClass: "c-speaker-list__heading" }, [
        _vm._v(_vm._s(_vm.heading)),
      ]),
      _vm._v(" "),
      _c(
        "Row",
        { staticClass: "c-speaker-list" },
        _vm._l(_vm.speakers, function (speaker, index) {
          return _c(
            "Column",
            {
              key: `speaker_${_vm._uid}_${index}`,
              staticClass: "c-speaker-list__item",
              attrs: { sm: 12, md: 6 },
            },
            [_c("Speaker", _vm._b({}, "Speaker", speaker, false))],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }