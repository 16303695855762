<template>
  <div class="c-hero-block">
    <Row class="c-hero-block__background">
        <BreakoutColumn :sm="10" :md="10" class="c-hero-block__col1" breakoutSide="left">
                <ResponsiveImage :src="image" v-bind="imageConfig"/>
        </BreakoutColumn>
        <Column
            :sm="12"
            :md="10"
            :offsetMd="2"
            :offsetSm="2"
            class="c-hero-block__col2"
            align="center"
        >
            <Heading class="c-hero-block__category" isCategory>{{label}}</Heading>
            <Heading class="u-add-colored-period u-no-margins" :level="2">{{title}}</Heading>
            <XhtmlField :items="introText" class="o-large-body" />
            <div class="c-hero-block__links">
                <Anchor v-if="linkOne" :href="linkOne" isFancy>{{linkOneText}}</Anchor>
                <Anchor v-if="linkTwo" :href="linkTwo" isFancy>{{linkTwoText}}</Anchor>
            </div>
      </Column>
    </Row>
  </div>
</template>

<script>
import Heading from '@/Scripts/components/atoms/Heading.vue';
import Anchor from '@/Scripts/components/atoms/Anchor.vue';

import BreakoutColumn from '@/Scripts/components/grid/BreakoutColumn.vue';

export default {
    props: ['introText', 'label', 'linkOne', 'linkTwo', 'linkOneText', 'linkTwoText', 'title', 'image'],
    components: {
        Heading,
        Anchor,
        BreakoutColumn
    },
    data() {
        return {
            imageConfig: {
                xs: {w: 355, h: 321},
                sm: {w: 309, h: 388},
                md: {w: 715, h: 684},
                lg: {w: 715, h: 684}
            }
        };
    }
};
</script>

<style lang="scss">
@import "@/Styles/tools/_breakpoints.scss";

.c-hero-block {
  color: $white;

  &__links {
    margin-top: 1em;

    @include media-md {
        margin-top: 3em;
    }
    a {
      color: $white;
      margin-right: 2em;

      &:hover {
        color: $white;
      }
    }
  }
  &__category {
    color: $white;
  }
  &__col1 {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 1em;

    @include media-sm {
      margin-bottom: 0;
    }

    .o-col--breakout__content {
        @include media-md {
            max-width: 713px;
        }
    }
  }
  &__col1,
  &__col2 {
        @include media-sm {
            padding-top: 4rem;
        }
    @include media-md {
      padding-top: 7rem;
    }
  }
  &__background {
    z-index: 1;
    position: relative;
    background-color: $darkgreen;

    &:before {
      content: "";
      background: $darkgreen;
      position: absolute;
      width: 400%;
      top: 0px;
      height: 100%;
      right: -200%;
      z-index: -1;
    }
  }
  &__col2 {
    flex-direction: column;
    z-index: 1;
    padding-bottom: 3rem;
  }
}
</style>
