var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-support-centers-block" },
    [
      _c(
        "Column",
        { attrs: { md: 9 } },
        [
          _c(
            "Heading",
            { staticClass: "u-no-margin-top", attrs: { level: 3 } },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "c-support-centers-block__intro" }, [
            _vm._v(_vm._s(_vm.intro)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Column",
        [
          _c(
            "Row",
            { staticClass: "o-flat-list", attrs: { wrapper: "ul" } },
            _vm._l(_vm.supportCenters, function (region, index) {
              return _c(
                "Column",
                {
                  key: region.regionName + index,
                  attrs: {
                    wrapper: "li",
                    md: 10,
                    offsetMd: (index + 1) % 2 === 0 && 4,
                  },
                },
                [
                  _c("Collapse", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _vm._v(
                                "\r\n                                " +
                                  _vm._s(region.regionName) +
                                  "\r\n                            "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "content",
                          fn: function () {
                            return _vm._l(
                              region.supportCenters,
                              function (center, index) {
                                return _c(
                                  "div",
                                  {
                                    key: center.name + index,
                                    staticClass:
                                      "c-support-centers-block__center",
                                  },
                                  [
                                    _c("p", { staticClass: "o-small-body" }, [
                                      _c(
                                        "span",
                                        { staticClass: "u-block o-large-body" },
                                        [_vm._v(_vm._s(center.name))]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "u-block" }, [
                                        _vm._v(
                                          "\r\n                                            " +
                                            _vm._s(
                                              _vm.getLabel(
                                                "supportCentersBlock",
                                                "supportHours"
                                              )
                                            ) +
                                            ": "
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(center.supportHours)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      center.breakFixHours
                                        ? _c(
                                            "span",
                                            { staticClass: "u-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabel(
                                                    "supportCentersBlock",
                                                    "breakFixHours"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(center.breakFixHours)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      center.email
                                        ? _c(
                                            "span",
                                            { staticClass: "u-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabel(
                                                    "supportCentersBlock",
                                                    "email"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(center.email)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      center.phoneNumber
                                        ? _c(
                                            "span",
                                            { staticClass: "u-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabel(
                                                    "supportCentersBlock",
                                                    "telephone"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(center.phoneNumber)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      center.languageName
                                        ? _c(
                                            "span",
                                            { staticClass: "u-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getLabel(
                                                    "supportCentersBlock",
                                                    "language"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(center.languageName)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }
                            )
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }