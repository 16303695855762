var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "c-page-list-slider" },
    [
      _vm.items.length > 3 && _vm.$screen.breakpoint !== "xs"
        ? _c(
            "BreakoutColumn",
            { attrs: { breakoutSide: "right" } },
            [
              _c("HorizontalSlider", {
                attrs: { items: _vm.items, scroll: 2 },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "PageListSliderItem",
                            _vm._b({}, "PageListSliderItem", item, false)
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3095211854
                ),
              }),
            ],
            1
          )
        : _vm.$screen.breakpoint !== "xs"
        ? _vm._l(_vm.items, function (item, index) {
            return _c(
              "Column",
              {
                key: `${_vm._uid}${index}`,
                staticClass: "c-page-list-slider__noslide-item",
                attrs: { sm: 12, md: 8 },
              },
              [
                _c(
                  "PageListSliderItem",
                  _vm._b({}, "PageListSliderItem", item, false)
                ),
              ],
              1
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$screen.breakpoint === "xs"
        ? _c("NewsComponentList", {
            attrs: { isVertical: "", items: _vm.items },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }