<!--
    Language selector that can be shown on any page.
-->

<template>
    <ul class="lang">
        <li v-for="item in availableLanguages" :key="item.name" :class="{ active: isCurrentLanguage(item) }">
            <epi-view-mode-link :url="item.link">{{item.displayName}}</epi-view-mode-link>
        </li>
    </ul>
</template>

<script>
import EpiViewModeLink from '@/Scripts/components/widgets/EpiViewModeLink.vue';

export default {
    props: ['existingLanguages', 'language'],
    methods: {
        isCurrentLanguage(item) {
            return item.name === this.language.name;
        }
    },
    computed: {
        availableLanguages: function () {
            return this.existingLanguages.filter(i => i.visibleInMenu);
        }
    },
    components: {
        EpiViewModeLink
    }
};
</script>
